import React, { type HTMLAttributes, useMemo } from "react";

import cx from "classnames";

import styles from "./ChipNumber.module.scss";

import { type Themes, type decorationColors } from "../commonProps";

export interface ChipNumberProps extends HTMLAttributes<HTMLDivElement> {
  readonly value: number;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly color?: (typeof decorationColors)[number];
}

function ChipNumber({ value, darkMode = false, theme, color = "Turquoise" }: ChipNumberProps) {
  const renderedValue = useMemo(() => {
    if (value > 99) {
      return "99+";
    }
    return value.toString();
  }, [value]);
  return (
    <div
      role="number-chip"
      className={cx(styles.chipNumber, styles[theme], styles[color], {
        [styles.darkMode]: darkMode,
      })}
    >
      <span role="rendered-value">{renderedValue}</span>
    </div>
  );
}
export { ChipNumber };
