import { DateTime, Duration, type DurationObjectUnits } from "luxon";

export type ISODurationString = string;

export type { DurationObjectUnits };

function parseStringToObject(isoDuration: ISODurationString): DurationObjectUnits {
  return Duration.fromISO(isoDuration).toObject();
}

function encodeObjectToString(duration: DurationObjectUnits): ISODurationString {
  return Duration.fromObject(duration).toISO();
}

function fromIsoToHumain(isoDuration: string): string {
  return Duration.fromISO(isoDuration).toHuman();
}

function fromIsoToHour(isoDuration: string): number {
  return Duration.fromISO(isoDuration).as("hours");
}

function isoStringToLocaleString(duration: ISODurationString, locale: string): string {
  return DateTime.fromISO(duration).toLocaleString({}, { locale });
}

function addDurationString(date: DateTime, duration: ISODurationString): DateTime {
  return date.plus(parseStringToObject(duration));
}

function substractDurationString(date: DateTime, duration: ISODurationString): DateTime {
  return date.minus(parseStringToObject(duration));
}

// For based 60 time
const roundTo15 = (minutes: number) => {
  const Min = (Math.round(minutes / 15) * 15) % 60;
  return { Min, addAnHour: Min == 0 && minutes % 60 > 45 };
};

const formatDurationAsHours = (duration: string | null) => {
  const value = "Sur demande";
  if (duration == null) return value;

  const durationAsMinutes = Duration.fromISO(duration).as("minutes");
  if (durationAsMinutes == 0 || isNaN(durationAsMinutes)) return value;

  let Hrs = Math.floor(durationAsMinutes / 60);
  const { Min, addAnHour } = roundTo15(durationAsMinutes);

  if (addAnHour) Hrs++;

  const strHrs = Hrs != 0 ? `${Hrs} heure${Hrs > 1 ? "s" : ""}` : "";
  const strMin = Min != 0 ? `${Hrs == 0 ? "" : " "}${Min} minute${Min > 1 ? "s" : ""}` : "";
  return strHrs + strMin;
};

const formatDurationAsSeconds = (duration: string | null) => {
  if (!duration) return null;
  const value = Duration.fromISO(duration);
  if (!value.isValid) return null;
  return value.as("seconds");
};

// For based 100 time
const roundTo25 = (number: number) => (Math.round(number * 4) / 4).toFixed(2);

const timeToDecimal = (hours: number, minutes: number) => {
  const dec = Number((minutes / 6) * 10);
  return parseFloat(hours + "." + (Number(dec < 10) ? "0" : "") + dec);
};

const formatDurationAsQuantity = (duration: string | null) => {
  const value = 0;
  if (duration == null) return value;

  const durationAsHours = Duration.fromISO(duration).as("hours");
  const durationAsMinutes = Duration.fromISO(duration).as("minutes");
  if (isNaN(durationAsHours) || isNaN(durationAsMinutes)) return value;

  const decimalTime = timeToDecimal(durationAsHours, durationAsMinutes % 60);
  return Number(roundTo25(decimalTime));
};

const startOfTomorrow = (fromDate = DateTime.now()) => {
  return fromDate.plus({ days: 1 }).startOf("day");
};

const startOfMonth = (fromDate = DateTime.now()) => {
  return fromDate.startOf("month");
};

export default {
  formatDurationAsHours,
  formatDurationAsQuantity,
  formatDurationAsSeconds,
  parseStringToObject,
  encodeObjectToString,
  isoStringToLocaleString,
  fromIsoToHumain,
  fromIsoToHour,
  addDurationString,
  substractDurationString,
  startOfMonth,
  startOfTomorrow,
};
