import { colors } from "../../assets/colors";

const modalities = {
  blended: {
    label: "Blended Learning",
    color: colors.secondaryBlue,
  },
  elearning: {
    label: "E-Learning",
    color: colors.secondaryBlue,
  },
  individual: {
    label: "Individuel",
    color: colors.modalityDigitalLearning,
  },
  mooc: {
    label: "MOOC",
    color: colors.secondaryBlue,
  },
  presential: {
    label: "Présentiel",
    color: colors.modalityDigitalLearning,
  },
  distancial: {
    label: "Distanciel",
    color: colors.modalityDigitalLearning,
  },
  "presential-with-distancial": {
    label: "Présentiel ou à distance",
    color: colors.modalityDigitalLearning,
  },
  digital: {
    label: "Digital",
    color: colors.secondaryBlue,
  },
  "virtual-classroom": {
    label: "Classe virtuelle",
    color: colors.secondaryBlue,
  },
  webinar: {
    label: "Webinar",
    color: colors.secondaryBlue,
  },
  conference: {
    label: "Conférence",
    color: colors.modalityDigitalLearning,
  },
};

export { modalities };
