import React, { type CSSProperties } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { Star, HalfStar } from "../Icon";
import { colors } from "../../assets/colors";
import styles from "./Rating.module.scss";

export interface IProps {
  readonly className?: string;
  readonly value?: number;
  readonly max?: number;
  readonly style?: CSSProperties;
  readonly disabled?: boolean;
}

const Rating = (props: IProps): JSX.Element => {
  const { className, value = 0, max = 5, style, disabled } = props;
  const score = (value / max) * 5;

  const renderStar = (_v: unknown, index: number): JSX.Element => {
    const props = {
      key: index,
      size: "1.25rem",
      className: styles.star,
    };

    if (score >= index + 1) return <Star {...props} color={colors.primaryYellow} />;

    if (score > index)
      return (
        <HalfStar
          {...props}
          backgroundColor={colors.avatarBackground}
          color={colors.primaryYellow}
        />
      );

    return <Star {...props} color={colors.avatarBackground} />;
  };

  return (
    <Flex
      className={cx(styles.Rating, className)}
      style={style}
      data-score={score}
      disabled={disabled}
      role="container"
    >
      {Array.from({ length: 5 }).map(renderStar)}
    </Flex>
  );
};

export { Rating };
