import React, { useContext } from "react";

import Autocomplete, {
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";

import { BaseInput, type BaseInputProps } from "components/Form/Inputs/BaseInput";
import { ArrowDownKeyboard, ArrowUpKeyboard } from "../MaterialIcons";
import { Button } from "components/Button";
import { type Themes } from "components/commonProps";
import { DesignSystemContext, withTheme } from "components/DesignSystemContext";

type BaseAutocompleteProps<Option> = AutocompleteProps<Option, false, false, false>;
type BasicAutocompleteProps<Option> = {
  renderInput?: BaseAutocompleteProps<Option>["renderInput"];
  theme: Themes;
} & Pick<
  BaseAutocompleteProps<Option>,
  | "autoHighlight"
  | "autoSelect"
  | "clearOnEscape"
  | "disableClearable"
  | "disableCloseOnSelect"
  | "disableListWrap"
  | "disablePortal"
  | "getOptionLabel"
  | "groupBy"
  | "options"
  | "onChange"
  | "placeholder"
  | "renderOption"
  | "openOnFocus"
  | "filterOptions"
  | "defaultValue"
>;

export function Basic<Option>(props: BasicAutocompleteProps<Option>) {
  const { renderInput, ...restProps } = props;
  return <Autocomplete {...restProps} renderInput={renderInput ?? defaultRenderInput} />;
}

export function AutocompleteBasic<T>(props: Omit<BasicAutocompleteProps<T>, "theme">) {
  const context = useContext(DesignSystemContext);
  const inputProps = {
    ...props,
    theme: context.theme,
  };

  return <Basic<T> {...inputProps} />;
}

function defaultRenderInput(params: AutocompleteRenderInputParams) {
  return (
    <div ref={params.InputProps.ref}>
      <ThemedAutocompleteInput
        name="autocomplete"
        type="text"
        onChange={params.inputProps.onChange}
        onFocus={params.inputProps.onFocus}
        onBlur={params.inputProps.onBlur}
        onMouseDown={params.inputProps.onMouseDown}
        placeholder={params.inputProps.placeholder}
        onClick={params.inputProps.onClick}
        ref={params.inputProps.ref}
        value={params.inputProps.value?.toString() ?? ""}
        actionButton={
          <Button
            icon={params.inputProps["aria-expanded"] ? <ArrowUpKeyboard /> : <ArrowDownKeyboard />}
            iconOnly
            buttonSize="S"
          />
        }
      />
    </div>
  );
}

const AutocompleteInput = React.forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
  return <BaseInput {...props} ref={ref} />;
});
const ThemedAutocompleteInput = withTheme<BaseInputProps>(AutocompleteInput, true);
