import { useEffect } from "react";
import { useSetState, useAsync } from "react-use";
import { isEmpty, isNil } from "lodash";

import request from "helpers/request";
import useHelpScout from "hooks/useHelpScout";
import useAuth from "hooks/useAuth";

import { AuthRoutes } from "api/types";

import Logo from "./Logo";
import styles from "./LoginViewStyles.module.scss";
import { AuthStrategyType } from "hooks/types";

interface Props {
  redirect?: string;
}

const LoginView = ({ redirect }: Props) => {
  const { setOrganizationHasBeenFetched, setAuthStrategy, authStrategy, setLogin, setLogo } =
    useAuth();
  const helpScout = useHelpScout();

  const [{ input, submit, hasError }, setState] = useSetState({
    input: "",
    submit: false,
    hasError: false,
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setState({ submit: true });
  };

  const {
    value: success,
    loading,
    error,
  } = useAsync(async (): Promise<boolean> => {
    if (submit) {
      const { data } = await request<AuthRoutes["POST"]["/v1/login"]["response"]>({
        target: "AUTH",
        data: { login: input, redirectUrl: redirect },
        method: "POST",
        url: "/login",
      });

      setOrganizationHasBeenFetched(true);
      setLogin(input);
      setAuthStrategy(data.authStrategy);
      setLogo(data.logo);
      return true;
    }
    return false;
  }, [submit]);

  useEffect(() => setState({ hasError: hasError || !isNil(error) }), [hasError, error, setState]);

  if (loading) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Veuillez patienter</h1>
          <p>Récupération des paramètres de connexion au service en cours.</p>
        </div>
      </div>
    );
  }

  if (success && authStrategy && authStrategy.type === AuthStrategyType.OTL) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>E-mail envoyé</h1>
          <p>
            Votre lien de connexion vous a été envoyé par e-mail. Pensez à vérifier vos courriers
            indésirables.
          </p>
          <button className={styles.link} onClick={helpScout.open}>
            Je n'ai pas reçu mon e-mail
          </button>
          {process.env.REACT_APP_ENV === "development" && (
            <>
              <br />
              <a
                className={styles.link}
                href={`http://${window.location.hostname}:1080`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ouvrir le mailcatcher
              </a>
            </>
          )}
          {!["development", "production"].includes(process.env.REACT_APP_ENV ?? "") && (
            <>
              <br />
              <a
                className={styles.link}
                href={process.env.REACT_APP_MAILCATCHER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ouvrir le mailcatcher
              </a>
            </>
          )}
        </div>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Erreur</h1>
          <p>
            Identifiant invalide, vérifiez votre saisie ou veuillez contacter votre service RH pour
            valider votre accès.
          </p>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="E-mail professionnel ou identifiant"
              inputMode="email"
              autoCapitalize="off"
              autoFocus
              onChange={({ currentTarget: { value } }) => setState({ input: value, submit: false })}
              defaultValue={input}
            />
            <div className={styles.actions}>
              <button type="submit" disabled={isEmpty(input) || submit === true}>
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Se connecter</h1>
        <p>Saisissez votre adresse e-mail professionnelle ou votre identifiant de connexion</p>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="E-mail professionnel ou identifiant"
            inputMode="email"
            autoCapitalize="off"
            autoFocus
            onChange={({ currentTarget: { value } }) => setState({ input: value })}
          />
          <div className={styles.actions}>
            <button type="submit" disabled={isEmpty(input)}>
              Suivant
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginView;
