import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ArrowBack = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M4.39995 10.9989C4.40584 10.8416 4.46773 10.6598 4.57082 10.5451L7.93552 6.8049C8.16353 6.59056 8.60073 6.50207 8.85556 6.7499C9.10657 6.99399 9.0991 7.46739 8.84904 7.71245L6.48323 10.3388H18.0691C18.4175 10.3388 18.7 10.6343 18.7 10.9989C18.7 11.3634 18.4175 11.6589 18.0691 11.6589H6.48323L8.84904 14.2853C9.06432 14.5109 9.10411 15.0009 8.85556 15.2478C8.60708 15.4947 8.15417 15.4158 7.93552 15.1928L4.57082 11.4526C4.45568 11.3246 4.39957 11.1745 4.39995 10.9989Z"
      fill={color}
    />
  </SVGBox>
);

export { ArrowBack };
