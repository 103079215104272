import React from "react";
import cx from "classnames";

import { useMediaQueries } from "../../../hooks/useMediaQueries";
import { Flex } from "../../Flex";

import styles from "../DSModal.module.scss";
import { DSModal } from "../";

export function ModalContentColumns({ children }: ModalContentColumnsProps) {
  const { isMobile } = useMediaQueries();
  const content = (
    <Flex className={cx(styles.contentColumns)} column={isMobile} id="modal-content-columns">
      {children}
    </Flex>
  );

  return isMobile ? wrapInScrollableLayer(content) : content;
}

function wrapInScrollableLayer(children: React.ReactNode) {
  return <DSModal.ScrollableLayer noPadding>{children}</DSModal.ScrollableLayer>;
}

export type ModalContentColumnsProps = {
  children: React.ReactElement<typeof DSModal.ScrollableLayer>[];
  debug?: boolean;
};

export type ModalContentColumnsType = React.ReactElement<typeof ModalContentColumns>;
