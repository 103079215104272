import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const EuroIcon = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 4C7.13425 4 4 7.13425 4 11C4 14.8658 7.13425 18 11 18C14.8658 18 18 14.8658 18 11C18 7.13425 14.8658 4 11 4ZM11.4375 9.6875C11.679 9.6875 11.875 9.8835 11.875 10.125C11.875 10.3665 11.679 10.5625 11.4375 10.5625H9.28675C9.26662 10.7051 9.25 10.8504 9.25 11C9.25 11.1496 9.26662 11.2949 9.28675 11.4375H11C11.2415 11.4375 11.4375 11.6335 11.4375 11.875C11.4375 12.1165 11.2415 12.3125 11 12.3125H9.5545C9.93337 13.093 10.6316 13.625 11.4375 13.625C11.819 13.625 12.1777 13.5121 12.5041 13.2899C12.7028 13.1551 12.9757 13.205 13.1114 13.4045C13.247 13.604 13.1962 13.8761 12.9959 14.0126C12.5286 14.3311 11.9896 14.5 11.4375 14.5C10.1556 14.5 9.05838 13.5926 8.6025 12.3125H7.9375C7.696 12.3125 7.5 12.1165 7.5 11.875C7.5 11.6335 7.696 11.4375 7.9375 11.4375H8.4135C8.39775 11.2923 8.375 11.1496 8.375 11C8.375 10.8504 8.39775 10.7077 8.4135 10.5625H7.9375C7.696 10.5625 7.5 10.3665 7.5 10.125C7.5 9.8835 7.696 9.6875 7.9375 9.6875H8.6025C9.05838 8.40737 10.1556 7.5 11.4375 7.5C11.9896 7.5 12.5286 7.66888 12.9959 7.98738C13.1962 8.12388 13.247 8.396 13.1114 8.5955C12.9757 8.79675 12.7019 8.84575 12.5041 8.71012C12.1777 8.48787 11.819 8.375 11.4375 8.375C10.6316 8.375 9.93337 8.907 9.5545 9.6875H11.4375Z"
      fill={color}
    />
  </SVGBox>
);

export { EuroIcon };
