import React, { useContext } from "react";

import { DesignSystemContext } from "components/DesignSystemContext";
import { Autocomplete, type AutocompletePropsWithoutTheme } from "./Autocomplete";

export function AutoComplete<T>(props: AutocompletePropsWithoutTheme<T>) {
  const context = useContext(DesignSystemContext);
  const inputProps = {
    ...props,
    theme: context.theme,
  };

  return <Autocomplete<T> {...inputProps} />;
}
