import React, {
  type CSSProperties,
  type ReactElement,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import {
  type Active,
  type Announcements,
  closestCenter,
  type CollisionDetection,
  DragOverlay,
  DndContext,
  type DropAnimation,
  KeyboardSensor,
  type KeyboardCoordinateGetter,
  type Modifiers,
  MouseSensor,
  type MeasuringConfiguration,
  type PointerActivationConstraint,
  type ScreenReaderInstructions,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
  MeasuringStrategy,
} from "@dnd-kit/core";

import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  type SortingStrategy,
  type AnimateLayoutChanges,
  type NewIndexGetter,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Item } from "./DSSortableItem";
import { List } from "./DSSortableList";
import { Wrapper } from "./DSSortableWrapper";
import { restrictToFirstScrollableAncestor, restrictToWindowEdges } from "@dnd-kit/modifiers";

interface DragItem {
  id: UniqueIdentifier;
  title: ReactNode | null;
  content?: ReactNode | null;
  disabled?: boolean | null;
  payload?: any;
  displayAfter?: ReactElement | null;
  displayBefore?: ReactElement | null;
  remove?: {
    disabled?: boolean;
    tooltip?: string;
  };
}

export interface DSSortableProps {
  removeAlwaysVisible?: boolean;
  activationConstraint?: PointerActivationConstraint;
  animateLayoutChanges?: AnimateLayoutChanges;
  adjustScale?: boolean;
  collisionDetection?: CollisionDetection;
  coordinateGetter?: KeyboardCoordinateGetter;
  Container?: any; // To-do: Fix me
  dropAnimation?: DropAnimation | null;
  getNewIndex?: NewIndexGetter;
  handle?: boolean;
  items?: DragItem[];
  measuring?: MeasuringConfiguration;
  modifiers?: Modifiers;
  renderItem?: any;
  removable?: boolean;
  reorderItems?: typeof arrayMove;
  strategy?: SortingStrategy;
  style?: CSSProperties;
  useDragOverlay?: boolean;
  locked?: boolean;
  getItemStyles?(args: {
    id: UniqueIdentifier;
    index: number;
    isSorting: boolean;
    isDragOverlay: boolean;
    overIndex: number;
    isDragging: boolean;
  }): CSSProperties;
  wrapperStyle?(args: {
    active: Pick<Active, "id"> | null;
    index: number;
    isDragging: boolean;
    id: UniqueIdentifier;
  }): CSSProperties;
  isDisabled?(id: UniqueIdentifier): boolean;
  onRemove?(id: string | number): void;
  dragContainer?: HTMLElement;
}

const dropAnimationConfig: DropAnimation = {
  duration: 0.5,
  easing: "ease",
};

const screenReaderInstructions: ScreenReaderInstructions = {
  draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `,
};

export function DSSortable({
  removeAlwaysVisible,
  activationConstraint,
  adjustScale = false,
  Container = List,
  collisionDetection = closestCenter,
  coordinateGetter = sortableKeyboardCoordinates,
  dropAnimation = dropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  handle = false,
  items = [],
  removable,
  renderItem,
  reorderItems = arrayMove,
  style,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
  onRemove = () => {},
  locked = false,
  dragContainer,
  strategy = verticalListSortingStrategy,
}: DSSortableProps) {
  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const measuring = { droppable: { strategy: MeasuringStrategy.Always } };
  const modifiers = [restrictToWindowEdges, restrictToFirstScrollableAncestor];

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: "Cypress" in window ? "auto" : undefined,
      coordinateGetter,
    })
  );
  const isFirstAnnouncement = useRef(true);
  const getIndex = (id: UniqueIdentifier) => items.findIndex((item) => item.id == id);
  const getPosition = (id: UniqueIdentifier) => getIndex(id) + 1;
  const activeIndex = activeId ? getIndex(activeId) : -1;
  const handleRemove = removable
    ? (id: UniqueIdentifier) => {
        onRemove(id);
      }
    : undefined;
  const announcements: Announcements = {
    onDragStart({ active: { id } }) {
      return `Picked up sortable item ${String(
        id
      )}. Sortable item ${id} is in position ${getPosition(id)} of ${items.length}`;
    },
    onDragOver({ active, over }) {
      // In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
      // The first `onDragOver` event therefore doesn't need to be announced, because it is called
      // immediately after the `onDragStart` announcement and is redundant.
      if (isFirstAnnouncement.current === true) {
        isFirstAnnouncement.current = false;
        return;
      }

      if (over) {
        return `Sortable item ${active.id} was moved into position ${getPosition(over.id)} of ${
          items.length
        }`;
      }

      return;
    },
    onDragEnd({ active, over }) {
      if (over) {
        return `Sortable item ${active.id} was dropped at position ${getPosition(over.id)} of ${
          items.length
        }`;
      }

      return;
    },
    onDragCancel({ active: { id } }) {
      return `Sorting was cancelled. Sortable item ${id} was dropped and returned to position ${getPosition(
        id
      )} of ${items.length}.`;
    },
  };

  useEffect(() => {
    if (!activeId) {
      isFirstAnnouncement.current = true;
    }
  }, [activeId]);

  return (
    <DndContext
      accessibility={{
        announcements,
        screenReaderInstructions,
      }}
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        setActiveId(null);

        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            reorderItems(items, activeIndex, overIndex);
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      measuring={measuring}
      modifiers={modifiers}
    >
      <Wrapper style={style} center>
        <SortableContext items={items} strategy={strategy}>
          <Container>
            {items.map((item, index) => (
              <>
                {!!item.displayBefore && item.displayBefore}
                <SortableItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  content={item.content}
                  handle={handle}
                  removeAlwaysVisible={removeAlwaysVisible}
                  remove={item.remove}
                  index={index}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  disabled={item.disabled || locked}
                  renderItem={renderItem}
                  onRemove={handleRemove}
                  animateLayoutChanges={animateLayoutChanges}
                  useDragOverlay={useDragOverlay}
                  getNewIndex={getNewIndex}
                />
                {!!item.displayAfter && item.displayAfter}
              </>
            ))}
          </Container>
        </SortableContext>
      </Wrapper>
      {useDragOverlay
        ? createPortal(
            <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
              {activeId ? (
                <Item
                  dragRef={null}
                  title={items[activeIndex].title}
                  content={items[activeIndex].content}
                  renderItem={renderItem}
                  wrapperStyle={wrapperStyle({
                    active: { id: activeId },
                    index: activeIndex,
                    isDragging: true,
                    id: items[activeIndex].id,
                  })}
                  style={getItemStyles({
                    id: items[activeIndex].id,
                    index: activeIndex,
                    isSorting: activeId !== null,
                    isDragging: true,
                    overIndex: -1,
                    isDragOverlay: true,
                  })}
                  dragOverlay
                />
              ) : null}
            </DragOverlay>,
            dragContainer ?? document.body
          )
        : null}
    </DndContext>
  );
}

interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  disabled?: boolean;
  getNewIndex?: NewIndexGetter;
  id: UniqueIdentifier;
  title: ReactNode;
  content: ReactNode;
  index: number;
  handle: boolean;
  useDragOverlay?: boolean;
  removeAlwaysVisible?: boolean;
  onRemove?(id: UniqueIdentifier): void;
  remove?: {
    disabled?: boolean;
    tooltip?: string;
  };
  style(values: any): CSSProperties;
  renderItem?(args: any): ReactElement;
  wrapperStyle: DSSortableProps["wrapperStyle"];
}

export function SortableItem({
  disabled,
  animateLayoutChanges,
  removeAlwaysVisible,
  getNewIndex,
  index,
  onRemove,
  remove,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
  id,
  title,
  content,
}: SortableItemProps) {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  });

  return (
    <Item
      ref={setNodeRef}
      title={title}
      content={content}
      disabled={disabled}
      dragging={isDragging}
      sorting={isSorting}
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
        ...style,
      })}
      onRemove={onRemove ? () => onRemove(id) : undefined}
      remove={remove}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      removeAlwaysVisible={removeAlwaysVisible}
      dragOverlay={!useDragOverlay && isDragging}
      {...attributes}
    />
  );
}
