import { DateTime } from "luxon";

const isSameDay = (date1: string, date2: string): boolean => {
  const date1StartOfDay = DateTime.fromISO(date1).startOf("day");
  const date2StartOfDay = DateTime.fromISO(date2).startOf("day");

  if (date1StartOfDay.isValid === false) {
    throw new Error("[isSameDate]: First date is not valid");
  }
  if (date2StartOfDay.isValid === false) {
    throw new Error("[isSameDate]: Second date is not valid");
  }

  return date1StartOfDay.diff(date2StartOfDay, "days").days === 0;
};

export { isSameDay };
