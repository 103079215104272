import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronUp = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M15.741 13.4845L11.2684 9.01193L6.78301 13.4845"
      stroke={color}
      strokeWidth="1.70872"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGBox>
);

export { ChevronUp };
