import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgDownload = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5555 14.3773C11.0908 13.8434 11.9583 13.843 12.4941 14.3774L14.67 16.5483V7.76655C14.67 7.01182 15.2836 6.39999 16.0408 6.39999C16.7978 6.39999 17.4116 7.01232 17.4116 7.76655V16.5733L19.6125 14.3774C20.1479 13.8434 21.0157 13.8433 21.5512 14.3773C22.0865 14.9112 22.0869 15.7764 21.5511 16.3108L16.7001 21.1496C16.3431 21.5057 15.7637 21.5059 15.4065 21.1496L10.5555 16.3108C10.0202 15.7768 10.02 14.9113 10.5555 14.3773ZM7.68717 23.0257C6.9763 23.0257 6.40002 23.602 6.40002 24.3129C6.40002 25.0237 6.9763 25.6 7.68717 25.6H24.4201C25.131 25.6 25.7073 25.0237 25.7073 24.3129C25.7073 23.602 25.131 23.0257 24.4201 23.0257H7.68717Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgDownload };
