// ToMigrate - Not updated with latest figma as of 16th April 2024

import React, { useMemo } from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";
import { Tooltip } from "../Tooltip";
import { Button } from "../Button/Button";

import styles from "./Alert.module.scss";
import {
  MdWarning,
  MdInfoOutline,
  MdInfo,
  MdDone,
  MdError,
  MdClose,
  MdHourglassTop,
} from "react-icons/md";

export interface AlertProps {
  readonly className?: string;
  readonly children?: React.ReactNode;
  readonly onClose?: () => void;
  readonly closeButton?: boolean;
  readonly type: AlertType;
  readonly theme: Themes;
  readonly display: AlertDisplay;
  readonly buttonLabel?: string;
  readonly onAction?: () => void;
  readonly secondaryButtonLabel?: string;
  readonly onSecondaryAction?: () => void;
  readonly disabledActionButton?: boolean;
  readonly alignButtons?: boolean;
}

export enum AlertType {
  SUCCESS = "success",
  INFO = "info",
  INFO_INLINE = "info_inline",
  TIMER = "timer",
  WARNING = "warning",
  ERROR = "error",
}

export enum AlertDisplay {
  FLOATING = "floating",
  FULL_BLEED = "full-bleed",
  INLINE = "inline",
}

const Alert = ({
  className,
  children,
  type,
  onClose,
  closeButton,
  theme,
  display,
  buttonLabel,
  onAction,
  secondaryButtonLabel,
  onSecondaryAction,
  disabledActionButton = false,
  alignButtons = false,
}: AlertProps) => {
  const icon = useMemo(() => {
    switch (type) {
      case AlertType.SUCCESS:
        return <MdDone size={24} />;
      case AlertType.INFO:
        return <MdInfoOutline size={24} />;
      case AlertType.INFO_INLINE:
        return <MdInfo size={24} />;
      case AlertType.TIMER:
        return <MdHourglassTop size={24} />;
      case AlertType.WARNING:
        return <MdWarning size={24} />;
      case AlertType.ERROR:
        return <MdError size={24} />;
    }
  }, [type]);

  return (
    <Tooltip className={cx(styles.Alert, styles[theme], styles[type], styles[display], className)}>
      <div className={cx(styles.Alert, styles.layer, styles[theme], styles[type])}>
        <div className={cx(styles.icon, styles[type], styles[display])}>{icon}</div>
        <div className={cx(styles.label, styles[type], styles[display])}>{children}</div>
        <div className={cx(styles.layer, styles[display], styles[type])}>
          {buttonLabel && alignButtons && (
            <ActionButton
              theme={theme}
              label={buttonLabel}
              onClick={onAction}
              disabled={disabledActionButton}
              className={styles.alignButtons}
            />
          )}
        </div>
        {closeButton && (
          <Button
            theme={theme}
            icon={<MdClose size={24} />}
            onClick={onClose}
            className={styles.close}
            iconOnly
            darkMode
          />
        )}
      </div>
      <div className={cx(styles.layer, styles[display], styles[type])}>
        {secondaryButtonLabel && !alignButtons && (
          <ActionButton
            theme={theme}
            label={secondaryButtonLabel}
            onClick={onSecondaryAction}
            disabled={disabledActionButton}
            className={styles.alertButtonLowEmphasis}
            emphasis="Low"
          />
        )}
        {buttonLabel && !alignButtons && (
          <ActionButton
            theme={theme}
            label={buttonLabel}
            onClick={onAction}
            disabled={disabledActionButton}
            className={styles.alertButton}
          />
        )}
      </div>
    </Tooltip>
  );
};

const ActionButton = ({
  theme,
  label,
  onClick,
  className,
  disabled,
  emphasis = "Mid",
}: {
  theme: Themes;
  label: string;
  onClick: (() => void) | undefined;
  className: string;
  disabled: boolean;
  emphasis?: "Low" | "Mid" | "High";
}) => {
  return (
    <Button
      theme={theme}
      buttonSize={"S"}
      emphasis={emphasis}
      label={label}
      onClick={onClick}
      disabled={disabled}
      className={className}
      type="button"
    />
  );
};

export { Alert };
