import React from "react";
import { type Themes } from "../commonProps";

export type PopoverActions = { open: () => void; close: () => void };
export type DropdownContextValue = {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  popoverActions?: PopoverActions;
  setPopoverActions: (actions: PopoverActions) => void;
  theme: Themes;
  darkMode: boolean;
};

export const DropdownContext = React.createContext<DropdownContextValue>(
  {} as DropdownContextValue
);
