import React from "react";

import styles from "./Loader.module.scss";

export function LoaderBar() {
  return (
    <div className={styles["LoaderBar"]}>
      <div className={styles.row}>
        <div className={styles.col}></div>
      </div>
    </div>
  );
}
