import React from "react";
import { Pie } from "@nivo/pie";
import { type LegendProps } from "@nivo/legends";
import cx from "classnames";

import styles from "./Gauge.module.scss";
import { Legend } from "../../atoms/Legend";
import { useMeasure } from "react-use";

export enum TypeGauge {
  PERCENTAGE = "percentage",
  RAW = "raw",
}

export type GaugeProps = {
  // Values Props
  readonly activeColor: string;
  readonly inactiveColor: string;
  readonly activeLabel: string;
  readonly inactiveLabel: string;
  readonly currentValue: number;
  readonly max: number;

  // Calculus props
  readonly type: TypeGauge.PERCENTAGE | TypeGauge.RAW;

  // Chart options
  readonly margin?: {
    readonly top?: number;
    readonly right?: number;
    readonly bottom?: number;
    readonly left?: number;
  };
  readonly width: number;
  readonly height: number;

  // Legends props
  readonly legends?: Partial<LegendProps>;
  readonly className?: string;
};

const innerRadius = 0.75;
const padAngle = 0;
const corderRadius = 0;
const startAngle = -90;
const endAngle = 90;

const defaultMargins = {
  top: 50,
  right: 0,
  bottom: 20,
  left: 0,
};

const defaultLegendsSettings: LegendProps = {
  anchor: "bottom",
  direction: "row",
  translateY: 0,
  translateX: -4,
  itemsSpacing: 8,
  itemWidth: 60,
  itemHeight: 14,
  itemTextColor: "#1a304d",
  itemDirection: "left-to-right",
  itemOpacity: 1,
  symbolSize: 14,
  symbolShape: "circle",
};

const COLUMN_PADDING = 16;

const Gauge = (props: GaugeProps) => {
  let activeValue = 0;
  let inactiveValue = 0;
  if (props.type === TypeGauge.PERCENTAGE) {
    activeValue = (props.currentValue / props.max) * 100;
    inactiveValue = 100 - activeValue;
  } else {
    activeValue = props.currentValue;
    inactiveValue = props.max - activeValue;
  }
  const data = [
    {
      id: props.activeLabel,
      label: props.activeLabel,
      value: activeValue,
      color: props.activeColor,
    },
    {
      id: props.inactiveLabel,
      label: props.inactiveLabel,
      value: inactiveValue,
      color: props.inactiveColor,
    },
  ];

  const [ref, { width: realWidth, height: realHeight }] = useMeasure();
  const width = Math.floor(realWidth || props.width);
  const height = Math.floor(realHeight || props.height);

  const margin = {
    ...defaultMargins,
    ...props.margin,
  };

  const CenteredMetric = ({ centerX, centerY }: { centerX: number; centerY: number }) => {
    let total = activeValue / props.max;
    total = Math.round(total * 100);

    return (
      <text
        x={centerX}
        y={centerY - 5}
        textAnchor="middle"
        dominantBaseline="central"
        fill="#1A304D"
        style={{
          fontSize: "1.25rem",
          fontWeight: 600,
        }}
      >
        {total} %
      </text>
    );
  };

  const Legends = () => {
    const isColumn = props.legends?.direction === "column";
    const itemWidth = isColumn ? 60 : 120;
    const spacing = props.legends?.itemsSpacing || 0;
    const itemHeight = props.legends?.itemHeight || 15;
    return (
      <g>
        {data.map((legend, legendIndex) => {
          return isColumn ? (
            <Legend
              key={legendIndex}
              legend={legend}
              {...legend}
              translateX={
                width -
                (itemWidth + COLUMN_PADDING) -
                (data.length - 1) * (itemWidth + COLUMN_PADDING)
              }
              translateY={
                height -
                itemHeight / 2 -
                ((data.length || 0) - 1 - legendIndex) * (itemHeight + spacing)
              }
            />
          ) : (
            <Legend
              key={legendIndex}
              legend={legend}
              {...legend}
              itemWidth={itemWidth}
              translateX={
                (width - data.length * (itemWidth + spacing)) / data.length +
                legendIndex * (itemWidth + spacing)
              }
              translateY={height - itemHeight}
            />
          );
        })}
      </g>
    );
  };

  return (
    // @ts-expect-error ref type
    <div ref={ref} className={cx(styles.Gauge, props.className)}>
      <Pie
        data={data}
        width={width}
        height={height}
        innerRadius={innerRadius}
        enableArcLabels={false}
        layers={["arcs", Legends, CenteredMetric]}
        enableArcLinkLabels={false}
        padAngle={padAngle}
        cornerRadius={corderRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        margin={margin}
        colors={[props.activeColor, props.inactiveColor]}
        animate={false}
        // legend
        legends={[
          {
            ...defaultLegendsSettings,
            ...props.legends,
          },
        ]}
        // tooltip={(data) => <text>{data.datum.formattedValue}</text>}
      />
    </div>
  );
};

export { Gauge };
