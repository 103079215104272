import React from "react";

import { Tick } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const CampaignIsOver = () => (
  <RectangleTag
    label="Campagne Terminée"
    icon={<Tick />}
    backgroundColor="#eeffed"
    textColor="#0eaf85"
  />
);

export { CampaignIsOver };
