import React, { type CSSProperties } from "react";
import cx from "classnames";
import { type Themes } from "components/commonProps";

import { Flex } from "../Flex";
import { Avatar as DSAvatar, type AvatarProps } from "../Avatar/Avatar";

import styles from "./UserPreview.module.scss";

export interface Props {
  readonly theme: Themes;
  readonly size: "S" | "M" | "L";
  readonly initials?: AvatarProps["initials"];
  readonly fullName?: string;
  readonly propertyLabel?: string;
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly randomColorSeedString?: string;
}

const UserPreview = ({
  className,
  style,
  propertyLabel,
  theme,
  initials,
  fullName,
  size,
  randomColorSeedString,
}: Props) => (
  <Flex
    className={cx(styles.UserPreview, styles[theme], styles[size], className)}
    style={style}
    data-testid="user-preview"
  >
    <DSAvatar
      className={styles.avatar}
      theme={theme}
      initials={initials}
      size={size}
      randomColorSeedString={randomColorSeedString}
    />
    <Flex column className={cx(styles.meta, styles[size])} data-testid="user-meta">
      <span className={styles.fullName}>{fullName}</span>
      {size !== "S" && <span className={styles.propertyLabel}>{propertyLabel}</span>}
    </Flex>
  </Flex>
);

export { UserPreview };
