import React, { type CSSProperties } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Link.module.scss";

export interface IProps {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly label?: string;
  readonly icon?: JSX.Element;
  readonly onClick?: () => void;
  readonly small?: boolean;
  readonly disabled?: boolean;
}

const PureLink = (props: IProps): JSX.Element | null => {
  const { className, label, onClick, small = false, style, disabled = false } = props;

  if (disabled) return null;

  return (
    <button
      role="link"
      className={cx(styles.Link, className, { [styles.small]: small })}
      onClick={onClick}
      style={style}
    >
      {label}
    </button>
  );
};

const LinkWithIcon = (props: IProps): JSX.Element => {
  const { className, icon, style, onClick, disabled = false, ...linkProps } = props;

  return (
    <Flex
      role="container"
      className={cx(styles.LinkWithIcon, className)}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <Link disabled={disabled} {...linkProps} />
    </Flex>
  );
};

export const Link = (props: IProps): JSX.Element =>
  props.icon ? <LinkWithIcon {...props} /> : <PureLink {...props} />;
