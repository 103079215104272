import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgCheckbox = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <rect stroke={color} x={3} y={3} width={14} height={14} rx={1} fill="none" fillRule="evenodd" />
  </SVGBox>
);

export { SvgCheckbox };
