import React from "react";

import { Bulb } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const HideFromManagerUntil = ({ status }: { status: string }) => (
  <RectangleTag
    label={`Partagé au responsable après : ${status}`}
    icon={<Bulb />}
    backgroundColor="#EE82EE"
    textColor="#000000"
  />
);

export { HideFromManagerUntil };
