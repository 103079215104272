import { FormEvent, useCallback, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

import User from "helpers/User";

import styles from "./LoginViewStyles.module.scss";
import { AuthRoutes } from "api/types";
import request from "helpers/request";
import Logo from "containers/Signin/Logo";

import useAuth from "hooks/useAuth";
import { usePasswordsFormLogic } from "hooks/usePasswords";
import { useAuthPaths, useIsInPath } from "../../hooks/useAuthPaths";

interface Props {
  redirect?: string;
}
const UpdatePassword = ({ redirect }: Props) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const authData = useAuth();
  const { setStep } = authData;
  const [loading, toggleLoading] = useToggle(false);
  const AuthPaths = useAuthPaths();
  const isInPasswordUpdateFlow = useIsInPath(AuthPaths.UpdatePassword);
  const { data: emailAsBase64 } = useParams<{ data?: string }>();
  const passwordPolicy = isInPasswordUpdateFlow ? "secure" : authData.passwordPolicy;

  const [oldPassword, setOldPassword] = useState(authData.password);
  const { password, passwordChecks, setPassword, setPasswordConfirm, submitError } =
    usePasswordsFormLogic({ oldPassword, passwordPolicy });

  const [login] = useState(isInPasswordUpdateFlow ? atob(emailAsBase64 ?? "") : authData.login);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      toggleLoading(true);

      const resetData = {
        login,
        oldPassword,
        newPassword: password,
      };

      try {
        const data = await updatePassword({ resetData, redirect });
        if ("nextStep" in data) {
          // Should not occur, but who knows, and it makes typescript compiler happy
          setStep(data.nextStep);
          return;
        }

        handleUpdatePasswordSuccess(data);
      } catch (error) {
        handleUpdatePasswordError({ error, addToast, navigate });
      } finally {
        toggleLoading(false);
      }
    },
    [toggleLoading, navigate, oldPassword, password, login, redirect, setStep, addToast]
  );

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Modification du mot de passe</h1>
        <p>
          {isInPasswordUpdateFlow
            ? "Vous avez demandé à modifier votre mot de passe. Pour cela, merci de définir un nouveau mot de passe."
            : "Afin de sécuriser l’accès à votre compte, merci de définir un nouveau mot de passe."}
        </p>
        <form onSubmit={onSubmit}>
          {isInPasswordUpdateFlow && (
            <div className={styles.formGroup}>
              <label htmlFor="old-password">
                Ancien mot de passe <strong>*</strong>
              </label>
              <input
                type="password"
                id="old-password"
                autoFocus
                onChange={({ currentTarget: { value } }) => setOldPassword(value)}
              />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="password">
              Nouveau mot de passe <strong>*</strong>
            </label>
            <input
              type="password"
              id="password"
              autoFocus={!isInPasswordUpdateFlow}
              onChange={({ currentTarget: { value } }) => setPassword(value)}
            />
            {!passwordChecks.differentThanOld && (
              <div className={styles.error}>
                Votre mot de passe doit être différent du précédent
              </div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label>Votre mot de passe doit contenir :</label>
            <div className={styles.passwordChecks}>
              {passwordPolicy !== "secure" && (
                <PasswordCheck label="8 caractères minimum" checked={passwordChecks.hasMinLength} />
              )}
              {passwordPolicy === "secure" && (
                <PasswordCheck
                  label="15 caractères minimum"
                  checked={passwordChecks.hasMinLength}
                />
              )}
              <PasswordCheck
                label="Au moins 1 lettre en minuscule"
                checked={passwordChecks.hasLowerCase}
              />
              <PasswordCheck
                label="Au moins 1 lettre en majuscule"
                checked={passwordChecks.hasUpperCase}
              />
              <PasswordCheck label="Au moins un chiffre" checked={passwordChecks.hasNumber} />
              {passwordPolicy === "secure" && (
                <PasswordCheck
                  label="Au moins un caractère spécial (!.@#$%)"
                  checked={passwordChecks.hasSpecialChar}
                />
              )}
              {isInPasswordUpdateFlow && (
                <PasswordCheck
                  label="Nouveau mot de passe différent de l'ancien"
                  checked={passwordChecks.differentThanOld}
                />
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password-verification">
              Confirmation du mot de passe <strong>*</strong>
            </label>
            <input
              type="password"
              id="password-verification"
              onChange={({ currentTarget: { value } }) => setPasswordConfirm(value)}
            />
          </div>
          <div className={styles.actions}>
            <div data-tip={submitError}>
              <button type="submit" disabled={loading || !!submitError}>
                Enregistrer le nouveau mot de passe
              </button>
            </div>
          </div>
        </form>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </div>
  );
};

function handleUpdatePasswordError({
  addToast,
  error,
  navigate,
}: {
  error: unknown;
  addToast: Function;
  navigate: Function;
}) {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 429) {
      addToast(
        "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
        {
          appearance: "error",
        }
      );
    } else if (error.response?.data?.message === "No such user with params") {
      addToast("Identifiants invalides.", {
        appearance: "error",
      });
    } else if (error.response?.data?.message === "token-expired") {
      addToast("Cette demande a expirée. Faites une nouvelle demande.", {
        appearance: "error",
      });

      navigate("/connexion", { replace: true });
    } else {
      addToast("Une erreur est survenue.", {
        appearance: "error",
      });
    }
  } else {
    addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
      appearance: "error",
    });
  }
}

function handleUpdatePasswordSuccess(data: Awaited<ReturnType<typeof updatePassword>>) {
  if (!("nextStep" in data)) {
    if (data.credentials.persistStrategy === "localStorage") {
      window.localStorage.setItem("X-Auth-Token", data.token);
      window.sessionStorage.removeItem("X-Auth-Token");
    } else if (data.credentials.persistStrategy === "sessionStorage") {
      window.sessionStorage.setItem("X-Auth-Token", data.token);
      window.localStorage.removeItem("X-Auth-Token");
    } else {
      window.sessionStorage.removeItem("X-Auth-Token");
      window.localStorage.removeItem("X-Auth-Token");
    }

    const { REACT_APP_PUBLIC_APP_URL } = process.env;
    if (User.isOF(data.credentials)) {
      window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
    } else if (User.isRF(data.credentials)) {
      window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
    } else {
      window.location.href = `${REACT_APP_PUBLIC_APP_URL}`;
    }
  }
}

function PasswordCheck({ label, checked }: { label: string; checked: boolean }) {
  return (
    <div className={styles.passwordCheck}>
      {checked ? (
        <div className={cx(styles.icon, styles.iconCheck)}>✓</div>
      ) : (
        <div className={cx(styles.icon, styles.iconCircle)} />
      )}
      <div className={styles.passwordCheckText}>{label}</div>
    </div>
  );
}

async function updatePassword({
  resetData,
  redirect,
}: {
  resetData: {
    login: string | null;
    oldPassword: string | null;
    newPassword: string;
  };
  redirect?: string;
}) {
  await request<AuthRoutes["POST"]["/v1/password/update"]["response"]>({
    target: "AUTH",
    data: resetData,
    method: "POST",
    url: `/password/update`,
  });

  const loginData = {
    login: resetData.login,
    password: resetData.newPassword,
    redirectUrl: redirect,
  };

  const { data } = await request<AuthRoutes["POST"]["/v1/password"]["response"]>({
    target: "AUTH",
    data: loginData,
    method: "POST",
    url: "/password",
  });

  return data;
}

export default UpdatePassword;
