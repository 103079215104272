import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Student = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.26401 2.38367L13.8835 5.4749L13.9073 5.48874C13.993 5.54391 14.0497 5.6396 14.0497 5.74839V11.3029C14.0178 11.2722 13.9853 11.2414 13.9522 11.2103L13.7372 11.0086L13.5223 11.2103C13.4892 11.2414 13.4567 11.2722 13.4247 11.3029V6.27426L8.26401 9.11312C8.16963 9.16504 8.05486 9.16504 7.96048 9.11312L2.33548 6.01888C2.12116 5.90099 2.12116 5.5958 2.33548 5.47791L7.96048 2.38367C8.05486 2.33175 8.16963 2.33175 8.26401 2.38367Z"
      fill={color}
    />
    <path
      d="M13.7372 11.0947C13.1073 11.7215 12.7997 12.2086 12.7997 12.5192C12.7997 13.0318 13.2195 13.4474 13.7372 13.4474C14.255 13.4474 14.6747 13.0318 14.6747 12.5192C14.6747 12.2086 14.3672 11.7215 13.7372 11.0947Z"
      fill={color}
    />
    <path
      d="M12.1745 10.7679L12.1747 7.91436L8.56753 9.89864C8.4259 9.97656 8.26896 10.0155 8.11203 10.0155C7.95524 10.0154 7.79846 9.97648 7.65695 9.89864L4.04974 7.91436L4.04953 10.7679C4.04953 10.8432 4.07726 10.9159 4.12752 10.9725C4.89095 11.8307 6.22417 12.2463 8.11203 12.2463C9.99989 12.2463 11.3331 11.8307 12.0965 10.9725C12.1468 10.9159 12.1745 10.8432 12.1745 10.7679Z"
      fill={color}
    />
  </SVGBox>
);

export { Student };
