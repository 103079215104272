import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgFile = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 23 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55588 3C5.06496 3 4.66699 3.39797 4.66699 3.88889V17.1111C4.66699 17.602 5.06496 18 5.55588 18H16.7781C17.269 18 17.667 17.602 17.667 17.1111V3.88889C17.667 3.39797 17.269 3 16.7781 3H5.55588ZM7.71144 8.77776C7.46598 8.77776 7.267 8.97675 7.267 9.22221C7.267 9.46767 7.46598 9.66665 7.71144 9.66665L14.6226 9.66665C14.868 9.66665 15.067 9.46767 15.067 9.22221C15.067 8.97675 14.868 8.77776 14.6226 8.77776L7.71144 8.77776ZM7.267 11.7222C7.267 11.4768 7.46598 11.2778 7.71144 11.2778L12.0226 11.2778C12.268 11.2778 12.467 11.4768 12.467 11.7222C12.467 11.9677 12.268 12.1667 12.0226 12.1667H7.71144C7.46598 12.1667 7.267 11.9677 7.267 11.7222ZM7.71144 13.7778C7.46598 13.7778 7.267 13.9768 7.267 14.2222C7.267 14.4677 7.46598 14.6667 7.71144 14.6667L13.7559 14.6667C14.0013 14.6667 14.2003 14.4677 14.2003 14.2222C14.2003 13.9768 14.0013 13.7778 13.7559 13.7778L7.71144 13.7778Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgFile };
