import React, { type CSSProperties, type ReactNode } from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { ArrowDropdown, ArrowDropup } from "../Icon";
import { colors } from "../../assets/colors";
import styles from "./Details.module.scss";

export interface IProps {
  readonly className?: string;
  readonly title?: string;
  readonly style?: CSSProperties;
  readonly opened?: boolean;
  readonly children: ReactNode;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
}

const Details = (props: IProps): JSX.Element => {
  const { className, title, style, opened = false, children, onOpen, onClose } = props;

  const [isOpen, toggleContent] = useToggle(opened);
  const ArrowIcon = isOpen ? ArrowDropup : ArrowDropdown;

  const toggle = (): void => {
    toggleContent();

    if (!isOpen && onOpen) onOpen();

    if (isOpen && onClose) onClose();
  };

  return (
    <Flex
      column
      className={cx(styles.Details, { [styles.open]: isOpen })}
      style={style}
      role="container"
    >
      <Flex className={styles.header} onClick={toggle} role="header">
        <span className={styles.title} role="title">
          {title}
        </span>
        <ArrowIcon size={16} color={colors.bwBlack} />
      </Flex>
      <Flex column className={cx(styles.content, className)} disabled={!isOpen} role="content">
        {children}
      </Flex>
    </Flex>
  );
};

export { Details };
