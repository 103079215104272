import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgRefused = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M6.257 10.316c.498 0 .9-.313.9-.7V4.7c0-.387-.402-.7-.9-.7H4.901C4.403 4 4 4.313 4 4.7v4.916c0 .386.403.7.9.7h1.357zM8.61 4a.816.816 0 00-.82.812v5.898c0 .131.04.252.11.352l.114.138.209.236c.691.755 2.14 2.164 2.222 2.733l.046.277c.124.676.377 1.512.761 1.552l.029.002h.137c.588 0 1.116-.46 1.175-1.034l.023-.242c.046-.593.09-2.383-.926-3.402h2.873v-.004l.103.004c.737 0 1.334-.546 1.334-1.22 0-.396-.206-.748-.526-.971.316-.18.526-.498.526-.86 0-.443-.314-.82-.753-.959.328-.177.548-.501.548-.871 0-.456-.332-.84-.79-.97a.786.786 0 00.38-.657c0-.42-.36-.764-.822-.809V4H8.61z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { SvgRefused };
