import React, { useState, useMemo, useRef, useCallback, useEffect } from "react";
import { isNumber, map, isEmpty, isNull } from "lodash";
import { useToggle, useClickAway } from "react-use";
import fuzzy from "fuzzy";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import * as Icon from "../Icon";
import { colors } from "../../assets/colors";

import styles from "./TextDropdown.module.scss";

type ItemValue = string | number | null;

interface Option {
  readonly label?: string;
  readonly value: ItemValue;
}

interface Props<T extends Option> {
  readonly className?: string;
  readonly activeItemContainerClass?: string;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly options: T[];
  readonly placeholder?: string;
  readonly onSelect?: (item: T & { index?: number }) => void;
  readonly onClear?: () => void;
  readonly selectedIndex?: number;
  readonly small?: boolean;
  readonly withSearch?: boolean;
  readonly onError?: boolean;
  readonly direction?: "up" | "down";
}

const TextDropdown = <T extends Option>({
  className,
  activeItemContainerClass,
  disabled,
  readOnly,
  options,
  placeholder,
  onSelect,
  onClear,
  selectedIndex,
  small,
  withSearch,
  direction,
  onError,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(selectedIndex);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpen, toggleDropdown] = useToggle(false);
  const [isSearchFocus, toggleSearchFocus] = useToggle(false);
  const [isHovered, setMouseHover] = useState(false);

  const filtrableLabels: string[] = useMemo(() => {
    return options
      .filter(({ label, value }) => !isEmpty(label) || !isNull(value))
      .map(({ label, value }) => label ?? (value as string | number).toString());
  }, [options]);

  const filteredLabels: string[] = useMemo(() => {
    if (withSearch && searchValue) {
      return map(fuzzy.filter(searchValue, filtrableLabels), "string");
    }

    return filtrableLabels;
  }, [filtrableLabels, searchValue]);

  const toggleOptions = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      if (isOpen && !isSearchFocus) {
        return toggleDropdown(false);
      }

      return toggleDropdown(true);
    },
    [toggleDropdown, isOpen, isSearchFocus]
  );

  const activeItem = useMemo(() => {
    if (isNumber(activeIndex) && options[activeIndex]) {
      const { label, value } = options[activeIndex];
      return <span className={styles.label}>{label ?? value}</span>;
    }

    if (withSearch) {
      return (
        <Flex grow className={styles.searchContainer}>
          <Icon.Search size="1.25rem" color={isSearchFocus ? colors.bwBlack : colors.blueyGrey} />
          <input
            className={styles.searchInput}
            onFocus={() => toggleSearchFocus(true)}
            onBlur={() => toggleSearchFocus(false)}
            onChange={({ target: { value } }) => setSearchValue(value)}
            type="text"
            placeholder="Rechercher"
          />
        </Flex>
      );
    }

    if (placeholder) {
      return <span className={styles.placeholder}>{placeholder}</span>;
    }

    return null;
  }, [placeholder, activeIndex, options, isSearchFocus, setSearchValue]);

  const onItemSelect = useCallback(
    (e: T & { index?: number }) => {
      if (onSelect) {
        onSelect(e);
      }

      setActiveIndex(e.index);
      toggleDropdown(false);
    },
    [setActiveIndex, toggleDropdown, onSelect]
  );

  const renderItems = useCallback(
    (option: T, index: number) => {
      const { value, label } = option;
      const active = index === activeIndex;
      const filtered = value && filteredLabels.includes(label ?? value.toString());
      const itemLabel = label ?? value;

      return (
        <Flex
          role="button"
          key={index}
          className={cx(styles.item, {
            [styles.small]: small,
            [styles.hidden]: active || !filtered,
          })}
          onClick={() => onItemSelect({ ...option, index })}
          title={itemLabel?.toString()}
        >
          <span className={styles.label}>{itemLabel}</span>
        </Flex>
      );
    },
    [onItemSelect, small, filteredLabels]
  );

  const clearActiveItem = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      setActiveIndex(undefined);

      if (withSearch && searchValue) {
        setSearchValue("");
      }

      if (onClear) {
        onClear();
      }
    },
    [setActiveIndex, withSearch, searchValue, onClear]
  );

  useEffect(() => {
    if (selectedIndex !== activeIndex) {
      setActiveIndex(selectedIndex);
    }
  }, [selectedIndex, setActiveIndex]);

  const mouseEnter = useCallback(() => {
    setMouseHover(true);
  }, [setMouseHover]);

  const mouseLeave = useCallback(() => {
    setMouseHover(false);
  }, [setMouseHover]);

  useClickAway(containerRef, () => toggleDropdown(false));

  if (disabled) {
    return null;
  }

  return (
    <div ref={containerRef} className={cx(styles.TextDropdown, className)}>
      <Flex
        className={cx(
          styles.activeItemContainer,
          {
            [styles.isOpen]: isOpen,
            [styles.readOnly]: readOnly,
            [styles.small]: small,
            [styles.onError]: onError,
            [styles.itemsAbove]: direction === "up",
          },
          activeItemContainerClass
        )}
        role="button"
        onClick={readOnly ? undefined : toggleOptions}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {activeItem}
        <Flex className={styles.iconsContainer}>
          <Icon.Container
            className={cx(styles.clearBtn, {
              [styles.visible]:
                !readOnly && onClear && isNumber(activeIndex) && activeIndex > -1 && isHovered,
            })}
            onClick={clearActiveItem}
            round
          >
            <Icon.Cross className={styles.clearIcon} />
          </Icon.Container>
          {readOnly ? null : isOpen ? <Icon.ArrowDropup /> : <Icon.ArrowDropdown />}
        </Flex>
      </Flex>

      {!readOnly && isOpen && (
        <div
          className={cx(styles.itemsDropdown, {
            [styles.itemsAbove]: direction === "up",
          })}
        >
          {options.map(renderItems)}
        </div>
      )}
    </div>
  );
};

export { TextDropdown };
