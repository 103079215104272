import React, { type CSSProperties, type MouseEventHandler, type ReactNode, useContext } from "react";
import cx from "classnames";

import { Done } from "components/MaterialIcons";

import styles from "./Dropdown.module.scss";
import { DropdownContext } from "./DropdownContext";
import { Tooltip } from "components/Tooltip";
import { type TooltipProps } from "components/Tooltip/Tooltip";

export interface IItemProps {
  readonly label: string | ReactNode;
  readonly disabled?: boolean;
  readonly index?: number;
  readonly selectable?: boolean;
  readonly selected?: boolean;
  readonly icon?: ReactNode;
  readonly tooltipLabel?: TooltipProps["label"];
  readonly tooltipDirection?: TooltipProps["direction"];
  readonly style?: CSSProperties;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
}

export function DropdownItem({
  label,
  disabled,
  selectable,
  selected,
  icon,
  tooltipLabel,
  tooltipDirection,
  onClick,
  index,
  style,
}: IItemProps) {
  const { popoverActions, activeIndex } = useContext(DropdownContext);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onClick?.(event);
      setTimeout(() => popoverActions?.close());
    }
  };

  return (
    <Tooltip
      data-testid="dropdown-item"
      className={cx(styles.DropdownItem, {
        [styles.focus]: activeIndex === index,
        [styles.disabled]: disabled,
      })}
      label={tooltipLabel}
      direction={tooltipDirection}
      onClick={handleClick}
      style={style}
    >
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          {selectable && <span className={styles.icon}>{selected && <Done />}</span>}
          {!selectable && icon && <span className={styles.icon}>{icon}</span>}
          <div className={styles.label}>{label}</div>
        </div>
      </div>
    </Tooltip>
  );
}
DropdownItem.className = "DropdownItem";
