import React from "react";
import { isString, isNumber } from "lodash";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

import { Modalities } from "../Modalities";
import type { modalities } from "../modalitiesData";
import styles from "./TrainingSummaryCard.module.scss";

interface Props {
  readonly className?: string;
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  readonly trainingName: string;
  readonly disabled?: boolean;
  readonly price?: number | string;
  readonly isCPF?: boolean;
  readonly mode?: keyof typeof modalities;
  readonly organizationName?: string;
}

const TrainingSummaryCard = ({
  className,
  disabled,
  onClick,
  trainingName,
  organizationName,
  mode,
  price,
  isCPF,
}: Props) => {
  const { isMobile } = useMediaQueries();

  return (
    <Flex
      className={cx(styles.TrainingSummaryCard, className)}
      onClick={onClick}
      disabled={disabled}
      data-testid="training-card-container"
      grow
    >
      <Flex column grow className={styles.left}>
        <Modalities mode={mode} isCPF={isCPF} disabled={isMobile} />
        <h5 className={styles.trainingName}>{trainingName}</h5>
        <span className={styles.organizationName}>{organizationName}</span>
      </Flex>

      <div className={styles.right}>
        <Flex center className={styles.price} disabled={!price}>
          <span className={styles.value} data-testid="training-price">
            {price}
            {isNumber(price) || (isString(price) && !price.includes("€")) ? "€" : ""}
          </span>
        </Flex>
      </div>
    </Flex>
  );
};

export { TrainingSummaryCard };
