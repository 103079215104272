import { withTheme } from "components/DesignSystemContext";
import { DSModal, type ModalProps } from "./DSModal";
import { generateModalContext } from "./generateModalContext";

import { BasicHeader, ClosableModalHeader } from "./components/ModalHeader";
import { ModalContent, DialogContent } from "./components/ModalContent";
import { ModalContentColumns } from "./components/ModalContentColumns";
import {
  BasicFooterComp,
  FooterWithOptionsComp,
  InfoFooterComp,
  ModalFooterEmpty,
} from "./components/ModalFooter";
import { ModalScrollableLayer } from "./components/ModalScrollableLayer";

export { generateModalContext };

type ThemedModal = ReturnType<typeof withTheme<ModalProps>>;

type ModalType = ThemedModal & {
  Header: typeof ClosableModalHeader;
  Content: typeof ModalContent;
  ContentColumns: typeof ModalContentColumns;
  ScrollableLayer: typeof ModalScrollableLayer;
  Footer: typeof BasicFooterComp;
  FooterWithOption: typeof FooterWithOptionsComp;
  FooterEmpty: typeof ModalFooterEmpty;
  FooterInfo: typeof InfoFooterComp;
};

type DialogType = ThemedModal & {
  Header: typeof BasicHeader;
  Content: typeof DialogContent;
  Footer: typeof BasicFooterComp;
};

export const ModalComponents = {
  ClosableHeader: ClosableModalHeader,
  BasicHeader: BasicHeader,
  Content: ModalContent,
  ContentColumns: ModalContentColumns,
  ScrollableLayer: ModalScrollableLayer,
  DialogContent: DialogContent,
  BasicFooter: BasicFooterComp,
  FooterWithOptions: FooterWithOptionsComp,
  FooterEmpty: ModalFooterEmpty,
  FooterInfo: InfoFooterComp,
};

const DSDialog = Object.assign(withTheme<ModalProps>(DSModal), {
  Header: BasicHeader,
  Content: DialogContent,
  Footer: BasicFooterComp,
}) as DialogType;

const Modal = Object.assign(withTheme<ModalProps>(DSModal), {
  Header: ClosableModalHeader,
  Content: ModalContent,
  ContentColumns: ModalContentColumns,
  ScrollableLayer: ModalScrollableLayer,
  Footer: BasicFooterComp,
  FooterWithOption: FooterWithOptionsComp,
  FooterEmpty: ModalFooterEmpty,
  FooterInfo: InfoFooterComp,
}) as ModalType;

export { Modal as DSModal };
export { DSDialog };
