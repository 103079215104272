import React, { type ChangeEvent, type InputHTMLAttributes, useCallback, type LegacyRef } from "react";
import cx from "classnames";

import styles from "./TextInput.module.scss";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  readonly onChange?: (value: string) => void;
  readonly value?: string;
  readonly errored?: boolean;
  readonly inputRef?: LegacyRef<HTMLInputElement>;
  readonly controlled?: boolean;
}

const TextInput = ({
  className,
  onChange,
  readOnly,
  value,
  errored,
  inputRef,
  controlled,
  ...props
}: Props) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChange && !readOnly) return onChange(e.target.value);
    },
    [onChange, readOnly]
  );

  return (
    <input
      className={cx(styles.TextInput, className, {
        [styles.readOnly]: readOnly,
        [styles.errored]: errored,
      })}
      ref={inputRef}
      type="text"
      data-testid="text-input"
      onChange={handleChange}
      readOnly={readOnly}
      {...(!controlled ? { defaultValue: value } : {})}
      {...(controlled ? { value } : {})}
      {...props}
    />
  );
};

export { TextInput };
