import React, { type HTMLAttributes, type InputHTMLAttributes } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { type Themes } from "../../commonProps";

import styles from "./BaseInput.module.scss";

export interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly error?: boolean;
  readonly autoFocus?: boolean;
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  readonly icon?: JSX.Element;
  readonly actionButton?: JSX.Element;

  readonly darkMode?: boolean;
  readonly theme: Themes;

  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
}

export const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      containerProps = {},
      name,
      type,
      value,
      error,
      disabled,
      readOnly,
      autoFocus,
      onChange,
      icon,
      actionButton,
      darkMode = false,
      theme,
      className,
      placeholder,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cx(styles.BaseInput, className, styles[theme], {
          [styles.darkMode]: darkMode,
          [styles.withIcon]: icon,
          [styles.disabled]: disabled,
          [styles.readOnly]: readOnly,
          [styles.empty]: isEmpty(value),
          [styles.error]: !!error,
        })}
        aria-label={`input${type}-${name}`}
        role="presentation"
        {...containerProps}
      >
        {icon}
        <input
          ref={ref}
          name={name}
          value={value}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          autoFocus={autoFocus}
          onChange={(e) => onChange?.(e)}
          placeholder={placeholder}
          className={cx({
            [styles.withIcon]: !!icon,
            [styles.withAction]: !!actionButton,
            [styles.empty]: isEmpty(value),
            [styles.readOnly]: readOnly,
            [styles.error]: !!error,
          })}
          type={type}
          {...props}
        />
        {actionButton}
      </div>
    );
  }
);

export type CommonInputProps = Pick<
  BaseInputProps,
  | "name"
  | "value"
  | "placeholder"
  | "required"
  | "disabled"
  | "readOnly"
  | "error"
  | "autoFocus"
  | "onChange"
  | "icon"
  | "actionButton"
  | "darkMode"
  | "theme"
  | "className"
>;
