import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronLeft = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M13.4983 6.76923L9.02572 11.2418L13.4983 15.7272"
      stroke={color}
      strokeWidth="1.70872"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGBox>
);

export { ChevronLeft };
