import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Plus = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.65 4C9.8768 4 9.25 4.6268 9.25 5.4V9.25H5.4C4.6268 9.25 4 9.8768 4 10.65V11.35C4 12.1232 4.6268 12.75 5.4 12.75H9.25V16.6C9.25 17.3732 9.8768 18 10.65 18H11.35C12.1232 18 12.75 17.3732 12.75 16.6V12.75H16.6C17.3732 12.75 18 12.1232 18 11.35V10.65C18 9.8768 17.3732 9.25 16.6 9.25H12.75V5.4C12.75 4.6268 12.1232 4 11.35 4H10.65Z"
      fill={color}
    />
  </SVGBox>
);

export { Plus };
