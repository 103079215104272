import { type ResponseError } from "../errors/errors";
import { UCTest } from "./UCTest";

type Func = (...args: any[]) => any;

type UsecaseParams<UseCase extends (...args: any[]) => any> = UseCase extends (
  params: infer P,
  deps: any
) => any
  ? P
  : never;

type UsecaseDeps<UseCase extends (...args: any[]) => any> = UseCase extends (
  params: any,
  deps: infer D
) => any
  ? D
  : never;

type UsecaseResult<UseCase extends (...args: any[]) => any> = UseCase extends (
  params: any,
  deps: any
) => infer R
  ? R
  : never;

type UseCaseTypes<UseCase extends (...args: any[]) => any> = UseCase extends (
  params: infer P,
  deps: infer D
) => infer R
  ? {
      Params: P;
      Deps: D;
      Result: R;
    }
  : never;

type UCTMap = {
  [key: string]: {
    Params: any;
    Deps: any;
    Result: any;
  };
};

type ExtractParamsFromUCType<T extends UCTMap, key extends keyof T> = T[key]["Params"];
type ExtractDepsFromUCType<T extends UCTMap, key extends keyof T> = T[key]["Deps"];
type ExtractResultFromUCType<T extends UCTMap, key extends keyof T> = T[key]["Result"];

type PrettySchema<T, TExclude> = T extends TExclude
  ? T
  : T extends object
  ? { [K in keyof T]: PrettySchema<T[K], TExclude> }
  : T;

export namespace hexa {
  export type infer<T extends RecursiveRecord<Func | Encapsulate<any, any, any, any>>> =
    ExtractUseCasesTypes<T>;

  export type inferUC<T extends Func> = UseCaseTypes<T>;
  export type portUC<T extends Func> = PortUC<T>;
  export type drivingPort<T extends RecursiveRecord<Func | Encapsulate<any, any, any, any>>> =
    GenerateDrivingPort<T>;
  export type drivingPortParams<T extends (...args: any[]) => any> = Parameters<T>[0];

  export type extractParams<T extends UCTMap, key extends keyof T> = ExtractParamsFromUCType<
    T,
    key
  >;
  export type extractDeps<T extends UCTMap, key extends keyof T> = ExtractDepsFromUCType<T, key>;
  export type extractResult<T extends UCTMap, key extends keyof T> = ExtractResultFromUCType<
    T,
    key
  >;

  export type Prettify<T> = PrettySchema<T, ResponseError<any>>;

  export const Test = UCTest;

  /**
   * This is a very important class. If you wrap your usecase in it, it will be detected
   * in the type helpers (like hexa.infer or hexa.drivingPort) and the "identity" parameter
   * will be hidden from type signature, allowing you to create adapters to the external world that do not
   * require it, while still forcing you to provide it when you call the usecase from inside the adapter.
   *
   * @example
   * ```ts
   * function usecase(params: { identity: Identity, foo: string }, deps: { bar: string }) {
   *  return 2;
   * }
   *
   * const signedUcs = { usecase: new hexa.SignedUsecase(usecase) };
   *
   * type Signed = hexa.infer<typeof signedUcs>;
   *
   * // Signed is now:
   * // {
   * //   usecase: {
   * //     Params: { foo: string }, // notice how the identity parameter is gone
   * //     Deps: { bar: string },
   * //     Result: number
   * //   }
   * // }
   *
   * type signedPort = hexa.drivingPort<typeof ucs>;
   *
   * // signedPort is now:
   * // {
   *  //   usecase: (params: { foo: string }) => number // notice how the identity parameter is gone
   * // }
   * ```
   */
  export class Encapsulate<Params, Deps, Result, THide extends keyof Params> {
    constructor(
      private readonly UC: (p: Params, d: Deps) => Result,
      _hiddenParams: { hide: THide[] }
    ) {}

    run(p: Params, d: Deps): Result {
      return this.UC(p, d);
    }
  }
}

export namespace UC {
  export type Params<T extends Func> = UsecaseParams<T>;
  export type Deps<T extends Func> = UsecaseDeps<T>;
  export type Result<T extends Func> = UsecaseResult<T>;
}

type RecursiveRecord<T extends Func | hexa.Encapsulate<any, any, any, any>> = {
  [key: string]: T | RecursiveRecord<T>;
};

type ExtractUseCasesTypes<T extends RecursiveRecord<Func | hexa.Encapsulate<any, any, any, any>>> =
  {
    [key in keyof T]: T[key] extends hexa.Encapsulate<infer P, infer D, infer R, infer THide>
      ? { Params: PrettySchema<Omit<P, THide>, never>; Deps: D; Result: R }
      : T[key] extends Func
        ? UseCaseTypes<T[key]>
        : T[key] extends RecursiveRecord<Func | hexa.Encapsulate<any, any, any, any>>
          ? ExtractUseCasesTypes<T[key]>
          : never;
  };

type GenerateDrivingPort<T extends RecursiveRecord<Func | hexa.Encapsulate<any, any, any, any>>> = {
  [key in keyof T]: T[key] extends hexa.Encapsulate<infer P, infer _D, infer R, infer THide>
    ? keyof Omit<P, THide> extends never
      ? () => R
      : (params: PrettySchema<Omit<P, THide>, void>) => R
    : T[key] extends Func
      ? (params: UsecaseParams<T[key]>) => UsecaseResult<T[key]>
      : T[key] extends RecursiveRecord<Func | hexa.Encapsulate<any, any, any, any>>
        ? GenerateDrivingPort<T[key]>
        : never;
};

export type PortUC<T extends Func> = (params: UsecaseParams<T>) => ReturnType<T>;
