import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Logout = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M17.9557 10.1933C17.926 10.1222 17.8834 10.0574 17.8292 10.0031L16.0797 8.25364C15.8516 8.02613 15.4829 8.02613 15.2548 8.25364C15.0267 8.48173 15.0267 8.85099 15.2548 9.0785L16.0091 9.83279H12.7504C12.4278 9.83279 12.1671 10.0941 12.1671 10.4161C12.1671 10.7382 12.4278 10.9995 12.7504 10.9995H16.0091L15.2548 11.7538C15.0267 11.9819 15.0267 12.3511 15.2548 12.5786C15.3685 12.693 15.5179 12.7496 15.6672 12.7496C15.8166 12.7496 15.9659 12.693 16.0797 12.5786L17.8292 10.8291C17.8834 10.7754 17.926 10.7107 17.9557 10.6389C18.0146 10.4967 18.0146 10.3357 17.9557 10.1933Z"
      fill={color}
    />
    <path
      d="M13.9171 12.167C13.5945 12.167 13.3337 12.4284 13.3337 12.7504V15.6672H11.0003V6.33343C11.0003 6.07617 10.8311 5.84866 10.5844 5.77458L8.55835 5.16673H13.3337V8.08354C13.3337 8.40555 13.5945 8.66689 13.9171 8.66689C14.2397 8.66689 14.5004 8.40555 14.5004 8.08354V4.58338C14.5004 4.26134 14.2397 4 13.9171 4H4.58335C4.56235 4 4.54367 4.00875 4.52327 4.01107C4.49585 4.014 4.47077 4.01865 4.44452 4.02508C4.38327 4.04083 4.32784 4.06475 4.27594 4.0974C4.26311 4.10558 4.24736 4.10615 4.23511 4.11548C4.23041 4.11901 4.22866 4.12543 4.22398 4.12893C4.16041 4.17908 4.10733 4.24034 4.06883 4.31326C4.06065 4.32901 4.0589 4.34594 4.0525 4.36227C4.03383 4.40659 4.01343 4.44977 4.00643 4.49877C4.0035 4.51627 4.00875 4.5326 4.00818 4.54952C4.0076 4.5612 4 4.5717 4 4.58335V16.2506C4 16.5288 4.19658 16.768 4.46902 16.8223L10.3026 17.989C10.3405 17.9972 10.379 18.0007 10.4169 18.0007C10.5505 18.0007 10.6818 17.9546 10.7868 17.8682C10.9215 17.7574 11.0003 17.5923 11.0003 17.4173V16.8339H13.9171C14.2397 16.8339 14.5004 16.5726 14.5004 16.2506V12.7504C14.5004 12.4284 14.2397 12.167 13.9171 12.167Z"
      fill={color}
    />
  </SVGBox>
);

export { Logout };
