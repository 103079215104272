import React, { useRef, useState } from "react";
import cx from "classnames";
import styles from "./Popover.module.scss";
import { useEffect } from "react";

type Placement = ["top" | "bottom" | "auto", "left" | "right" | "auto"];

interface Props {
  readonly className?: string;
  readonly contentClassName?: string;
  readonly togglerClassName?: string;
  readonly maskClassName?: string;
  readonly placement?: Placement;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly content: () => React.ReactNode;
  readonly children: React.ReactNode;
  readonly actions?: (params: { open: () => void; close: () => void }) => void;
}

export function Popover({
  className,
  contentClassName,
  togglerClassName,
  maskClassName,
  content,
  onOpen,
  onClose,
  actions,
  placement,
  children,
}: Props) {
  const [showPopover, setShowPopover] = useState(false);
  const togglerElt = useRef<HTMLDivElement>(null);

  function contentPlacementStyle() {
    if (!togglerElt.current) return;
    const docWidth = document.documentElement.clientWidth || document.body.clientWidth;
    const docHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const togglerRect = togglerElt.current.getBoundingClientRect();
    const res: any = {};
    const actualPlacement: Placement = Array.isArray(placement) ? [...placement] : ["auto", "auto"];
    if (actualPlacement[0] === "auto")
      actualPlacement[0] = togglerRect.y < docHeight / 2 ? "bottom" : "top";
    switch (actualPlacement[0]) {
      case "top":
        res.bottom = `${docHeight - togglerRect.top}px`;
        break;
      case "bottom":
        res.top = `${togglerRect.top + togglerRect.height}px`;
        break;
    }
    if (actualPlacement[1] === "auto")
      actualPlacement[1] = togglerRect.x < docWidth / 2 ? "right" : "left";
    switch (actualPlacement[1]) {
      case "left":
        res.right = `${docWidth - togglerRect.right}px`;
        break;
      case "right":
        res.left = `${togglerRect.left}px`;
        break;
    }
    return res;
  }

  function open() {
    onOpen?.();
    setShowPopover(true);
  }
  function close() {
    onClose?.();
    setShowPopover(false);
  }

  useEffect(() => {
    if (actions) {
      actions({
        open,
        close,
      });
    }
  }, []);

  function handleKeyPress(e: KeyboardEvent) {
    if (e.key === "Escape") {
      e.stopPropagation();
      close();
    }
  }
  useEffect(() => {
    if (!showPopover) return;
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showPopover]);

  return (
    <div className={cx(styles.Popover, className)}>
      <div
        ref={togglerElt}
        className={cx(styles.Toggler, togglerClassName)}
        onClick={(e) => {
          e.stopPropagation();
          open();
        }}
      >
        {children}
      </div>
      {showPopover && (
        <>
          <div
            aria-label="fond-menu"
            className={cx(styles.Mask, maskClassName)}
            tabIndex={-1}
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <div
              aria-label="contenu-menu"
              className={cx(styles.Content, contentClassName)}
              style={contentPlacementStyle()}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {content()}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
