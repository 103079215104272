import React from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Skill.module.scss";

export interface IProps {
  readonly className?: string;
  readonly label: string;
}

const Skill = ({ className, label }: IProps) => (
  <Flex center className={cx(styles.Skill, className)} data-testid="skill-container">
    <span className={styles.label} data-testid="skill-label">
      #{label}
    </span>
  </Flex>
);

export { Skill };
