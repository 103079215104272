import { useMemo } from "react";
import { useEvent } from "react-use";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import { IUser } from "@skillup/types";
import { has, get, isNil } from "lodash";

export interface IUserDataHook extends Partial<IUser> {
  readonly isLogged: boolean;
  readonly isManager: () => boolean;
  readonly isCompanyAdmin: () => boolean;
  readonly isInterviewManager: () => boolean;
  readonly isCompanyUser: () => boolean;
  readonly isRegularUser: () => boolean;
  readonly isDemoUser: () => boolean;
  readonly isSkillupAdmin: () => boolean;
  readonly isSkillupDeveloper: () => boolean;
  readonly isImpostor: () => boolean;
  readonly isUserless: () => boolean;
  readonly isTrainingOrganizationAdmin: () => boolean;
  readonly hasInterviews: () => boolean;
  readonly hasTrainingsEnabled: () => boolean;
  // readonly isPortalCollectionEnabled: () => boolean;
  readonly isPortalSearchDisabled: () => boolean;
  readonly isPortalPriceDisabled: () => boolean;
  readonly isPortalCPFDisabled: () => boolean;
  readonly computeFullName: () => string;
  readonly getFullName: () => string;
  readonly getFirstLetters: () => string;
  readonly logout: () => void;
}

const DEMO_COMPANY_UUID = "b2359493-a019-4627-82e7-a8751ffcebaf";

const localUserData = atom<Partial<IUser>>({
  key: "_skillup_userData",
  default: JSON.parse(localStorage.getItem("_skillup_userData") ?? "{}"),
});

export const useSetUserData = () => useSetRecoilState(localUserData);

export const useSyncWithStorage = () => {
  const setUserData = useSetUserData();

  useEvent("storage", ({ key, oldValue, newValue }) => {
    if (key === "_skillup_userData" && oldValue !== newValue) {
      try {
        const nextContextValue = JSON.parse(newValue);

        setUserData(nextContextValue);
      } catch (e) {
        setUserData(newValue);
      }
    }
  });
};

const useUserData = (): IUserDataHook => {
  const user = useRecoilValue(localUserData);
  const { pathname } = useLocation();

  const data = useMemo(() => {
    const isCollabPortal = pathname.startsWith("/collaborateur/");
    const isManagerPortal = pathname.startsWith("/manager/");

    const isLogged = !isNil(user);
    const isManager = () =>
      (get(user, "activeCompany.userScope", []) as string[]).includes("manager");
    const isCompanyAdmin = () =>
      (get(user, "activeCompany.userScope", []) as string[]).includes("admin");
    const isInterviewManager = () =>
      (get(user, "activeCompany.userScope", []) as string[]).includes("interview-manager");
    const isCompanyUser = () => has(user, "activeCompany");
    const isRegularUser = () => isCompanyUser() && !isManager() && !isCompanyAdmin();
    const isDemoUser = () => isCompanyUser() && user?.activeCompany?.uuid === DEMO_COMPANY_UUID;
    // const isPortalCollectionEnabled = () =>
    //   Boolean(user?.activeCompany?.portalEnabled && user?.activeCompany?.portalCollectionEnabled);
    const isPortalSearchDisabled = () =>
      Boolean(user?.activeCompany?.portalEnabled && user?.activeCompany?.portalSearchDisabled);
    const isPortalUsersPriceDisabled = () =>
      Boolean(user?.activeCompany?.portalEnabled && user?.activeCompany?.portalUsersPriceDisabled);
    const isPortalManagersPriceDisabled = () =>
      Boolean(
        user?.activeCompany?.portalEnabled && user?.activeCompany?.portalManagersPriceDisabled
      );
    const isPortalCPFDisabled = () =>
      Boolean(user?.activeCompany?.portalEnabled && user?.activeCompany?.portalCPFDisabled);
    const isPortalPriceDisabled = () =>
      (isManagerPortal && isPortalManagersPriceDisabled()) ||
      (isCollabPortal && isPortalUsersPriceDisabled()) ||
      false;
    const isSkillupAdmin = () => (get(user, "scope", []) as string[]).includes("admin");
    const isSkillupDeveloper = () =>
      isSkillupAdmin() && (get(user, "scope", []) as string[]).includes("developer");
    const isImpostor = () => Boolean(user?.impostor);
    const isUserless = () => Boolean(!user?.uuid && user?.activeCompany);
    const isTrainingOrganizationAdmin = () =>
      Boolean(user?.activeTrainingOrganization?.userScope?.includes?.("admin"));

    const hasInterviews = () =>
      isCompanyUser() &&
      (get(user, "activeCompany.features", []) as string[]).includes("interviews");
    const hasTrainingsEnabled = () =>
      isCompanyUser() &&
      (get(user, "activeCompany.features", []) as string[]).includes("trainings");

    const computeFullName = () =>
      `${(user?.firstName ?? "").trim()} ${(user?.lastName ?? "").trim()}`.trim();
    const getFullName = () => user?.fullName ?? computeFullName();

    const getFirstLetters = () => {
      return (
        (user?.firstName ?? "").trim().charAt(0).toUpperCase() +
        (user?.lastName ?? "").trim().charAt(0).toUpperCase()
      );
    };

    const logout = () => {
      localStorage.removeItem("_skillup_userData");
      localStorage.removeItem("__acta__userData");
      const allPersistentStorages = [localStorage, sessionStorage];
      for (const storage of allPersistentStorages) {
        storage.removeItem("X-Auth-Token");
        storage.removeItem("X-Auth-As-Organization");
        storage.removeItem("X-Auth-As-User");
      }

      window.location.replace("/connexion/");
    };

    return {
      ...user,
      isLogged,
      isManager,
      isCompanyAdmin,
      isInterviewManager,
      isCompanyUser,
      isRegularUser,
      isDemoUser,
      isSkillupAdmin,
      isSkillupDeveloper,
      isImpostor,
      isUserless,
      isTrainingOrganizationAdmin,
      hasInterviews,
      hasTrainingsEnabled,
      computeFullName,
      getFullName,
      getFirstLetters,
      // isPortalCollectionEnabled,
      isPortalSearchDisabled,
      isPortalPriceDisabled,
      isPortalCPFDisabled,
      logout,
    };
  }, [user, pathname]);

  return data;
};

export default useUserData;
