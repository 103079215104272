import React, { type ReactElement } from "react";
import cx from "classnames";

import { Flex } from "../Flex";
import { Button } from "components/Button";

import styles from "./FileDrop.module.scss";

interface Props {
  readonly btnLabel?: string;
  readonly dropLabel?: string;
  readonly className?: string;
  readonly btnClassName?: string;
  readonly dropClassName?: string;
  readonly isDragActive: boolean;
  readonly inputProps: React.HTMLProps<HTMLInputElement>;
  readonly rootProps: Record<string, string>;
  readonly onDrop?: () => void;
  readonly value?: string | ReactElement;
}

const FileDrop = ({
  btnLabel,
  dropLabel,
  className,
  btnClassName,
  dropClassName,
  isDragActive,
  rootProps,
  inputProps,
  value,
  onDrop,
  ...rest
}: Props & React.HTMLAttributes<HTMLElement>) => {
  return (
    <Flex
      center
      column
      {...rootProps}
      {...rest}
      className={cx(styles.FileDrop, className, { [styles.isDragActive]: isDragActive })}
    >
      {!value && (
        <>
          <Button
            className={cx(styles.pickFileBtn, btnClassName)}
            label={btnLabel}
            disabled={isDragActive}
            emphasis="High"
            buttonSize="M"
            type="button"
          />
          <input className={styles.fileInput} {...inputProps} />
          <span className={cx(styles.dropLabel, dropClassName)}>{dropLabel}</span>
        </>
      )}
      {value}
    </Flex>
  );
};

export { FileDrop };
