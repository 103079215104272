import React, { useCallback } from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./RadioBox.module.scss";

export interface RadioChoice<T = any> {
  readonly label?: string;
  readonly value: T;
  readonly help?: string;
  readonly helpText?: string;
}

interface Props<T = any> extends RadioChoice<T> {
  readonly className?: string;
  readonly checked?: boolean;
  readonly canUncheck?: boolean;
  readonly onClick?: (value: T) => void;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly errored?: boolean;
  readonly horizontal?: boolean;
  readonly style?: React.CSSProperties;
}

const RadioBox = ({
  className,
  checked,
  canUncheck,
  onClick,
  readOnly,
  disabled,
  label,
  value,
  help,
  helpText,
  horizontal,
  errored,
  style,
}: Props) => {
  const [hiddenTooltip, toggleTooltip] = useToggle(true);

  const handleChange = useCallback((): void => {
    if (onClick && !readOnly) {
      return onClick(checked && canUncheck ? null : value);
    }
  }, [onClick, readOnly, value, checked]);

  return (
    <Flex
      className={cx(styles.RadioBox, className, {
        [styles.checked]: checked,
        [styles.readOnly]: readOnly,
        [styles.errored]: errored,
        [styles.horizontal]: horizontal,
      })}
      onClick={handleChange}
      data-testid="radio-wrapper"
      aria-label={`option-${label}`}
      disabled={disabled}
      style={style}
    >
      <Flex column>
        <Flex className={styles.line}>
          <Flex
            className={styles.circle}
            role="radio"
            aria-checked={checked}
            disabled={horizontal}
            center
          >
            <div className={styles.dot} />
          </Flex>
          <Flex className={styles.label} data-testid="radio-label">
            {label}
          </Flex>
          {help && (
            <Flex
              className={styles.help}
              onMouseEnter={() => toggleTooltip(false)}
              onMouseLeave={() => toggleTooltip(true)}
              disabled={horizontal}
              aria-label="aide-hover"
            >
              <Flex center className={styles.questionIcon}>
                ?
              </Flex>
              <Flex className={styles.tooltip} disabled={hiddenTooltip}>
                <p>{help}</p>
              </Flex>
            </Flex>
          )}
        </Flex>
        {helpText !== undefined && (
          <Flex className={styles.helpText}>
            <span data-testid="radio-helptext">{helpText}</span>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export { RadioBox };
