import React from "react";
import cx from "classnames";

import styles from "./SkillLevel.module.scss";

interface SkillLevelProps {
  readonly level?: number;
  readonly totalLevel: Array<{ level: number }>;
}

const SkillLevel = ({ level, totalLevel }: SkillLevelProps) => {
  return (
    <div className={cx(styles.SkillLevel)}>
      {totalLevel
        .sort((a, b) => a.level - b.level)
        .map((e) => (
          <span
            key={e.level}
            className={cx(styles.level, {
              [styles.reached]: level !== undefined && level >= e.level,
            })}
          ></span>
        ))}
    </div>
  );
};

export { SkillLevel };
