import React from "react";

import { type Themes } from "components/commonProps";

import { useMediaQueries } from "../../hooks/useMediaQueries";

import { TrainingProgramListItem } from "./TrainingProgramListItem";
import { TrainingProgramCard } from "./TrainingProgramCard";

export interface TrainingProgramProps {
  readonly title: string;
  readonly details?: string[];
  readonly rating?: {
    score: number;
    voters: number;
  };
  readonly organisation?: {
    iconUrl: string;
    label: string;
  };
  readonly isSelected?: boolean;
  readonly onSelectionChange?: (isSelected: boolean) => void;
  readonly onShowProgram?: () => void;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

export function TrainingProgram(props: TrainingProgramProps) {
  const { isMobile } = useMediaQueries();
  return isMobile ? <TrainingProgramListItem {...props} /> : <TrainingProgramCard {...props} />;
}
