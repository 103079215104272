import { useEffect, useState } from "react";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default function useCookieConsent() {
  const [cookieConsentState, setCookieConsentState] = useState({
    necessary: true,
    functionality: false,
    analytics: false,
  });

  useEffect(() => {
    CookieConsent.run({
      root: "#cookie-consent",
      mode: "opt-in",
      disablePageInteraction: false,
      revision: 0,
      autoShow: true,
      manageScriptTags: false,
      autoClearCookies: false,
      onConsent: ({ cookie }) => {
        setCookieConsentState({
          necessary: cookie.categories.includes("necessary"),
          functionality: cookie.categories.includes("functionality"),
          analytics: cookie.categories.includes("analytics"),
        });
      },
      onChange: ({ cookie }) => {
        setCookieConsentState({
          necessary: cookie.categories.includes("necessary"),
          functionality: cookie.categories.includes("functionality"),
          analytics: cookie.categories.includes("analytics"),
        });
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functionality: {
          enabled: true,
        },
        analytics: {
          enabled: true,
        },
      },
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom left",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "Your Privacy is Important to Us",
              description:
                "To provide you with the best experience, we use cookies on our site for technical and analytical purposes. By continuing to browse or by clicking 'Accept all', you agree to our use of cookies. You can manage your preferences at any time.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description:
                    "These cookies are essential for the operation of our site, enabling core functionality such as security, network management, and accessibility. They do not gather information about you that could be used for marketing or remember where you've been on the internet. These cookies cannot be disabled.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Functionality Cookies",
                  description:
                    "Functional cookies enable advanced features and personalization on our site. They are used for error tracking, providing product news updates, supporting interactive chatbots, and improving user interface based on your preferences. These cookies enhance your browsing experience by remembering your preferences and settings. Accepting these cookies is optional.",
                  linkedCategory: "functionality",
                },
                {
                  title: "Analytics Cookies",
                  description:
                    "Analytics cookies collect information about how you use our website, such as which pages you visit most often and if you receive error messages from web pages. This data helps us to improve the functionality of our site, understand user interaction, and measure the effectiveness of our services. These cookies are used for statistical purposes only and do not collect information that identifies a visitor. Accepting these cookies is optional but can help us to provide you with a better user experience.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
          fr: {
            consentModal: {
              title: "Nous respectons votre vie privée",
              description:
                "Pour vous offrir une meilleure expérience, nous utilisons des cookies sur notre site à des fins techniques et analytiques. En cliquant sur Tout accepter, vous consentez à l'utilisation des cookies. Vous pouvez gérer vos préférences à tout moment.",
              acceptAllBtn: "Tout accepter",
              acceptNecessaryBtn: "Tout rejeter",
              showPreferencesBtn: "Gérer les préférences",
            },
            preferencesModal: {
              title: "Préférences de cookies",
              acceptAllBtn: "Tout accepter",
              acceptNecessaryBtn: "Tout rejeter",
              savePreferencesBtn: "Sauvegarder les préférences",
              closeIconLabel: "Fermer la modale",
              serviceCounterLabel: "Services",
              sections: [
                {
                  title:
                    'Cookies Strictement Nécessaires <span class="pm__badge">Toujours Activé</span>',
                  description:
                    "Ces cookies sont essentiels pour le fonctionnement de notre site, permettant des fonctionnalités de base telles que la sécurité, la gestion du réseau et l'accessibilité. Ils ne recueillent pas d'informations sur vous qui pourraient être utilisées à des fins de marketing ou se souvenir des sites que vous avez visités sur Internet. Ces cookies ne peuvent pas être désactivés.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Cookies de Fonctionnalités",
                  description:
                    "Les cookies fonctionnels permettent des fonctionnalités avancées et une personnalisation sur notre site. Ils sont utilisés pour le suivi des erreurs, fournir des mises à jour sur les nouveautés produits, soutenir les chatbots interactifs, et améliorer l'interface utilisateur en fonction de vos préférences. Ces cookies améliorent votre expérience de navigation en se souvenant de vos préférences et paramètres. L'acceptation de ces cookies est facultative.",
                  linkedCategory: "functionality",
                },
                {
                  title: "Cookies Analytiques",
                  description:
                    "Les cookies analytiques recueillent des informations sur la manière dont vous utilisez notre site Web, telles que les pages que vous visitez le plus souvent et si vous recevez des messages d'erreur des pages web. Ces données nous aident à améliorer la fonctionnalité de notre site, à comprendre l'interaction des utilisateurs, et à mesurer l'efficacité de nos services. Ces cookies sont utilisés à des fins statistiques uniquement et ne recueillent pas d'informations permettant d'identifier un visiteur. L'acceptation de ces cookies est facultative mais peut nous aider à vous offrir une meilleure expérience utilisateur.",
                  linkedCategory: "analytics",
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return {
    showCookieConsent: CookieConsent.show,
    cookieConsentState,
  };
}
