import { withTheme } from "../DesignSystemContext";
import {
  NavigationSideNav as PureNavigationSideNav,
  type NavigationSideNavProps,
} from "./NavigationSideNav";
import { type ItemPropsWithTheme } from "./NavigationItem";
import { type SubItemPropsWithTheme } from "./NavigationSubItem";

export const Item = withTheme<ItemPropsWithTheme>(PureNavigationSideNav.Item);
export const SubItem = withTheme<SubItemPropsWithTheme>(PureNavigationSideNav.SubItem);

export const NavigationSideNav = withTheme<NavigationSideNavProps>(PureNavigationSideNav);
