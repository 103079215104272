import React, { type ReactNode } from "react";
import cx from "classnames";
import { type NavLinkProps } from "react-router-dom";

import { Flex } from "../../components/Flex";

import styles from "./FloatingTabs.module.scss";

export interface Tab {
  readonly link: string;
  readonly label: string;
  readonly active?: boolean;
}

export interface Props {
  readonly className?: string;
  readonly navLink: (props: NavLinkProps & { key: string }) => ReactNode;
  readonly tabs: Array<Tab>;
}

const FloatingTabs = ({ className, navLink, tabs }: Props) => {
  return (
    <Flex className={cx(styles.FloatingTabs, className)}>
      {tabs.map(({ link, label, active }) => {
        return navLink({
          key: label,
          className: cx(styles.tab, { [styles.active]: !!active }),
          to: link,
          children: <span className={styles.label}>{label}</span>,
        });
      })}
    </Flex>
  );
};

export { FloatingTabs };
