import React, { type FunctionComponent, useContext } from "react";

import { Themes } from "../commonProps";

interface DesignSystemContextProviderProps {
  children: React.ReactNode;
  context: ContextType;
}

type ContextType =
  | {
      theme: Themes.ESPACE_COLLABORATEUR;
    }
  | {
      theme: Themes.ESPACE_RH;
    };

export const DesignSystemContext = React.createContext<ContextType>({
  theme: Themes.ESPACE_COLLABORATEUR,
});

/**
 * This component is used to provide context data to the design system components.
 * First usage will be theme, but it can be used for any data that's needed accross
 * multiple components within the same app.
 */
export const DesignSystemContextProvider = ({
  children,
  context,
}: DesignSystemContextProviderProps) => {
  return <DesignSystemContext.Provider value={context}>{children}</DesignSystemContext.Provider>;
};

/**
 * This component is used to consume context data in order for the design system components
 * to be able to access and use it.
 * @param Component The component to wrap
 * @returns The component with the context data
 */
export function withTheme<T extends { theme: Themes }>(
  Component: FunctionComponent<Omit<T, "theme"> & { theme: Themes }>,
  withRef?: boolean
) {
  /**
   * If you arrived here to find the component, you're already in the good folder.
   * You're here because we've wrapped the component with this function.
   * To find the component directly from where you're coming, ctrl+click on one of the props
   */
  if (!withRef) {
    return (props: Omit<T, "theme">) => {
      const context = useContext(DesignSystemContext);
      return <Component {...props} theme={context.theme} />;
    };
  }
  return React.forwardRef((props: Omit<T, "theme">, ref) => {
    const context = useContext(DesignSystemContext);
    return <Component {...props} ref={ref} theme={context.theme} />;
  });
}

export const SkuiSpacing = {
  xxs: "0.25rem",
  xs: "0.5rem",
  s: "1rem",
  m: "1.5rem",
  l: "2rem",
  xl: "2.5rem",
  xxl: "3rem",
  "3xl": "3.5rem",
  jumbo: "4rem",
};

export const DSColor = {
  "espace-rh-text": "black",
  "espace-rh-text-darkMode": "white",

  // Plain text
  "plainText-onLight-lighter": "#69798c",
  "plainText-onLight-default": "#1a304d",
  "plainText-onDark-default": "white",
  "placeholder-onLight": "#9aa7b8",
  "plainText-onDark-darker": "#c1cfdc",
  "plainText-onLight-placeholder": "rgba(26, 48, 77, 0.5)",

  // Surface
  "surface-light": "#ffffff",
  "surface-light-darker": "#fafafa",
  "surface-dark": "#0c5d88",
  "surface-dark-darker": "#0a4d71",
  "surface-scrim": "rgba(0, 0, 0, 0.6)",

  // Border
  "border-onLight": "rgba(105, 121, 140, 0.2)",
  "border-onDark": "rgba(255, 255, 255, 0.4)",

  // Action
  "action-notification": "#ff3d33",
  "action-onLight-lightest": "#c0e2f1",
  "action-onLight-lighter": "#7ec2e2",
  "action-onLight-default": "#0088c7",
  "action-onLight-darker": "#0076ad",
  "action-onLight-darkest": "#00547a",
  "action-onDark-lightest": "#ffdb80",
  "action-onDark-lighter": "#ffc21a",
  "action-onDark-default": "#ffbb00",
  "action-onDark-darker": "#f6b604",
  "action-onDark-darkest": "#e1a809",

  // Status
  "status-error-lightest": "#fdeded",
  "status-error-lighter": "#fad3d1",
  "status-error-default": "#ff3d33",
  "status-error-darker": "#db0b00",
  "status-success-lightest": "#d9f7f0",
  "status-success-lighter": "#9eead6",
  "status-success-default": "#0eaf84",
  "status-success-darker": "#005223",
  "status-warning-lightest": "#fcf1e8",
  "status-warning-lighter": "#f8d9c4",
  "status-warning-default": "#fe9d58",
  "status-warning-darker": "#b74d01",

  // Effects
  "effects-hover-onLight": "rgba(193, 207, 220, 0.3)",
  "effects-hover-onDark": "rgba(5, 38, 56, 0.5)",

  // Greyscale
  "greyscale-lighter": "#f6f7f8",
  "greyscale-light": "#e5e8eb",
  "greyscale-medium": "#c9ced4",
  "greyscale-dark": "#909aa7",
  "greyscale-darker": "#1a304d",

  // Decoration
  "decoration-blue-lightest": "#f3fbfc",
  "decoration-blue-lighter": "#ceedf3",
  "decoration-blue-base": "#60c3d7",
  "decoration-blue-darker": "#4490a7",
  "decoration-yellow-darker": "#b28600",
  "decoration-yellow-base": "#ffbb00",
  "decoration-yellow-lighter": "#feedb4",
  "decoration-yellow-lightest": "#fffbec",
  "decoration-red-darker": "#ad4a48",
  "decoration-red-base": "#f4645d",
  "decoration-red-lighter": "#fcd2cf",
  "decoration-red-lightest": "#fef4f3",
  "decoration-green-darker": "#6b9627",
  "decoration-green-base": "#99cd32",
  "decoration-green-lighter": "#e1f0c1",
  "decoration-green-lightest": "#f8fbf0",
  "decoration-pink-darker": "#b375a7",
  "decoration-pink-base": "#fe9fd8",
  "decoration-pink-lighter": "#ffe0f2",
  "decoration-pink-lightest": "#fff7fc",
  "decoration-orange-darker": "#b24a00",
  "decoration-orange-base": "#ff6600",
  "decoration-orange-lighter": "#ffd1b3",
  "decoration-orange-lightest": "#fff4ec",

  // Brand
  "brand-blue": "#60c3d7",

  // Elevations (box-shadow)
  "elevation-modal": "0px 4px 16px rgba(0, 0, 0, 0.08)",
  "elevation-menu": "0px 4px 16px rgba(0, 0, 0, 0.08)",
  "elevation-nonActionableCard": "0px 0px 2px rgba(12, 92, 136, 0.15)",
  "elevation-actionableCard": "0px 2px 4px 0px rgba(12, 92, 136, 0.15)",
  "elevation-actionableCard-hover": "2px 4px 8px rgba(12, 92, 136, 0.2)",
  "elevation-dataTable": "0px 0px 2px rgba(12, 92, 136, 0.15)",
  "elevation-datePicker": "0px 0px 2px rgba(12, 92, 136, 0.15)",
  "elevation-listItem": "0px 2px 4px rgba(12, 92, 136, 0.15)",
  "elevation-listItem-hover": "2px 4px 8px rgba(12, 92, 136, 0.2)",
  "elevation-alert": "0px 2px 4px rgba(12, 92, 136, 0.15)",

  // Panel shadows (box-shadow)
  "panel-shadow-below": "0px 4px 4px rgba(0, 0, 0, 0.1)",
  "panel-shadow-above": "0px -4px 4px rgba(0, 0, 0, 0.1)",
  "panel-shadow-right": "4px 0px 4px rgba(0, 0, 0, 0.1)",
  "panel-shadow-left": "-4px 0px 4px rgba(0, 0, 0, 0.1)",
};

const commonStyles = {
  h1: {
    fontWeight: 600,
    lineHeight: "3rem",
    fontSize: "2rem",
  },
  h2: {
    fontWeight: 600,
    lineHeight: "2.625rem",
    fontSize: "1.75rem",
  },
  h3: {
    fontWeight: 600,
    lineHeight: "2.25rem",
    fontSize: "1.5rem",
  },
  h4: {
    fontWeight: 600,
    lineHeight: "1.875rem",
    fontSize: "1.25rem",
  },
  h5: {
    fontWeight: 600,
    lineHeight: "1.6875rem",
    fontSize: "1.125rem",
  },
  h6: {
    fontWeight: 600,
    lineHeight: "1.5rem",
    fontSize: "1rem",
  },
  body1Regular: {
    fontWeight: 400,
    lineHeight: "1.5rem",
    fontSize: "1rem",
  },
  body1Bold: {
    fontWeight: 600,
    lineHeight: "1.5rem",
    fontSize: "1rem",
  },
  body1Link: {
    fontWeight: 400,
    lineHeight: "1.5rem",
    fontSize: "1rem",
    textDecoration: "underline",
  },
  body1Italic: {
    fontWeight: 400,
    lineHeight: "1.5rem",
    fontSize: "1rem",
    fontStyle: "italic",
  },
  body2Regular: {
    fontWeight: 400,
    lineHeight: "1.3125rem",
    fontSize: "0.875rem",
  },
  body2Bold: {
    fontWeight: 600,
    lineHeight: "1.3125rem",
    fontSize: "0.875rem",
  },
  body2Link: {
    fontWeight: 400,
    lineHeight: "1.3125rem",
    fontSize: "0.875rem",
    textDecoration: "underline",
  },
  captionRegular: {
    fontWeight: 400,
    lineHeight: "1.125rem",
    fontSize: "0.75rem",
  },
  captionBold: {
    fontWeight: 600,
    lineHeight: "1.125rem",
    fontSize: "0.75rem",
  },
  captionLink: {
    fontWeight: 400,
    lineHeight: "1.125rem",
    fontSize: "0.75rem",
    textDecoration: "underline",
  },
};

const commonStylesWithFont = (fontFamily: string) => {
  const newStyles = { ...commonStyles };
  for (const style in commonStyles) {
    newStyles[style] = { ...commonStyles[style], fontFamily };
  }

  return newStyles;
};

export const EspaceFont = {
  rh: commonStylesWithFont("Noto Sans Display, Helvetica Neue, sans-serif"),
  collab: commonStylesWithFont("DM Sans, Helvetica Neue, sans-serif"),
};
