import { useAsync, useSetState } from "react-use";

import request from "helpers/request";
import useHelpScout from "hooks/useHelpScout";
import Logo from "./Logo";

import styles from "./LoginViewStyles.module.scss";

import { AuthRoutes } from "api/types";

interface Props {
  hash: string;
}

const OTLCheck = ({ hash }: Props) => {
  const [{ newLink, humanValidation }, setState] = useSetState({
    newLink: false,
    humanValidation: false,
  });
  const helpScout = useHelpScout();

  const link = useAsync(async (): Promise<
    AuthRoutes["GET"]["/v1/link/{hash}"]["response"] | undefined
  > => {
    let data;

    if (newLink) {
      await request<AuthRoutes["POST"]["/v1/link"]["response"]>({
        target: "AUTH",
        data: { hash },
        method: "POST",
        url: `/link`,
      });
    } else {
      ({ data } = await request<AuthRoutes["GET"]["/v1/link/{hash}"]["response"]>({
        target: "AUTH",
        url: `/link/${hash}${humanValidation ? "?human=true" : ""}`,
      }));

      if (!data.humanValidationRequired && data.token && data.credentials) {
        const { REACT_APP_PUBLIC_APP_URL } = process.env;

        if (data.credentials.persistStrategy === "localStorage") {
          window.localStorage.setItem("X-Auth-Token", data.token);
          window.sessionStorage.removeItem("X-Auth-Token");
        } else if (data.credentials.persistStrategy === "sessionStorage") {
          window.sessionStorage.setItem("X-Auth-Token", data.token);
          window.localStorage.removeItem("X-Auth-Token");
        } else {
          window.sessionStorage.removeItem("X-Auth-Token");
          window.localStorage.removeItem("X-Auth-Token");
        }

        if (data.redirectUrl) {
          window.location.href = data.redirectUrl;
        } else if (data.credentials?.activeTrainingOrganization) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
        } else if (data.credentials?.activeCompany?.userScope?.includes("admin")) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
        } else {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}collaborateur/`;
        }
      }
    }

    return data;
  }, [hash, newLink, humanValidation]);

  if (link.error) {
    if (newLink) {
      return (
        <div className={styles.view}>
          <div className={styles.box}>
            <Logo />
            <h1>Erreur</h1>
            <p>
              Identifiant invalide, veuillez contacter votre service RH pour valider votre accès.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Lien expiré</h1>
          <p>Ce lien de connexion est expiré. Recevez un nouveau lien par e-mail.</p>
          <div className={styles.actions}>
            <button onClick={() => setState({ newLink: true })}>Recevoir un nouveau lien</button>
          </div>
        </div>
      </div>
    );
  }

  if (link.value?.humanValidationRequired) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Bienvenue</h1>
          <p>Pour accéder à votre espace, cliquez sur le bouton ci-dessous.</p>
          <div className={styles.actions}>
            <button onClick={() => setState({ humanValidation: true })}>
              Accéder à mon espace
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (newLink) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>E-mail envoyé</h1>
          <p>
            Votre lien de connexion vous a été envoyé par e-mail. Pensez à vérifier vos courriers
            indésirables.
          </p>
          <button className={styles.link} onClick={helpScout.open}>
            Je n'ai pas reçu mon e-mail
          </button>
          {process.env.REACT_APP_ENV === "development" && (
            <>
              <br />
              <a
                className={styles.link}
                href={`http://${window.location.hostname}:1080`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ouvrir le mailcatcher
              </a>
            </>
          )}
          {!["development", "production"].includes(process.env.REACT_APP_ENV ?? "") && (
            <>
              <br />
              <a
                className={styles.link}
                href={process.env.REACT_APP_MAILCATCHER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ouvrir le mailcatcher
              </a>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Veuillez patienter</h1>
        <p>Récupération des paramètres de connexion au service en cours.</p>
      </div>
    </div>
  );
};

export default OTLCheck;
