import React from "react";
import cx from "classnames";

import styles from "./SkillLevel.module.scss";

interface SkillLevelProps {
  readonly level?: number;
  readonly totalLevel: Array<{ level: number }>;
  readonly expectedLevel?: number;
}

const SkillLevel = ({ level, totalLevel, expectedLevel }: SkillLevelProps) => {
  return (
    <div className={cx(styles.SkillLevel)}>
      {totalLevel
        .sort((a, b) => a.level - b.level)
        .map((e) => (
          <span
            key={e.level}
            className={cx(styles.level, {
              [styles.reached]: level !== undefined && level >= e.level,
            })}
          >
            <span
              className={cx(styles.expectedLevel, {
                [styles.expected]: expectedLevel !== undefined && expectedLevel === e.level,
              })}
            ></span>
          </span>
        ))}
    </div>
  );
};

export { SkillLevel };
