import React, { type CSSProperties } from "react";
import { round } from "lodash";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { type Themes } from "components/commonProps";
import { Star, HalfStar } from "../../../assets/Icon";
import colors from "../../../atoms/Colors/ColorsExport.module.scss";
import spacing from "../../../atoms/SpacingTokens/SpacingExport.module.scss";
import { Flex } from "../../Flex";

import styles from "./Rating.module.scss";

export interface Props {
  readonly theme: Themes;
  readonly className?: string;
  readonly value?: number;
  readonly max?: number;
  readonly style?: CSSProperties;
  readonly grade?: boolean;
  readonly reviews?: number;
  readonly darkMode?: boolean;
  readonly vertical?: boolean;
}

const Rating = ({ ...props }: Props): JSX.Element => {
  const {
    className,
    value = 0,
    max = 5,
    style,
    grade,
    reviews,
    theme,
    darkMode = false,
    vertical = false,
  } = props;
  const score = (value / max) * 5;
  const { t } = useTranslation();

  const renderStar = (_v: unknown, index: number): JSX.Element => {
    const props = {
      key: index,
      size: spacing.skuiSpacingS,
      className: styles.star,
    };

    if (score >= index + 1) return <Star {...props} color={colors["decoration-yellow-base"]} />;

    if (score > index)
      return (
        <HalfStar
          {...props}
          backgroundColor={darkMode ? colors["greyscale-darker"] : colors["greyscale-light"]}
          color={colors["decoration-yellow-base"]}
        />
      );

    return (
      <Star {...props} color={darkMode ? colors["greyscale-darker"] : colors["greyscale-light"]} />
    );
  };

  return (
    <Flex
      className={cx(
        styles.Rating,
        className,
        styles[theme],
        { [styles.darkMode]: darkMode },
        { [styles.vertical]: vertical }
      )}
      style={style}
      data-score={score}
      role="container"
      {...(vertical && { column: true })}
    >
      <Flex>{Array.from({ length: 5 }).map(renderStar)}</Flex>
      {grade && (
        <Flex className={styles.grade}>
          <p className={styles.dividend}>{round(value, 1)}</p>
          <p className={styles.divisor}>/{max}</p>
        </Flex>
      )}
      {reviews && (
        <p className={styles.reviews}>
          ({reviews}{" "}
          {t("training.reviews", {
            defaultValue: "Avis",
          })}
          )
        </p>
      )}
    </Flex>
  );
};

export { Rating };
