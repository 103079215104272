import React from "react";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import styles from "./Toggle.module.scss";

export interface IProps {
  readonly "aria-label"?: string;
  readonly className?: string;
  readonly onToggle?: (status?: boolean) => void;
  readonly active?: boolean;
  readonly disabled?: boolean;
  readonly darkMode?: boolean;
}

export function toggleEvent({
  active,
  disabled,
  onToggle,
}: Pick<IProps, "active" | "disabled" | "onToggle">) {
  return () => !disabled && onToggle?.(!active);
}

const Toggle = ({
  "aria-label": ariaLabel,
  className,
  onToggle,
  active,
  disabled,
  darkMode,
}: IProps) => {
  const handleToggle = toggleEvent({ active, disabled, onToggle });

  return (
    <Flex
      className={cx(styles.Toggle, className, {
        [styles.active]: active,
        [styles.disabled]: disabled,
        [styles.darkMode]: darkMode,
      })}
      data-testid="switch"
      role="switch"
      aria-checked={active}
      onClick={handleToggle}
      aria-label={ariaLabel}
    >
      <Flex center className={styles.slider} />
    </Flex>
  );
};

export { Toggle };
