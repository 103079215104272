import React, { type FC } from "react";
import cx from "classnames";
import { Pie as NPie, type PieTooltipProps } from "@nivo/pie";
import { type LegendProps } from "@nivo/legends";
import { useMeasure } from "react-use";

import { Legend, LegendStyle } from "../../atoms/Legend";
import styles from "./Pie.module.scss";

export type IPieLegend = Partial<LegendProps>;

export type PieProps = {
  // Values Props
  readonly data: Array<{
    value: string;
    label: string;
    color: string;
  }>;

  // Chart options
  readonly margin?: {
    readonly top?: number;
    readonly right?: number;
    readonly bottom?: number;
    readonly left?: number;
  };
  readonly width: number;
  readonly height: number;
  readonly innerRadius?: number;
  readonly cornerRadius?: number;

  // Legends props
  readonly legends?: Array<IPieLegend>;
  readonly legendsByRow?: number;

  // Tooltip props
  readonly tooltip?:
    | FC<PieTooltipProps<{ id: number; label: string; value: string; color: string }>>
    | undefined;

  // Interactivity props
  readonly isInteractive?: boolean;
  readonly animate?: boolean;
  readonly activeOuterRadiusOffset?: number;

  readonly startAngle?: number;
  readonly endAngle?: number;

  readonly className?: string;
};

const padAngle = 0;

const defaultMargins = {
  top: 50,
  right: 0,
  bottom: 20,
  left: 0,
};

const defaultLegendsSettings: LegendProps = {
  anchor: "bottom",
  direction: "row",
  translateY: 0,
  translateX: -4,
  itemsSpacing: 8,
  itemWidth: 60,
  itemHeight: 14,
  itemTextColor: "#999",
  itemDirection: "left-to-right",
  itemOpacity: 1,
  symbolSize: 14,
  symbolShape: "circle",
};

const ROW_PADDING = 16;
const COLUMN_PADDING = 16;

const Pie = ({
  data: pData,
  margin: pMargin,
  innerRadius = 0,
  cornerRadius = 0,
  legends,
  legendsByRow = 3,
  className,
  width: pWidth,
  height: pHeight,
  tooltip,
  isInteractive = false,
  animate = false,
  activeOuterRadiusOffset = 0,
  startAngle = 0,
  endAngle = 360,
}: PieProps) => {
  const data = pData.map((rest, index) => ({ id: index, ...rest }));
  const [ref, { width: realWidth, height: realHeight }] = useMeasure();
  const width = Math.floor(realWidth || pWidth);
  const height = Math.floor(realHeight || pHeight);

  const margin = {
    ...defaultMargins,
    ...pMargin,
  };

  return (
    // @ts-expect-error ref type
    <div ref={ref} className={cx(styles.Pie, className)}>
      <NPie
        data={data}
        width={width}
        height={height}
        innerRadius={innerRadius}
        enableArcLabels={false}
        layers={[
          "arcs",
          () => {
            const isColumn = legends?.[0]?.direction === "column";
            const itemWidth = isColumn ? 80 : Math.floor(width / legendsByRow);
            return (
              <g>
                {legends?.map((legendGroup, groupIndex) => {
                  const spacing = legendGroup.itemsSpacing || 0;
                  const itemHeight = legendGroup.itemHeight || 15;
                  return legendGroup?.data?.map((legend, legendIndex) =>
                    isColumn ? (
                      <Legend
                        key={legendIndex}
                        legend={legend}
                        {...legendGroup}
                        translateX={
                          width -
                          (itemWidth + COLUMN_PADDING) -
                          (legends.length - 1 - groupIndex) * (itemWidth + COLUMN_PADDING)
                        }
                        translateY={
                          height -
                          itemHeight / 2 -
                          ((legendGroup.data?.length || 0) - 1 - legendIndex) *
                            (itemHeight + spacing)
                        }
                      />
                    ) : (
                      <Legend
                        key={legendIndex}
                        legend={legend}
                        {...legendGroup}
                        itemWidth={itemWidth}
                        translateX={legendIndex * (itemWidth + spacing)}
                        translateY={height - itemHeight - groupIndex * (itemHeight + ROW_PADDING)}
                      />
                    )
                  );
                })}
              </g>
            );
          },
        ]}
        enableArcLinkLabels={false}
        padAngle={padAngle}
        cornerRadius={cornerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        margin={margin}
        colors={data.map(({ color }) => color)}
        theme={{
          legends: {
            text: LegendStyle,
          },
        }}
        legends={legends?.map((legend) => ({
          ...defaultLegendsSettings,
          ...legend,
        }))}
        tooltip={tooltip}
        isInteractive={tooltip ? true : isInteractive}
        animate={animate}
        activeOuterRadiusOffset={activeOuterRadiusOffset}
      />
    </div>
  );
};

export { Pie };
