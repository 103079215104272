import React, { type ReactNode, type ButtonHTMLAttributes } from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import styles from "./Button.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly kind?: "primary" | "secondary" | "danger";
  readonly label?: string;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  readonly icon?: (isHover: boolean) => ReactNode;
  readonly fullWidth?: boolean;
}

const Button = ({
  className,
  label,
  disabled = false,
  readOnly = false,
  fullWidth = false,
  icon,
  kind = "primary",
  onClick,
  ...props
}: Props) => {
  const [isHover, toggleHover] = useToggle(false);

  if (disabled) return null;

  return (
    <button
      data-testid="button-container"
      className={cx(styles.Button, className, styles[kind], {
        [styles.readOnly]: readOnly,
        [styles.hasIcon]: icon,
        [styles.fullWidth]: fullWidth,
      })}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={readOnly ? undefined : onClick}
      {...props}
    >
      {icon && icon(isHover)}
      {label && (
        <span className={styles.label} data-testid="button-label">
          {label}
        </span>
      )}
    </button>
  );
};

export { Button };
