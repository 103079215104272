import { useParams, useLocation } from "react-router-dom";
import qs from "qs";
import { isEmpty } from "lodash";

import { AuthStrategyType } from "hooks/types";
import useAuth from "hooks/useAuth";

import LoginView from "./LoginView";
import OrganizationCheck from "./OrganizationCheck";
import OTLCheck from "./OTLCheck";
import SAMLRequest from "./SAMLRequest";
import Logout from "./Logout";
import Password from "./Password";
import UpdatePassword from "./UpdatePassword";

interface Query {
  organization?: string;
  redirect?: string;
  reason?: string;
  strategy?: string;
}

const Login = () => {
  const { organizationHasBeenFetched, authStrategy, step } = useAuth();

  const params = useParams<{ hash?: string }>();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as Query;

  if (!organizationHasBeenFetched && query.organization && !isEmpty(query.organization)) {
    return <OrganizationCheck reference={query.organization} strategy={query.strategy} />;
  }

  if (query.reason && !isEmpty(query.reason)) {
    return <Logout reason={query.reason} redirect={query.redirect} />;
  }

  if (params.hash && !isEmpty(params.hash)) {
    return <OTLCheck hash={params.hash} />;
  }

  if (authStrategy && authStrategy.type === AuthStrategyType.PASSWORD) {
    if (step === "password-update") {
      return <UpdatePassword redirect={query.redirect} />;
    }
    return <Password redirect={query.redirect} />;
  }

  if (authStrategy && authStrategy.type === AuthStrategyType.SSO && authStrategy.authProvider) {
    return (
      <SAMLRequest
        provider={authStrategy.authProvider}
        settings={authStrategy.authSettings}
        redirect={query.redirect}
      />
    );
  }

  return <LoginView redirect={query.redirect} />;
};

export default Login;
