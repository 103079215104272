import { useMemo } from "react";
import { type Column, type Table } from "@tanstack/react-table";

import { type Column as ColumnDef, type DataTableRow } from "../types";

export function useParentOffset<R extends DataTableRow>(
  column: Column<R>,
  table: Table<any>,
  mode: "compact" | "large"
) {
  const parentOffset = useMemo(() => {
    const columnDef = column.columnDef.meta as ColumnDef<R> & { checkboxes: boolean };
    let offset = 0;
    if (columnDef.sticky) {
      const keys = Object.keys(table.getState().columnSizing);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === column.id) {
          break;
        }
        offset += table.getState().columnSizing[keys[i]];
      }
      if (columnDef.checkboxes) {
        if (mode === "large") {
          offset += 3 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        } else {
          offset += 2 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        }
      }
    }
    return offset;
  }, [table.getState().columnSizing, column]);

  return parentOffset;
}
