import { withTheme } from "components/DesignSystemContext";
import { List as DSList, ListItem, type ListProps } from "./List";

type ThemedList = ReturnType<typeof withTheme<ListProps>>;

type ListType = ThemedList & {
  Item: typeof ListItem;
};

const List = Object.assign(withTheme<ListProps>(DSList), {
  Item: ListItem,
}) as ListType;

export { List as DSList };
