import { formatToLocaleDate } from "./formatDate";
import { isSameDay } from "../isSameDay/isSameDay";

function renderCellDate(date: Date): string {
  const stringDate = date.toISOString();
  const dateToLocaleDate = formatToLocaleDate(stringDate);
  if (dateToLocaleDate === "Invalid Date") {
    return "-";
  }
  const todayDate = new Date().toISOString();
  if (isSameDay(stringDate, todayDate)) {
    return "Aujourd'hui";
  }
  return dateToLocaleDate;
}
export { renderCellDate };
