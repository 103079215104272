import React from "react";
import cx from "classnames";

import styles from "./SelectItem.module.scss";

import { type Themes } from "../../commonProps";

export interface SelectItemProps {
  readonly label?: string;
  readonly theme: Themes;
  readonly isSelected?: boolean;
}

const SelectItem = ({ label, theme, isSelected }: SelectItemProps) => {
  return (
    <span className={cx(styles.SelectItem, styles[theme], { [styles.active]: isSelected })}>
      {label}
    </span>
  );
};

export { SelectItem };
