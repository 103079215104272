import React from "react";
import cx from "classnames";

import styles from "./HorizontalDivider.module.scss";

export type Spacing = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | "none";

interface Props {
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly top: Spacing;
  readonly bottom: Spacing;
}

const HorizontalDivider = ({ className, darkMode, top, bottom }: Props) => {
  return (
    <div
      data-testid="horizontal-divider"
      className={cx(
        styles.HorizontalDivider,
        { [styles.onDark]: darkMode },
        styles[`mt-${top}`],
        styles[`mb-${bottom}`],
        className
      )}
    ></div>
  );
};

export { HorizontalDivider };
