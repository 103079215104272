import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Themes } from "components/commonProps";

import { type ISubItemProps } from "./TableOfContentSubItem";

import styles from "./TableOfContentItem.module.scss";
import { debounce } from "lodash";

export interface IItemProps extends ISubItemProps {
  readonly children?: JSX.Element[] | JSX.Element;
}

export interface IItemPropsWithTheme extends Omit<IItemProps, "theme"> {
  readonly theme: Themes;
}

const TableOfContentItem = ({
  id,
  label,
  to,
  active,
  disabled,
  children,
  className,
  onClick,
  onChangeItem,
  darkMode,
  theme = Themes.ESPACE_RH,
  scrollFromLinkItemClick,
}: IItemProps) => {
  const childItems = React.Children.toArray(children);

  const debounceSetScrollFromLinkItemClick = useMemo(
    () =>
      debounce(() => {
        if (scrollFromLinkItemClick) {
          scrollFromLinkItemClick.current = false;
        }
      }, 1000),
    [scrollFromLinkItemClick]
  );

  useEffect(() => {
    return () => {
      debounceSetScrollFromLinkItemClick.cancel();
    };
  }, [debounceSetScrollFromLinkItemClick]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry.isIntersecting && !scrollFromLinkItemClick?.current) onChangeItem?.(id);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [],
      }
    );
    const element = document.querySelector(`[id="${id}"]`);
    if (element) {
      observer.observe(element);
    }

    return () => {
      const element = document.querySelector(`[id="${id}"]`);
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [id, scrollFromLinkItemClick]);

  return (
    <div
      className={cx(styles.TableOfContentItem, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
    >
      <Link
        to={to ?? ""}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (disabled) return;
          onClick?.(e);
          const element = document.querySelector(`[id="${id}"]`);
          if (element) {
            if (scrollFromLinkItemClick) {
              scrollFromLinkItemClick.current = true;
            }
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            debounceSetScrollFromLinkItemClick();
          }
        }}
        className={cx(styles.button, { [styles.active]: active, [styles.disabled]: disabled })}
      >
        {label}
      </Link>
      <div className={cx(styles.children)}>{childItems}</div>
      <hr className={cx(styles.divider)} />
    </div>
  );
};

export { TableOfContentItem };
