import React from "react";
import cx from "classnames";

import { type GridColumnMenuProps } from "@mui/x-data-grid-pro";

import { type ListUtils } from "@skillup/shared-utils";

import { useDarkMode } from "storybook-dark-mode";

import { Flex } from "components/Flex";
import { DSDropdownDivider } from "components/Dropdown";
import { DropdownContext } from "components/Dropdown/DropdownContext";
import { type Themes } from "components/commonProps";

import { type OnFilterHandler, type OnSortHandler } from "../../types";

import Styles from "./ColumnMenu.module.scss";

import { FilterItem } from "./FilterItem";
import { SortingItems } from "./SortingItems";

export const ColumnMenu =
  (
    theme: Themes,
    onFilter?: OnFilterHandler,
    onSort?: OnSortHandler,
    sorting?: ListUtils.SortingProperties
  ) =>
  (props: GridColumnMenuProps) => {
    const { hideMenu, colDef } = props;
    const darkMode = useDarkMode();

    if (!onFilter && !props.colDef.sortable) {
      return null;
    }

    return (
      <DropdownContext.Provider
        value={{
          activeIndex: -1,
          theme: theme,
          setActiveIndex: () => {},
          isLoading: false,
          setIsLoading: () => {},
          popoverActions: undefined,
          setPopoverActions: () => {},
          darkMode,
        }}
      >
        <Flex column className={cx(Styles.ColumnMenu, Styles[theme])}>
          {colDef.sortable !== false && (
            <>
              <SortingItems colDef={colDef} onSort={onSort} hideMenu={hideMenu} sorting={sorting} />
              <DSDropdownDivider />
            </>
          )}
          <FilterItem colDef={colDef} onFilter={onFilter} hideMenu={hideMenu} />
        </Flex>
      </DropdownContext.Provider>
    );
  };
