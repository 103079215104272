import React, { useRef } from "react";
import cx from "classnames";
import { type Column, type Table } from "@tanstack/react-table";

import { type Column as ColumnDef, type DataTableRow } from "../../types";
import { Add } from "../../../MaterialIcons";
import { renderText } from "../../hooks/useFilter";
import { useIsOverflow } from "../../../../hooks/useIsOverflow";
import { useParentOffset } from "../../hooks/useParentOffset";
import { Tooltip } from "components/Tooltip";

import styles from "./DataTableCell.styles.module.scss";

export interface Props<R extends DataTableRow> {
  readonly className?: string;
  readonly colIndex: number;
  readonly row: R;
  readonly column: Column<R>;
  readonly table: Table<any>;
  readonly isTableOverflow?: boolean;
  readonly hasScrolled?: boolean;
  readonly mode?: "compact" | "large";
}

export function DataTableCell<R extends DataTableRow>({
  colIndex,
  column,
  table,
  row,
  hasScrolled,
  mode = "large",
}: Props<R>) {
  const rootRef = useRef<HTMLTableCellElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const isOverflow = useIsOverflow(textRef.current);

  const columnDef = column.columnDef.meta as ColumnDef<R> & { checkboxes: boolean };
  const value = columnDef.renderCell
    ? columnDef.renderCell(row.data, row)
    : renderText(row, columnDef);
  const onClickCell = columnDef.hasActionOnClickCell?.(row);
  const isEditable = !!onClickCell;
  const minWidth = columnDef.minWidth
    ? columnDef.minWidth * parseFloat(getComputedStyle(document.documentElement).fontSize)
    : undefined;
  const width =
    table.getState().columnSizing[column.id] > 1
      ? table.getState().columnSizing[column.id]
      : undefined;

  const parentOffset = useParentOffset<R>(column, table, mode);

  const display =
    !value && isEditable ? (
      <Add
        onClick={
          onClickCell &&
          ((e) => {
            e.stopPropagation();
            onClickCell({ columnKey: column.id, row });
          })
        }
        className={styles.addIcon}
      />
    ) : (
      value
    );

  return (
    <td
      ref={rootRef}
      aria-colindex={colIndex}
      data-key={`dt-td-${column.id}`}
      className={cx(
        styles.DataTableCell,
        styles[mode],
        {
          [styles.rtl]: columnDef.cellAlign === "right",
          [styles.center]: columnDef.cellAlign === "center",
          [styles.editable]: isEditable,
          [styles.sticky]: columnDef.sticky,
          [styles.scroll]: hasScrolled,
        },
        columnDef.className
      )}
      style={
        {
          // width: isFullWidth ? undefined : width,
          width,
          minWidth: minWidth || width,
          "--parent-offset": `${parentOffset}px`,
        } as React.CSSProperties
      }
    >
      {/* TODO: simplify html structure without nested div when edit mode disappears */}
      <div
        className={cx(styles.cell, styles[mode])}
        onClick={
          onClickCell &&
          ((e) => {
            e.stopPropagation();
            onClickCell({ columnKey: column.id, row });
          })
        }
      >
        <div
          className={cx(styles.content, styles[mode], {
            [styles.isDefaultCell]: !columnDef.renderCell,
            [styles.customWidth]: !!columnDef.width,
          })}
        >
          <Tooltip
            className={styles.tooltip}
            direction="bottom"
            withPortal
            label={value}
            disabled={!isOverflow || !!columnDef.renderCell}
          >
            <div ref={textRef} className={styles.textWrapper}>
              {display}
            </div>
          </Tooltip>
        </div>
      </div>
    </td>
  );
}
