import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgRoundRepeat = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 14 15" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.82421 3.71846C3.66349 1.04541 7.23022 0.0474328 10.2348 1.51289C13.5441 3.12692 14.9184 7.11805 13.3043 10.4273C11.6903 13.7366 7.69916 15.1108 4.38989 13.4968C3.05927 12.8478 2.00566 11.7947 1.35539 10.4987C1.19027 10.1696 1.32319 9.76896 1.65228 9.60384C1.98137 9.43872 2.38201 9.57165 2.54713 9.90074C3.0674 10.9377 3.90884 11.7787 4.97439 12.2984C7.6218 13.5896 10.8147 12.4902 12.1059 9.84282C13.3972 7.19541 12.2978 4.00251 9.65035 2.71128C7.11278 1.47362 4.07405 2.43233 2.68917 4.84538H3.82421C4.1924 4.84538 4.49088 5.14386 4.49088 5.51205C4.49088 5.88024 4.1924 6.17871 3.82421 6.17871H1.15754C0.789352 6.17871 0.490875 5.88024 0.490875 5.51205V2.84538C0.490875 2.47719 0.789352 2.17872 1.15754 2.17872C1.52573 2.17872 1.82421 2.47719 1.82421 2.84538V3.71846Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgRoundRepeat };
