import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { Toast } from "@skillup/ui";
import * as Sentry from "@sentry/react";

import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import useUserData from "hooks/useUserData";
import useHelpScout from "hooks/useHelpScout";
import { useAuthPaths } from "hooks/useAuthPaths";
import useCookieConsent from "hooks/useCookieConsent";

import Signin from "containers/Signin";
import LogIn from "containers/Password";
import ResetPassword from "containers/Password/ResetPassword";
import CreatePassword from "containers/Password/CreatePassword";
import UpdatePassword from "containers/Signin/UpdatePassword";

const { NODE_ENV } = process.env;

const App = (): JSX.Element | null => {
  const user = useUserData();
  const AuthPaths = useAuthPaths();

  const { cookieConsentState } = useCookieConsent();
  // Failing to pass memoized options will trigger reload of analytics scripts
  const options = useMemo(
    () => ({ development: false, root: true, consent: cookieConsentState }),
    [cookieConsentState]
  );
  useGoogleAnalytics(options);
  useHelpScout(options);

  return (
    <Sentry.ErrorBoundary dialogOptions={{ user }} showDialog={NODE_ENV === "development"}>
      <ToastProvider placement="bottom-right" components={{ Toast }} autoDismiss>
        <Routes>
          <Route path={`${AuthPaths.DEPRECATED_LOGIN}`} element={<LogIn />} />
          <Route path={`${AuthPaths.ResetPassword}/:data/:token`} element={<ResetPassword />} />
          <Route path={`${AuthPaths.CreatePassword}/:data/:token`} element={<CreatePassword />} />
          <Route path={`${AuthPaths.UpdatePassword}/:data`} element={<UpdatePassword />} />
          <Route path={`${AuthPaths.Signin}/`} element={<Signin />} />
          <Route path={`${AuthPaths.Signin}/:hash`} element={<Signin />} />
        </Routes>
      </ToastProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
