import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ListUtils, shortString, resolveString } from "@skillup/shared-utils";
import { type FilterProp } from "./types";
import { type UseFilterProp } from "./hooks";

export const useShortenedFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  filters: UseFilterProp<Config>
) => {
  const initialFilters = useMemo(() => {
    return Object.keys(config).reduce((acc, key) => {
      const filter = filters[key];
      return {
        ...acc,
        [key]: {
          type: config[key].type,
          visibilityMode: filter?.visibilityMode,
          defaultValue: filter?.defaultValue,
          options: filter?.options,
          value: filter?.initialValue || filter?.value,
          operator: ListUtils.FilterOperator.CONTAINS,
          label: config[key]?.label,
          placeholder: filter?.placeholder,
          sortPosition: filter?.sortPosition,
        },
      };
    }, {} as FilterProp<Config>);
  }, [config, filters]);

  const [filterValues, setFilterValues] = useState<ListUtils.FilterValues<Config>>(
    Object.keys(config)
      .filter((filterId) => initialFilters[filterId]?.value != null)
      .reduce((acc, filterId) => {
        const filter = initialFilters[filterId];
        return {
          ...acc,
          [filterId]: {
            type: filter.type,
            value: filter.value,
            operator: filter.operator,
          },
        };
      }, {} as ListUtils.FilterValues<Config>)
  );

  return [initialFilters, filterValues, setFilterValues] as [
    typeof initialFilters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};

export const useUrlSyncedShortenedFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  initialValues: UseFilterProp<Config>
) => {
  const history = useHistory();
  const location = useLocation();

  const filtersConfig = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get("filter");
    let filterValues = { ...initialValues };

    if (filterParam) {
      const originalFilterString = resolveString(filterParam);
      const originalFilterValues = originalFilterString ? JSON.parse(originalFilterString) : {};

      filterValues = Object.keys(originalFilterValues).reduce(
        (acc, key) => {
          const originalFilter = originalFilterValues[key];
          const initialFilter = initialValues[key];

          return {
            ...acc,
            [key]: {
              ...originalFilter,
              options:
                originalFilter.type === "multiselect" && initialFilter?.options
                  ? initialFilter.options
                  : originalFilter.options,
            },
          };
        },
        { ...initialValues }
      );
    }

    return filterValues;
  }, [config, location.search, initialValues]);

  const [filters, filterValues, setFilterValues] = useShortenedFilters(config, filtersConfig);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    const filterString = JSON.stringify(filterValues);
    const shortFilterString = shortString(filterString);

    searchParams.set("filter", shortFilterString);
    searchParams.sort();

    const currentSearchParams = new URLSearchParams(history.location.search);
    currentSearchParams.sort();

    if (currentSearchParams.toString() !== searchParams.toString()) {
      history.replace({ search: searchParams.toString() });
    }
  }, [filterValues, config, history]);

  return [filters, filterValues, setFilterValues] as [
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
  ];
};
