import React, { type CSSProperties, useMemo } from "react";
import { type ToastProps } from "react-toast-notifications";
import cx from "classnames";

import { colors } from "../../assets/colors";
import { Flex } from "../../components/Flex";
import { Bell, ThumbDown, ThumbUp, Plus, Info } from "../Icon";

import styles from "./Toast.module.scss";

export interface IProps extends ToastProps {
  readonly style?: CSSProperties;
}

const state = {
  error: { icon: ThumbDown, color: colors.secondaryRed },
  info: { icon: Info, color: colors.primaryBlue },
  success: { icon: ThumbUp, color: colors.secondaryGreen },
  warning: { icon: Bell, color: colors.primaryYellow },
};

const Toast = (props: IProps): JSX.Element => {
  const { style, appearance, children, onDismiss, transitionState, transitionDuration } = props;

  const { icon: Icon, color } = useMemo(() => state[appearance], [state, appearance]);

  return (
    <Flex
      className={cx(styles.Toast, styles[appearance], styles[transitionState])}
      style={{
        ...style,
        transition: `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1)`,
      }}
      role="container"
    >
      <Flex className={styles.leftContainer}>
        <Icon size="1.25rem" color={colors.statusViolet} />
      </Flex>
      <Flex grow className={styles.children} role="content">
        {children}
      </Flex>
      <Flex className={styles.closeContainer} role="close">
        <Plus
          aria-label="close-toast-icon"
          className={styles.closeIcon}
          onClick={() => onDismiss()}
          color={color}
        />
      </Flex>
    </Flex>
  );
};

export { Toast };
