import React from "react";

import { type BarLegendProps, ResponsiveBar } from "@nivo/bar";
import { Legend, LegendStyle } from "../../atoms/Legend";

export type IBarLegend = Partial<BarLegendProps>;

export type BarProps = {
  // Values Props
  readonly data: Array<{
    value: string | number;
    label: string;
    color: string;
  }>;

  // Chart options
  readonly margin?: {
    readonly top?: number;
    readonly right?: number;
    readonly bottom?: number;
    readonly left?: number;
  };

  // Legends props
  readonly legends?: Array<IBarLegend>;
  readonly legendsByRow?: number;
};

const defaultMargins = {
  top: 50,
  right: 130,
  bottom: 50,
  left: 60,
};

const defaultLegendsSettings: BarLegendProps = {
  dataFrom: "keys",
  anchor: "bottom",
  direction: "row",
  translateY: 0,
  translateX: -4,
  itemsSpacing: 8,
  itemWidth: 60,
  itemHeight: 14,
  itemTextColor: "#1a304d",
  itemDirection: "left-to-right",
  itemOpacity: 1,
  symbolSize: 14,
  symbolShape: "circle",
};

const ROW_PADDING = 16;
const COLUMN_PADDING = 16;

const Bar = ({ data: pData, margin: pMargin, legends, legendsByRow = 3 }: BarProps) => {
  const data = pData.map(({ value, label, color }, index) => ({
    id: index,
    label,
    value,
    color,
  }));

  const margin = {
    ...defaultMargins,
    ...pMargin,
  };

  return (
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="label"
      layers={[
        "bars",
        ({ bars }) => {
          return (
            <g>
              {bars?.map(({ width, x, y, data }, i) => {
                return (
                  <text
                    key={i}
                    transform={`translate(${x + Math.floor(width / 2) - 10}, ${y - 12})`}
                    textAnchor="left"
                    dominantBaseline="central"
                    style={LegendStyle}
                  >{`${data.value ?? ""}`}</text>
                );
              })}
            </g>
          );
        },
        (layerData) => {
          const isColumn = legends?.[0]?.direction === "column";
          const itemWidth = isColumn ? 80 : Math.floor(layerData.innerWidth / legendsByRow);
          return (
            <g>
              {legends?.map((legendGroup, groupIndex) => {
                const spacing = legendGroup.itemsSpacing || 0;
                const itemHeight = legendGroup.itemHeight || 15;
                return legendGroup?.data?.map((legend, legendIndex) =>
                  isColumn ? (
                    <Legend
                      key={legendIndex}
                      legend={legend}
                      {...legendGroup}
                      translateX={
                        layerData.innerWidth +
                        margin.right -
                        (itemWidth + COLUMN_PADDING) -
                        (legends.length - 1 - groupIndex) * (itemWidth + COLUMN_PADDING)
                      }
                      translateY={
                        layerData.innerHeight -
                        itemHeight / 2 -
                        ((legendGroup.data?.length || 0) - 1 - legendIndex) * (itemHeight + spacing)
                      }
                    />
                  ) : (
                    <Legend
                      key={legendIndex}
                      legend={legend}
                      {...legendGroup}
                      itemWidth={itemWidth - spacing}
                      translateX={legendIndex * itemWidth}
                      translateY={
                        layerData.innerHeight +
                        Math.floor(itemHeight / 2) +
                        ROW_PADDING +
                        groupIndex * (itemHeight + ROW_PADDING)
                      }
                    />
                  )
                );
              })}
            </g>
          );
        },
      ]}
      margin={margin}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ indexValue }) =>
        data.find(({ label }) => label === indexValue)?.color || "lightgrey"
      }
      tooltipLabel={({ indexValue }: any) => indexValue}
      axisLeft={null}
      axisBottom={null}
      animate={false}
      enableGridY={false}
      enableLabel={false}
      theme={{
        legends: {
          text: LegendStyle,
        },
      }}
      legends={legends?.map((legend) => ({
        ...defaultLegendsSettings,
        ...legend,
      }))}
    />
  );
};

export { Bar };
