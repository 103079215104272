import React from "react";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import * as Icon from "../../Icon";
import { colors } from "../../../assets/colors";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

import { Reviews } from "../Reviews";
import { Modalities } from "../Modalities";
import type { modalities } from "../modalitiesData";
import styles from "./CatalogTrainingCard.module.scss";

interface Props {
  readonly className?: string;
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  readonly trainingName: string;
  readonly disabled?: boolean;
  readonly mode: keyof typeof modalities;
  readonly isCPF?: boolean;
  readonly organizationName?: string;
  readonly duration?: string;
  readonly reviewsGlobalScore?: number;
  readonly reviewsCount?: number;
}

const CatalogTrainingCard = ({
  className,
  disabled,
  onClick,
  trainingName,
  organizationName,
  reviewsGlobalScore,
  reviewsCount,
  duration,
  mode,
  isCPF,
}: Props) => {
  const { isMobile } = useMediaQueries();

  return (
    <Flex
      className={cx(styles.CatalogTrainingCard, className)}
      onClick={onClick}
      disabled={disabled}
      data-testid="training-card-container"
      column
    >
      <div className={styles.top}>
        <Modalities mode={mode} isCPF={isCPF} disabled={isMobile} />
        <h5 className={styles.trainingName}>{trainingName}</h5>
        <span className={styles.organizationName}>par {organizationName}</span>
      </div>

      <Flex className={styles.footer}>
        <Flex className={styles.duration}>
          <Icon.Time size="1rem" color={colors.slate} />
          <span className={styles.value}>{duration}</span>
        </Flex>
        <Reviews reviewsGlobalScore={reviewsGlobalScore} reviewsCount={reviewsCount} small />
      </Flex>
    </Flex>
  );
};

export { CatalogTrainingCard };
