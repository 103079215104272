function decimalNumberToFormatedString(value: number): string {
  const valueSplitted = value.toString().split(".");
  const integer = valueSplitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  let decimal = "";

  if (valueSplitted.length > 1) {
    decimal = `.${valueSplitted[1].slice(0, 2)}`;
  }

  return `${integer}${decimal}`;
}

export default { decimalNumberToFormatedString };
