import React, { type ReactNode } from "react";
import cx from "classnames";

import { Button } from "components/Button";

import { type ICard } from "../DashboardCardView/types";
import { DashboardCardWrapper, type DashboardCardWrapperProps } from "../DashboardCardWrapper";

import styles from "./DashboardCardTable.module.scss";
import { useMedia } from "react-use";

export type IDashboardCardTable = {
  title: string;
  tooltip?: string;
  actionItems?: ReactNode[];
  firstHeaderLink?: string;
  firstColumnLabel?: boolean;
  lastColumnTotal?: boolean;
  singleRow?: boolean;
  className?: string;
  header?: Array<
    | { value?: string; align?: "right" | "left" | "center"; fill?: boolean }
    | { value?: string; align?: "right" | "left" | "center"; width?: number }
  >;
  data: Array<{
    cells: Array<{ value?: string | number; bold?: boolean; align?: "right" | "left" | "center" }>;
    highlight?: boolean;
  }>;
};

export interface DashboardCardTableProps
  extends ICard<IDashboardCardTable>,
    DashboardCardWrapperProps {}

const DashboardCardTable = ({
  componentData,
  size = "L",
  theme,
  darkMode,
  ...props
}: DashboardCardTableProps) => {
  const isWide = useMedia("(min-width: 768px)");
  const colCount = componentData.header?.length || componentData.data?.[0]?.cells?.length;

  const renderWideView = () => (
    <div
      className={styles.table}
      style={
        {
          "--col-count": colCount,
        } as React.CSSProperties
      }
    >
      <div className={cx(styles.thead, { [styles.withScroll]: componentData.data?.length > 8 })}>
        <div
          className={cx(styles.tr, styles[size], {
            [styles.firstLabel]: componentData.firstColumnLabel,
          })}
        >
          {componentData.header?.map(
            (h: { value?: string; align?: "right" | "left" | "center"; width?: number }, index) =>
              index === 0 && componentData.firstHeaderLink ? (
                <Button
                  key={index}
                  emphasis="Low"
                  buttonSize="S"
                  label={h.value}
                  onClick={() => {
                    window.location.href = componentData.firstHeaderLink as string;
                  }}
                  className={cx(styles.th, styles[size], styles[h.align || "left"], {
                    [styles.fill]: !!h.width,
                  })}
                  style={{ width: h.width }}
                />
              ) : (
                <div
                  key={index}
                  className={cx(styles.th, styles[size], styles[h.align || "left"], {
                    [styles.singleRow]: componentData.singleRow,
                    [styles.fill]: !!h.width,
                  })}
                  style={{ width: h.width }}
                >
                  {h.value}
                </div>
              )
          )}
        </div>
      </div>
      <div className={styles.tbody}>
        {componentData.data?.map((row, i) => (
          <div
            key={i}
            className={cx(styles.tr, styles[size], {
              [styles.firstLabel]: componentData.firstColumnLabel,
            })}
          >
            {row.cells.map((cell, index) => (
              <div
                key={index}
                className={cx(
                  styles.td,
                  styles[size],
                  styles[cell.align || componentData.header?.[index]?.align || "left"],
                  {
                    [styles.singleRow]: componentData.singleRow,
                    [styles.highlight]: row.highlight,
                    [styles.bold]: cell.bold,
                    // @ts-expect-error width exist but optional
                    [styles.fill]: !!componentData.header?.[index]?.width,
                  }
                )}
                style={{
                  // @ts-expect-error width exist but optional
                  width: componentData.header?.[index]?.width,
                }}
              >
                {cell.value}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

  const renderMobileView = () => (
    <div className={styles.card}>
      {componentData.firstHeaderLink && !componentData.singleRow && (
        <Button
          emphasis="Low"
          buttonSize="S"
          label={componentData.header?.[0]?.value}
          onClick={() => {
            window.location.href = componentData.firstHeaderLink as string;
          }}
          className={cx(styles.th, styles[componentData.header?.[0]?.align || "left"], {
            // @ts-expect-error width exist but optional
            [styles.fill]: !!componentData.header?.[0]?.width,
          })}
        />
      )}
      {!componentData.firstHeaderLink &&
        componentData.firstColumnLabel &&
        !componentData.singleRow && (
          <div
            className={cx(styles.th, styles[componentData.header?.[0]?.align || "left"], {
              // @ts-expect-error width exist but optional
              [styles.fill]: !!componentData.header?.[0]?.width,
            })}
          >
            {componentData.header?.[0]?.value}
          </div>
        )}
      {componentData.data?.map((row, i) => (
        <div
          key={i}
          className={cx({
            [styles.tr]: !componentData.singleRow,
            [styles.highlight]: row.highlight && !componentData.singleRow,
          })}
        >
          {componentData.singleRow &&
            row.cells.map((cell, index) => (
              <div className={styles.tr} key={index}>
                <div className={styles.th}>{componentData.header?.[index].value}</div>
                <span
                  className={cx(styles.td, styles.singleRow, {
                    [styles.highlight]: row.highlight,
                    [styles.bold]: cell.bold,
                  })}
                >
                  {cell.value}
                </span>
              </div>
            ))}
          {!componentData.singleRow && (
            <>
              <div className={styles.th}>
                {componentData.firstColumnLabel
                  ? row.cells[0].value
                  : componentData.header?.[0]?.value}
              </div>
              <div className={styles.cells}>
                {row.cells.map((cell, index) => {
                  if (componentData.firstColumnLabel && index === 0) return null;
                  if (componentData.lastColumnTotal && index === row.cells.length - 1) return null;
                  const isNotLast =
                    (!componentData.lastColumnTotal && index < row.cells.length - 1) ||
                    (componentData.lastColumnTotal && index < row.cells.length - 2);
                  return (
                    <span
                      key={index}
                      className={cx(styles.td, {
                        [styles.highlight]: row.highlight,
                        [styles.bold]: cell.bold,
                      })}
                      dangerouslySetInnerHTML={{
                        __html: `${cell.value} ${componentData.header?.[index]?.value ?? ""}${
                          isNotLast ? "&nbsp;|&nbsp;" : ""
                        }`,
                      }}
                    />
                  );
                })}
              </div>
              {componentData.lastColumnTotal && (
                <div
                  className={cx(styles.total, {
                    [styles.highlight]: row.highlight,
                    [styles.bold]: row.cells[row.cells.length - 1].bold,
                  })}
                >
                  {componentData.header?.[row.cells.length - 1]?.value}{" "}
                  {row.cells[row.cells.length - 1].value}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <DashboardCardWrapper
      {...props}
      title={componentData.title}
      tooltip={componentData.tooltip}
      actionItems={componentData.actionItems}
      className={cx(styles.DashboardCardTable, componentData.className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      size={size}
      theme={theme}
      darkMode={darkMode}
    >
      {isWide && renderWideView()}
      {!isWide && renderMobileView()}
    </DashboardCardWrapper>
  );
};

export { DashboardCardTable };
