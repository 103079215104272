import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgPortal = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63605 7C7.72608 7 7 7.73248 7 8.63605V13.5458C7 14.4557 7.73248 15.1818 8.63605 15.1818H13.5458C14.4557 15.1818 15.1818 14.4493 15.1818 13.5458V8.63605C15.1818 7.72608 14.4493 7 13.5458 7H8.63605ZM8.63605 16.8182C7.72608 16.8182 7 17.5507 7 18.4542V23.3639C7 24.2739 7.73248 25 8.63605 25H13.5458C14.4557 25 15.1818 24.2675 15.1818 23.3639V18.4542C15.1818 17.5443 14.4493 16.8182 13.5458 16.8182H8.63605ZM16.8182 18.4542C16.8182 17.5507 17.5443 16.8182 18.4543 16.8182H23.364C24.2676 16.8182 25.0001 17.5443 25.0001 18.4542V23.3639C25.0001 24.2675 24.274 25 23.364 25H18.4543C17.5507 25 16.8182 24.2739 16.8182 23.3639V18.4542ZM18.4543 7C17.5444 7 16.8183 7.73248 16.8183 8.63605V13.5458C16.8183 14.4557 17.5508 15.1818 18.4543 15.1818H23.364C24.274 15.1818 25.0001 14.4493 25.0001 13.5458V8.63605C25.0001 7.72608 24.2676 7 23.364 7H18.4543Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgPortal };
