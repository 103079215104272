import React, { useRef } from "react";
import cx from "classnames";

import { useMediaQueries } from "../../../hooks/useMediaQueries";
import { useScrollPosition } from "../../../hooks/useScrollPosition";

import styles from "../DSModal.module.scss";

export type ModalScrollableLayerProps = {
  children: React.ReactNode;
  className?: string;
  debug?: boolean;
  hideOnMobile?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  noPadding?: boolean;
};

export function ModalScrollableLayer({
  children,
  className,
  debug,
  hideOnMobile,
  borderColor,
  backgroundColor = "white",
  noPadding,
}: ModalScrollableLayerProps) {
  const { isMobile } = useMediaQueries();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollPosition, handleScroll } = useScrollPosition(scrollRef.current, "vertical");

  if (isMobile && hideOnMobile) {
    return null;
  }
  return (
    <div
      className={cx(styles.scrollableLayer, className, {
        [styles.scrollTop]: ["end", "middle"].includes(scrollPosition),
        [styles.scrollBottom]: ["start", "middle"].includes(scrollPosition),
      })}
      style={{
        backgroundColor,
        borderTop: borderColor ? `1px solid ${borderColor}` : "none",
        borderBottom: borderColor ? `1px solid ${borderColor}` : "none",
      }}
      id="modal-scrollable-layer"
    >
      <div
        className={cx(styles.scroller, { [styles.noPadding]: noPadding })}
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {debug && <div className={styles.debug}>{children}</div>}
        {!debug && children}
      </div>
    </div>
  );
}
