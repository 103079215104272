import React from "react";

import { Repeat } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const OngoingCampaign = () => (
  <RectangleTag
    label="Entretiens automatisés"
    icon={<Repeat />}
    backgroundColor="#f5f5f5"
    textColor="#92929d"
  />
);

export { OngoingCampaign };
