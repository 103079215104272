import { useMedia } from "react-use";

const MOBILE_MAX_WIDTH = 640;

const useMediaQueries = () => {
  const isTooNarrow = useMedia(`screen and (max-width: 1000px)`);
  const isMobile = useMedia(`screen and (max-width: ${MOBILE_MAX_WIDTH}px)`);
  const isPortrait = useMedia("(orientation: portrait)");
  const isLandscape = useMedia("(orientation: landscape)");
  const isRetina = useMedia("(min-resolution: 2dppx)");

  return {
    isMobile,
    isLandscape,
    isPortrait,
    isTooNarrow,
    isRetina,
  };
};

export { useMediaQueries };
