import { useEffect, useMemo } from "react";
import { debounce } from "lodash";

export function useDebounce({
  debounceValue,
  onChange,
}: {
  debounceValue?: number;
  onChange: (...args: any[]) => any;
}) {
  const debouncedOnChange = useMemo(
    () => debounce(onChange, debounceValue ?? 0),
    [onChange, debounceValue]
  );

  useEffect(() => {
    debouncedOnChange.cancel();
  }, [debouncedOnChange]);

  return debouncedOnChange;
}
