import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronRightDouble = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M16.7293 10.8472L12.1537 6.27158C11.7928 5.91069 11.2075 5.91069 10.8466 6.27158C10.4858 6.63246 10.4858 7.21776 10.8466 7.57865L14.7683 11.5003L10.8466 15.4219C10.4858 15.7828 10.4858 16.3681 10.8466 16.729C11.2075 17.0899 11.7928 17.0899 12.1537 16.729L16.7293 12.1534C17.0902 11.7934 17.0902 11.2081 16.7293 10.8472ZM11.2277 10.5701L7.59503 6.74147C7.23001 6.35724 6.63878 6.35724 6.27376 6.74147C5.90875 7.12571 5.90875 7.74902 6.27376 8.13372L9.24602 11.2663L6.27376 14.3988C5.90875 14.7831 5.90875 15.4068 6.27376 15.791C6.63878 16.1753 7.23001 16.1753 7.59503 15.791L11.2277 11.9624C11.5927 11.5782 11.5927 10.9548 11.2277 10.5701Z"
      fill={color}
    />
  </SVGBox>
);

export { ChevronRightDouble };
