import React, {
  type HTMLAttributes,
  useRef,
  useMemo,
  useState,
  useEffect,
  type UIEvent,
  Fragment,
} from "react";
import cx from "classnames";
import { findLastIndex, isEmpty, isEqual, isNil } from "lodash";
import { useUpdateEffect } from "react-use";
import { useTranslation } from "react-i18next";

import {
  type ColumnDef,
  type ColumnSizingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  type PaginationState,
  type RowSelectionState,
  type SortingState,
  useReactTable,
  type Row,
} from "@tanstack/react-table";

import styles from "./DataTable.module.scss";

import { type Themes } from "../commonProps";
import { Checkbox } from "../../Legacy_components/Checkbox";
import { LoaderBar } from "components/Loader/LoaderBar";
import { type DropdownProps } from "components/Dropdown/Dropdown";
import { Pagination } from "components/Pagination/Pagination";
import { Button } from "components/Button/Button";
import { RowActions } from "./components/RowActions";
import { type Column, type DataTableRow, type TableFilters, type TableSorts } from "./types";
import { TableHeaderCell } from "./components/TableHeaderCell";
import { DataTableCell } from "./components/DataTableCell";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import { useIsOverflow } from "../../hooks/useIsOverflow";
import { getSortKey, useFilters } from "./hooks/useFilter";
import { useCellWidth } from "./hooks/useCellWidth";
import { useIsStickyPinned } from "./hooks/useIsSticky";

export { ColumnType } from "./types";

type DataTableHeaderProps = {
  totalRowsLabel?: (totalFiltered: number, total: number) => React.ReactNode;
  selectedRowsLabel?: (selected: number, total: number) => React.ReactNode;
  actions?: () => React.ReactNode;
  className?: string;
  labelClassName?: string;
  actionsClassName?: string;
};
type PaginationProps = {
  rowsPerPage?: number;
  rowsPerPageLabel?: string;
  itemsCountLabel?: string;
  pageLabel?: string;
  className?: string;
  stateHook?: [number, (n: number) => void];
  pageSize?: number;
  setPageSize?: (n: number) => void;
  activeTablePage?: number;
  onPageChange?: (pageIndex: number) => void;
  displayNoMatchingRows?: boolean;
};
export type FilterProps<R extends DataTableRow> = {
  activeFilters?: TableFilters<R>;
  onFiltersChange?: (filters: TableFilters<R>) => void;
  defaultFilters?: TableFilters<R>;
};
export interface DataTableProps<R extends DataTableRow> extends HTMLAttributes<HTMLDivElement> {
  readonly theme: Themes;
  readonly rowsPerPage?: number;
  readonly columns: Column<R>[];
  readonly rows: R[];
  readonly header?: DataTableHeaderProps;
  readonly pagination?: PaginationProps;
  readonly filters?: FilterProps<R>;
  readonly checkboxes?: boolean;
  readonly loading?: boolean;
  readonly loadingLabel?: string;
  readonly actions?: (row: R) => DropdownProps["children"];
  readonly actionsTooltip?: string;
  readonly onClickRow?: (row: R) => void;
  readonly onCheckRows?: (ids: Set<string>) => void;
  readonly containerClassName?: string;
  readonly tableClassName?: string;
  readonly defaultSort?: TableSorts<R>;
  readonly mode?: "compact" | "large";
  readonly displayNoMatchingRows?: boolean;
}

export function DataTable<R extends DataTableRow>({
  theme,
  className,
  containerClassName,
  tableClassName,
  rows,
  columns: columnDefs,
  checkboxes,
  loading,
  loadingLabel,
  actions,
  actionsTooltip,
  onCheckRows,
  onClickRow,
  header,
  pagination,
  filters: filtersOptions,
  defaultSort,
  displayNoMatchingRows,
  mode = "large",
}: DataTableProps<R>) {
  const { t } = useTranslation();
  const { isTooNarrow } = useMediaQueries();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: pagination?.activeTablePage ?? 0,
    pageSize: pagination?.pageSize
      ? pagination.pageSize
      : pagination?.stateHook?.[0]
        ? pagination?.stateHook?.[0]
        : 10,
  });

  const tablePagination = useMemo(() => {
    if (pagination?.stateHook?.[1]) pagination.stateHook[1](pageSize);
    if (pagination?.setPageSize) pagination.setPageSize(pageSize);

    return {
      pageIndex: pagination?.activeTablePage ?? pageIndex,
      pageSize,
    };
  }, [
    pageIndex,
    pageSize,
    pagination?.stateHook,
    pagination?.setPageSize,
    pagination?.activeTablePage,
  ]);

  const initalSort = useMemo(
    () => (defaultSort ? [{ id: defaultSort.key as string, desc: !!defaultSort.desc }] : []),
    [defaultSort]
  );
  const [sorting, setSorting] = useState<SortingState>(initalSort);

  function onPageSizeChange(newPageSize: number) {
    setPagination({ pageSize: newPageSize, pageIndex });
  }

  const tableSizeObserver = useRef<ResizeObserver>();
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const scrollRef = useRef<HTMLTableSectionElement>(null);
  const isTableOverflow = useIsOverflow(theadRef.current);
  const isHeaderPinned = useIsStickyPinned(theadRef.current);

  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "middle" | "none">(
    isTableOverflow ? "start" : "none"
  );

  const lastStickyColumnKey = useMemo(() => {
    const index = findLastIndex(columnDefs, (c) => !!c.sticky);
    return columnDefs[index]?.key;
  }, [columnDefs]);

  useEffect(() => {
    setScrollPosition(isTableOverflow ? "start" : "none");
  }, [isTableOverflow]);

  const columns = useMemo<ColumnDef<R>[]>(() => {
    const columns: ColumnDef<R>[] = [];
    if (checkboxes) {
      columns.push({
        id: "select",
        // @ts-expect-error custom props scroll
        header: ({ table, scrollPosition, displayedRowsSize }) => {
          const cellWidth = useCellWidth('td[data-key="dt-td-select"]');
          return (
            <div
              key="select"
              data-key="dt-th-select"
              className={cx(styles.th, styles.selection, styles[mode], {
                [styles.scroll]:
                  (!lastStickyColumnKey || isTooNarrow) &&
                  !["none", "start"].includes(scrollPosition) &&
                  displayedRowsSize > 0,
              })}
              style={{
                width: cellWidth,
                minWidth: cellWidth,
                maxWidth: cellWidth,
              }}
              onClick={table.getToggleAllRowsSelectedHandler()}
            >
              {!!filteredRows.length && (
                <Checkbox
                  className={styles.tableCheckbox}
                  value="all"
                  aria-label="select-all"
                  checked={
                    table.getIsSomeRowsSelected() ? "intermediate" : table.getIsAllRowsSelected()
                  }
                />
              )}
            </div>
          );
        },
        // @ts-expect-error custom props scroll
        cell: ({ row, scrollPosition }) => (
          <td
            key={row.id}
            data-key="dt-td-select"
            className={cx(styles.selection, styles[mode], {
              [styles.scroll]:
                (!lastStickyColumnKey || isTooNarrow) &&
                !["none", "start"].includes(scrollPosition),
            })}
            onClick={row.getToggleSelectedHandler()}
          >
            <Checkbox
              aria-label="select-row"
              className={styles.tableCheckbox}
              value={row.id}
              checked={row.getIsSelected()}
            />
          </td>
        ),
      });
    }
    let i = 0;
    for (const columnDef of columnDefs) {
      const { sortFn } = columnDef;

      columns.push({
        id: columnDef.key as string,
        accessorFn: (row) => getSortKey(row, columnDef),
        enableSorting: columnDef.sortable !== false,
        sortDescFirst: false,
        sortingFn: sortFn ? (row1, row2) => sortFn(row1.original, row2.original) || 0 : "auto",
        meta: {
          ...columnDef,
          checkboxes,
        },
        // @ts-expect-error custom props scroll
        header: ({ column, filters, table, scrollPosition, isTableOverflow }) => (
          <TableHeaderCell
            key={column.id}
            column={column}
            filters={filters}
            table={table}
            theme={theme}
            onFilter={handleFilter}
            onCloseFilter={handleCloseFilter}
            largeFilterForm={columnDef.largeFilterForm}
            isTableOverflow={isTableOverflow}
            hasScrolled={
              lastStickyColumnKey === columnDef.key && !["none", "start"].includes(scrollPosition)
            }
            mode={mode}
            className={styles.th}
          />
        ),
        // @ts-expect-error custom props scroll
        cell: ({ row, column, table, isTableOverflow, scrollPosition }) => (
          <DataTableCell
            key={column.id}
            colIndex={i + 1}
            className={columnDef.className}
            column={column}
            table={table}
            row={row.original}
            isTableOverflow={isTableOverflow}
            hasScrolled={
              lastStickyColumnKey === columnDef.key && !["none", "start"].includes(scrollPosition)
            }
            mode={mode}
          />
        ),
      });
      i++;
    }
    if (actions) {
      columns.push({
        id: "actions",
        // @ts-expect-error custom props scroll
        cell: ({ table, row, column, scrollPosition }) => (
          <RowActions
            theme={theme}
            row={row.original}
            visible={!table.getIsSomeRowsSelected()}
            actions={actions}
            mode={mode}
            hasScrolled={!["none", "end"].includes(scrollPosition)}
            tooltip={actionsTooltip}
          />
        ),
        // @ts-expect-error custom props scroll
        header: ({ scrollPosition, displayedRowsSize }) => (
          <div
            key="actions"
            className={cx(styles.th, styles[mode], styles.action, {
              [styles.scroll]: !["none", "end"].includes(scrollPosition) && displayedRowsSize > 0,
            })}
          >
            &nbsp;
          </div>
        ),
      });
    }
    return columns;
  }, [columnDefs]);

  const { filters, filteredRows, resetFilters, handleFilter } = useFilters({
    rows,
    columns: columnDefs,
    defaultFilters: filtersOptions?.defaultFilters || filtersOptions?.activeFilters,
  });

  const handleCloseFilter = () => {
    if (!isEqual(filtersOptions?.activeFilters, filters)) {
      filtersOptions?.onFiltersChange?.(filters);
    }
  };

  useUpdateEffect(() => {
    const rowIds = Object.keys(rowSelection).reduce((acc: string[], curr: any) => {
      if (filteredRows[curr]) {
        return [...acc, filteredRows[curr].id];
      }
      return acc;
    }, []);
    onCheckRows?.(new Set(rowIds));
  }, [rowSelection, filteredRows]);

  const handleCellsResize = () => {
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const minWidth = fontSize * 7; // 7rem
    const newColumnSizing: ColumnSizingState = {};

    for (const columnDef of columnDefs) {
      // recompute all cell...
      const cell = tableRef.current?.querySelector(
        `td[data-key="dt-td-${columnDef.key as string}"]`
      );
      const head = tableRef.current?.querySelector(
        `div[data-key="dt-th-${columnDef.key as string}"]`
      );
      if (cell instanceof HTMLElement && head instanceof HTMLElement) {
        // update computed position
        if (!columnDef.width) {
          if (head.offsetWidth > cell.offsetWidth) {
            // @ts-expect-error table index
            newColumnSizing[columnDef.key] = Math.round(head.offsetWidth);
          } else {
            // @ts-expect-error table index
            newColumnSizing[columnDef.key] = Math.round(cell.offsetWidth);
          }
          // @ts-expect-error table index
          if (!columnDef.icon && newColumnSizing[columnDef.key] < minWidth) {
            // @ts-expect-error table index
            newColumnSizing[columnDef.key] = minWidth;
          }
        } else {
          // @ts-expect-error table index
          newColumnSizing[columnDef.key] = columnDef.width * fontSize;
        }
      }
    }
    table.setColumnSizing((old) => ({
      ...old,
      ...newColumnSizing,
    }));
  };

  useEffect(() => {
    if (!tableRef.current || tableSizeObserver.current) return;

    if (window.ResizeObserver) {
      const observer = new ResizeObserver(handleCellsResize);
      observer.observe(tableRef.current);
      tableSizeObserver.current = observer;
    }
    handleCellsResize();

    return () => {
      tableSizeObserver.current?.disconnect();
    };
  }, [tableRef.current]);

  useUpdateEffect(() => {
    // reset pagination when dataset change
    table.setPageIndex(0);
    table.resetRowSelection();
  }, [filteredRows]);
  useUpdateEffect(() => {
    setTimeout(handleCellsResize); // next tick
  }, [sorting, rows]);

  useEffect(() => {
    if (pagination?.activeTablePage !== undefined) {
      const tablePagesCount = Math.ceil(filteredRows.length / pageSize);
      if (tablePagesCount !== 0 && pagination.activeTablePage >= tablePagesCount) {
        if (pagination.onPageChange) pagination.onPageChange(tablePagesCount - 1);
      } else {
        setPagination({ pageSize, pageIndex: pagination.activeTablePage });
      }
    }
  }, [pagination?.activeTablePage, pageSize, filteredRows]);

  const table = useReactTable<R>({
    data: filteredRows,
    columns,
    pageCount: Math.ceil(filteredRows.length / pageSize),
    state: {
      sorting,
      pagination: tablePagination,
      rowSelection,
    },
    defaultColumn: {
      size: 1,
      minSize: 1,
      maxSize: 1,
    },
    enableRowSelection: checkboxes,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: pagination?.onPageChange ? undefined : setPagination,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  const displayedRows = useMemo(() => {
    return isNil(pageSize)
      ? table.getRowModel().rows
      : table.getRowModel().rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
  }, [table, pageSize, pageIndex, handleCellsResize]);

  const handleScroll = (event: UIEvent) => {
    if (theadRef.current) {
      theadRef.current.scrollLeft = event.currentTarget.scrollLeft;
      if (theadRef.current.scrollLeft === 0) {
        setScrollPosition("start");
      } else if (
        theadRef.current.scrollLeft >=
        theadRef.current.scrollWidth - theadRef.current.clientWidth - 2
      ) {
        setScrollPosition("end");
      } else {
        setScrollPosition("middle");
      }
    }
  };

  const handleClickRow = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | React.KeyboardEvent,
    row: Row<R>
  ) => {
    const element = event.target as HTMLElement;
    const clickOnCheckbox = element.matches(
      "td[data-key='dt-td-select'], td[data-key='dt-td-select'] *"
    );
    if (checkboxes && clickOnCheckbox) {
      event.preventDefault();
    } else if (onClickRow) {
      if (table.getIsSomeRowsSelected()) table.resetRowSelection();
      // row.getToggleSelectedHandler()(event);
      onClickRow(row.original);
    }
  };

  return (
    <div
      className={cx(
        styles.DataTable,
        styles[theme],
        styles[mode],
        { [styles["loading"]]: loading },
        className
      )}
    >
      {header && (
        <div className={cx(styles.TableHeader, header.className)}>
          <div className={cx(styles.TableHeaderLabel, header.labelClassName)}>
            {loading &&
              (loadingLabel ||
                t("datatable.pagination.loading", {
                  defaultValue: "Loading...",
                }))}
            {!loading &&
              (table.getIsSomePageRowsSelected() && header.selectedRowsLabel
                ? header.selectedRowsLabel(
                    table.getSelectedRowModel().rows.length,
                    filteredRows.length
                  )
                : header.totalRowsLabel?.(filteredRows.length, rows.length))}
          </div>
          {header.actions && (
            <div className={cx(styles.TableHeaderActions, header.actionsClassName)}>
              {header.actions()}
            </div>
          )}
        </div>
      )}
      <div className={cx(styles.Table, styles[mode], containerClassName)} ref={tableRef}>
        <div
          aria-label="table-loader"
          className={cx(styles.LoaderWrapper, {
            [styles["fade-out"]]: !loading,
            [styles["display"]]: loading,
          })}
        >
          <LoaderBar />
        </div>
        {!checkboxes && !lastStickyColumnKey && (
          <div
            className={cx(styles.simpleScroll, styles[mode], {
              [styles.scrollLeft]: !["none", "start"].includes(scrollPosition),
            })}
          />
        )}
        <div
          className={cx(styles.thead, styles[mode], {
            [styles.selectionMode]: table.getIsSomePageRowsSelected(),
            [styles.pinned]: isHeaderPinned,
          })}
          ref={theadRef}
        >
          {table.getHeaderGroups().map((headerGroup, i) => (
            <Fragment key={i}>
              {headerGroup.headers.map((header, key) => (
                <Fragment key={key}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, {
                        ...header.getContext(),
                        scrollPosition,
                        isTableOverflow,
                        filters,
                        displayedRowsSize: displayedRows.length || 0,
                      })}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </div>
        <div
          className={cx(styles.headerShadow, styles[mode], { [styles.pinned]: isHeaderPinned })}
        />
        <div
          className={cx(styles.TableWrapper, { [styles.noScroll]: isEmpty(filteredRows) })}
          onScroll={handleScroll}
          ref={scrollRef}
        >
          <table
            className={cx(tableClassName, {
              [styles.selectionMode]: table.getIsSomePageRowsSelected(),
            })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const r = document.activeElement?.parentNode?.parentNode?.parentNode;
                // @ts-expect-error
                const focusedRowIndex = r?.dataset?.row as string;
                if (focusedRowIndex != null) {
                  const focusedRow = displayedRows.find(
                    (row) => row.original.id === focusedRowIndex
                  );
                  if (focusedRow) handleClickRow(event, focusedRow);
                }
              }
            }}
          >
            <tbody>
              {displayedRows.map((row, i) => (
                <tr
                  key={row.id}
                  aria-rowindex={i + 1}
                  className={cx(styles[mode], {
                    [styles.selected]: row.getIsSelected(),
                    [styles.clickable]: !!onClickRow,
                  })}
                  onClick={(event) => handleClickRow(event, row)}
                >
                  {row.getVisibleCells().map((cell, key) => (
                    <Fragment key={key}>
                      {flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        scrollPosition,
                        isTableOverflow,
                      })}
                    </Fragment>
                  ))}
                </tr>
              ))}
              {((!isEmpty(filters) && isEmpty(displayedRows)) || displayNoMatchingRows) && (
                <div
                  className={styles.emptyResult}
                  style={{ width: theadRef.current?.scrollWidth }}
                >
                  <div className={styles.message}>
                    <p>
                      {t("datatable.results.empty", {
                        defaultValue: "Aucune ligne ne correspond à vos filtres.",
                      })}
                    </p>
                    <Button
                      emphasis="Low"
                      buttonSize="S"
                      label={t("datatable.filters.reset", {
                        defaultValue: "Réinitialiser les filtres",
                      })}
                      onClick={resetFilters}
                      theme={theme}
                    />
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
        {!actions && (
          <div
            className={cx(styles.simpleScroll, styles[mode], {
              [styles.scrollRight]: !["none", "end"].includes(scrollPosition),
            })}
          />
        )}
      </div>
      {pagination && (
        <Pagination
          table={table}
          rowsPerPageLabel={pagination.rowsPerPageLabel}
          itemsCountLabel={pagination.itemsCountLabel}
          pageLabel={pagination.pageLabel}
          className={pagination.className}
          theme={theme}
          activePage={pagination.activeTablePage}
          onPageChange={pagination.onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          loadingLabel={loadingLabel}
        />
      )}
    </div>
  );
}
