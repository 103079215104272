import React, { type MouseEventHandler, type ReactNode } from "react";
import cx from "classnames";

import styles from "./FlatButton.module.scss";

interface Props {
  readonly icon?: ReactNode;
  readonly title: string;
  readonly description: string;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
  readonly className?: string;
  readonly ariaLabel?: string;
  readonly name?: string;
  readonly disabled?: boolean;
}

const FlatButton = (props: Props) => {
  const { icon, title, description, onClick, className, ariaLabel, name, disabled } = props;
  return (
    <button
      className={cx(styles.flatButton, { [styles.disabled]: disabled }, className)}
      name={name ?? title}
      onClick={(!disabled && onClick) || undefined}
      aria-label={ariaLabel}
    >
      {icon}
      <div className={styles.label}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </button>
  );
};

export { FlatButton };
