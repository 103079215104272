import React, { useEffect, useState } from "react";
import { useToggle } from "react-use";
import cx from "classnames";
import { Tooltip } from "components/Tooltip";
import { type TooltipProps } from "components/Tooltip/Tooltip";
import { type Themes } from "../../commonProps";
import styles from "./Checkbox.module.scss";

export interface CheckboxProps {
  readonly name?: HTMLInputElement["name"];
  readonly value?: HTMLInputElement["value"];
  readonly checked?: HTMLInputElement["checked"];
  readonly disabled?: HTMLInputElement["disabled"];
  readonly error?: boolean;
  readonly onChange?: (checked?: boolean) => void;
  readonly stopPropagation?: boolean;
  readonly clickable?: boolean;
  readonly tooltip?: Omit<TooltipProps, "theme" | "children">;
  readonly label: string;
  readonly helpText?: string;

  readonly className?: string;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

export const CHECKBOX_STATE = {
  Checked: true,
  Unchecked: false,
  Indeterminate: undefined,
};

const PureCheckbox = ({
  label,
  helpText,
  name,
  value,
  checked,
  disabled,
  onChange,
  darkMode = false,
  theme,
  className,
  stopPropagation = false,
  clickable = false,
  tooltip,
  ...props
}: CheckboxProps) => {
  const [isChecked, setChecked] = useState<CheckboxProps["checked"]>(checked);
  const [isFocused, toggleFocus] = useToggle(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const handleToggle = (e) => {
    if (stopPropagation || clickable) {
      e.stopPropagation();
    }
    onChange?.(!isChecked);
    !disabled && setChecked(!isChecked);
  };

  // Be carefully about this code, because if you click on the label and not the checkbox,
  // the onChange will be triggered two times so you have to get the onChange idempotent

  return (
    <div
      className={cx(styles.Checkbox, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.checked]: isChecked == true,
        [styles.focused]: isFocused,
        [styles.disabled]: disabled,
      })}
      aria-label={`checkbox-${label}`}
      role="presentation"
      {...props}
      onClick={clickable ? handleToggle : undefined}
      onMouseOver={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      <div
        className={cx(styles.box, {
          [styles.checked]: isChecked == true,
          [styles.indeterminate]: isChecked === undefined,
          [styles.focused]: isFocused,
          [styles.disabled]: disabled,
        })}
        onClick={handleToggle}
        role="presentation"
      >
        <input
          id={`${name || label}-id`}
          type="checkbox"
          name={name || label}
          value={value}
          checked={isChecked == true}
          disabled={disabled}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          onChange={() => {
            onChange?.(!isChecked);
            setChecked(!isChecked);
          }}
        />
        {isChecked !== undefined ? (
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.88885 7.79994L1.08885 4.99994L0.155518 5.93328L3.88885 9.66661L11.8889 1.66661L10.9555 0.733276L3.88885 7.79994Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.66671 1.66659H0.333374V0.333252H9.66671V1.66659Z" fill="white" />
          </svg>
        )}
      </div>
      <div
        className={cx(styles.textWrapper, {
          [styles.checked]: isChecked == true,
          [styles.focused]: isFocused,
          [styles.disabled]: disabled,
        })}
      >
        <div>
          {label && (
            <Tooltip
              disabled={!tooltip}
              displayArrow={false}
              opened={isTooltipOpen}
              direction={"top"}
              {...tooltip}
            >
              <label htmlFor={`${name || label}-id`} className={styles.label}>
                {label}
              </label>
            </Tooltip>
          )}
        </div>
        {helpText && (
          <label htmlFor={`${name || label}-id`} className={styles.helpText}>
            {helpText}
          </label>
        )}
      </div>
    </div>
  );
};

export const Checkbox = Object.assign(PureCheckbox, {
  STATE_CHECKED: CHECKBOX_STATE.Checked,
  STATE_UNCHECKED: CHECKBOX_STATE.Unchecked,
  STATE_INDETERMINATE: CHECKBOX_STATE.Indeterminate,
});
