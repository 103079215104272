export type Row<T extends string, U extends Record<T, any>> = {
  id: string;
  data: U;
};
export type DataTableRow = Row<string, any>;
export type FilterValue = number | string | boolean | Set<string> | undefined;
export type TableFilters<R extends DataTableRow> = Partial<Record<keyof R["data"], FilterValue>>;
export type TableSorts<R extends DataTableRow> = {
  key: keyof R["data"];
  desc?: boolean;
};

export type Column<R extends DataTableRow> = {
  key: keyof R["data"] | string;
  className?: string;
  title?: string;
  icon?: React.ReactNode;
  type?: ColumnType;
  width?: number; // rem
  minWidth?: number; // rem
  renderCell?: (data: R["data"], row: R) => React.ReactNode;
  renderFilter?: (props: FilterProps<R>) => React.ReactNode;
  sortFn?: (row1: R, row2: R) => number;
  filterFn?: (row: R, value: NonNullable<FilterValue>) => boolean;
  filterable?: boolean;
  sortable?: boolean;
  sticky?: boolean;
  headAlign?: "left" | "center" | "right";
  cellAlign?: "left" | "center" | "right";
  options?: Array<{ label: string; value: string; isSelected?: boolean }>;
  largeFilterForm?: boolean;
  hasActionOnClickCell?: (row: R) => ((params: { columnKey: string; row: R }) => void) | undefined;
};

export enum ColumnType {
  TEXT = "text",
  DATE = "date",
  NUMBER = "number",
  SELECT = "select",
  BOOL = "bool",
  SEARCH_SELECT = "search_select",
}

export type FilterProps<R extends DataTableRow> = {
  column: Column<R>;
  value: FilterValue;
  onChange: (v: FilterValue) => void;
};
