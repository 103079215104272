import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Cross = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M14.5961 6.11084C14.9866 6.50136 14.9866 7.13453 14.5961 7.52505L12.1212 9.99993L14.5961 12.4748C14.9866 12.8653 14.9866 13.4985 14.5961 13.889L13.889 14.5961C13.4985 14.9866 12.8653 14.9866 12.4748 14.5961L9.99993 12.1212L7.52505 14.5961C7.13453 14.9866 6.50136 14.9866 6.11084 14.5961L5.40373 13.889C5.01321 13.4985 5.01321 12.8653 5.40373 12.4748L7.87861 9.99993L5.40373 7.52505C5.01321 7.13453 5.01321 6.50136 5.40373 6.11084L6.11084 5.40373C6.50136 5.01321 7.13453 5.01321 7.52505 5.40373L9.99993 7.87861L12.4748 5.40373C12.8653 5.01321 13.4985 5.01321 13.889 5.40373L14.5961 6.11084Z"
      fill={color}
    />
  </SVGBox>
);

export { Cross };
