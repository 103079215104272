import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

export const SvgStar = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M10.456 4.305l1.46 3.087c.08.154.215.266.39.294l3.256.491c.415.07.59.604.281.912l-2.358 2.413a.557.557 0 00-.148.477l.563 3.395c.067.435-.362.772-.75.562l-2.909-1.6a.492.492 0 00-.482 0l-2.908 1.6c-.376.21-.818-.127-.751-.562l.563-3.395a.57.57 0 00-.148-.477L4.157 9.09c-.309-.308-.134-.856.281-.912l3.257-.49a.56.56 0 00.389-.295l1.46-3.087a.493.493 0 01.912 0z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);
