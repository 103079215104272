export function dataInPercent<
  T extends {
    label: string;
    value: number;
  }
>(data: T[]) {
  const sumOfVal = data.reduce((acc, curr) => acc + curr.value, 0);
  if (!sumOfVal) return data;
  return data.map((d) => ({
    ...d,
    value: Math.round((d.value * 100) / sumOfVal),
  }));
}
