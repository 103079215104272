import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import cx from "classnames";

import { Themes } from "components/commonProps";

import { useMediaQueries } from "../../hooks/useMediaQueries";

import { type HeaderType } from "./components/ModalHeader";
import { type ModalContentType } from "./components/ModalContent";
import { type FooterType } from "./components/ModalFooter";

import styles from "./DSModal.module.scss";

/**
 * FIGMA: https://www.figma.com/file/KAzWBhAvqLI1MagHiuKTMr/Skillup-UI?node-id=28%3A103&t=DGVxLjD8dXEXYMA9-0
 */
export type ModalProps = {
  isOpen: boolean;
  children: [HeaderType, ModalContentType, FooterType];
  /**
   * The theme key will not be exposed outside of the component which will be exported wrapped in the withTheme HOC (cf ./index.tsx)
   * This is to avoid having to pass the theme key as a prop to the component, which would be redundant.
   * The theme key is set by a ThemeProvider at the root of each app, but can be overriden by another ThemeProvider
   * in a sub-tree of the app (for example, if you want to preview a component in a different theme in a settings view).
   */
  theme: Themes;
  /**
   * This is where the modal will be rendered. By default it will be rendered in #modal-root element,
   * but it can be changed to any other element in the DOM.
   */
  portalTarget?: HTMLElement;
  /**
   * Inner custom className
   */
  className?: string;
};

/**
 * This modal component requires a header, a content, and an optional footer.
 * The header and the footer are fixed, and the content is scrollable.
 * These components are passed as children to the modal, and are rendered in the order they are passed.
 * You can find them in the same folder as this component, under the names
 * _ModalHeader.tsx_, _ModalContent.tsx_, and _ModalFooter.tsx_.
 * @param props ModalProps
 * @returns A beautiful modal in a Portal
 */
export function DSModal(props: ModalProps) {
  const { children, isOpen, portalTarget, theme } = props;
  const container = useRootContainer(portalTarget);

  if (!isOpen) return null;
  if (!container) return null;

  return createPortal(
    <ModalBackground>
      <InnerModal theme={theme} className={props.className}>
        {children}
      </InnerModal>
    </ModalBackground>,
    container
  );
}

// hook version
function useRootContainer(portalTarget?: HTMLElement) {
  const [container, setContainer] = useState<Element | null>(
    portalTarget ?? document.querySelector("#modal-root")
  );

  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    if (!container) {
      const rootDiv = document.createElement("div");
      rootDiv.id = "modal-root";
      document.body.appendChild(rootDiv);

      setContainer(rootDiv);
    }
  }, [portalTarget]);

  return container;
}

/**
 * This is the wrapper for the modal content
 * It is a white box, with a mandatory header, an optional footer, and a content section
 * that will receive the children passed as props.
 * On hover, the footer's top will display a shadow to separate content from actions.
 * On scroll, the footer's top and the header's bottom will display a shadow to differentiate
 * the scrollable content from the fixed header and footer.
 * @param children Any React valid node (string, number, component, etc)
 * @returns JSX.Element
 */
function InnerModal({
  className,
  children,
  theme,
}: {
  className?: string;
  theme: Themes;
  children: React.ReactNode;
  backgroundColor?: string;
  hasBorders?: boolean;
}) {
  const { isMobile } = useMediaQueries();

  return (
    <div
      role="dialog"
      className={cx(styles.InnerModal, className, {
        [styles.collab]: theme === Themes.ESPACE_COLLABORATEUR,
        [styles.rh]: theme === Themes.ESPACE_RH,
        [styles.isMobile]: isMobile, // use media queries hook for this :)
      })}
    >
      {children}
    </div>
  );
}

/**
 * This is the background that covers the entire screen
 * It is a greyish semi-transparent blurred overlay that cannot be clicked and
 * does not let click event pass through, so that the user cannot interact with the
 * underlying content, and is forced to close the modal through the modal"s own close button
 * or by proceeding with the modal"s action.
 * @param children Any React valid node (string, number, component, etc)
 * @returns JSX.Element
 */
function ModalBackground({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.ModalBackground} onClick={() => {}}>
      {children}
    </div>
  );
}

/**
 * TODO:
 * - Add buttons to the modal footer
 * - Add a third potential button to the modal footer, displayed first, with a different style
 * - Add a close button icon to the modal header
 * - Rounded borders
 * - Hover effect on content only, not the whole modal
 * - Handle mobile
 *
 */
