import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ThumbUp = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56841 16.7073C8.56841 17.2006 8.97246 17.6 9.47089 17.6H16.0195L16.0196 17.5945C16.5275 17.545 16.9226 17.1657 16.9226 16.7051C16.9226 16.4081 16.7584 16.1449 16.5055 15.9825C17.009 15.8398 17.3742 15.4159 17.3742 14.9152C17.3742 14.5082 17.1329 14.152 16.7721 13.9567C17.2544 13.8031 17.6 13.3887 17.6 12.9017C17.6 12.5037 17.3692 12.1542 17.0216 11.9561C17.3731 11.7109 17.6 11.3237 17.6 10.8881C17.6 10.1468 16.9429 9.54576 16.1324 9.54576C16.0943 9.54576 16.0566 9.54708 16.0193 9.54968L16.0195 9.54576L12.8586 9.54554C14.1636 8.23814 13.8801 5.77538 13.8576 5.59693L13.8523 5.53784C13.7878 4.90613 13.207 4.39999 12.5599 4.39999H12.409L12.3773 4.40165C11.8945 4.45215 11.6003 5.64716 11.4898 6.41355C11.4167 6.92055 10.3598 8.03184 9.55593 8.87712C9.2492 9.19964 8.97931 9.48343 8.81494 9.68016L8.75467 9.75449C8.63906 9.87522 8.56841 10.0387 8.56841 10.2186V16.7073ZM7.87359 11.4227C7.87359 10.9971 7.43 10.6526 6.88282 10.6526H5.39067C4.84338 10.6526 4.3999 10.9974 4.3999 11.4227V16.83C4.3999 17.2555 4.84349 17.6 5.39067 17.6H6.88282C7.43011 17.6 7.87359 17.2552 7.87359 16.83V11.4227Z"
      fill={color}
    />
  </SVGBox>
);

export { ThumbUp };
