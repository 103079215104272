import React from "react";
import cx from "classnames";

import { type ProgressBarPropsWithPercent, ValueLayoutProgressBar } from "./ProgressBar";

import styles from "./ProgressBar.module.scss";

export function ProgressBarList({
  data,
  valueLayout = ValueLayoutProgressBar.VALUE,
  border = false,
  thin = false,
  labelFormatter = (label: string, _: number) => label,
  valueFormatter = (_: string, value: number) => value.toString(),
}: ProgressBarPropsWithPercent) {
  return (
    <section className={cx(styles.section, styles.list)}>
      <div className={styles.col}>
        {data.map(({ label, value }, i) => (
          <label className={styles.label} key={i}>
            {labelFormatter(label ?? "", value)}
          </label>
        ))}
      </div>
      <div className={cx(styles.col, styles.content)}>
        {data.map(({ percentage, color }, i) => (
          <div className={styles.row} key={i}>
            <div className={cx(styles.bar, border && styles.border, thin && styles.thin)}>
              <span
                className={cx(styles.subBar, color ? styles[color] : styles.gradiantBlue)}
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={cx(styles.col, styles.values)}>
        {data.map(({ label, value, percentage }, i) => (
          <div className={cx(styles.row, styles.values)} key={i}>
            {[ValueLayoutProgressBar.PERCENTAGE, ValueLayoutProgressBar.VALUE_PERCENTAGE].includes(
              valueLayout
            ) && <label className={styles.percentage}>{Math.floor(percentage)} %</label>}
            {[ValueLayoutProgressBar.VALUE, ValueLayoutProgressBar.VALUE_PERCENTAGE].includes(
              valueLayout
            ) && <label className={styles.value}>{valueFormatter(label ?? "", value)}</label>}
          </div>
        ))}
      </div>
    </section>
  );
}
