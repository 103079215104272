import React, { useCallback } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { Check } from "../Icon";
import styles from "./Checkbox.module.scss";
import { HorizontalRule } from "components/MaterialIcons";

export interface CheckboxChoice<T = any> {
  readonly label?: string;
  readonly value: T;
}

export interface Props<T = any> extends CheckboxChoice<T> {
  readonly className?: string;
  readonly checked?: true | false | "intermediate";
  readonly onClick?: (value: T) => void;
  readonly readOnly?: boolean;
  readonly errored?: boolean;
  readonly disabled?: boolean;
  readonly "aria-label"?: string;
}

const Checkbox = ({
  className,
  label,
  value,
  checked = false,
  onClick,
  "aria-label": ariaLabel,
  readOnly = false,
  errored = false,
  disabled = false,
}: Props) => {
  const toogleCheckbox = useCallback((): void => {
    if (onClick && !readOnly) {
      return onClick(value);
    }
  }, [onClick, readOnly, value]);

  return (
    <Flex
      className={cx(styles.Checkbox, className, {
        [styles.checked]: !!checked,
        [styles.readOnly]: readOnly,
        [styles.errored]: errored,
      })}
      onClick={toogleCheckbox}
      disabled={disabled}
      data-testid="checkbox-option"
      aria-label={ariaLabel}
    >
      <Flex center className={styles.square} data-testid="checkbox-check">
        <input
          className={styles.inputHidden}
          type="checkbox"
          checked={checked === true}
          disabled={disabled}
          onChange={toogleCheckbox}
        />
        {checked === true && <Check className={styles.checkIcon} color="white" focusable={false} />}
        {checked === "intermediate" && (
          <HorizontalRule className={styles.checkIcon} color="white" focusable={false} />
        )}
      </Flex>
      {label && (
        <span className={styles.label} data-testid="checkbox-label">
          {label}
        </span>
      )}
    </Flex>
  );
};

export { Checkbox };
