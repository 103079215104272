import React from "react";
import cx from "classnames";

import { type Themes } from "../../commonProps";

import { Label, type LabelProps } from "components/Form/Label/Label";
import {
  AssistiveArea,
  type AssistiveAreaProps,
} from "components/Form/AssistiveArea/AssistiveArea";

import styles from "./FormGroupInput.module.scss";

export interface FormGroupInputProps extends LabelProps {
  readonly assistiveText?: AssistiveAreaProps["text"];
  readonly assistiveArea?: AssistiveAreaProps["area"];
  readonly children: JSX.Element;
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const FormGroupInput = ({
  label,
  required,
  assistiveText,
  assistiveArea,
  darkMode = false,
  theme,
  className,
  children,
  ...props
}: FormGroupInputProps) => {
  return (
    <div
      className={cx(styles.FormGroupInput, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      aria-label={`forminput-${label}`}
      role="presentation"
      {...props}
    >
      <Label label={label} required={required} theme={theme} darkMode={darkMode} />
      {children}
      <AssistiveArea text={assistiveText} area={assistiveArea} theme={theme} darkMode={darkMode} />
    </div>
  );
};

export { FormGroupInput };
