import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Place = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M11.3513 4C8.40059 4 6 6.40059 6 9.35131C6 13.0132 10.7889 18.3892 10.9928 18.6162C11.1843 18.8295 11.5187 18.8292 11.7099 18.6162C11.9138 18.3892 16.7027 13.0132 16.7027 9.35131C16.7026 6.40059 14.3021 4 11.3513 4ZM11.3513 12.0437C9.86675 12.0437 8.65897 10.8359 8.65897 9.35131C8.65897 7.86672 9.86677 6.65894 11.3513 6.65894C12.8359 6.65894 14.0437 7.86675 14.0437 9.35134C14.0437 10.8359 12.8359 12.0437 11.3513 12.0437Z"
      fill={color}
    />
  </SVGBox>
);

export { Place };
