import { withTheme } from "components/DesignSystemContext";
import { DSSidePanel, type SidePanelProps } from "./DSSidePanel";
import { generateSidePanelContext } from "./generateSidePanelContext";

import { SidePanelHeader } from "./SidePanelHeader";
import { SidePanelContent } from "./SidePanelContent";
import {
  SidePanelFooter,
  EmptySidePanelFooter,
  ActionableSidePanelFooter,
} from "./SidePanelFooter";

export { generateSidePanelContext };

type ThemedSidePanel = ReturnType<typeof withTheme<SidePanelProps>>;

type SidePanelType = ThemedSidePanel & {
  Header: typeof SidePanelHeader;
  Content: typeof SidePanelContent;
  Footer: typeof SidePanelFooter;
  FooterEmpty: typeof EmptySidePanelFooter;
  FooterWithActions: typeof ActionableSidePanelFooter;
};

export const SidePanelComponents = {
  Header: SidePanelHeader,
  Content: SidePanelContent,
  Footer: SidePanelFooter,
  FooterEmpty: EmptySidePanelFooter,
};

const SidePanel = Object.assign(withTheme<SidePanelProps>(DSSidePanel), {
  Header: SidePanelHeader,
  Content: SidePanelContent,
  Footer: SidePanelFooter,
  FooterEmpty: EmptySidePanelFooter,
  FooterWithActions: ActionableSidePanelFooter,
}) as SidePanelType;

export { SidePanel as DSSidePanel };
