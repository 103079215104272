import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Lock = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2179 9.38233H17.8044C18.0965 9.38233 18.3333 9.62375 18.3333 9.92155V19.6274C18.3333 19.9252 18.0965 20.1666 17.8044 20.1666H5.1121C4.82002 20.1666 4.58325 19.9252 4.58325 19.6274V9.92155C4.58325 9.62375 4.82002 9.38233 5.1121 9.38233H6.69864V6.95586C6.69864 4.12676 8.68355 1.83331 11.4583 1.83331C14.233 1.83331 16.2179 4.12676 16.2179 6.95586V9.38233ZM14.6313 9.38233V6.95586C14.6313 5.02016 13.3567 3.45096 11.4583 3.45096C9.55977 3.45096 8.28518 5.02016 8.28518 6.95586V9.38233H14.6313Z"
      fill={color}
    />
  </SVGBox>
);

export { Lock };
