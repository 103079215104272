import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ArrowDropup = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M5.415 12.35l3.826-4.464a1 1 0 011.518 0l3.826 4.463a1 1 0 01-.76 1.651h-7.65a1 1 0 01-.76-1.65z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { ArrowDropup };
