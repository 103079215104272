import React, { type HTMLAttributes } from "react";
import cx from "classnames";

import styles from "./Navigation.module.scss";

export type NavigationProps = HTMLAttributes<HTMLDivElement>;

function Navigation({ className, children, ...props }: NavigationProps) {
  return (
    <div className={cx(styles.Navigation, className)} {...props}>
      {children}
    </div>
  );
}

export { Navigation };
