const urlMapKey = "urlMap";

interface UrlEntry {
  originalString: string;
}

function loadUrlMap(): { [key: string]: UrlEntry } {
  const data = localStorage.getItem(urlMapKey);
  if (data) {
    return JSON.parse(data);
  }
  return {};
}

function saveUrlMap(urlMap: { [key: string]: UrlEntry }) {
  localStorage.setItem(urlMapKey, JSON.stringify(urlMap));
}

function hashing(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash.toString(16);
}

export const shortString = (str: string): string => {
  const hash = hashing(str);
  const shortUrl = hash.substring(0, 8);

  const urlMap = loadUrlMap();
  urlMap[shortUrl] = { originalString: str };
  saveUrlMap(urlMap);

  return shortUrl;
};

export const resolveString = (filterParam: string): string | null => {
  const key = filterParam;
  const urlMap = loadUrlMap();
  const entry = urlMap[key];

  if (!entry) {
    urlMap[key] = entry;
    saveUrlMap(urlMap);
    return null;
  }

  return entry.originalString;
};
