import React, { useEffect, useState } from "react";

import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";

import { type HeaderType } from "./SidePanelHeader";
import { type SidePanelContentType } from "./SidePanelContent";

import { Themes } from "components/commonProps";
import { slideFromRight } from "components/animations";
import { useMediaQueries } from "../../hooks/useMediaQueries";

import styles from "./DSSidePanel.module.scss";

import cx from "classnames";
import {
  type ActionableSidePanelFooterType,
  type BasicSidePanelFooterType,
  type EmptySidePanelFooterType,
} from "./SidePanelFooter";

/**
 * FIGMA: https://www.figma.com/file/KAzWBhAvqLI1MagHiuKTMr/Skillup-UI?node-id=4634-47645&viewport=1543,-727,0.5&t=qEddtFfj4ksfGVN9-0
 */
export type SidePanelProps = {
  isOpen: boolean;
  onBackgroundClick?: () => void;
  children:
    | [HeaderType, SidePanelContentType, EmptySidePanelFooterType]
    | [HeaderType, SidePanelContentType, BasicSidePanelFooterType]
    | [HeaderType, SidePanelContentType, ActionableSidePanelFooterType];
  /**
   * The theme key will not be exposed outside of the component which will be exported wrapped in the withTheme HOC (cf ./index.tsx)
   * This is to avoid having to pass the theme key as a prop to the component, which would be redundant.
   * The theme key is set by a ThemeProvider at the root of each app, but can be overriden by another ThemeProvider
   * in a sub-tree of the app (for example, if you want to preview a component in a different theme in a settings view).
   */
  theme: Themes;
  /**
   * This is where the sidepanel will be rendered. By default it will be rendered in #sidepanel-root element,
   * but it can be changed to any other element in the DOM.
   */
  portalTarget?: HTMLElement;
  /**
   * Inner custom className
   */
  className?: string;
  isAnimated?: boolean;
};

/**
 * This sidepanel component requires a header, a content, and an optional footer.
 * The header and the footer are fixed, and the content is scrollable.
 * These components are passed as children to the sidepanel, and are rendered in the order they are passed.
 * You can find them in the same folder as this component, under the names
 * _SidePanelHeader.tsx_, _SidePanelContent.tsx_, and _SidePanelFooter.tsx_.
 * @param props SidePanelProps
 * @returns A beautiful sidepanel in a Portal
 */
export function DSSidePanel(props: SidePanelProps) {
  const { children, isOpen, portalTarget, theme, isAnimated = true } = props;
  const container = useRootContainer(portalTarget);

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <InnerSidePanel theme={theme} className={props.className} isAnimated={isAnimated}>
          {children}
        </InnerSidePanel>
      )}
    </AnimatePresence>,
    container ? container : document.body
  );
}

// hook version
function useRootContainer(portalTarget?: HTMLElement) {
  const [container, setContainer] = useState<Element | null>(
    portalTarget ?? document.querySelector("#sidepanel-root")
  );

  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    if (!container) {
      const rootDiv = document.createElement("div");
      rootDiv.id = "sidepanel-root";
      document.body.appendChild(rootDiv);

      setContainer(rootDiv);
    }
  }, [portalTarget]);

  return container;
}

/**
 * This is the wrapper for the sidepanel content
 * It is a white box, with a mandatory header, an optional footer, and a content section
 * that will receive the children passed as props.
 * On hover, the footer's top will display a shadow to separate content from actions.
 * On scroll, the footer's top and the header's bottom will display a shadow to differentiate
 * the scrollable content from the fixed header and footer.
 * @param children Any React valid node (string, number, component, etc)
 * @returns JSX.Element
 */
function InnerSidePanel({
  className,
  children,
  theme,
  isAnimated,
}: {
  className?: string;
  children: React.ReactNode;
  theme: Themes;
  isAnimated: boolean;
}) {
  const { isMobile } = useMediaQueries();
  const Component = isAnimated ? motion.div : "div";

  return (
    <Component
      {...slideFromRight}
      role="dialog"
      onClick={(e) => e.stopPropagation()}
      className={cx(styles.InnerSidePanel, className, {
        [styles.collab]: theme === Themes.ESPACE_COLLABORATEUR,
        [styles.rh]: theme === Themes.ESPACE_RH,
        [styles.isMobile]: isMobile, // use media queries hook for this :)
      })}
    >
      {children}
    </Component>
  );
}
