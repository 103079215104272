import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgRepeat = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 16 16" {...props}>
    <g opacity="0.5">
      <path
        d="M2.92308 5.51939C2.92308 4.74876 3.54299 4.12404 4.30769 4.12404H10.5753L9.51937 5.19093C9.33937 5.37281 9.33975 5.6673 9.52022 5.8487C9.7007 6.03011 9.99293 6.02972 10.1729 5.84785L11.9844 4.01759C12.0836 3.94552 12.1538 3.80798 12.1538 3.6559C12.1548 3.53383 12.107 3.41401 12.0182 3.32476L10.1721 1.46914C9.99161 1.28774 9.69938 1.28813 9.51937 1.47C9.33937 1.65188 9.33975 1.94637 9.52022 2.12778L10.5808 3.19381H4.30769C3.03319 3.19381 2 4.23501 2 5.51939V9.86048C2 10.1174 2.20664 10.3256 2.46154 10.3256C2.71644 10.3256 2.92308 10.1174 2.92308 9.86048V5.51939Z"
        fill="#92929D"
      />
      <path
        d="M11.6923 11.876H5.42352L6.48045 10.8093C6.66055 10.6275 6.66034 10.333 6.47996 10.1515C6.29958 9.96999 6.00735 9.97021 5.82725 10.152L4.00699 11.9891C3.91099 12.0651 3.84615 12.1979 3.84616 12.3428C3.84562 12.4651 3.89328 12.5842 3.98158 12.673L5.82773 14.5307C6.00811 14.7122 6.30034 14.712 6.48045 14.5302C6.66055 14.3484 6.66034 14.0539 6.47996 13.8724L5.42036 12.8062H11.6923C12.9668 12.8062 14 11.765 14 10.4806V6.13954C14 5.88267 13.7934 5.67443 13.5385 5.67443C13.2836 5.67443 13.0769 5.88267 13.0769 6.13954V10.4806C13.0769 11.2513 12.457 11.876 11.6923 11.876Z"
        fill="#92929D"
      />
    </g>
  </SVGBox>
);

export { SvgRepeat };
