import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Loading = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M7.19067 10.1134H4V11.8857H7.19067V10.1134ZM10.1152 17.9997H11.8869V14.8096H10.1152V17.9997ZM14.8096 10.1134V11.8857H18.0003V10.1134H14.8096ZM10.1152 7.19008H11.8869V4H10.1152V7.19008ZM5.42441 15.3233L6.67762 16.5771L8.93292 14.3212L7.6797 13.068L5.42441 15.3233ZM5.42441 6.67643L7.6797 8.93172L8.93292 7.67912L6.67762 5.42321L5.42441 6.67643ZM13.0686 14.32L15.3233 16.5753L16.5777 15.3233L14.32 13.0668L13.0686 14.32Z"
      fill={color}
    />
  </SVGBox>
);

export { Loading };
