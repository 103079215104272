import React, { type HTMLAttributes, type ChangeEvent } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { type Themes } from "../../commonProps";

import styles from "./TextInput.module.scss";

export interface TextInputProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  readonly name: HTMLInputElement["name"];
  readonly value?: HTMLInputElement["value"];
  readonly placeholder?: HTMLInputElement["placeholder"];
  readonly required?: HTMLInputElement["required"];
  readonly disabled?: HTMLInputElement["disabled"];
  readonly readOnly?: HTMLInputElement["readOnly"];
  readonly error?: boolean;
  readonly autoFocus?: boolean;
  readonly onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  readonly defaultValue?: HTMLInputElement["defaultValue"];

  readonly icon?: JSX.Element;
  readonly iconRight?: JSX.Element;

  readonly actionButton?: JSX.Element;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      value,
      placeholder,
      required,
      error,
      disabled,
      readOnly,
      autoFocus,
      onChange,
      onFocus,
      onBlur,
      icon,
      iconRight,
      actionButton,
      darkMode = false,
      theme,
      className,
      defaultValue,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cx(styles.TextInput, className, styles[theme], {
          [styles.darkMode]: darkMode,
          [styles.withIcon]: icon,
          [styles.withIconRight]: iconRight,
          [styles.disabled]: disabled,
          [styles.readOnly]: readOnly,
          [styles.empty]: isEmpty(value),
          [styles.error]: !!error,
        })}
        aria-label={`inputtext-${name}`}
        role="presentation"
        {...props}
      >
        {icon}
        <input
          ref={ref}
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled || readOnly}
          required={required}
          readOnly={readOnly}
          autoFocus={autoFocus}
          onChange={(e) => onChange?.(e.target.value, e)}
          defaultValue={defaultValue}
          className={cx({
            [styles.withIcon]: !!icon && !iconRight,
            [styles.withAction]: !!actionButton,
            [styles.empty]: isEmpty(value),
            [styles.readOnly]: readOnly,
            [styles.error]: !!error,
          })}
          onBlur={onBlur ?? (() => {})}
          onFocus={onFocus ?? (() => {})}
        />
        {actionButton}
        {iconRight}
      </div>
    );
  }
);

export { TextInput };
