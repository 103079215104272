import { useMemo } from "react";
import { uniqueId } from "lodash";
import { useModalContext } from "./context";

export const useModal = () => {
  const id = useMemo<string>(() => uniqueId(), []);
  const { currentModal, setCurrentModal, removeCurrentModal } = useModalContext();
  const isOpen = useMemo(() => currentModal === id, [currentModal, id]);

  return {
    isOpen,
    show: () => setCurrentModal(id),
    hide: () => removeCurrentModal(),
  };
};
