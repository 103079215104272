import React from "react";
import { isNil, round } from "lodash";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import { Rating } from "../../Rating";
import * as Icon from "../../Icon";
import { colors } from "../../../assets/colors";

import styles from "./Reviews.module.scss";

interface Props {
  readonly className?: string;
  readonly small?: boolean;
  readonly reviewsGlobalScore?: number;
  readonly reviewsCount?: number;
}

const Reviews = ({ className, small, reviewsGlobalScore, reviewsCount }: Props) => {
  if (isNil(reviewsGlobalScore) || reviewsGlobalScore === 0) {
    return null;
  }

  if (small) {
    return (
      <Flex className={cx(styles.Reviews, styles.small, className)}>
        <Icon.Star size="1rem" color={colors.primaryYellow} />
        <span className={styles.rating}>{round(reviewsGlobalScore ?? 0, 1)}</span>
        <span className={styles.count} data-testid="training-reviews-count">
          ({reviewsCount} avis)
        </span>
      </Flex>
    );
  }

  return (
    <Flex className={cx(styles.Reviews, className)}>
      <Rating max={10} value={reviewsGlobalScore} className={styles.stars} />
      <span className={styles.count} data-testid="training-reviews-count">
        {reviewsCount} avis
      </span>
    </Flex>
  );
};

export { Reviews };
