import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ArrowDropdown = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M5.415 8.65l3.826 4.464a1 1 0 001.518 0l3.826-4.463A1 1 0 0013.825 7h-7.65a1 1 0 00-.76 1.65z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { ArrowDropdown };
