import React from "react";
import classNames from "classnames";

import styles from "./DSSortable.module.scss";

interface Props {
  children: React.ReactNode;
  center?: boolean;
  style?: React.CSSProperties;
}

export function Wrapper({ children, center, style }: Props) {
  return (
    <div className={classNames(styles.wrapper, center && styles.center)} style={style}>
      {children}
    </div>
  );
}
