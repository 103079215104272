import React, { useMemo } from "react";
import cx from "classnames";

import { colors } from "../../assets/colors";
import { Flex } from "../../components/Flex";
import styles from "./ProgressBar.module.scss";

interface Props {
  readonly className?: string;
  readonly max: number;
  readonly value: number;
  readonly disabled?: boolean;
  readonly color?: string;
  readonly gradientColor?: string;
  readonly side?: "left" | "right" | "none";
}

const ProgressBar = ({
  className,
  max,
  value,
  disabled,
  color = colors.primaryYellow,
  gradientColor,
  side = "left",
}: Props) => {
  const progress = useMemo(() => (max === 0 ? 0 : Math.round((value / max) * 100)), [value, max]);
  const isFull = progress >= 100;

  return (
    <Flex
      className={cx(styles.ProgressBar, className)}
      data-testid="progress-bar-wrapper"
      disabled={disabled}
    >
      {side === "left" && (
        <Flex
          className={styles.valueContainer}
          data-progress={progress}
          data-testid="progress-bar-progress"
        >
          <span className={styles.value}>{progress}</span>
          <span className={styles.unit}>%</span>
        </Flex>
      )}

      <Flex className={styles.barContainer}>
        <div
          className={cx(styles.progress, { [styles.full]: isFull })}
          style={{
            width: progress + "%",
            background: gradientColor
              ? `linear-gradient(to right, ${gradientColor}, ${color})`
              : color,
          }}
        />
      </Flex>

      {side === "right" && (
        <Flex
          className={styles.valueContainer}
          data-progress={progress}
          data-testid="progress-bar-progress"
        >
          <span className={styles.value}>{progress}</span>
          <span className={styles.unit}>%</span>
        </Flex>
      )}
    </Flex>
  );
};

ProgressBar.defaultProps = {};

export { ProgressBar };
