import React, { type HTMLAttributes } from "react";
import cx from "classnames";

import styles from "./Label.module.scss";

import { type Themes } from "../../commonProps";

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  readonly label?: string | JSX.Element;
  readonly required?: boolean;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

export const Label = ({
  label,
  required,
  darkMode = false,
  theme,
  className,
  ...props
}: LabelProps) => {
  return (
    <div
      className={cx(styles.Label, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      aria-label={`inputlabel-${label}`}
      role="presentation"
      {...props}
    >
      {label} {required && <span className={styles.required}>*</span>}
    </div>
  );
};
