import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const InfoIcon = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 4C7.14025 4 4 7.14025 4 11C4 14.8597 7.14025 18 11 18C14.8597 18 18 14.8597 18 11C18 7.14025 14.8597 4 11 4ZM11.4553 15.1533C11.1228 15.2087 10.461 15.3472 10.125 15.375C9.84055 15.3986 9.57213 15.2357 9.40851 15.002C9.24446 14.7683 9.20515 14.4692 9.30254 14.2009L10.6257 10.5625H9.24999C9.24881 9.80437 9.81748 9.24692 10.5448 9.03298C10.8919 8.93086 11.5386 8.79111 11.875 8.81252C12.0765 8.82534 12.4278 8.95179 12.5915 9.18552C12.7555 9.41922 12.7948 9.71829 12.6974 9.98661L11.3743 13.625H12.7496C12.7498 14.3823 12.2024 15.0288 11.4553 15.1533ZM11.875 8.37501C11.3917 8.37501 11 7.98321 11 7.5C11 7.01674 11.3917 6.62499 11.875 6.62499C12.3582 6.62499 12.75 7.01674 12.75 7.5C12.75 7.98324 12.3583 8.37501 11.875 8.37501Z"
      fill={color}
    />
  </SVGBox>
);

export { InfoIcon };
