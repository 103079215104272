import React, {
  type MouseEvent,
  type KeyboardEvent,
  useEffect,
  useState,
  type ReactElement,
  type HTMLAttributes,
  useMemo,
  useLayoutEffect,
  cloneElement,
  type CSSProperties,
} from "react";
import { useUpdateEffect } from "react-use";
import ReactDOM from "react-dom";
import cx from "classnames";
import { usePopper } from "react-popper";
import { type Placement } from "@popperjs/core";

import styles from "./Popover.module.scss";

const POPPER_PLACEMENT_MAPPING: { [key: string]: Placement } = {
  "down-left": "bottom-end",
  "down-right": "bottom-start",
  "up-left": "top-end",
  "up-right": "top-start",
};
type PopoverPosition = "auto" | "down-left" | "down-right" | "up-left" | "up-right";
export interface PopoverProps extends Omit<React.HTMLAttributes<HTMLElement>, "content"> {
  readonly contentClassName?: string;
  readonly backDropClassName?: string;
  readonly position?: PopoverPosition;
  readonly placementShift?: number | [number, number];
  readonly triggerAction?: "click" | "hover";
  readonly triggerDelay?: number;
  readonly children: ReactElement;
  readonly overflow?: CSSProperties["overflow"];
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly content: () => React.ReactNode | React.ReactNode[];
  readonly actions?: (params: { open: () => void; close: () => void }) => void;
}

function Popover({
  className,
  contentClassName,
  backDropClassName,
  content,
  onOpen,
  onClose,
  actions,
  position = "auto",
  overflow = "hidden",
  placementShift = 8,
  triggerAction = "click",
  triggerDelay = 0,
  children,
  onKeyDown,
  ...props
}: PopoverProps) {
  const [showPopover, setShowPopover] = useState(false);
  const [togglerElt, setTogglerElt] = useState<HTMLDivElement | null>(null);
  const [backdropElt, setBackdropElt] = useState<HTMLDivElement | null>(null);
  const [localPosition, setLocalPosition] = useState(position);

  useEffect(() => {
    // on click away content
    if (triggerAction !== "click") return;
    const handler = (event: any) => {
      if (
        backdropElt &&
        togglerElt &&
        !backdropElt.contains(event.target) &&
        !togglerElt.contains(event.target)
      ) {
        close();
      }
    };
    window.addEventListener("mousedown", handler);
    window.addEventListener("touchstart", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
      window.removeEventListener("touchstart", handler);
    };
  }, [backdropElt]);

  // https://popper.js.org/docs/v2/modifiers/offset/
  const offsetModifier = useMemo(
    () => ({
      name: "offset",
      options: {
        offset: ({
          placement,
        }: {
          placement: Placement;
          reference: { x: number; y: number; width: number; height: number };
        }) => {
          if (placement.includes("end")) {
            // left
            if (placementShift instanceof Array) {
              return [-placementShift[0], -placementShift[1]];
            }
            return [-placementShift, -placementShift];
          } else if (placement.includes("start")) {
            // right
            if (placementShift instanceof Array) {
              return [placementShift[0], placementShift[1]];
            }
            return [placementShift, -placementShift];
          }
          return [0, 0];
        },
      },
    }),
    [placementShift]
  );

  const flipModifier = useMemo(
    () => ({
      name: "flip",
      enabled: false,
    }),
    []
  );

  const [maxHeight, setMaxHeight] = useState<string>();

  const setPositionAndMaxHeight = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const togglerRect = togglerElt?.getBoundingClientRect();
    if (togglerRect === undefined) return;
    if (!backdropElt) return;
    const halfScreenWidth = windowWidth / 2;
    const [placementShiftX, placementShiftY] =
      placementShift instanceof Array ? placementShift : [placementShift, placementShift];
    const popperHorizontalPosition =
      togglerRect.left < placementShiftX + halfScreenWidth ? "right" : "left";
    if (position !== "auto") {
      // If position is set, we just check if we need to change the maxHeight
      setLocalPosition(position);
      const availableHeight = position.includes("up")
        ? togglerRect.top // If the popover is above the toggler, we have to check the space above the toggler
        : windowHeight - togglerRect.bottom; // If the popover is below the toggler, we have to check the space below the toggler
      const isOverflowing = backdropElt?.scrollHeight >= availableHeight;
      setMaxHeight(!isOverflowing ? "auto" : availableHeight + "px");
    } else if (backdropElt?.scrollHeight < windowHeight - togglerRect.bottom) {
      // If there is enough space below the toggler, we display the popover below (just checking horizontal position)
      setLocalPosition(("down-" + popperHorizontalPosition) as PopoverPosition);
      setMaxHeight("auto");
    } else {
      // If there is not enough space below the toggler
      // Based on the toggler position on the viewport (x,y) and the window size, we decide to display the popover above or below, left or right
      const popperVerticalPosition =
        togglerRect.top < placementShiftY + windowHeight / 2 ? "down" : "up";
      setLocalPosition(
        (popperVerticalPosition + "-" + popperHorizontalPosition) as PopoverPosition
      );
      const availableHeight =
        popperVerticalPosition === "up" ? togglerRect.top : windowHeight - togglerRect.bottom;
      const maxHeight = availableHeight;
      setMaxHeight(maxHeight + "px");
    }
  };

  useLayoutEffect(() => {
    setPositionAndMaxHeight();
    window.addEventListener("scroll", setPositionAndMaxHeight);
    window.addEventListener("resize", setPositionAndMaxHeight);
    return () => {
      window.removeEventListener("scroll", setPositionAndMaxHeight);
      window.removeEventListener("resize", setPositionAndMaxHeight);
    };
  }, [togglerElt, backdropElt, placementShift]);

  const { styles: popperStyle, attributes } = usePopper(togglerElt, backdropElt, {
    placement: POPPER_PLACEMENT_MAPPING[localPosition],
    modifiers: [offsetModifier, flipModifier],
  });

  function handleClickInside(e: MouseEvent) {
    e.stopPropagation();
  }
  function handleClickOutside() {
    if (triggerAction === "click") close();
  }

  function open() {
    onOpen?.();
    setShowPopover(true);
  }
  function close() {
    onClose?.();
    setShowPopover(false);
    setMaxHeight("auto");
  }

  function toggle() {
    if (showPopover) {
      close();
    } else {
      open();
    }
  }

  useEffect(() => {
    if (actions) {
      actions({
        open,
        close,
      });
    }
    return () => {
      close();
    };
  }, []);

  const handleKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (showPopover) {
      if (e.key === " " || e.key === "Escape") {
        e.preventDefault();
        close();
        return;
      }
    }
    onKeyDown?.(e);
  };

  const [mouseInsideToggler, setMouseInsideToggler] = useState(false);
  const [mouseInsideMenu, setMouseInsideMenu] = useState(false);
  const ToggleButton = cloneElement(children, toggleButtonAttributes());

  function handleHoverInside() {
    setMouseInsideMenu(true);
  }
  function handleHoverOutside() {
    setMouseInsideMenu(false);
  }

  function toggleButtonAttributes() {
    const res: Partial<HTMLAttributes<HTMLElement>> = {
      tabIndex: 0,
    };

    if (triggerAction === "click") res.onClick = handleToggleClick;
    else if (triggerAction === "hover") {
      res.onMouseOver = handleToggleEnter;
      res.onMouseOut = handleToggleExit;
    }

    function handleToggleClick(e: MouseEvent) {
      e.stopPropagation();
      if (!triggerDelay) toggle();
      else setTimeout(toggle, triggerDelay);
    }
    function handleToggleEnter() {
      setMouseInsideToggler(true);
      if (!triggerDelay) open();
      else {
        setTimeout(() => {
          setMouseInsideToggler((prev) => {
            if (prev) open();
            return prev;
          });
        }, triggerDelay);
      }
    }
    function handleToggleExit() {
      setMouseInsideToggler(false);
    }

    return res;
  }

  useUpdateEffect(() => {
    if (triggerAction === "hover" && !mouseInsideToggler && !mouseInsideMenu) close();
  }, [mouseInsideToggler, mouseInsideMenu, triggerAction]);
  return (
    <>
      <div
        ref={setTogglerElt}
        {...props}
        className={cx(styles.Popover, className)}
        onKeyDown={handleKey}
      >
        {ToggleButton}
      </div>
      {showPopover &&
        ReactDOM.createPortal(
          <div
            className={cx(
              styles.PopoverBackdrop,
              backDropClassName,
              styles[triggerAction],
              styles[localPosition]
            )}
            ref={setBackdropElt}
            onClick={handleClickOutside}
            aria-label="fond-menu"
            {...attributes.popper}
            style={{ ...popperStyle.popper, overflow: overflow }}
          >
            <div
              onClick={handleClickInside}
              onMouseEnter={handleHoverInside}
              onMouseLeave={handleHoverOutside}
              className={cx(styles.menu, contentClassName)}
              aria-label="contenu-menu"
              style={{ maxHeight: maxHeight }}
            >
              {content()}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}

export { Popover };
