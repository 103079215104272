import React from "react";
import styles from "./AvatarGroup.module.scss";
import { Avatar as DSAvatar } from "../Avatar/Avatar";
import cx from "classnames";
import { useMemo } from "react";
import { type AvatarProps } from "../Avatar/Avatar";
import { type Themes } from "components/commonProps";
import { Tooltip } from "../Tooltip/Tooltip";

const MORE_BUBBLE_MAX = 99;

export interface AvatarGroupProps extends AvatarProps {
  avatarClassName?: string;
  moreClassName?: string;

  users: Array<{
    uuid: string;
    initials: string;
    fullName?: string;
  }>;
  totalItems?: number;
  firstElementForeground?: boolean;
  maxItems?: number;
}
function AvatarGroup({
  className,
  onClick,
  users,
  theme,
  maxItems,
  totalItems,
  avatarClassName,
  moreClassName,
  firstElementForeground = false,
  ...avatarProps
}: AvatarGroupProps) {
  const sortedUsers = useMemo(() => {
    const usersCopy = [...users];
    if (firstElementForeground) {
      return usersCopy.reverse();
    }
    return usersCopy;
  }, [users, firstElementForeground]);

  const renderedUsers = useMemo(() => sortedUsers.slice(0, maxItems), [sortedUsers, maxItems]);

  let countDiff = 0;
  if (totalItems && renderedUsers.length < totalItems) {
    countDiff = totalItems - renderedUsers.length;
  }

  return (
    <div
      className={cx(
        className,
        styles[theme],
        styles.AvatarGroup,
        avatarProps?.size && styles[avatarProps?.size]
      )}
      onClick={onClick}
    >
      <div className={cx(styles.avatarWrapper, { [styles.flexDirection]: firstElementForeground })}>
        {renderedUsers.map((user) => (
          <SingleAvatar
            key={user.uuid}
            avatarProps={avatarProps}
            className={avatarClassName}
            user={user}
            theme={theme}
            onClick={onClick}
          />
        ))}
      </div>
      {countDiff > 0 && (
        <div className={styles.moreBubbleContainer}>
          <MoreBubble className={moreClassName} count={countDiff} theme={theme} />
        </div>
      )}
    </div>
  );
}
function SingleAvatar({
  avatarProps,
  className,
  user,
  theme,
  onClick,
}: {
  avatarProps?: Omit<AvatarProps, "theme">;
  className?: string;
  user: AvatarGroupProps["users"][0];
  theme: Themes;
  onClick?: () => void;
}) {
  return (
    <div className={styles.singleAvatar}>
      <DSAvatar
        theme={theme}
        generateRandomColor
        initials={user.initials}
        tooltip={user.fullName}
        randomColorSeedString={user.uuid}
        className={className}
        onClick={onClick}
        {...avatarProps}
      />
    </div>
  );
}

function MoreBubble({
  theme,
  count,
  className,
}: {
  theme: Themes;
  count: number;
  className?: string;
}) {
  if (count > MORE_BUBBLE_MAX) {
    return (
      <Tooltip label={count.toString()} theme={theme}>
        <div className={cx(styles.moreBubble, className)}>
          <span>+</span>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={cx(styles.moreBubble, className)}>
      <span>+ {count}</span>
    </div>
  );
}

export { AvatarGroup };
