import React, { type ChangeEvent, type InputHTMLAttributes, type LegacyRef, useCallback } from "react";
import { toNumber, isEmpty } from "lodash";
import cx from "classnames";

import styles from "./NumberInput.module.scss";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
  readonly onChange?: (value?: number) => void;
  readonly value?: number;
  readonly errored?: boolean;
  readonly inputRef?: LegacyRef<HTMLInputElement>;
  readonly controlled?: boolean;
}

const NumberInput = ({
  className,
  onChange,
  readOnly,
  value,
  errored,
  inputRef,
  controlled,
  ...props
}: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: currentValue },
      } = event;
      const nextValue = currentValue.replace(",", ".");

      if (onChange && !readOnly) {
        return onChange(isEmpty(nextValue) ? undefined : toNumber(nextValue));
      }
    },
    [onChange, readOnly]
  );

  return (
    <input
      className={cx(styles.NumberInput, className, {
        [styles.readOnly]: readOnly,
        [styles.errored]: errored,
      })}
      ref={inputRef}
      type="number"
      data-testid="number-input"
      onChange={handleChange}
      readOnly={readOnly}
      {...(controlled ? { value } : { defaultValue: value ?? undefined })}
      {...props}
    />
  );
};

export { NumberInput };
