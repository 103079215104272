import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

import useQuery from "hooks/useQuery";
import Colors from "components/Colors";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import { arobase, key, login } from "components/StrokeIcons";
import SkillupLogo from "components/SkillupLogo";
import User from "helpers/User";

import styles from "./LoginStyles.module.scss";
import request from "helpers/request";
import { AuthRoutes } from "api/types";

const LogIn = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const query = useQuery<{ url?: string }>();
  const [loading, toggleLoading] = useToggle(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onSubmit = useCallback(
    async (e: React.FormEvent): Promise<void> => {
      e.preventDefault();
      toggleLoading(true);

      try {
        const { data } = await request<AuthRoutes["POST"]["/v1/signin"]["response"]>({
          target: "AUTH",
          data: { email, password },
          method: "POST",
          url: `/signin`,
        });
        window.localStorage.setItem("X-Auth-Token", data.token);

        if (query.url) {
          window.location.href = query.url;
        } else {
          const { REACT_APP_PUBLIC_APP_URL } = process.env;
          const isSkillupMember =
            User.isSkillupAdmin(data.credentials) || User.isSkillupDeveloper(data.credentials);

          if (User.isRF(data.credentials) || isSkillupMember) {
            window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
          } else if (User.isOF(data.credentials)) {
            window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
          } else if (User.isCompanyUser(data.credentials)) {
            window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
          } else {
            window.location.href = `${REACT_APP_PUBLIC_APP_URL}`;
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 429) {
            addToast(
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
              {
                appearance: "error",
              }
            );
          } else if (error.response?.data?.message === "No such user with params") {
            addToast(
              "Identifiants invalides. Utilisez le formulaire de réinitialisation de mot de passe si vous avez oublié vos identifiants.",
              {
                appearance: "error",
              }
            );
          }
        } else {
          addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
            appearance: "error",
          });
        }

        toggleLoading(false);
      }
    },
    [toggleLoading, email, password, query, addToast]
  );

  return (
    <div className={styles.view}>
      <div className={styles.LogIn}>
        <div>
          <header>
            <SkillupLogo />
            <h1>Se connecter</h1>
          </header>
          <main>
            <form onSubmit={onSubmit}>
              <TextInput
                autoComplete="username"
                label="Email"
                name="email"
                placeholder="Votre email"
                onChange={(_e, value) => setEmail(value)}
                required
                strokeIcon={arobase}
                type="email"
              />
              <TextInput
                autoComplete="current-password"
                label="Mot de passe"
                name="password"
                placeholder="Votre mot de passe"
                onChange={(_e, value) => setPassword(value)}
                required
                strokeIcon={key}
                type="password"
              />

              <InteractiveButton
                label="Connexion"
                strokeIconLeft={login}
                style={{ margin: "30px auto 0 auto" }}
                loading={loading}
                type="submit"
              />
            </form>
            <footer>
              <p>Mot de passe oublié ?</p>
              <InteractiveButton
                label="Réinitialiser"
                iconLeft={key}
                size="small"
                background="#fff"
                color={Colors.blue}
                onClick={() => navigate("/connexion/reinitialisation")}
                style={{ marginRight: 10 }}
              />
            </footer>
          </main>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
