import React, { type CSSProperties, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { usePrevious, useToggle } from "react-use";
import { format, type Locale, parse, isValid } from "date-fns";

import { Flex } from "components/Flex";
import { TextInput } from "components/Form/TextInput";
import { Button } from "components/Button";

import * as Icon from "../../Icon";
import { DateRangeCalendar } from "./DateRangeCalendar";

import styles from "./DateRangeInput.module.scss";

export interface Props {
  readonly label?: string;
  readonly placeholder?: string;
  readonly value?: string | null;
  readonly minValue?: string | null;
  readonly maxValue?: string | null;
  readonly styleCalendar?: CSSProperties;
  readonly className?: string;
  readonly classNameContainer?: string;
  readonly classNameCalendar?: string;
  readonly autoFormat?: boolean;
  readonly autoFocus?: boolean;
  readonly hasError?: boolean;
  readonly locale?: Locale;
  readonly onChange?: (date: Date | null) => void;
  readonly disabled?: boolean;
  readonly withIcon?: boolean;
}

const DateRangeInput = ({
  label = "Période",
  placeholder = ".. / .. / ..",
  value: date,
  minValue,
  maxValue,
  hasError,
  autoFormat,
  autoFocus,
  className,
  classNameCalendar,
  classNameContainer,
  locale,
  onChange,
  disabled,
  withIcon,
  styleCalendar,
}: Props) => {
  const [isCalendarVisible, toggleCalendar] = useToggle(false);
  const [value, setValue] = useState(date ? format(new Date(date), "dd/MM/yyyy") : "");
  const previousValue = usePrevious(value);
  const [state, setState] = useState<"invalid" | "valid">(hasError ? "invalid" : "valid");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!autoFormat || !!date) {
      const newValue = date ? format(new Date(date), "dd/MM/yyyy") : "";
      if (inputRef.current) {
        // TextInput value key is actually used as defaultValue preventing component to update
        inputRef.current.value = newValue;
      }
      setValue(newValue);
    }
  }, [date, autoFormat]);

  useEffect(() => {
    setState(hasError ? "invalid" : "valid");
  }, [hasError]);

  useEffect(() => {
    const parsedDate = parse(value, "dd/MM/yyyy", new Date(), { locale });
    if (value && value.length === 10 && isValid(parsedDate)) {
      onChange?.(parsedDate);
      !hasError && setState("valid");
    } else if (value) {
      onChange?.(null);
      if (
        autoFormat &&
        (value.length === 2 || value.length === 5) &&
        previousValue &&
        previousValue.length < value.length &&
        !value.endsWith("/")
      ) {
        setValue(value + "/");
      }
      setState("invalid");
    } else {
      onChange?.(null);
      !hasError && setState("valid"); // empty
    }
  }, [value]);

  return (
    <Flex column className={cx(styles.DateRangeInput, className)}>
      <label className={styles.label}>{label}</label>
      <Flex className={cx(styles.periodInputContainer, classNameContainer)}>
        <TextInput
          name="date"
          placeholder={placeholder}
          value={value}
          error={state === "invalid"}
          onChange={setValue}
          ref={inputRef}
          autoFocus={autoFocus}
          onClick={() => {
            if (!withIcon && !disabled) {
              toggleCalendar(true);
            }
          }}
          disabled={disabled}
          actionButton={
            withIcon ? (
              <Button iconOnly icon={<Icon.Calendar />} onClick={() => toggleCalendar(true)} />
            ) : (
              <></>
            )
          }
        />
      </Flex>
      {isCalendarVisible && (
        <DateRangeCalendar
          value={date}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(date) => {
            onChange?.(date);
            toggleCalendar(false);
          }}
          onClickAway={() => toggleCalendar(false)}
          locale={locale}
          className={classNameCalendar}
          style={styleCalendar}
        />
      )}
    </Flex>
  );
};

export { DateRangeInput };
