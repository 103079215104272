import React, { type ReactNode, type ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useLockBodyScroll } from "react-use";

import cx from "classnames";

import { Themes } from "components/commonProps";
import { Flex } from "../Flex";
import { Close } from "../MaterialIcons";
import { Cross } from "../../Legacy_components/Icon";
import { Button } from "../../Legacy_components/Button";
import { Button as DSButton } from "../Button";
import { colors } from "../../assets/colors";
import { useMediaQueries } from "../../hooks/useMediaQueries";
import { useModalContext } from "./context";

import styles from "./Modal.module.scss";

export interface Props {
  readonly children: ReactNode;
  readonly title?: string;
  readonly className?: string;
  readonly closeBtnClassName?: string;
  readonly titleClassName?: string;
  readonly onClose?: () => void;
  readonly fullWidth?: boolean;
  readonly fit?: boolean;
  readonly fill?: boolean;
  readonly theme: Themes;
  readonly disableOnClickAway?: boolean;
  readonly size?: "big" | "small";
}

const Modal = ({
  children,
  fullWidth,
  className,
  onClose,
  title,
  closeBtnClassName,
  titleClassName,
  fit,
  fill,
  theme = Themes.ESPACE_COLLABORATEUR,
  disableOnClickAway = false,
  size,
}: Props): ReactElement => {
  const { isMobile } = useMediaQueries();
  const { removeCurrentModal } = useModalContext();

  const handleCloseModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
    removeCurrentModal();
  }, [onClose]);

  const escFunction = useMemo(
    () => (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        handleCloseModal();
        event.preventDefault();
      }
    },
    [handleCloseModal]
  );

  const onClickAway = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      handleCloseModal();
    },
    [handleCloseModal]
  );

  useLockBodyScroll(isMobile);

  useEffect(() => {
    window.addEventListener("keydown", escFunction);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  const [isModal, setModal] = useState(false);
  useEffect(() => {
    if (isModal) return;
    if (!document.getElementById(styles.modal)) {
      const $modal = document.createElement("div");
      $modal.id = styles.modal;
      document.body.appendChild($modal);
    }
    setModal(true);
  }, [isModal]);

  if (!isModal) return <></>;

  return createPortal(
    <div className={styles.modalPortal}>
      <div className={styles.background} onClick={!disableOnClickAway ? onClickAway : undefined} />
      <div className={cx(styles.scrollContainer, { [styles.fit]: fit })}>
        <Flex center className={styles.modalContainer}>
          <div
            className={cx(styles.Modal, styles[theme], {
              [styles.fullWidth]: fullWidth,
              [styles.small]: size === "small",
              [styles.fill]: fill,
              [styles.fit]: fit,
            })}
          >
            {theme === "espace-collab" && (
              <Button
                className={cx(styles.closeBtn, closeBtnClassName)}
                kind="secondary"
                onClick={handleCloseModal}
                aria-label="Fermer"
                icon={(isHover: boolean) => (
                  <Cross
                    className={styles.closeIcon}
                    size="1.25rem"
                    color={isHover ? "white" : colors.bwBlack}
                  />
                )}
              />
            )}
            {theme === "espace-rh" && (
              <div className={cx(styles.header, { [styles.withTitle]: !!title })}>
                {title && <h2 className={styles.title}>{title}</h2>}
                <DSButton
                  label="Close Modal"
                  iconOnly
                  className={cx(styles.closeBtn, closeBtnClassName)}
                  icon={<Close className={styles.closeIcon} />}
                  onClick={handleCloseModal}
                />
              </div>
            )}
            <Flex column className={cx(styles.content, className)}>
              {theme === "espace-collab" && title && (
                <h2 className={cx(styles.title, titleClassName)}>{title}</h2>
              )}
              {children}
            </Flex>
          </div>
        </Flex>
      </div>
    </div>,
    document.getElementById(styles.modal) as HTMLDivElement
  );
};

export { Modal };
