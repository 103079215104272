import { useCallback } from "react";
import { type StylesConfig, type Theme } from "react-select";

import { type SelectProps } from "../type";
import { Themes } from "components/commonProps";

import colors from "../../../atoms/Colors/ColorsExport.module.scss";
import spacing from "../../../atoms/SpacingTokens/SpacingExport.module.scss";

export function useReactSelectTheme({
  error,
  darkMode,
  multi,
  theme,
  paginationCustomStyle: customStyle,
}: SelectProps<any>) {
  const customTheme = useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        neutral50: darkMode ? colors["plainText-onDark-default"] : colors["placeholder-onLight"],
        primary25: darkMode ? colors["effects-hover-onDark"] : colors["effects-hover-onLight"],
        primary: darkMode ? colors["action-onDark-default"] : colors["action-onLight-default"],
        neutral10: darkMode ? colors["greyscale-dark"] : colors["greyscale-light"],
        dangerLight: darkMode ? colors["greyscale-dark"] : colors["greyscale-light"],
      },
    }),
    []
  );

  const styles: StylesConfig<any> = {
    control: (base, { isFocused }) => ({
      ...base,
      ...(error
        ? {
            borderColor: darkMode ? colors["status-error-default"] : colors["status-error-darker"],
            "&:hover": {
              borderColor: colors["status-error-darker"],
            },
          }
        : {
            borderColor: isFocused
              ? colors["greyscale-medium"]
              : colors[`border-${darkMode ? "onDark" : "onLight"}`],
            "&:hover": {
              borderColor: colors["greyscale-medium"],
            },
          }),
      boxShadow: "none",
      outline:
        isFocused && !error
          ? `2px solid ${
              darkMode ? colors["action-onDark-default"] : colors["action-onLight-default"]
            }`
          : "none",
      outlineOffset: "1px",
      width: customStyle?.width,
      height: customStyle?.height,
      minHeight: customStyle?.height ? "unset" : undefined,
      flexWrap: "nowrap",
    }),
    input: (base) => ({
      ...base,
      margin: customStyle ? 0 : undefined,
      padding: customStyle ? 0 : undefined,
      color: error
        ? colors[`status-error-${darkMode ? "lighter" : "darker"}`]
        : colors[`plainText-${darkMode ? "onDark" : "onLight"}-default`],
    }),
    placeholder: (base) => ({
      ...base,
      fontStyle: "italic",
    }),
    singleValue: (base) => ({
      ...base,
      margin: customStyle ? 0 : undefined,
      color: error
        ? colors[`status-error-${darkMode ? "lighter" : "darker"}`]
        : colors[`plainText-${darkMode ? "onDark" : "onLight"}-default`],
      "&:hover": {
        color: error
          ? colors[`status-error-${darkMode ? "lightest" : "darker"}`]
          : colors[`plainText-${darkMode ? "onDark" : "onLight"}-default`],
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: customStyle?.height,
      paddingRight: customStyle?.height && "unset",
      paddingLeft: customStyle?.height && "unset",
      paddingTop: customStyle?.height && "unset",
      paddingBottom: customStyle?.height && "unset",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base, { isFocused }) => ({
      ...base,
      paddingTop: customStyle?.paddingTop || spacing.skuiSpacingXS,
      paddingRight: customStyle?.paddingRight || spacing.skuiSpacingXS,
      paddingLeft: 0,
      paddingBottom: customStyle?.paddingBottom || spacing.skuiSpacingXS,
      color: isFocused
        ? colors[`plainText-${darkMode ? "onDark" : "onLight"}-default`]
        : colors[`plainText-${darkMode ? "onDark-darker" : "onLight-lighter"}`],
      "&:hover": {
        color: darkMode ? colors["plainText-onDark-default"] : colors["plainText-onLight-default"],
      },
      cursor: "pointer",
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingRight: spacing.skuiSpacingXXS,
      cursor: "pointer",
      color: darkMode ? colors["plainText-onDark-darker"] : colors["plainText-onLight-lighter"],
      "&:hover": {
        color: darkMode ? colors["plainText-onDark-default"] : colors["plainText-onLight-default"],
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: customStyle?.paddingTop,
      paddingTop: customStyle?.paddingTop || spacing.skuiSpacingXXS,
      paddingRight: customStyle?.paddingRight || spacing.skuiSpacingXS,
      paddingBottom: customStyle?.paddingBottom || spacing.skuiSpacingXXS,
      paddingLeft: customStyle?.paddingLeft || spacing.skuiSpacingXS,
      ...(multi && {
        paddingTop: customStyle?.paddingTop || spacing.skuiSpacingXS,
        paddingBottom: customStyle?.paddingBottom || spacing.skuiSpacingXS,
        flexWrap: customStyle?.flexWrap || "nowrap",
      }),
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: spacing.skuiSpacingS,
      paddingRight: customStyle?.paddingRight || spacing.skuiSpacingXS,
      paddingLeft: customStyle?.paddingLeft || spacing.skuiSpacingXS,
      margin: spacing.skuiSpacingXXS,
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: darkMode ? colors["plainText-onDark-darker"] : colors["plainText-onLight-lighter"],
      cursor: "pointer",
      "&:hover": {
        color: darkMode ? colors["plainText-onDark-default"] : colors["plainText-onLight-default"],
      },
    }),
    menu: (base) => ({
      ...base,
      fontFamily: theme === Themes.ESPACE_RH ? "Noto Sans Display" : "DM Sans",
      zIndex: 10000,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10000 }),
    option: (base, { isSelected, isFocused, isDisabled }) => ({
      ...base,
      color: "",
      cursor: isDisabled ? "not-allowed" : "pointer",
      opacity: isDisabled ? 0.5 : 1,
      display: multi ? "flex" : undefined,
      padding: multi ? 0 : "0.5rem 1rem",
      backgroundColor: isFocused ? colors["effects-hover-onLight"] : "transparent",
      "&:active": {
        backgroundColor: colors["effects-hover-onLight"],
      },
      fontWeight: isSelected ? "bold" : undefined,
    }),
    groupHeading: (base) => ({
      ...base,
      fontSize: "0.875rem",
      textTransform: "none",
    }),
  };
  return { theme: customTheme, styles };
}
