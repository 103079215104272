import { withTheme } from "components/DesignSystemContext";

import { FormGroupText, type FormGroupTextProps } from "./FormGroupText";
import { CustomTextInput } from "./CustomTextInput";

type ThemedModal = ReturnType<typeof withTheme<FormGroupTextProps>>;

type FormGroupTextType = ThemedModal & {
  /**
   * The TextInput child passed to this component does not take the following props:
   * - onChange
   * - onBlur
   * - onFocus
   * - value
   * - error
   * These props are handled by the parent component
   */
  TextInput: typeof CustomTextInput;
};

const DSFormGroupText = Object.assign(withTheme<FormGroupTextProps>(FormGroupText), {
  TextInput: CustomTextInput,
}) as FormGroupTextType;

export { DSFormGroupText };
