import React, { useContext } from "react";

import { DesignSystemContext } from "components/DesignSystemContext";
import { Select as PureSelect } from "./Select";
import { type SelectPropsWithoutTheme } from "./type";
export { MenuPlacement, type SelectPropsWithoutTheme } from "./type";

export function Select<T>(props: SelectPropsWithoutTheme<T>) {
  const context = useContext(DesignSystemContext);
  const selectProps = {
    ...props,
    theme: context.theme,
  };

  return <PureSelect {...selectProps} />;
}
