import React, { type CSSProperties } from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import * as Icon from "../Icon";
import { colors } from "../../assets/colors";

import styles from "./FilterShortcut.module.scss";

export interface IProps {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly label: string;
  readonly disabled?: boolean;
  readonly withIcon?: boolean;
  readonly active?: boolean;
  readonly onClick?: (label?: string) => void;
}

const FilterShortcut = (props: IProps) => {
  const { className, style, label, disabled, withIcon, active, onClick } = props;
  const [isHover, toggleHover] = useToggle(false);

  return (
    <Flex
      className={cx(styles.FilterShortcut, className, { [styles.active]: active })}
      style={style}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={onClick ? () => onClick(label) : undefined}
      disabled={disabled}
      data-testid="shortcut-container"
    >
      <Icon.Container
        className={styles.iconContainer}
        size="1rem"
        backgroundColor={isHover || active ? colors.secondaryBlue : colors.slate}
        disabled={!withIcon}
        round
      >
        <Icon.Plus color="white" />
      </Icon.Container>
      <span className={styles.label} data-testid="shortcut-label">
        {label}
      </span>
    </Flex>
  );
};

export { FilterShortcut };
