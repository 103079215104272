type Skill = {
  name: string;
  uuid?: string;
  href?: string;
  level: number;
  levelName: string;
  expectedLevel?: number;
  levelDescription?: string;
  totalLevel?: Array<{ level: number }>;
  expectedLevelName?: string;
  expectedLevelDescription?: string;
  levelRange: { min: number; max: number };
};

export function computeRadarData(skills: Array<Skill>) {
  const enhancedSkills = skills.map((skill) => {
    return {
      ...skill,
      href: `#${skill.uuid}`,
    };
  });

  const hasSameMaxLevel = enhancedSkills.every(
    (skill) => skill.levelRange.max === enhancedSkills[0].levelRange.max
  );

  const gridLevels =
    hasSameMaxLevel && enhancedSkills[0].levelRange ? enhancedSkills[0].levelRange.max : 1;

  const keys = skills.some((keys) => "expectedLevel" in keys)
    ? ["level", "expectedLevel"]
    : ["level"];

  return {
    keys,
    gridLevels,
    enhancedSkills,
  };
}
