import React from "react";
import cx from "classnames";

import { ArrowDownKeyboard, ArrowUpKeyboard } from "components/MaterialIcons";
import { type Themes } from "../commonProps";

import styles from "./ExpansionPanel.module.scss";

export interface ExpansionPanelProps {
  readonly "aria-label"?: string;
  readonly className?: string;
  readonly children?: React.ReactNode;
  readonly title: string;
  readonly opened?: boolean;
  readonly fullBleed?: boolean;
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const ExpansionPanel = (props: ExpansionPanelProps) => {
  const { className, title } = props;
  const [isOpen, setIsOpen] = React.useState(props.opened ?? false);

  const toggleOpen = React.useCallback(() => {
    const nextOpenStatus = !isOpen;
    setIsOpen(nextOpenStatus);
  }, [isOpen]);

  const actionLabel = isOpen ? "Fermer" : "Ouvrir";

  return (
    <div
      aria-label={"extensible:" + (props["aria-label"] ?? title)}
      className={cx(
        styles.ExpansionPanel,
        styles[props.theme],
        {
          [styles.fullBleed]: props.fullBleed,
          [styles.darkMode]: props.darkMode,
        },
        className
      )}
    >
      <button
        className={styles.head}
        onClick={toggleOpen}
        aria-expanded={isOpen}
        aria-label={actionLabel}
      >
        <div className={styles.label}>{title}</div>
        <div className={styles.iconWrapper}>
          {isOpen ? <ArrowUpKeyboard size={24} /> : <ArrowDownKeyboard size={24} />}
        </div>
      </button>
      <div aria-label="contenu" className={cx(styles.content, { [styles.opened]: isOpen })}>
        {props.children}
      </div>
    </div>
  );
};

export { ExpansionPanel };
