import React, { type CSSProperties } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { colors } from "../../assets/colors";
import styles from "./Avatar.module.scss";

export interface IProps {
  readonly className?: string;
  readonly label?: string;
  readonly labelColor?: string;
  readonly backgroundColor?: string;
  readonly style?: CSSProperties;
  readonly loading?: boolean;
  readonly small?: boolean;
}

const Avatar = (props: IProps): JSX.Element => {
  const {
    className,
    label,
    labelColor = colors.primaryBlue,
    backgroundColor = colors.statusViolet,
    style,
    loading = false,
    small = false,
  } = props;

  return (
    <Flex
      center
      className={cx(styles.Avatar, className, {
        [styles.loading]: loading,
        [styles.small]: small,
      })}
      role="avatar"
      style={{ ...style, backgroundColor }}
    >
      <span className={styles.label} style={{ color: labelColor }}>
        {label}
      </span>
    </Flex>
  );
};

export { Avatar };
