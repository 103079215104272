export function getInitials({
  firstName,
  lastName,
  fullName,
}: {
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
}): string {
  if (fullName) return extractInitialsFromString(fullName);

  const firstNameInitials = extractInitialsFromString(firstName ?? "");
  const lastNameInitials = extractInitialsFromString(lastName ?? "");

  return `${firstNameInitials}${lastNameInitials}`.toUpperCase();
}

function extractInitialsFromString(str: string) {
  return str
    .replaceAll("-", " ")
    .replaceAll("'", " ")
    .split(" ")
    .filter((word) => !["de", "la", "du", "d", "des", "di", "den", "del"].includes(word))
    .map((word) => word.slice(0, 1))
    .join("")
    .toUpperCase();
}
