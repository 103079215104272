export enum Themes {
  ESPACE_RH = "espace-rh",
  ESPACE_COLLABORATEUR = "espace-collab",
}
export const decorationColors = [
  "Pink",
  "Salmon",
  "Gold",
  "Peach",
  "Tan",
  "Forest",
  "Lime",
  "Sky",
  "Steel",
  "Turquoise",
  "Plum",
  "Violet",
  "Misty",
  "Silver",
  "Grey",
] as const;
