import React from "react";

import { Time } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const CampaignOutOfTime = () => (
  <RectangleTag
    label="Campagne Terminée"
    icon={<Time color="#ff6760" strokeWidth="1.2" />}
    backgroundColor="#feece7"
    textColor="#ff6760"
  />
);

export { CampaignOutOfTime };
