import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgTrash = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10855 3.1335L5.5566 1.78934C5.64734 1.51712 5.9021 1.3335 6.18905 1.3335H9.78905C10.076 1.3335 10.3308 1.51712 10.4215 1.78934L10.8696 3.1335H13.989C14.3572 3.1335 14.6557 3.43197 14.6557 3.80016C14.6557 4.16835 14.3572 4.46683 13.989 4.46683H13.4177L12.9284 12.7843C12.8662 13.8414 11.9908 14.6668 10.9318 14.6668H5.04626C3.98731 14.6668 3.1119 13.8414 3.04971 12.7843L2.56045 4.46683H1.99967C1.63148 4.46683 1.33301 4.16835 1.33301 3.80016C1.33301 3.43197 1.63148 3.1335 1.99967 3.1335H5.10855ZM6.51401 3.1335H9.4641L9.30855 2.66683H6.66956L6.51401 3.1335ZM12.082 4.46683H3.89609L4.38074 12.706C4.40147 13.0584 4.69328 13.3335 5.04626 13.3335H10.9318C11.2848 13.3335 11.5766 13.0584 11.5974 12.706L12.082 4.46683ZM9.12369 6.15858C9.14665 5.7911 9.46317 5.51183 9.83064 5.53479C10.1981 5.55776 10.4774 5.87428 10.4544 6.24175L10.1544 11.0417C10.1315 11.4092 9.81494 11.6885 9.44747 11.6655C9.08 11.6426 8.80072 11.326 8.82369 10.9586L9.12369 6.15858ZM7.15442 10.9586C7.17739 11.326 6.89811 11.6426 6.53064 11.6655C6.16317 11.6885 5.84665 11.4092 5.82369 11.0417L5.52369 6.24175C5.50072 5.87428 5.78 5.55776 6.14747 5.53479C6.51494 5.51183 6.83146 5.7911 6.85442 6.15858L7.15442 10.9586Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgTrash };
