import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Bulb = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M10.5 4.44871C10.2067 4.44871 9.96875 4.21071 9.96875 3.91746V2.53125C9.96875 2.238 10.2067 2 10.5 2C10.7932 2 11.0312 2.238 11.0312 2.53125V3.91746C11.0312 4.21071 10.7932 4.44871 10.5 4.44871Z"
      fill={color}
    />
    <path
      d="M15.1549 6.37686C15.0189 6.37686 14.8829 6.32515 14.7794 6.22103C14.5719 6.01349 14.5719 5.67703 14.7794 5.46949L15.7598 4.48915C15.9673 4.28161 16.3038 4.28161 16.5113 4.48915C16.7189 4.69669 16.7189 5.03315 16.5113 5.24069L15.531 6.22103C15.4269 6.32444 15.2909 6.37686 15.1549 6.37686Z"
      fill={color}
    />
    <path
      d="M18.4687 11.0312H17.0825C16.7893 11.0312 16.5513 10.7932 16.5513 10.5C16.5513 10.2067 16.7893 9.96875 17.0825 9.96875H18.4687C18.762 9.96875 19 10.2067 19 10.5C19 10.7932 18.762 11.0312 18.4687 11.0312Z"
      fill={color}
    />
    <path
      d="M16.1352 16.6659C15.9992 16.6659 15.8632 16.6142 15.7598 16.5101L14.7794 15.5298C14.5719 15.3222 14.5719 14.9858 14.7794 14.7782C14.987 14.5707 15.3234 14.5707 15.531 14.7782L16.5113 15.7585C16.7189 15.9661 16.7189 16.3025 16.5113 16.5101C16.4072 16.6142 16.2712 16.6659 16.1352 16.6659Z"
      fill={color}
    />
    <path
      d="M4.86481 16.6659C4.72881 16.6659 4.59281 16.6142 4.4894 16.5101C4.28185 16.3025 4.28185 15.9661 4.4894 15.7585L5.46973 14.7782C5.67727 14.5707 6.01373 14.5707 6.22127 14.7782C6.42881 14.9858 6.42881 15.3222 6.22127 15.5298L5.24094 16.5101C5.13681 16.6142 5.00081 16.6659 4.86481 16.6659Z"
      fill={color}
    />
    <path
      d="M3.91746 11.0312H2.53125C2.238 11.0312 2 10.7932 2 10.5C2 10.2067 2.238 9.96875 2.53125 9.96875H3.91746C4.21071 9.96875 4.44871 10.2067 4.44871 10.5C4.44871 10.7932 4.21071 11.0312 3.91746 11.0312Z"
      fill={color}
    />
    <path
      d="M5.84515 6.37686C5.70915 6.37686 5.57315 6.32515 5.46973 6.22103L4.4894 5.24069C4.28185 5.03315 4.28185 4.69669 4.4894 4.48915C4.69694 4.28161 5.0334 4.28161 5.24094 4.48915L6.22127 5.46949C6.42881 5.67703 6.42881 6.01349 6.22127 6.22103C6.11644 6.32444 5.98115 6.37686 5.84515 6.37686Z"
      fill={color}
    />
    <path
      d="M12.625 16.875V17.7604C12.625 18.4404 12.0654 19 11.3854 19H9.61458C9.01958 19 8.375 18.5467 8.375 17.555V16.875H12.625Z"
      fill={color}
    />
    <path
      d="M13.6238 6.64651C12.4621 5.70443 10.9321 5.33609 9.43752 5.65484C7.56043 6.04443 6.03752 7.57443 5.64793 9.45151C5.25126 11.3782 5.97376 13.2907 7.51793 14.4594C7.93585 14.7711 8.22627 15.2528 8.32543 15.8123V15.8194C8.3396 15.8123 8.36085 15.8123 8.37502 15.8123H12.625C12.6392 15.8123 12.6463 15.8123 12.6604 15.8194V15.8123C12.7596 15.274 13.0783 14.7782 13.5671 14.3957C14.7642 13.4465 15.4584 12.0298 15.4584 10.4998C15.4584 8.99818 14.7925 7.59568 13.6238 6.64651ZM13.1563 10.854C12.8658 10.854 12.625 10.6132 12.625 10.3228C12.625 9.24609 11.7538 8.37484 10.6771 8.37484C10.3867 8.37484 10.1458 8.13401 10.1458 7.84359C10.1458 7.55318 10.3867 7.31234 10.6771 7.31234C12.3346 7.31234 13.6875 8.66526 13.6875 10.3228C13.6875 10.6132 13.4467 10.854 13.1563 10.854Z"
      fill={color}
    />
    <path
      d="M8.3252 15.8125H8.37478C8.36061 15.8125 8.33936 15.8125 8.3252 15.8196V15.8125Z"
      fill={color}
    />
    <path
      d="M12.6604 15.8125V15.8196C12.6463 15.8125 12.6392 15.8125 12.625 15.8125H12.6604Z"
      fill={color}
    />
  </SVGBox>
);

export { Bulb };
