import React, { type KeyboardEvent, type ReactNode, useCallback } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Switch.module.scss";
import { Toggle } from "./Toggle";
import { toggleEvent } from "./Toggle/Toggle";
import { type Themes } from "../commonProps";

export interface SwitchProps {
  readonly "aria-label"?: string;
  readonly label?: ReactNode;
  readonly className?: string;
  readonly toggleClassName?: string;
  readonly labelClassName?: string;
  readonly theme: Themes;
  readonly onToggle?: (status?: boolean) => void;
  readonly active?: boolean;
  readonly disabled?: boolean;
  readonly darkMode?: boolean;
}

const Switch = ({
  "aria-label": ariaLabel,
  className,
  toggleClassName,
  labelClassName,
  theme,
  onToggle,
  active,
  disabled,
  darkMode,
  label,
}: SwitchProps) => {
  const handleToggle = toggleEvent({ active, disabled, onToggle });
  const handleKey = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " " || e.key === "Spacebar") {
        handleToggle();
      }
    },
    [handleToggle]
  );

  return (
    <Flex
      center
      className={cx(styles.Switch, styles[theme], className, {
        [styles.disabled]: disabled,
        [styles.darkMode]: darkMode,
      })}
      onClick={handleToggle}
      onKeyDown={handleKey}
      tabIndex={0}
    >
      <Toggle
        aria-label={ariaLabel}
        className={cx(styles.toggle, toggleClassName)}
        active={active}
        disabled={disabled}
        darkMode={darkMode}
      />
      <Flex className={cx(styles.label, labelClassName)}>{label}</Flex>
    </Flex>
  );
};

export { Switch };
