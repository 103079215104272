import { MdCheck as Check } from "react-icons/md";
import { MdError as Error } from "react-icons/md";
import { MdWarning as Warning } from "react-icons/md";
import { MdInfo as Info } from "react-icons/md";
import React from "react";

import styles from "./DSSidePanel.module.scss";

export function SidePanelAlert({ type, message }: SidePanelAlertProps) {
  return (
    <div className={styles.alertContainer}>
      <div className={styles.alertTypeIcon}>{iconMap[type]}</div>
      <div className={styles.alertMessage}>{message}</div>
    </div>
  );
}

export type SidePanelAlertProps = {
  type: "success" | "error" | "warning" | "info";
  message: string;
};

export type SidePanelAlertType = React.ReactElement<typeof SidePanelAlert>;

const iconMap = {
  success: <Check fontSize={"1.5rem"} color="green" />,
  error: <Error fontSize={"1.5rem"} color="#FF3D33" />,
  warning: <Warning fontSize={"1.5rem"} color="#FE9D58" />,
  info: <Info fontSize={"1.5rem"} color="#0088C7" />,
};
