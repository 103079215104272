import { useTranslation } from "react-i18next";
import React from "react";
import cx from "classnames";

import { Avatar as DSAvatar } from "components/Avatar";

import styles from "./SkillPopover.module.scss";

export interface SkillPopoverValueProps {
  readonly name: string;
  readonly description?: string;
  readonly level?: number;
  readonly levelName?: string;
  readonly levelDescription?: string;
  readonly expectedLevel?: number;
  readonly expectedLevelName?: string;
  readonly expectedLevelDescription?: string;
  readonly comment?: string;
  readonly evaluatedBy?: string;
  readonly evaluatedAt?: string;
}

interface SkillPopoverProps {
  readonly skill: SkillPopoverValueProps;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly toReach?: boolean;
}

const SkillPopover = ({ skill, className, darkMode, toReach }: SkillPopoverProps) => {
  const { t } = useTranslation();

  const {
    name,
    description,
    level,
    levelName,
    levelDescription,
    expectedLevel,
    expectedLevelName,
    expectedLevelDescription,
    comment,
    evaluatedBy,
    evaluatedAt,
  } = skill;

  return (
    <div
      className={cx(
        styles.skillPopover,
        {
          [styles.darkMode]: darkMode,
        },
        className
      )}
    >
      <div className={styles.skillContainer}>
        <div className={styles.skillName}>{name}</div>
        {description && <div className={styles.skillDescription}>{description}</div>}

        {expectedLevel && (
          <div className={styles.content}>
            <span className={styles.levelLabel}>
              {t("collaborator.skills.expectedLevel", {
                defaultValue: "Niveau de maîtrise attendu",
              })}
              :
            </span>
            <div>
              <div className={styles.levelName}>
                {expectedLevel} - {expectedLevelName}
                {expectedLevelDescription && <span> - {expectedLevelDescription}</span>}
              </div>
            </div>
          </div>
        )}

        {toReach && levelName && (
          <div className={styles.content}>
            <span className={styles.levelLabel}>
              {t("collaborator.skills.expectedLevel", {
                defaultValue: "Niveau de maîtrise attendu",
              })}
              :
            </span>
            <div>
              <div className={styles.levelName}>
                {level} - {levelName}
                {levelDescription && <span> - {levelDescription}</span>}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.evaluationContainer}>
        {evaluatedAt && (
          <div className={styles.evaluationTitle}>
            {t("collaborator.skills.evaluatedBy", {
              defaultValue: "Évaluation de ",
            })}
            {evaluatedBy} - {evaluatedAt}
          </div>
        )}

        <div className={styles.content}>
          {!toReach && (
            <div>
              <span className={styles.levelLabel}>
                {t("collaborator.skills.evaluatedLevel", {
                  defaultValue: "Niveau de maîtrise évalué",
                })}
                :
              </span>
              <div className={styles.evaluatedLevel}>
                <div className={styles.levelName}>
                  {level} - {levelName}
                  {level !== expectedLevel && levelDescription && (
                    <span> - {levelDescription}</span>
                  )}
                </div>
              </div>
            </div>
          )}
          {comment && (
            <div className={styles.commentSection}>
              {evaluatedBy && (
                <DSAvatar
                  size="S"
                  initials={evaluatedBy
                    .split(" ")
                    .map((word) => word[0])
                    .join("")}
                />
              )}
              <span className={styles.comment}>{comment}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SkillPopover };
