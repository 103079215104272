import React from "react";

export class DashboardErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // @ts-ignore
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return <div>Cannot load component</div>;
    }

    return this.props.children;
  }
}
