import React, { type ComponentPropsWithoutRef } from "react";

import { colors } from "../../assets/colors";
import { SVGBox } from "./SVGBox";

interface Props extends ComponentPropsWithoutRef<typeof SVGBox> {
  readonly backgroundColor?: string;
}

const HalfStar = ({
  color = colors.bwBlack,
  backgroundColor = colors.blueyGrey,
  size = "1rem",
  ...props
}: Props) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <defs>
      <path
        d="M6.456.305l1.46 3.087c.08.154.215.266.39.294l3.256.491c.415.07.59.604.281.912L9.485 7.502a.557.557 0 00-.148.477l.563 3.395c.067.435-.362.772-.75.562l-2.909-1.6a.492.492 0 00-.482 0l-2.908 1.6c-.376.21-.818-.127-.751-.562l.563-3.395a.57.57 0 00-.148-.477L.157 5.09c-.309-.308-.134-.856.281-.912l3.257-.49a.56.56 0 00.389-.295L5.544.305a.493.493 0 01.912 0z"
        id="Half-Star_svg__a"
      />
    </defs>
    <g transform="translate(4 4)" fill="none" fillRule="evenodd">
      <mask id="Half-Star_svg__b" fill="#fff">
        <use xlinkHref="#Half-Star_svg__a" />
      </mask>
      <use fill={backgroundColor} fillRule="nonzero" xlinkHref="#Half-Star_svg__a" />
      <path fill={color} mask="url(#Half-Star_svg__b)" d="M-4-4H6v20H-4z" />
    </g>
  </SVGBox>
);

export { HalfStar };
