export class UCTest<Params, Dependencies, Result> {
  private params!: Params;
  private dependencies!: Dependencies;

  constructor(
    private usecase: (params: Params, dependencies: Dependencies) => Result,
    params?: Params,
    dependencies?: Dependencies
  ) {
    if (params) this.params = params;
    if (dependencies) this.dependencies = dependencies;
  }

  withParams(params: Params): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, params, this.dependencies);
  }

  withPartialParams(params: Partial<Params>): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, params as Params, this.dependencies);
  }

  withInvalidParams(
    params: Partial<Record<keyof Params, any>>
  ): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, params as Params, this.dependencies);
  }

  withDependencies(dependencies: Dependencies): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, this.params, dependencies);
  }

  withPartialDependencies(
    dependencies: Partial<Dependencies>
  ): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, this.params, dependencies as Dependencies);
  }

  withInvalidDependencies(
    dependencies: Partial<Record<keyof Dependencies, any>>
  ): UCTest<Params, Dependencies, Result> {
    return new UCTest(this.usecase, this.params, dependencies as Dependencies);
  }

  async execute(): Promise<Awaited<Result>> {
    // @ts-expect-error the point is to allow invalid params and dependencies
    return this.usecase(this.params ?? undefined, this.dependencies ?? undefined);
  }

  getParams(): Params {
    return this.params;
  }

  getDependencies(): Dependencies {
    return this.dependencies;
  }

  logParams() {
    console.log(this.params);
  }

  logDependencies() {
    console.log(this.dependencies);
  }

  reset() {
    this.params = {} as Params;
    this.dependencies = {} as Dependencies;
  }
}
