import React, { useMemo } from "react";
import cx from "classnames";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { type DashboardCard } from "./types";
import { type Themes } from "../../commonProps";

import { DashboardCardTable } from "components/Dashboard/DashboardCardTable";
import { DashboardCardGauge } from "components/Dashboard/DashboardCardGauge";
import { DashboardCardBar } from "components/Dashboard/DashboardCardBar";
import { DashboardCardWrapper } from "components/Dashboard/DashboardCardWrapper";

import { type IDashboardCardTable } from "../DashboardCardTable";
import { type IDashboardCardGauge } from "../DashboardCardGauge";
import { type IDashboardCardBar } from "../DashboardCardBar";

import styles from "./DashboardCardView.module.scss";

export function DashboardSortableItem({
  id,
  item,
  theme,
  darkMode,
}: {
  id: string;
  item: DashboardCard;
  theme: Themes;
  darkMode: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: true, // TODO: handle drag&drop
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { componentName, componentData, fallback, ...props } = item;

  const DashboardComponent = useMemo(() => {
    switch (componentName) {
      case "DashboardCardTable":
        return DashboardCardTable;
      case "DashboardCardGauge":
        return DashboardCardGauge;
      case "DashboardCardBar":
        return DashboardCardBar;
    }
  }, [componentName]);

  const fallbackComponent = fallback?.();

  return (
    <div
      ref={setNodeRef}
      className={cx(styles.cardWrapper, styles[item.size || "S"])}
      style={style}
      {...attributes}
      {...listeners}
    >
      {fallbackComponent ? (
        <DashboardCardWrapper
          {...props}
          title={componentData.title}
          theme={theme}
          darkMode={darkMode}
        >
          {fallbackComponent}
        </DashboardCardWrapper>
      ) : (
        <DashboardComponent
          {...props}
          theme={theme}
          darkMode={darkMode}
          componentName={componentName}
          componentData={
            componentData as IDashboardCardTable & IDashboardCardGauge & IDashboardCardBar
          }
        />
      )}
    </div>
  );
}
