import { DateTime } from "luxon";

export const FromParsableJS = (e: string | number | Date): DateTime => {
  const date = new Date(e);
  return DateTime.fromJSDate(date);
};

export const FromStringFormat = (stringDate: string, format: string): DateTime => {
  return DateTime.fromFormat(stringDate, format);
};

export const FromISO = (stringDate: string, zone?: "utc" | "Europe/Paris"): DateTime => {
  return DateTime.fromISO(stringDate, zone ? { zone, setZone: true } : undefined);
};

export const FromMiliTimestamp = (miliTimestamp: number): DateTime => {
  return DateTime.fromMillis(miliTimestamp);
};
