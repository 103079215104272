import { useEffect } from "react";

export function useResize(resizeCallback: () => void, htmlElement: HTMLElement | null | undefined) {
  useEffect(() => {
    if (!window.ResizeObserver || !htmlElement) return;
    const observer = new ResizeObserver(resizeCallback);
    observer.observe(htmlElement);
    window.addEventListener("resize", resizeCallback);
    htmlElement?.addEventListener("resize", resizeCallback);
    resizeCallback();
    return () => {
      observer.unobserve(htmlElement);
      window.removeEventListener("resize", resizeCallback);
      htmlElement?.removeEventListener("resize", resizeCallback);
    };
  }, [htmlElement]);
}
