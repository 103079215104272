import React from "react";
import cx from "classnames";

import styles from "./VerticalDivider.module.scss";

export type Spacing = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";

interface Props {
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly left: Spacing;
  readonly right: Spacing;
}

const VerticalDivider = ({ className, darkMode, left, right }: Props) => {
  return (
    <div
      data-testid="vertical-divider"
      className={cx(
        styles.VerticalDivider,
        darkMode && styles["on-dark"],
        styles[`ml-${left}`],
        styles[`mr-${right}`],
        className
      )}
    ></div>
  );
};

export { VerticalDivider };
