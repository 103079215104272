import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Burger = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M22.1665 10.5H5.83317C5.18884 10.5 4.6665 9.97769 4.6665 9.33335C4.6665 8.68902 5.18884 8.16669 5.83317 8.16669H22.1665C22.8108 8.16669 23.3332 8.68902 23.3332 9.33335C23.3332 9.97769 22.8108 10.5 22.1665 10.5Z"
      fill={color}
    />
    <path
      d="M22.1665 19.8334H5.83317C5.18884 19.8334 4.6665 19.311 4.6665 18.6667C4.6665 18.0224 5.18884 17.5 5.83317 17.5H22.1665C22.8108 17.5 23.3332 18.0224 23.3332 18.6667C23.3332 19.311 22.8108 19.8334 22.1665 19.8334Z"
      fill={color}
    />
  </SVGBox>
);

export { Burger };
