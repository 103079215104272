import React, { useMemo, useState, useCallback } from "react";
import cx from "classnames";

import { type Themes } from "../../../commonProps";

import { Label, type LabelProps } from "components/Form/Label/Label";
import {
  AssistiveArea,
  type AssistiveAreaProps,
} from "components/Form/AssistiveArea/AssistiveArea";
import { TextInput } from "components/Form/TextInput";

import { type TextInputComponent } from "./CustomTextInput";
import styles from "./FormGroupText.module.scss";

export type InputStatus = "onFocus" | "onBlur";

export type FormGroupTextProps = Omit<LabelProps, "onChange"> & {
  readonly assistiveText?: AssistiveAreaProps["text"];
  readonly multipleAssistiveTexts?: AssistiveAreaProps["multipleTexts"];
  readonly assistiveArea?: AssistiveAreaProps["area"];
  readonly children: TextInputComponent;
  readonly onChange?: (value: string) => void;
  readonly darkMode?: boolean;
  readonly theme: Themes;
};

const FormGroupText = ({
  label,
  required,
  assistiveText,
  multipleAssistiveTexts,
  assistiveArea,
  onChange,
  darkMode = false,
  theme,
  className,
  children,
  ...props
}: FormGroupTextProps) => {
  const [inputStatus, setInputStatus] = useState<InputStatus>("onFocus");

  const onInputFocus = useCallback(() => setInputStatus("onFocus"), [setInputStatus]);

  const onInputBlur = useCallback(() => setInputStatus("onBlur"), [setInputStatus]);

  const mode = useMemo(() => {
    if (
      required &&
      inputStatus === "onBlur" &&
      inputIsEmpty(children.props.defaultValue ?? children.props.value ?? undefined) &&
      assistiveHasText(multipleAssistiveTexts, assistiveText)
    )
      return "error";
    return undefined;
  }, [required, inputStatus, children.props, assistiveText, multipleAssistiveTexts]);

  return (
    <div
      className={cx(styles.FormGroupText, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      aria-label={`forminput-${label}`}
      role="presentation"
      {...props}
    >
      <Label label={label} required={required} theme={theme} darkMode={darkMode} />
      <TextInput
        {...children.props}
        name={children.props.name}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={onChange}
        error={mode === "error"}
        theme={theme}
        darkMode={darkMode}
      />
      <AssistiveArea
        text={assistiveText}
        multipleTexts={multipleAssistiveTexts}
        area={assistiveArea}
        mode={mode}
        theme={theme}
        darkMode={darkMode}
      />
    </div>
  );
};

export { FormGroupText };

function inputIsEmpty(inputValue: string | undefined) {
  return inputValue === undefined || inputValue === "";
}

function assistiveHasText(
  multiple: AssistiveAreaProps["multipleTexts"],
  single: AssistiveAreaProps["text"]
) {
  return multiple !== undefined || single !== undefined;
}
