import React from "react";

import { SVGBox } from "assets/Icon/SVGBox";
import { Button as DSButton } from "./../../../Button";

import styles from "./ErrorOverlay.module.scss";

interface ErrorOverlayProps {
  text?: string | string[];
  button?: {
    label: string;
    callback: () => void;
  };
}

export const ErrorOverlay = ({ text, button }: ErrorOverlayProps) => {
  const textArr = Array.isArray(text) ? text : [text];

  return (
    <div className={styles.errorOverlay}>
      <SVGBox height="auto" width="14rem">
        <mask id="mask0_2297_8314" maskUnits="userSpaceOnUse" x="0" y="0" width="213" height="138">
          <rect width="213" height="138" fill="url(#paint0_linear_2297_8314)" />
        </mask>
        <g mask="url(#mask0_2297_8314)">
          <g filter="url(#filter0_d_2297_8314)">
            <rect x="-0.13414" y="48.1627" width="102.5" height="68" rx="8" fill="white" />
            <path
              d="M23.8659 58.8293C16.5059 58.8293 10.5325 64.8027 10.5325 72.1627C10.5325 79.5227 16.5059 85.496 23.8659 85.496C31.2259 85.496 37.1992 79.5227 37.1992 72.1627C37.1992 64.8027 31.2259 58.8293 23.8659 58.8293ZM23.8659 62.8293C26.0792 62.8293 27.8659 64.616 27.8659 66.8293C27.8659 69.0427 26.0792 70.8293 23.8659 70.8293C21.6525 70.8293 19.8659 69.0427 19.8659 66.8293C19.8659 64.616 21.6525 62.8293 23.8659 62.8293ZM23.8659 81.7627C20.5325 81.7627 17.5859 80.056 15.8659 77.4693C15.9059 74.816 21.1992 73.3627 23.8659 73.3627C26.5192 73.3627 31.8259 74.816 31.8659 77.4693C30.1459 80.056 27.1992 81.7627 23.8659 81.7627Z"
              fill="#E5E8EB"
            />
            <rect x="46.3658" y="62.1627" width="48" height="4" rx="2" fill="#C9CED4" />
            <rect x="46.3658" y="70.1627" width="48" height="4" rx="2" fill="#C9CED4" />
            <rect x="46.3658" y="78.1627" width="48" height="4" rx="2" fill="#C9CED4" />
            <path
              d="M86.3659 93.4961C82.6859 93.4961 79.6992 96.4828 79.6992 100.163C79.6992 103.843 82.6859 106.829 86.3659 106.829C90.0459 106.829 93.0325 103.843 93.0325 100.163C93.0325 96.4828 90.0459 93.4961 86.3659 93.4961ZM85.0325 103.496L81.6992 100.163L82.6392 99.2228L85.0325 101.609L90.0925 96.5494L91.0325 97.4961L85.0325 103.496Z"
              fill="#7EC2E2"
            />
          </g>
          <g filter="url(#filter1_d_2297_8314)">
            <g clipPath="url(#clip0_2297_8314)">
              <rect
                x="67.0976"
                y="18.7588"
                width="102.5"
                height="68"
                rx="8"
                transform="rotate(-5 67.0976 18.7588)"
                fill="white"
              />
              <path
                d="M91.9359 27.2932C84.604 27.9347 79.174 34.4059 79.8154 41.7379C80.4569 49.0699 86.9281 54.4999 94.2601 53.8584C101.592 53.217 107.022 46.7458 106.381 39.4138C105.739 32.0818 99.2679 26.6518 91.9359 27.2932ZM92.2846 31.278C94.4895 31.0851 96.4251 32.7093 96.618 34.9142C96.8109 37.1191 95.1867 39.0547 92.9818 39.2476C90.7769 39.4405 88.8413 37.8163 88.6484 35.6114C88.4555 33.4065 90.0797 31.4709 92.2846 31.278ZM93.9347 50.1393C90.6141 50.4298 87.5299 48.9865 85.591 46.5596C85.3996 43.9128 90.5461 42.0037 93.2026 41.7713C95.8458 41.54 101.259 42.5253 101.53 45.1651C100.042 47.8918 97.2554 49.8488 93.9347 50.1393Z"
                fill="#E5E8EB"
              />
              <rect
                x="114.641"
                y="28.6527"
                width="48"
                height="4"
                rx="2"
                transform="rotate(-5 114.641 28.6527)"
                fill="#C9CED4"
              />
              <rect
                x="115.338"
                y="36.6223"
                width="48"
                height="4"
                rx="2"
                transform="rotate(-5 115.338 36.6223)"
                fill="#C9CED4"
              />
              <rect
                x="116.035"
                y="44.5918"
                width="48"
                height="4"
                rx="2"
                transform="rotate(-5 116.035 44.5918)"
                fill="#C9CED4"
              />
              <path
                d="M157.219 56.3806C153.553 56.7014 150.838 59.937 151.159 63.603C151.48 67.269 154.716 69.984 158.382 69.6632C162.048 69.3425 164.763 66.1069 164.442 62.4409C164.121 58.7749 160.885 56.0599 157.219 56.3806ZM158.755 66.2845L157.427 66.4007L157.311 65.0724L158.639 64.9562L158.755 66.2845ZM158.523 63.628L157.194 63.7442L156.846 59.7594L158.174 59.6432L158.523 63.628Z"
                fill="#7EC2E2"
              />
            </g>
          </g>
          <g filter="url(#filter2_d_2297_8314)">
            <g clipPath="url(#clip1_2297_8314)">
              <rect
                x="105.098"
                y="63.8253"
                width="102.5"
                height="68"
                rx="8"
                transform="rotate(5 105.098 63.8253)"
                fill="white"
              />
              <path
                d="M128.077 76.5432C120.745 75.9017 114.273 81.3317 113.632 88.6637C112.99 95.9957 118.42 102.467 125.752 103.108C133.084 103.75 139.556 98.3198 140.197 90.9879C140.839 83.6559 135.409 77.1846 128.077 76.5432ZM127.728 80.528C129.933 80.7209 131.557 82.6565 131.364 84.8614C131.171 87.0663 129.236 88.6904 127.031 88.4975C124.826 88.3046 123.202 86.369 123.395 84.1641C123.587 81.9592 125.523 80.3351 127.728 80.528ZM126.078 99.3892C122.757 99.0987 119.97 97.1417 118.482 94.415C118.754 91.7753 124.153 90.7888 126.81 91.0212C129.453 91.2525 134.613 93.1628 134.422 95.8095C132.483 98.2364 129.398 99.6798 126.078 99.3892Z"
                fill="#E5E8EB"
              />
              <rect
                x="150.2"
                y="81.8247"
                width="48"
                height="4"
                rx="2"
                transform="rotate(5 150.2 81.8247)"
                fill="#C9CED4"
              />
              <rect
                x="149.503"
                y="89.7943"
                width="48"
                height="4"
                rx="2"
                transform="rotate(5 149.503 89.7943)"
                fill="#C9CED4"
              />
              <rect
                x="148.806"
                y="97.7639"
                width="48"
                height="4"
                rx="2"
                transform="rotate(5 148.806 97.7639)"
                fill="#C9CED4"
              />
              <path
                d="M187.317 116.525C183.651 116.204 180.416 118.919 180.095 122.585C179.774 126.251 182.489 129.487 186.155 129.808C189.821 130.128 193.057 127.413 193.378 123.747C193.698 120.081 190.983 116.846 187.317 116.525ZM185.118 126.371L182.087 122.76L183.106 121.905L185.282 124.491L190.764 119.892L191.618 120.917L185.118 126.371Z"
                fill="#7EC2E2"
              />
            </g>
          </g>
        </g>
        <path
          d="M106.5 55.6666C99.14 55.6666 93.1667 61.64 93.1667 69C93.1667 76.36 99.14 82.3333 106.5 82.3333C113.86 82.3333 119.833 76.36 119.833 69C119.833 61.64 113.86 55.6666 106.5 55.6666ZM107.833 75.6666H105.167V73H107.833V75.6666ZM107.833 70.3333H105.167V62.3333H107.833V70.3333Z"
          fill="#FF3D33"
        />
        <defs>
          <filter
            id="filter0_d_2297_8314"
            x="-8.13414"
            y="42.1627"
            width="118.5"
            height="84"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8314" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8314"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_2297_8314"
            x="59.0976"
            y="3.82532"
            width="124.037"
            height="92.6747"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8314" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8314"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_2297_8314"
            x="91.171"
            y="57.8253"
            width="124.037"
            height="92.6747"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2297_8314" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2297_8314"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2297_8314"
            x1="106.5"
            y1="0"
            x2="106.5"
            y2="138"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D9D9" />
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_2297_8314">
            <rect
              x="67.0976"
              y="18.7588"
              width="102.5"
              height="68"
              rx="8"
              transform="rotate(-5 67.0976 18.7588)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_2297_8314">
            <rect
              x="105.098"
              y="63.8253"
              width="102.5"
              height="68"
              rx="8"
              transform="rotate(5 105.098 63.8253)"
              fill="white"
            />
          </clipPath>
        </defs>
      </SVGBox>

      {textArr &&
        textArr.map((text, index) => (
          <p key={index} className={styles.text}>
            {text}
          </p>
        ))}

      {button?.callback && button?.label && (
        <DSButton emphasis="Low" label={button.label} onClick={button.callback} />
      )}
    </div>
  );
};
