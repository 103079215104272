import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgTime = ({
  color = colors.bwBlack,
  size = "1rem",
  strokeWidth = "0.625",
  ...props
}: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 18.3125C15.0386 18.3125 18.3125 15.0386 18.3125 11C18.3125 6.96142 15.0386 3.6875 11 3.6875C6.96142 3.6875 3.6875 6.96142 3.6875 11C3.6875 15.0386 6.96142 18.3125 11 18.3125Z"
      stroke={color}
      strokeWidth={strokeWidth}
    />
    <path
      d="M11 6.16199V11.0001L8.68579 13.0638"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGBox>
);

export { SvgTime };
