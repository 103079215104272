import React, { type ReactNode, useMemo, useRef } from "react";
import ReactTooltip from "react-tooltip";
import cx from "classnames";

import styles from "./Tooltip.module.scss";

import { v4 as uuid } from "uuid";

interface IProps {
  className?: string;
  tooltipClassName?: string;
  content: TemplateStringsArray | string;
  position?: "top" | "bottom" | "left" | "right";
  children: ReactNode;
}

const Tooltip = ({
  children,
  className,
  tooltipClassName,
  content,
  position = "top",
}: IProps): JSX.Element => {
  const id = useMemo(() => uuid(), []);
  const tooltipRef = useRef<HTMLParagraphElement>(null);

  return (
    <div className={cx(styles.Tooltip, className)}>
      <p ref={tooltipRef} data-tip={content} />
      <button
        type="button"
        onMouseEnter={() => ReactTooltip.show(tooltipRef.current as Element)}
        onMouseLeave={() => ReactTooltip.hide(tooltipRef.current as Element)}
        data-tip={content}
        data-for={id}
      >
        {children}
      </button>

      <ReactTooltip
        id={id}
        className={cx(styles.dataTip, tooltipClassName)}
        place={position}
        effect="solid"
        multiline
        html
      />
    </div>
  );
};

export { Tooltip };
