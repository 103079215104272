import React, { useState } from "react";
import cx from "classnames";

import * as Icon from "../Icon";
import { colors } from "../../assets/colors";

import styles from "./UserImportError.module.scss";

interface IProps {
  readonly message: string;
  readonly className?: string;
  readonly hideMessage?: () => void;
}

const UserImportError = ({ message, hideMessage, className }: IProps): JSX.Element | null => {
  const [iconColor, setIconColor] = useState<string>(colors.errorL);

  return (
    <div className={cx(styles.UserImportError, className)}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Icon.SignError className={styles.icon} width={28} />
        </div>
        <div className={styles.info}>{message}</div>
      </div>
      {hideMessage && (
        <button
          className={styles.hideBtn}
          type="reset"
          onClick={hideMessage}
          onMouseEnter={() => setIconColor(colors.mainColorL)}
          onMouseLeave={() => setIconColor(colors.errorL)}
        >
          <Icon.Cross color={iconColor} className={styles.hideIcon} width={20} strokeWidth={20} />
        </button>
      )}
    </div>
  );
};

export { UserImportError };
