import React, { type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import isEmpty from "lodash/isEmpty";

import { useGridApiContext, type GridSlotsComponentsProps } from "@mui/x-data-grid-pro";

import { Flex } from "components/Flex";
import { Dropdown } from "components/Dropdown";

import { type TranslationParams } from "../Footer/Footer";

import styles from "./Toolbar.module.scss";

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    buttons?: ReactNode[] | ReactNode;
    actionItems?: ReactNode[] | ReactNode;
    translationPrefix?: string;
    entityName?: string;
  }
}

export interface ToolbarButtonProps {
  buttons?: ReactNode[] | ReactNode;
  actionItems?: ReactNode[] | ReactNode;
  translationPrefix?: string;
  entityName?: string;
  disableToolbar?: boolean;
}

const Toolbar = ({
  entityName = "objet",
  buttons,
  actionItems,
  disableToolbar,
  translationPrefix = "datagrid",
}: NonNullable<GridSlotsComponentsProps["toolbar"]>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const renderTranslation = ({ key, options }: TranslationParams) => {
    return t(`${translationPrefix}.${key}`, options);
  };
  const rowsCount = apiRef.current.state.rows.totalRowCount;

  return !disableToolbar ? (
    <Flex className={styles.toolbar}>
      {renderTranslation({
        key: "toolbar.label",
        options: { defaultValue: entityName, count: rowsCount },
      })}
      <Flex className={styles.right}>
        {buttons}
        {!isEmpty(actionItems) && (
          <Dropdown buttonSize="S" className={styles.dropdown}>
            {actionItems}
          </Dropdown>
        )}
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};

export { Toolbar };
