import React, { type MouseEventHandler, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Themes } from "components/commonProps";

import styles from "./TableOfContentSubItem.module.scss";
import { debounce } from "lodash";

export interface ISubItemProps {
  readonly id: string;
  readonly label: string;
  readonly to?: string;
  readonly active?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly onChangeItem?: (itemId: string) => void;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme?: Themes;
  scrollFromLinkItemClick?: React.MutableRefObject<boolean>;
}

export interface ISubItemPropsWithTheme extends Omit<ISubItemProps, "theme"> {
  readonly theme: Themes;
}

const TableOfContentSubItem = ({
  id,
  label,
  to,
  active,
  disabled,
  className,
  onClick,
  onChangeItem,
  darkMode,
  theme = Themes.ESPACE_RH,
  scrollFromLinkItemClick,
}: ISubItemProps) => {
  const debounceSetScrollFromLinkItemClick = useMemo(
    () =>
      debounce(() => {
        if (scrollFromLinkItemClick) {
          scrollFromLinkItemClick.current = false;
        }
      }, 1000),
    [scrollFromLinkItemClick]
  );

  useEffect(() => {
    return () => {
      debounceSetScrollFromLinkItemClick.cancel();
    };
  }, [debounceSetScrollFromLinkItemClick]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry.isIntersecting && !scrollFromLinkItemClick?.current) onChangeItem?.(id);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [],
      }
    );
    const element = document.querySelector(`[id="${id}"]`);
    if (element) {
      observer.observe(element);
    }
    return () => {
      const element = document.querySelector(`[id="${id}"]`);
      if (element && observer) {
        observer.unobserve(element);
      }
    };
  }, [id, scrollFromLinkItemClick]);

  return (
    <div
      className={cx(styles.TableOfContentSubItem, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
    >
      <Link
        to={to ?? ""}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (disabled) return;
          onClick?.(e);
          const element = document.querySelector(`[id="${id}"]`);
          if (element) {
            if (scrollFromLinkItemClick) {
              scrollFromLinkItemClick.current = true;
            }
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            debounceSetScrollFromLinkItemClick();
          }
        }}
        className={cx(styles.button, { [styles.active]: active, [styles.disabled]: disabled })}
      >
        {label}
      </Link>
    </div>
  );
};

export { TableOfContentSubItem };
