import React, { type HTMLAttributes, type ReactNode } from "react";
import cx from "classnames";
import { type Themes } from "components/commonProps";
import { Flex } from "components/Flex/Flex";

import styles from "./List.module.scss";

export interface ListProps extends HTMLAttributes<HTMLDivElement> {
  readonly title?: string;
  readonly description?: string;
  readonly mode?: "default" | "muted";
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

export function List({
  title,
  description,
  mode = "default",
  children,
  className,
  darkMode,
  theme,
}: ListProps): JSX.Element {
  return (
    <Flex
      column
      className={cx(styles.List, className, styles[theme], styles[mode], {
        [styles.darkMode]: darkMode,
      })}
    >
      {(title || description) && (
        <Flex column className={styles.header}>
          {title && <h6>{title}</h6>}
          {description && <p>{description}</p>}
        </Flex>
      )}
      {children}
    </Flex>
  );
}

export interface ListItemProps {
  readonly className?: string;
  readonly children: ReactNode | ReactNode[];
}

export function ListItem({ children, className }: ListItemProps) {
  return <Flex className={cx(styles.ListItem, className)}>{children}</Flex>;
}
