import React from "react";
import { useMedia } from "react-use";

import { Gauge, type GaugeProps } from "components/Gauge";

import { type ICard } from "../DashboardCardView/types";
import { DashboardCardWrapper, type DashboardCardWrapperProps } from "../DashboardCardWrapper";

import styles from "./DashboardCardGauge.module.scss";

const MIN_WIDTH = 285;
const MAX_WIDTH = 600;

export interface IDashboardCardGauge
  extends Pick<
    GaugeProps,
    | "activeColor"
    | "inactiveColor"
    | "activeLabel"
    | "inactiveLabel"
    | "currentValue"
    | "max"
    | "type"
  > {
  title: string;
  tooltip?: string;
}

export interface DashboardCardGaugeProps
  extends ICard<IDashboardCardGauge>,
    DashboardCardWrapperProps {}

const DashboardCardGauge = ({
  id,
  size,
  order,
  componentName,
  componentData,
  ...props
}: DashboardCardGaugeProps) => {
  const isWide = useMedia("(min-width: 768px)");
  const isSizeS = size === "S" || !isWide;

  const defaultLegends: GaugeProps["legends"] = {
    anchor: isSizeS ? "bottom" : "right",
    itemHeight: isSizeS ? 14 : 22,
    itemsSpacing: isSizeS ? 10 : 8,
    direction: isSizeS ? "row" : "column",
  };

  const defaultMargin = isSizeS
    ? {
        top: 0,
        bottom: 64,
        right: 0,
        left: 0,
      }
    : {
        top: 0,
        bottom: 8,
        right: 120,
        left: 0,
      };

  return (
    <DashboardCardWrapper
      {...props}
      id={id}
      size={size}
      order={order}
      title={componentData.title}
      tooltip={componentData.tooltip}
      className={styles.DashboardCardGauge}
      contentClassName={styles.cardContent}
    >
      <Gauge
        {...componentData}
        width={isSizeS ? MIN_WIDTH : MAX_WIDTH}
        height={200}
        legends={defaultLegends}
        margin={defaultMargin}
        type={componentData.type}
      />
    </DashboardCardWrapper>
  );
};

export { DashboardCardGauge };
