import React from "react";

import { CalendarEmpty } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const LegacyCampaign = () => (
  <RectangleTag
    label="Campagne d'entretiens"
    icon={<CalendarEmpty />}
    backgroundColor="#f5f5f5"
    textColor="#92929d"
  />
);

export { LegacyCampaign };
