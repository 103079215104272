import React, { useState } from "react";

import cx from "classnames";

import { Themes } from "../../commonProps";

import styles from "./Radio.module.scss";

import { useRadioGroupContext } from "./RadioGroup";
import { Tooltip } from "components/Tooltip";
import { type TooltipProps } from "components/Tooltip/Tooltip";
export interface RadioProps {
  name?: string;
  label?: string;
  helperText?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  theme?: Themes;
  tooltip?: Omit<TooltipProps, "theme" | "children">;
}

const Radio = ({
  label,
  helperText,
  checked,
  disabled,
  onChange,
  theme = Themes.ESPACE_RH,
  tooltip,
}: RadioProps) => {
  const name = useRadioGroupContext();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <label
      className={cx(styles.Radio, styles[theme], {
        [styles.disabled]: disabled,
        [styles.withLabel]: !!label,
      })}
      onMouseOver={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      <input type="radio" name={name} disabled={disabled} checked={checked} onChange={onChange} />
      {label && (
        <div className={styles.textContainer}>
          <div>
            <Tooltip
              disabled={!tooltip}
              direction={"top"}
              displayArrow={false}
              opened={isTooltipOpen}
              {...tooltip}
            >
              <span className={styles.label}>{label}</span>
            </Tooltip>
          </div>
          {helperText && <span className={styles.helperText}>{helperText}</span>}
        </div>
      )}
    </label>
  );
};

export { Radio };
