import React, { type ChangeEvent, useCallback, useState } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { type Themes } from "components/commonProps";

import styles from "./SimpleTextArea.module.scss";

export interface SimpleTextAreaProps {
  readonly refreshID?: string; // This is used to refresh the textarea when the ID changes.
  // usually you would use a key, but in this case it is not applicable since the textarea is a child of the component
  // not the component itself (and we cannot access key from props...)
  readonly name?: HTMLTextAreaElement["name"];
  readonly id?: HTMLTextAreaElement["id"];
  readonly value?: HTMLTextAreaElement["value"];
  readonly placeholder?: HTMLTextAreaElement["placeholder"];
  readonly error?: boolean;
  readonly onChange?: (value: string, e: ChangeEvent<HTMLTextAreaElement>) => void;
  readonly className?: string;
  readonly autoFocus?: boolean;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly rows?: HTMLTextAreaElement["rows"];
  readonly defaultValue?: HTMLTextAreaElement["defaultValue"];
  readonly disabled?: boolean;
}

const SimpleTextArea = ({
  id,
  name,
  placeholder,
  value,
  error,
  autoFocus,
  className,
  onChange,
  theme,
  darkMode,
  rows,
  defaultValue,
  disabled = false,
  refreshID,
  ...props
}: SimpleTextAreaProps) => {
  const [emptyValue, setEmptyValue] = useState(!value && !defaultValue);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e.currentTarget ? e.currentTarget.value : "", e);
      setEmptyValue(isEmpty(e.currentTarget.value));
    },
    [onChange]
  );

  return (
    <textarea
      id={id}
      aria-label={`inputtext-${name}`}
      role="presentation"
      key={refreshID}
      rows={rows}
      placeholder={placeholder}
      onChange={handleChange}
      name={name}
      value={value}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      disabled={disabled}
      {...props}
      className={cx(styles.SimpleTextArea, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.empty]: emptyValue,
        [styles.error]: !!error,
      })}
    />
  );
};

export { SimpleTextArea };
