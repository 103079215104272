import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Mouse = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M11.6234 9.62524L10.9162 10.3324C10.7073 10.5414 10.7099 10.8775 10.9162 11.0838C11.1234 11.291 11.4604 11.291 11.6676 11.0838L12.3748 10.3766C12.582 10.1695 12.582 9.83239 12.3748 9.62524C12.1676 9.41809 11.8306 9.41809 11.6234 9.62524Z"
      fill={color}
    />
    <path
      d="M18.9814 3.33578C18.908 3.0875 18.6473 2.94563 18.3989 3.01905L16.1197 3.69257C15.1753 3.96357 14.6223 4.92194 14.8607 5.8754C14.9349 6.17213 14.8466 6.49082 14.6303 6.7071C14.5078 6.82961 14.3322 7.00523 14.2003 7.13715C12.4517 5.71682 9.86862 5.81124 8.22848 7.45141L4.30765 11.3722C2.56509 13.1148 2.56509 15.9502 4.30765 17.6928C6.05015 19.4353 8.88556 19.4354 10.6282 17.6928L14.549 13.7719C16.1942 12.1268 16.2789 9.54323 14.8633 7.80017L15.2943 7.36912C15.7414 6.92131 15.9238 6.26195 15.7703 5.648C15.6591 5.20326 15.9028 4.73273 16.3834 4.59234L18.6646 3.91825C18.9129 3.84487 19.0547 3.58409 18.9814 3.33578ZM13.7907 8.87274L13.3153 9.34808C13.5873 9.8974 13.4952 10.5826 13.0384 11.0394L12.3312 11.7466C11.8808 12.197 11.1982 12.2999 10.6399 12.0235L10.2096 12.4538C10.0265 12.6369 9.72969 12.6369 9.54661 12.4538C9.36352 12.2707 9.36352 11.9739 9.54661 11.7908L9.97688 11.3605C9.70494 10.8112 9.79698 10.126 10.2538 9.66921L10.961 8.96203C11.4178 8.50519 12.103 8.41318 12.6523 8.68509L13.1277 8.20975C13.3107 8.02667 13.6076 8.02667 13.7907 8.20975C13.9737 8.39284 13.9737 8.68965 13.7907 8.87274Z"
      fill={color}
    />
  </SVGBox>
);

export { Mouse };
