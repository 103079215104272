import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Dashboard = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9122 5.95592C16.9122 5.43253 16.4837 5 15.9551 5H14.6208C14.0884 5 13.6637 5.42798 13.6637 5.95592V17.0441C13.6637 17.5675 14.0922 18 14.6208 18H15.9551C16.4875 18 16.9122 17.572 16.9122 17.0441V5.95592ZM11.6233 7.59998C12.1518 7.59998 12.5803 8.02055 12.5803 8.55974V17.0402C12.5803 17.5703 12.1557 18 11.6233 18H10.2889C9.76034 18 9.33184 17.5794 9.33184 17.0402V8.55974C9.33184 8.02968 9.75652 7.59998 10.2889 7.59998H11.6233ZM7.29142 10.2C7.82 10.2 8.2485 10.6194 8.2485 11.1564V17.0436C8.2485 17.5718 7.82382 18 7.29142 18H5.95709C5.4285 18 5 17.5807 5 17.0436V11.1564C5 10.6282 5.42469 10.2 5.95709 10.2H7.29142Z"
      fill={color}
    />
  </SVGBox>
);

export { Dashboard };
