import { type GridColumnVisibilityModel, type GridInitialState } from "@mui/x-data-grid-pro";
import { type GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { type MutableRefObject, useEffect, useMemo, useState } from "react";

function setStateInLocalStorage(persistenceId: string, state?: GridInitialState) {
  if (state) {
    localStorage.setItem(`grid-${persistenceId}`, JSON.stringify(state));
  }
}

function getStateFromLocalStorage(persistenceId: string): GridInitialState | undefined {
  const rawState = localStorage.getItem(`grid-${persistenceId}`);

  if (!rawState) return undefined;

  try {
    return JSON.parse(rawState);
  } catch (e) {
    console.warn(`Failed to parse: ${rawState.substring(0, 50)}`);
    return undefined;
  }
}

type UsePersistColumnSettings = {
  apiRef: MutableRefObject<GridApiPro>;
  persistenceId: string;
  defaultColumnVisibilityModel?: GridColumnVisibilityModel;
};

export function usePersistColumnSettings({
  persistenceId,
  apiRef,
  defaultColumnVisibilityModel,
}: UsePersistColumnSettings) {
  useEffect(() => {
    if (apiRef.current?.subscribeEvent) {
      const unsubscribe = apiRef.current.subscribeEvent("columnsChange", () => {
        const state = apiRef.current.exportState();
        setStateInLocalStorage(persistenceId, state);
      });

      return () => {
        unsubscribe();
      };
    }

    return () => {};
  }, [apiRef.current]);

  const gridInitialState = useMemo(() => {
    const state = getStateFromLocalStorage(persistenceId);
    return state;
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    gridInitialState?.columns?.columnVisibilityModel ?? defaultColumnVisibilityModel ?? {}
  );

  /**
   * Synchro the columnVisibilityModel ourselves as the onColumnVisibilityModelChange prop on the data-grid
   * is "lagging". It behave like it's being one step backward of its true columnVisibilityModel.
   */
  useEffect(() => {
    if (apiRef.current?.exportState && columnVisibilityModel) {
      const state = apiRef.current.exportState();
      setStateInLocalStorage(persistenceId, state);
    }
  }, [columnVisibilityModel, apiRef.current]);

  return { columnVisibilityModel, setColumnVisibilityModel, gridInitialState };
}
