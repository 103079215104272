import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Tick = ({ color = colors.secondaryGreen, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.80221 6.64801C3.54056 6.38896 3.11845 6.39108 2.85941 6.65273C2.60037 6.91438 2.60248 7.33648 2.86413 7.59553L6.7477 11.4404C7.02187 11.7118 7.46852 11.6946 7.72093 11.4028L13.1707 5.10282C13.4116 4.82437 13.3811 4.40336 13.1027 4.16247C12.8242 3.92159 12.4032 3.95206 12.1623 4.23051L7.17904 9.99117L3.80221 6.64801Z"
      fill={color}
    />
  </SVGBox>
);

export { Tick };
