import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgTraining = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M4.876 9.285l3.216 1.656c.66.34 1.401.52 2.14.52a4.7 4.7 0 002.162-.532l.22-.114v.813a1.005 1.005 0 00-.484.863v1.504c0 .277.433.501.967.501s.967-.224.967-.501V12.49c0-.37-.195-.69-.483-.863v-1.317l1.934-1.009v4.192c0 1.384-2.381 2.506-5.32 2.506-2.937 0-5.319-1.122-5.319-2.506V9.285zM10.232 4c.667 0 1.333.16 1.945.478l4.984 2.6a.454.454 0 01.239.402c0 .17-.093.326-.24.403L13.58 9.75l-2.1-1.813a.765.765 0 00.166-.457c0-.553-.65-1.002-1.451-1.002s-1.45.449-1.45 1.002c0 .553.649 1.002 1.45 1.002.126 0 .247-.014.364-.035l2.054 1.774v.033l-.437.227a4.2 4.2 0 01-3.87.01L3.241 7.883A.452.452 0 013 7.479c0-.17.093-.327.241-.403l5.065-2.607A4.202 4.202 0 0110.232 4z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { SvgTraining };
