import z from "zod";

import { FilterOperator, FilterType, type FilterConfigurationMap } from "./types";

export type {
  FilterConfiguration,
  FilterTypes,
  FilterOperators,
  FilterValue,
  FilterValues,
  FilterValueType,
  FilterConfigurationMap,
} from "./types";

export { InMemoryDatasetFilter } from "./InMemoryDatasetFilter";
export { InMemoryDataset } from "./InMemoryDataset";
export { IFilterManager } from "./IFilterManager";
export { Neo4JFilterBuilder } from "./Neo4JFilterBuilder";
export { FilterType, FilterOperator, FilterTypeOperator, FilterVisibility } from "./types";

export const FilterValueSchema = z.object({
  type: z.nativeEnum(FilterType),
  operator: z.nativeEnum(FilterOperator),
  value: z.string().or(z.string().array()).or(z.number().array()),
});

export const FilterValueMapSchema = <T extends FilterConfigurationMap>(configuration: T) =>
  z.object(
    Object.keys(configuration).reduce(
      (acc, key) => ({ ...acc, [key]: FilterValueSchema.optional() }),
      {}
    )
  ) as z.ZodObject<{ [key in keyof T]: typeof FilterValueSchema }>;

export const SortingValueSchema = z.object({
  property: z.string(),
  direction: z.enum(["ASC", "DESC"]),
});

export const PaginationValueSchema = z.object({
  page: z.number().min(0),
  pageSize: z.number(),
});

export type SortingProperties = z.infer<typeof SortingValueSchema>;
export type PaginationProperties = z.infer<typeof PaginationValueSchema>;