import { FormEvent, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

import Colors from "components/Colors";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import { user, key, login } from "components/StrokeIcons";

import User from "helpers/User";

import styles from "./ComponentsStyles.module.scss";
import { AuthRoutes } from "api/types";
import request from "helpers/request";

const CreatePassword = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { token, data: emailAsBase64 } = useParams<{ token: any; data: any }>();

  const [success, toggleSuccess] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const [password, setPassword] = useState<string>();
  const [passwordVerification, setPasswordVerification] = useState<string>();

  const onCreate = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      toggleLoading(true);

      const resetData = {
        email: emailAsBase64,
        password,
        passwordVerification,
      };

      try {
        await request<AuthRoutes["POST"]["/v1/reset/{token}"]["response"]>({
          target: "AUTH",
          data: resetData,
          method: "POST",
          url: `/reset/${token}`,
        });

        toggleSuccess(true);

        const loginData = {
          email: typeof atob !== "undefined" ? atob(resetData.email) : "",
          password: resetData.password,
        };

        const { data } = await request<AuthRoutes["POST"]["/v1/signin"]["response"]>({
          target: "AUTH",
          data: loginData,
          method: "POST",
          url: `/signin`,
        });
        window.localStorage.setItem("X-Auth-Token", data.token);

        const { REACT_APP_PUBLIC_APP_URL } = process.env;
        if (User.isOF(data.credentials)) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
        } else if (User.isRF(data.credentials)) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
        } else {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}`;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 429) {
            addToast(
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
              {
                appearance: "error",
              }
            );
          } else if (error.response?.data?.message === "No such user with params") {
            addToast("Identifiants invalides.", {
              appearance: "error",
            });
          } else if (error.response?.data?.message === "token-expired") {
            addToast("Cette demande a expirée. Faites une nouvelle demande.", {
              appearance: "error",
            });

            navigate("/authentification/reinitialisation", { replace: true });
          }
        } else {
          addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
            appearance: "error",
          });
        }

        toggleLoading(false);
      }
    },
    [
      toggleLoading,
      navigate,
      token,
      emailAsBase64,
      password,
      passwordVerification,
      toggleSuccess,
      addToast,
    ]
  );

  return (
    <div className={styles.view}>
      <div className={styles.Components}>
        <header>
          <h1>Créer mon mot de passe !</h1>
          <h2>{typeof atob !== "undefined" ? atob(emailAsBase64) : ""}</h2>
        </header>
        <main>
          {!!token && (
            <Fragment>
              {!success && (
                <form onSubmit={onCreate}>
                  <TextInput
                    label="Mot de passe"
                    name="password"
                    placeholder="Votre mot de passe"
                    onChange={(_e, value) => setPassword(value)}
                    required
                    strokeIcon={key}
                    type="password"
                  />
                  <TextInput
                    label="Confirmation mot de passe"
                    name="passwordVerification"
                    placeholder="Votre mot de passe (confirmation)"
                    onChange={(_e, value) => setPasswordVerification(value)}
                    required
                    strokeIcon={key}
                    type="password"
                  />

                  <InteractiveButton
                    label="Valider"
                    strokeIconLeft={login}
                    style={{ margin: "30px auto 0 auto" }}
                    loading={loading}
                    type="submit"
                  />
                </form>
              )}
              {!!success && (
                <Fragment>
                  <p>Votre mot de passe a été créé.</p>
                  <p>Connexion en cours...</p>
                </Fragment>
              )}
            </Fragment>
          )}
          <small>Vous avez votre mot de passe ?</small>
          <InteractiveButton
            label="Se connecter"
            iconLeft={user}
            size="small"
            background="#fff"
            color={Colors.textColor}
            style={{ margin: "10px 0 0", display: "inline-block" }}
            onClick={() => navigate("/connexion/authentification")}
          />
        </main>
      </div>
    </div>
  );
};

export default CreatePassword;
