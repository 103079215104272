import React, { type ReactNode, type HTMLAttributes, forwardRef } from "react";
import cx from "classnames";

import styles from "./Flex.module.scss";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  readonly children?: ReactNode;
  readonly row?: boolean;
  readonly column?: boolean;
  readonly center?: boolean;
  readonly grow?: boolean;
  readonly wrap?: boolean;
  readonly auto?: boolean;
  readonly debug?: boolean;
  readonly disabled?: boolean;
}

const Flex = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      row = false,
      column = false,
      center = false,
      grow = false,
      disabled = false,
      wrap = false,
      auto,
      debug = false,
      ...props
    },
    ref
  ): JSX.Element | null => {
    if (disabled) return null;

    return (
      <div
        ref={ref}
        className={cx(styles.Flex, className, {
          [styles.debug]: debug,
          [styles.flexRow]: row,
          [styles.flexColumn]: column,
          [styles.flexCenter]: center,
          [styles.flexGrow]: grow,
          [styles.flexWrap]: wrap,
          [styles.flexAuto]: auto,
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export { Flex };
