import React, { type CSSProperties } from "react";
import { isUndefined } from "lodash";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import { Tag } from "../../Tag";
import { colors } from "../../../assets/colors";

import { modalities } from "../modalitiesData";
import styles from "./Modalities.module.scss";

interface Props {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly disabled?: boolean;
  readonly mode?: keyof typeof modalities;
  readonly isCPF?: boolean;
}

const Modalities = ({ className, style, disabled, mode, isCPF }: Props) => (
  <Flex
    className={cx(styles.Modalities, className)}
    disabled={disabled || !mode || isUndefined(modalities[mode])}
    style={style}
    data-testid="training-modalities"
  >
    {mode && modalities[mode] && (
      <Tag
        label={modalities[mode].label}
        color={modalities[mode].color ?? colors.modalityDigitalLearning}
      />
    )}
    {isCPF && <Tag className={styles.isCPF} label="CPF" color={colors.secondaryBlueCpf} />}
  </Flex>
);

export { Modalities };
