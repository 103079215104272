import React, { cloneElement, useMemo, type ReactElement, useRef } from "react";
import cx from "classnames";

import { type Themes } from "components/commonProps";

import { TableOfContentItem } from "./TableOfContentItem";
import { TableOfContentSubItem } from "./TableOfContentSubItem";

import styles from "./TableOfContent.module.scss";
export interface TableOfContentProps {
  readonly activeItem?: string;
  readonly onChangeItem?: (itemId: string) => void;

  // actions elements
  readonly children?: JSX.Element[] | JSX.Element;

  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly className?: string;
}

const PureTableOfContent = ({
  activeItem,
  onChangeItem,
  children,
  darkMode,
  theme,
  className,
  ...props
}: TableOfContentProps): JSX.Element => {
  // this var disabled intersection observer in items when scroll is coming from click on link
  const scrollFromLinkItemClick = useRef(false);

  const enhanceItem = (elements: JSX.Element[] | JSX.Element | undefined) =>
    React.Children.toArray(elements).map((child) => {
      // @ts-ignore
      const subItems = React.Children.toArray(child.props.children);
      // @ts-ignore
      const mainId = child.props.id;
      const isOpen =
        mainId === activeItem ||
        // @ts-ignore
        subItems.findIndex((sub) => sub.props.id === activeItem) >= 0;
      return cloneElement(child as ReactElement, {
        scrollFromLinkItemClick,
        open: isOpen,
        active: activeItem === mainId,
        onClick: () => onChangeItem?.(mainId),
        onChangeItem,
        theme: theme,
        darkMode,
        children: subItems.map((subChild) => {
          // @ts-ignore
          const subId = subChild.props.id;
          return cloneElement(subChild as ReactElement, {
            scrollFromLinkItemClick,
            active: activeItem === subId,
            onClick: () => onChangeItem?.(subId),
            onChangeItem,
            theme: theme,
            darkMode,
          });
        }),
      });
    });

  const { actionElements } = useMemo(
    () => ({
      actionElements: enhanceItem(children),
    }),
    [children, activeItem]
  );

  return (
    <div
      aria-label="table-of-content"
      className={cx(styles.TableOfContent, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      {...props}
    >
      {actionElements}
    </div>
  );
};

const TableOfContent = Object.assign(PureTableOfContent, {
  Item: TableOfContentItem,
  SubItem: TableOfContentSubItem,
});

export { TableOfContent };
