import { useState, useEffect } from "react";
import addons from "@storybook/addons";

import { Themes } from "../components/commonProps";

const useThemes = () => {
  const [theme, setTheme] = useState<Themes>(Themes.ESPACE_COLLABORATEUR); // default set in preview.js

  useEffect(() => {
    const chan = addons.getChannel();
    chan.on("storybook-addon-themes/change", setTheme);
    return () => chan.off("storybook-addon-themes/change", setTheme);
  }, []);

  return {
    theme,
    setTheme,
  };
};

export { useThemes };
