import React, { type CSSProperties } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { colors } from "../../assets/colors";
import styles from "./Badge.module.scss";

export interface Props {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly value?: number;
  readonly size?: CSSProperties["width"];
  readonly backgroundColor?: CSSProperties["backgroundColor"];
  readonly disabled?: boolean;
}

const Badge = ({
  className,
  style,
  size = "1.381rem",
  backgroundColor = colors.secondaryRed,
  value = 0,
  disabled,
}: Props) => (
  <Flex
    role="badge"
    className={cx(styles.Badge, className)}
    style={{ ...style, width: size, height: size, backgroundColor }}
    disabled={disabled}
    center
  >
    <span className={styles.value} role="value">
      {value > 9 ? "9+" : value}
    </span>
  </Flex>
);

export { Badge };
