import { CSSProperties, useMemo, forwardRef, HTMLAttributes, RefObject } from "react";
import Colors from "components/Colors";

export interface Props extends HTMLAttributes<SVGSVGElement> {
  className?: string;
  fill?: string;
  height?: number;
  icon?: string;
  stroke?: string;
  strokeIcon?: string;
  strokeWidth?: number;
  style?: CSSProperties;
  width?: number;
  viewBox?: string;
}

const Icon = (props: Props, ref: RefObject<SVGSVGElement>) => {
  const {
    className,
    fill,
    icon,
    stroke,
    strokeIcon,
    strokeWidth,
    style,
    width = 32,
    viewBox = "0 0 32 32",
  } = props;

  const height = useMemo(() => props.height ?? props.width ?? 32, [props]);

  const styles: CSSProperties = useMemo(() => {
    if (strokeIcon) {
      return {
        fill: "none",
        overflow: "visible",
        stroke: stroke || Colors.blue,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: strokeWidth || 48 / (width || 32),
        transition: "stroke .2s ease-out, transform .2s ease-out",
        ...style,
      };
    }

    return {
      fill: fill ?? Colors.blue,
      stroke: "none",
      transition: "fill .2s ease-out, transform .2s ease-out",
      ...style,
    };
  }, [fill, style, strokeIcon, strokeWidth, width, stroke]);

  return (
    <svg
      ref={ref}
      className={className || ""}
      dangerouslySetInnerHTML={{ __html: icon || strokeIcon || "" }}
      height={height}
      style={styles}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    />
  );
};

// @ts-ignore
export default forwardRef(Icon);
