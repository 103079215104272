import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgCalendarEmpty = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 16 15" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3332 1.66668V1.00001C11.3332 0.63182 11.6316 0.333344 11.9998 0.333344C12.368 0.333344 12.6665 0.63182 12.6665 1.00001V1.66668H13.3332C14.4377 1.66668 15.3332 2.56211 15.3332 3.66668V5.66668V7.00001V10.3333C15.3332 12.5425 13.5423 14.3333 11.3332 14.3333H4.6665C2.45736 14.3333 0.666504 12.5425 0.666504 10.3333V7.00001V5.66668V3.66668C0.666504 2.56211 1.56193 1.66668 2.6665 1.66668H3.99984V1.00001C3.99984 0.63182 4.29831 0.333344 4.6665 0.333344C5.03469 0.333344 5.33317 0.63182 5.33317 1.00001V1.66668H11.3332ZM1.99984 5.66668H13.9998V3.66668C13.9998 3.29849 13.7014 3.00001 13.3332 3.00001H12.6665C12.6665 3.3682 12.368 3.66668 11.9998 3.66668C11.6316 3.66668 11.3332 3.3682 11.3332 3.00001H5.33317C5.33317 3.3682 5.03469 3.66668 4.6665 3.66668C4.29831 3.66668 3.99984 3.3682 3.99984 3.00001H2.6665C2.29831 3.00001 1.99984 3.29849 1.99984 3.66668V5.66668ZM1.99984 7.00001V10.3333C1.99984 11.8061 3.19374 13 4.6665 13H11.3332C12.8059 13 13.9998 11.8061 13.9998 10.3333V7.00001H1.99984Z"
      fill="#92929D"
      opacity="0.5"
    />
  </SVGBox>
);

export { SvgCalendarEmpty };
