import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgUnseen = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 24 21" fill="none" {...props}>
    <path
      d="M11.8538 3.65399C7.32422 3.65399 3.21655 6.13218 0.1855 10.1574C-0.0618333 10.4872 -0.0618333 10.9479 0.1855 11.2777C3.21655 15.3078 7.32422 17.7859 11.8538 17.7859C16.3834 17.7859 20.4911 15.3078 23.5221 11.2825C23.7695 10.9527 23.7695 10.492 23.5221 10.1623C20.4911 6.13218 16.3834 3.65399 11.8538 3.65399ZM12.1787 15.6957C9.17195 15.8849 6.68891 13.4067 6.87805 10.395C7.03324 7.912 9.04585 5.89939 11.5289 5.7442C14.5357 5.55506 17.0187 8.03325 16.8296 11.0449C16.6695 13.5231 14.6569 15.5357 12.1787 15.6957ZM12.0284 13.397C10.4086 13.4988 9.0701 12.1652 9.1768 10.5454C9.25924 9.20687 10.3456 8.12539 11.6841 8.0381C13.3039 7.93625 14.6424 9.26991 14.5357 10.8897C14.4484 12.2331 13.3621 13.3145 12.0284 13.397Z"
      fill={color}
    />
    <line
      x1="2.08617"
      y1="0.654488"
      x2="21.4364"
      y2="19.7137"
      stroke="#828282"
      strokeWidth="0.969935"
    />
  </SVGBox>
);

export { SvgUnseen };
