import React from "react";

import { TextInput as DSTextInput } from "components/Form/TextInput";
import { type TextInputProps } from "components/Form/TextInput/TextInput";

export type TextInputCustomProps = Omit<
  TextInputProps,
  "onChange" | "onFocus" | "onBlur" | "error" | "theme" | "darkMode"
>;
export type TextInputComponent = React.ReactElement<TextInputCustomProps>;

export const CustomTextInput = (props: TextInputCustomProps) => {
  return <DSTextInput {...props} />;
};
