/* eslint-disable import/no-anonymous-default-export */
import { head, isEmpty } from "lodash";

const getUserData = (credentials: any) => {
  return credentials;
};

const isRF = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("admin");
};

const isManager = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("manager");
};

const isInterviewManager = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.userScope?.includes?.("interview-manager");
};

const isRegularUser = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData &&
    userData.uuid &&
    userData.activeCompany &&
    !isManager(credentials) &&
    !isRF(credentials)
  );
};

const isOF = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeTrainingOrganization?.userScope?.includes?.("admin");
};

const isDemoUser = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData &&
    userData.uuid &&
    userData.activeCompany &&
    userData.activeCompany.uuid === "b2359493-a019-4627-82e7-a8751ffcebaf"
  );
};

const canCreateInterviewCampaignForDemo = (credentials: any = null): boolean => {
  const allowedCompanies = [
    "6f1b6ba5-d3d9-49a7-abe2-059cf48aecb3", // PeopleSpheres
    "61ceded8-7d9b-4221-99b7-f6633f5d2126", // Fortify
    "f6f6d74f-6f48-4a47-8505-0c8ebefd9347", // Oppida 1
  ];

  const userData = getUserData(credentials);
  return allowedCompanies.includes(userData?.activeCompany?.uuid);
};

const isSkillupAdmin = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(userData && userData.scope && userData.scope.includes("admin"));
};

const isSkillupDeveloper = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(userData?.scope?.includes("admin") && userData?.scope?.includes("developer"));
};

const isImpostor = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.impostor;
};

const isUserless = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(!userData?.uuid && userData?.activeCompany);
};

const isCompanyUser = (credentials: any = null) => {
  const userData = getUserData(credentials);

  return userData?.uuid && userData?.activeCompany;
};

const isPortalEnabled = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!userData?.activeCompany?.portalEnabled;
};

const isPortalSearchDisabled = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return !!(
    userData?.activeCompany?.portalEnabled && userData?.activeCompany?.portalSearchDisabled
  );
};

const hasInterviews = (credentials: any = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("interviews");
};

const canAccessCompetencesModule = (credentials: any = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("competences");
};

const hasTrainingsEnabled = (credentials: any = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("trainings");
};

const hasColdReviewsEnabled = (credentials: any = null) => {
  const userData = getUserData(credentials);

  return !!userData?.activeCompany?.features?.includes?.("trainings-cold-evaluations");
};

const getSchedules = ({ archived = false } = {}, credentials = null) => {
  const userData = getUserData(credentials);

  const schedules = userData?.activeCompany?.schedules;
  if (!schedules) return [];

  const sortedSchedules = schedules.sort((a: any, b: any) => {
    if (a.active) return -1;
    else if (b.active) return 1;

    if (a.createdAt > b.createdAt) return -1;
    else if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

  if (archived) {
    return sortedSchedules;
  }

  return sortedSchedules.filter((s: any) => !s.archivedAt);
};

const getActiveSchedule = (credentials: any = null) => {
  const userData = getUserData(credentials);

  const schedules = userData?.activeCompany?.schedules;
  if (!schedules) return null;

  return schedules.find((s: any) => s.active);
};

const defaultGroup = { name: "Groupe par défaut", uuid: null };

const getGroups = (credentials: any = null) => {
  const userData = getUserData(credentials);

  const groups = userData?.activeCompany?.groups;
  if (!groups) return [defaultGroup];

  return [defaultGroup, ...groups];
};

const getUserGroup = (credentials: any = null) => {
  const userData = getUserData(credentials);

  const userGroup = userData?.activeCompany?.userGroup;
  if (!userGroup) return defaultGroup;
  if (!userGroup.uuid) return { ...defaultGroup, ...userGroup };

  return userGroup;
};

const getAreas = (credentials: any = null) => {
  const userData = getUserData(credentials);

  const areas = userData?.activeCompany?.areas;
  return areas;
};

type UserNameData = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
};

function computeFullName<User extends UserNameData>(user: User): string {
  const { firstName, lastName } = user;
  return `${(firstName ?? "").trim()} ${(lastName ?? "").trim()}`.trim();
}

function getFullName<User extends UserNameData>(user: User): string {
  const { fullName } = user;
  return fullName ?? computeFullName(user);
}

function getFirstLetters<User extends UserNameData>(user: User): string {
  const { firstName, lastName } = user;
  return (
    (firstName ?? "").trim().charAt(0).toUpperCase() +
    (lastName ?? "").trim().charAt(0).toUpperCase()
  );
}

function getUserInitialsFromFullName<User extends UserNameData>(user: User): string {
  const { fullName } = user;
  if (isEmpty(fullName)) {
    return "";
  }

  // @ts-ignore
  return fullName
    .split(" ")
    .map((nameFragment) => nameFragment?.[0])
    .join("");
}

function getUserInitials<User extends UserNameData>(user: User): string {
  const { firstName, lastName } = user;
  if (isEmpty(firstName) || isEmpty(lastName)) {
    return getUserInitialsFromFullName(user);
  }

  // @ts-ignore
  return head(firstName)?.toUpperCase() + head(lastName)?.toUpperCase();
}

const isCompanyWithTax = (credentials: any = null) => {
  const userData = getUserData(credentials);
  return Boolean(userData?.activeCompany?.withTax);
};

export {
  canAccessCompetencesModule,
  computeFullName,
  getActiveSchedule,
  getFirstLetters,
  getFullName,
  getGroups,
  getSchedules,
  getUserData,
  getUserGroup,
  getUserInitials,
  getAreas,
  hasInterviews,
  hasTrainingsEnabled,
  hasColdReviewsEnabled,
  isCompanyUser,
  isDemoUser,
  canCreateInterviewCampaignForDemo,
  isImpostor,
  isInterviewManager,
  isManager,
  isOF,
  isPortalEnabled,
  isPortalSearchDisabled,
  isRegularUser,
  isRF,
  isSkillupAdmin,
  isSkillupDeveloper,
  isUserless,
  isCompanyWithTax,
};

export default {
  canAccessCompetencesModule,
  computeFullName,
  getActiveSchedule,
  getFirstLetters,
  getFullName,
  getGroups,
  getSchedules,
  getUserData,
  getUserGroup,
  getUserInitials,
  getAreas,
  hasInterviews,
  hasTrainingsEnabled,
  hasColdReviewsEnabled,
  isCompanyUser,
  isDemoUser,
  canCreateInterviewCampaignForDemo,
  isImpostor,
  isInterviewManager,
  isManager,
  isOF,
  isPortalEnabled,
  isPortalSearchDisabled,
  isRegularUser,
  isRF,
  isSkillupAdmin,
  isSkillupDeveloper,
  isUserless,
  isCompanyWithTax,
};
