import React from "react";
import { useMedia } from "react-use";
import { chunk } from "lodash";
import cx from "classnames";

import { Bar, type BarProps, type IBarLegend } from "components/Bar";

import { type ICard } from "../DashboardCardView/types";
import { DashboardCardWrapper, type DashboardCardWrapperProps } from "../DashboardCardWrapper";

import styles from "./DashboardCardBar.module.scss";
export interface IDashboardCardBar extends Pick<BarProps, "data"> {
  title: string;
  tooltip?: string;
}

export interface DashboardCardBarProps
  extends ICard<IDashboardCardBar>,
    DashboardCardWrapperProps {}

const DashboardCardBar = ({
  id,
  size,
  order,
  componentName,
  componentData,
  className,
  ...props
}: DashboardCardBarProps) => {
  const isWide = useMedia("(min-width: 768px)");
  const isSizeS = size === "S" || !isWide;

  const maxItemPerLine = isSizeS ? 3 : 4;

  const defaultLegends: IBarLegend = {
    anchor: isSizeS ? "bottom" : "right",
    itemHeight: isSizeS ? 14 : 22,
    itemsSpacing: isSizeS ? 16 : 8,
    direction: isSizeS ? "row" : "column",
  };

  const defaultMargin = isSizeS
    ? {
        top: 20,
        bottom: 32 * Math.ceil(componentData.data.length / maxItemPerLine),
        right: 0,
        left: 0,
      }
    : {
        top: 50,
        bottom: 0,
        right: 120 * Math.ceil(componentData.data.length / 6),
        left: 0,
      };

  const legends = isSizeS
    ? // rows
      chunk(componentData.data, maxItemPerLine).map((chunk) => ({
        ...defaultLegends,
        data: chunk.map((d) => ({ id: JSON.stringify(d), ...d })),
      }))
    : // columns
      chunk(componentData.data, 6).map((chunk) => ({
        ...defaultLegends,
        data: chunk.map((d) => ({ id: JSON.stringify(d), ...d })),
      }));

  return (
    <DashboardCardWrapper
      {...props}
      id={id}
      size={size}
      order={order}
      title={componentData.title}
      tooltip={componentData.tooltip}
      className={cx(styles.DashboardCardBar, className)}
    >
      <div style={{ height: 300 }}>
        <Bar
          {...componentData}
          legends={legends}
          legendsByRow={maxItemPerLine}
          margin={defaultMargin}
        />
      </div>
    </DashboardCardWrapper>
  );
};

export { DashboardCardBar };
