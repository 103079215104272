import React, { useMemo } from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";

import styles from "./Status.module.scss";
import { MdWarning, MdHourglassTop, MdDone, MdError, MdSchedule } from "react-icons/md";

export interface StatusProps {
  readonly type: StatusType;
  readonly label: string;
  readonly withIcon?: boolean;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

export enum StatusType {
  DONE = "done",
  SUCCESS = "success",
  PROGRESS = "progress",
  WARNING = "warning",
  ERROR = "error",
  TIME = "time",
}

const Status = ({ label, type, theme, darkMode, className, withIcon }: StatusProps) => {
  const icon = useMemo(() => {
    if (!withIcon) return null;
    switch (type) {
      case StatusType.SUCCESS:
      case StatusType.DONE:
        return <MdDone />;
      case StatusType.PROGRESS:
        return <MdHourglassTop size={12} />;
      case StatusType.WARNING:
        return <MdWarning />;
      case StatusType.ERROR:
        return <MdError />;
      case StatusType.TIME:
        return <MdSchedule />;
      default:
        return null;
    }
  }, [type, withIcon]);

  return (
    <div
      className={cx(
        styles.Status,
        styles[theme],
        styles[type],
        {
          [styles.darkMode]: darkMode,
        },
        className
      )}
    >
      {icon}
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export { Status };
