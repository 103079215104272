import React, { useState } from "react";
import cx from "classnames";
import { Popover } from "../Popover";
import styles from "./DropDownMenu.module.scss";

interface Item {
  readonly icon?: React.ReactNode;
  readonly label: string;
  readonly onClick?: (item: Item, index: number) => void;
}

type Placement = ["top" | "bottom" | "auto", "left" | "right" | "auto"];

interface Props {
  readonly className?: string;
  readonly menuClassName?: string;
  readonly togglerClassName?: string;
  readonly maskClassName?: string;
  readonly items: Array<Item>;
  readonly placement?: Placement;
  readonly children: React.ReactNode;
}

export function DropDownMenu({
  className,
  menuClassName,
  togglerClassName,
  maskClassName,
  items,
  placement,
  children,
}: Props) {
  const [closePopover, setClosePopover] = useState<() => void>();

  function handleItemClick(item: Item, index: number) {
    item.onClick?.(item, index);
    closePopover?.();
  }

  return (
    <Popover
      className={cx(styles.DropDownMenu, className)}
      placement={placement}
      maskClassName={maskClassName}
      togglerClassName={togglerClassName}
      contentClassName={menuClassName}
      actions={({ close }) => setClosePopover(() => close)}
      content={() => (
        <div role="menu" className={styles.Menu}>
          {items.map((item, index) => (
            <div role="menuitem" key={item.label} onClick={() => handleItemClick(item, index)}>
              {item.icon}
              {item.label}
            </div>
          ))}
        </div>
      )}
    >
      {children}
    </Popover>
  );
}
