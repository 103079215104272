import React from "react";
import { useTranslation } from "react-i18next";

import { useGridApiContext, type GridSlotsComponentsProps } from "@mui/x-data-grid-pro";
import FirstPage from "@mui/icons-material/FirstPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import LastPage from "@mui/icons-material/LastPage";

import { Flex } from "components/Flex";
import { Button as DSButton } from "components/Button";
import { SkuiSpacing } from "components/DesignSystemContext";
import { Select as DSSelect, MenuPlacement } from "components/Select";
import { ThemedNumberInput as DSNumberInput } from "components/Form/Inputs/NumberInput";

import { PAGE_SIZE_OPTIONS } from "../../types";

import styles from "./Footer.module.scss";

declare module "@mui/x-data-grid-pro" {
  interface FooterPropsOverrides {
    entityName?: string;
    translationPrefix?: string;
  }
}

export interface FooterProps {
  entityName?: string;
  translationPrefix?: string;
}

export interface TranslationParams {
  key: string;
  options?: any;
}

const Footer = ({
  entityName = "objet",
  translationPrefix = "datagrid",
}: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const { page, pageSize } = apiRef.current.state.pagination.paginationModel;
  // todo: change rowsCount when the pagination can be managed in backend
  const rowsCount = apiRef.current.state.rows.totalRowCount;

  const pageCount = Math.ceil(rowsCount / pageSize);
  const isFirstPage = page === 0;
  const isLastPage = page === pageCount - 1;

  const hasLessRowsThanMinimumPageSize = rowsCount < PAGE_SIZE_OPTIONS[0];
  const pageOffset = page * pageSize;
  const fromOffset = pageOffset + 1;
  const toOffset = hasLessRowsThanMinimumPageSize
    ? rowsCount
    : isLastPage
      ? rowsCount
      : pageOffset + pageSize;

  const renderTranslation = ({ key, options }: TranslationParams) => {
    return t(`${translationPrefix}.${key}`, options);
  };

  const handlePageChange = (value: string) => {
    const page = parseInt(value, 10);

    if (page && page >= 0 && page <= pageCount) {
      apiRef.current.setPage(page - 1);
    } else {
      apiRef.current.setPage(0);
    }
  };

  return (
    <Flex className={styles.footer}>
      <Flex className={styles.linesPerPage}>
        <p className={styles.text}>
          {renderTranslation({
            key: "footer.linesPerPage",
            options: { defaultValue: "Lignes par page" },
          })}
        </p>

        <Flex className={styles.select}>
          <DSSelect
            paginationCustomStyle={{
              width: "fit-content",
              paddingTop: "unset",
              paddingBottom: "unset",
              paddingLeft: SkuiSpacing.xs,
              paddingRight: SkuiSpacing.xxs,
              height: "100%",
            }}
            options={PAGE_SIZE_OPTIONS.map((option) => ({
              label: option.toString(),
              value: option,
            }))}
            defaultValue={{
              label: pageSize.toString(),
              value: pageSize,
            }}
            value={pageSize}
            menuPlacement={MenuPlacement.TOP}
            onChange={(option) => {
              if (option) apiRef.current.setPageSize(option);
            }}
          />
        </Flex>
      </Flex>
      <Flex className={styles.lineCounter}>
        <p className={styles.text}>
          {renderTranslation({
            key: "footer.objects.name",
            options: {
              count: rowsCount,
              defaultValue: entityName,
            },
          })}
          <strong>
            {renderTranslation({
              key: "footer.objects.count",
              options: {
                defaultValue: " {{fromOffset}} - {{toOffset}} ",
                fromOffset,
                toOffset,
              },
            })}
          </strong>
          {renderTranslation({
            key: "footer.objects.on",
            options: { defaultValue: " sur {{rowsCount}}", rowsCount },
          })}
        </p>
      </Flex>
      <Flex className={styles.pagination}>
        <Flex className={styles.buttonGroup}>
          <DSButton
            iconOnly
            icon={<FirstPage />}
            disabled={isFirstPage}
            onClick={() => {
              apiRef.current.setPage(0);
            }}
          />
          <DSButton
            iconOnly
            icon={<ChevronLeft />}
            disabled={isFirstPage}
            onClick={() => {
              apiRef.current.setPage(page - 1);
            }}
          />
        </Flex>

        <Flex row center grow={false} className={styles.footerLabel}>
          <span>
            {renderTranslation({
              key: "footer.page.label",
              options: { defaultValue: "Page" },
            })}
          </span>
          {/* We must convert the value to string or it displayas as italic :/ */}
          <DSNumberInput
            className={styles.pageInput}
            value={(page + 1).toString()}
            onChange={handlePageChange}
          />
          <span>/ {pageCount}</span>
        </Flex>

        <Flex className={styles.buttonGroup}>
          <DSButton
            iconOnly
            icon={<ChevronRight />}
            disabled={isLastPage}
            onClick={() => {
              apiRef.current.setPage(page + 1);
            }}
          />
          <DSButton
            iconOnly
            icon={<LastPage />}
            disabled={isLastPage}
            onClick={() => {
              apiRef.current.setPage(pageCount - 1);
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Footer };
