import React, { type ReactNode } from "react";
import cx from "classnames";

import styles from "./SVGBox.module.scss";

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  readonly color?: string;
  readonly size?: number | string;
  readonly disabled?: boolean;
  readonly "aria-label"?: string;
}

export interface Props extends SVGProps {
  readonly children?: ReactNode;
}

const SVGBox = (props: Props) => {
  const { children, size, className, disabled, ...svgIconProps } = props;

  if (disabled) return null;

  return (
    <svg
      className={cx(styles.SVGBox, className)}
      width={size}
      height={size}
      {...svgIconProps}
      data-testid="icon"
      role="icon"
    >
      {children}
    </svg>
  );
};

export { SVGBox };
