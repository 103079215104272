import { Fragment, FormEvent, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

import Colors from "components/Colors";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import { user, arobase, key, login } from "components/StrokeIcons";

import User from "helpers/User";

import styles from "./ComponentsStyles.module.scss";
import request from "helpers/request";
import { AuthRoutes } from "api/types";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { token, data: emailAsBase64 } = useParams<{ token: any; data: any }>();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordVerification, setPasswordVerification] = useState<string>();

  const [success, toggleSuccess] = useToggle(false);
  const [expired, toggleExpired] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      toggleLoading(true);

      try {
        await request<AuthRoutes["POST"]["/v1/reset"]["response"]>({
          target: "AUTH",
          data: { email },
          method: "POST",
          url: `/reset`,
        });

        toggleSuccess(true);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 429) {
            addToast(
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
              {
                appearance: "error",
              }
            );
          } else if (error.message === "No such user with params") {
            addToast("Identifiants invalides.", {
              appearance: "error",
            });
          }
        } else {
          addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
            appearance: "error",
          });
        }

        toggleLoading(false);
      }
    },
    [toggleLoading, email, toggleSuccess, addToast]
  );

  const onReset = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      toggleLoading(true);

      const resetData = {
        email: emailAsBase64,
        password,
        passwordVerification,
      };

      try {
        await request<AuthRoutes["POST"]["/v1/reset/{token}"]["response"]>({
          target: "AUTH",
          data: resetData,
          method: "POST",
          url: `/reset/${token}`,
        });

        toggleSuccess(true);

        const loginData = {
          email: typeof atob !== "undefined" ? atob(resetData.email) : "",
          password: resetData.password,
        };

        const { data } = await request<AuthRoutes["POST"]["/v1/signin"]["response"]>({
          target: "AUTH",
          data: loginData,
          method: "POST",
          url: `/signin`,
        });
        window.localStorage.setItem("X-Auth-Token", data.token);

        const { REACT_APP_PUBLIC_APP_URL } = process.env;
        if (User.isOF(data.credentials)) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}admin-organisme/`;
        } else if (User.isRF(data.credentials)) {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}responsable/`;
        } else {
          window.location.href = `${REACT_APP_PUBLIC_APP_URL}/`;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 429) {
            addToast(
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
              {
                appearance: "error",
              }
            );
          } else if (error.response?.data?.message === "No such user with params") {
            addToast("Identifiants invalides.", {
              appearance: "error",
            });
          } else if (error.response?.data?.message === "token-expired") {
            addToast("Cette demande a expirée. Faites une nouvelle demande.", {
              appearance: "error",
            });

            toggleExpired(true);
          }
        } else {
          addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
            appearance: "error",
          });
        }

        toggleLoading(false);
      }
    },
    [
      toggleExpired,
      toggleLoading,
      token,
      emailAsBase64,
      toggleSuccess,
      password,
      passwordVerification,
      addToast,
    ]
  );

  const phase1 = !token || expired;
  const phase2 = !!token && !expired;

  return (
    <div className={styles.view}>
      <div className={styles.Components}>
        <header>
          <h1>Réinitialiser mon mot de passe !</h1>
          {phase1 && <h2>Demande par e-mail</h2>}
          {phase2 && <h2>Changement de mot de passe</h2>}
        </header>
        <main>
          {phase1 && (
            <Fragment>
              {!success && (
                <form onSubmit={onSubmit}>
                  <TextInput
                    label="Email"
                    placeholder="Votre email"
                    strokeIcon={arobase}
                    type="email"
                    required
                    name="email"
                    onChange={(_e, value) => setEmail(value)}
                  />

                  <InteractiveButton
                    label="Réinitialiser"
                    strokeIconLeft={login}
                    style={{ margin: "30px auto 0 auto" }}
                    loading={loading}
                    type="submit"
                  />
                </form>
              )}
              {!!success && (
                <p>Vérifiez votre boîte de réception pour réinitialiser votre mot de passe.</p>
              )}
            </Fragment>
          )}
          {phase2 && (
            <Fragment>
              {!success && (
                <form onSubmit={onReset}>
                  <TextInput
                    label="Mot de passe"
                    name="password"
                    placeholder="Votre mot de passe"
                    onChange={(_e, value) => setPassword(value)}
                    required
                    strokeIcon={key}
                    type="password"
                  />
                  <TextInput
                    label="Confirmation mot de passe"
                    name="passwordVerification"
                    placeholder="Votre mot de passe (confirmation)"
                    onChange={(_e, value) => setPasswordVerification(value)}
                    required
                    strokeIcon={key}
                    type="password"
                  />

                  <InteractiveButton
                    label="Valider"
                    strokeIconLeft={login}
                    style={{ margin: "30px auto 0 auto" }}
                    loading={loading}
                    type="submit"
                  />
                </form>
              )}
              {!!success && (
                <Fragment>
                  <p>Votre mot de passe a été changé.</p>
                  <p>Connexion en cours...</p>
                </Fragment>
              )}
            </Fragment>
          )}
          <small>Vous avez votre mot de passe ?</small>
          <InteractiveButton
            label="Se connecter"
            iconLeft={user}
            size="small"
            background="#fff"
            color={Colors.textColor}
            style={{ margin: "10px 0 0", display: "inline-block" }}
            onClick={() => navigate("/connexion/authentification")}
          />
        </main>
      </div>
    </div>
  );
};

export default ResetPassword;
