import React, { useMemo, useState } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { TextInput } from "components/Form/TextInput";

import styles from "./SearchSelectFilter.styles.module.scss";
import { type Column, type Row } from "components/DataTable/types";
import { SelectFilter } from "../SelectFilter";
export interface Props {
  readonly className?: string;
  readonly options: Required<Column<Row<string, any>>>["options"];
  readonly values?: Set<string>;
  readonly onChange: (key?: Set<string>) => void;
}

export function SearchSelectFilter({ className, options, values, onChange }: Props) {
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    if (isEmpty(search)) {
      return options.map((e) => ({ ...e, display: true }));
    }

    return options.map((e) => ({
      ...e,
      display: e.label.toLowerCase().includes(search.toLowerCase()),
    }));
  }, [search, options]);

  return (
    <div className={cx(styles.searchSelectFilter, className)}>
      <div className={styles.searchSelectFilterContainer}>
        <TextInput
          name={"DataTable-SearchSelectFilter-Input"}
          className={styles.searchInput}
          value={search}
          onChange={setSearch}
          placeholder={"Recherche..."}
        />
        {filteredData.length === 0 && !isEmpty(search) && (
          <p className={styles.empty}>
            {"Aucun résultat trouvé, veuillez modifier votre recherche."}
          </p>
        )}
        <SelectFilter
          options={filteredData}
          values={values}
          withSelectAll={!search}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
