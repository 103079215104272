import React from "react";
import cx from "classnames";

import { type ProgressBarPropsWithPercent, ValueLayoutProgressBar } from "./ProgressBar";

import styles from "./ProgressBar.module.scss";

export function ProgressBarBlock({
  data,
  showLegends = true,
  border = false,
  thin = false,
  valueLayout = ValueLayoutProgressBar.VALUE,
  labelFormatter = (label: string, _: number) => label,
  valueFormatter = (_: string, value: number) => value.toString(),
}: ProgressBarPropsWithPercent) {
  return (
    <section className={cx(styles.section, styles.block)}>
      {data.map(
        (
          { label, value, percentage, component, containerProps, headerProps, barProps, color },
          i
        ) => (
          <div
            {...containerProps}
            className={cx(styles.container, containerProps?.className)}
            key={i}
          >
            {showLegends && (
              <div {...headerProps} className={cx(styles.header, headerProps?.className)}>
                <label className={styles.label}>{labelFormatter(label ?? "", value)}</label>
                {component}
                {[
                  ValueLayoutProgressBar.PERCENTAGE,
                  ValueLayoutProgressBar.VALUE_PERCENTAGE,
                ].includes(valueLayout) && (
                  <label className={styles.percentage}>{Math.floor(percentage)} %</label>
                )}
                {[ValueLayoutProgressBar.VALUE, ValueLayoutProgressBar.VALUE_PERCENTAGE].includes(
                  valueLayout
                ) && <label className={styles.value}>{valueFormatter(label ?? "", value)}</label>}
              </div>
            )}
            <div
              {...barProps}
              className={cx(
                styles.bar,
                barProps?.className,
                border && styles.border,
                thin && styles.thin
              )}
            >
              <span
                className={cx(styles.subBar, color ? styles[color] : styles.gradiantBlue)}
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        )
      )}
    </section>
  );
}
