import React from "react";

import cx from "classnames";

import { MdChatBubble } from "components/MaterialIcons";
import { Popover } from "components/Popover";
import { SkillLevel } from "../SkillLevel";
import { ExpectedSkillLevel } from "../ExpectedSkillLevel";
import { SkillPopover } from "../SkillPopover";

import styles from "./SkillItem.module.scss";

interface SkillItemValueProps {
  readonly name: string;
  readonly level?: number;
  readonly levelName?: string;
  readonly levelDescription?: string;
  readonly totalLevel: Array<{ level: number }>;
  readonly expectedLevel?: number;
  readonly expectedLevelName?: string;
  readonly expectedLevelDescription?: string;
  readonly comment?: string;
  readonly evaluatedBy: string;
  readonly evaluatedAt: string;
}

interface SkillItemProps {
  readonly skill: SkillItemValueProps;
  readonly categoryName: string;
  readonly darkMode?: boolean;
  readonly toReach?: boolean;
  readonly onlyExpected?: boolean;
}

const SkillItem = ({ skill, darkMode, toReach, onlyExpected }: SkillItemProps) => {
  return (
    <Popover
      className={cx(styles.skillPopover)}
      position={"auto"}
      triggerAction="click"
      content={() => <SkillPopover skill={skill} darkMode={darkMode} toReach={toReach} />}
    >
      <div className={styles.toggler}>
        <div className={styles.skillContainer}>
          <div className={styles.skillName}>
            <div className={styles.skillNameOverflow}>{skill.name}</div>
            {!onlyExpected && skill.comment && <MdChatBubble color="#69798C" size="1rem" />}
          </div>
          <div className={styles.content}>
            {onlyExpected ? (
              <ExpectedSkillLevel
                level={skill.level}
                totalLevel={skill.totalLevel}
                expectedLevel={skill.expectedLevel}
              />
            ) : (
              <SkillLevel
                level={skill.level}
                totalLevel={skill.totalLevel}
                expectedLevel={skill.expectedLevel}
              />
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export { SkillItem };
