import React from "react";
import styles from "./Dialog.module.scss";

type Props = {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
};
function Dialog({ title, children, onClose }: Props) {
  function preventClose(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return (
    <div className={styles.DialogBackdrop} onClick={onClose}>
      <div className={styles.Dialog} onClick={preventClose}>
        <div className={styles.DialogHeader}>
          <div className={styles.DialogTitle}>{title}</div>
          <div className={styles.DialogClose} onClick={onClose}>
            &times;
          </div>
        </div>
        <div className={styles.DialogBody}>{children}</div>
      </div>
    </div>
  );
}

export default Dialog;
