import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Block = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 17 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9286 10.9716C13.4991 10.1226 13.832 9.10049 13.832 8.00065C13.832 5.05513 11.4442 2.66732 8.4987 2.66732C7.39792 2.66732 6.37503 3.0008 5.52555 3.57226L12.9286 10.9716ZM12.0506 11.9792L4.51837 4.45072C3.67684 5.39362 3.16536 6.6374 3.16536 8.00065C3.16536 10.9462 5.55318 13.334 8.4987 13.334C9.8629 13.334 11.1075 12.8218 12.0506 11.9792ZM8.4987 1.33398C12.1806 1.33398 15.1654 4.31875 15.1654 8.00065C15.1654 11.6826 12.1806 14.6673 8.4987 14.6673C4.8168 14.6673 1.83203 11.6826 1.83203 8.00065C1.83203 4.31875 4.8168 1.33398 8.4987 1.33398Z"
      fill={color}
    />
  </SVGBox>
);

export { Block };
