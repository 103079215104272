import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Camera = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M13.5804 11.5935C13.5804 13.0164 12.4229 14.1739 11 14.1739C9.5771 14.1739 8.42003 13.0164 8.42003 11.5935C8.42003 10.1706 9.5771 9.0131 11 9.0131C12.4229 9.0131 13.5804 10.1711 13.5804 11.5935ZM19 7.92945V15.2585C19 16.2355 18.2078 17.0277 17.2308 17.0277H4.76923C3.79217 17.0277 3 16.2355 3 15.2585V7.92945C3 6.9524 3.79217 6.16023 4.76923 6.16023H6.94538V5.54807C6.94538 4.69309 7.63803 4 8.49345 4H13.5066C14.362 4 15.0546 4.69309 15.0546 5.54807V6.15978H17.2308C18.2078 6.16023 19 6.9524 19 7.92945ZM14.9073 11.5935C14.9073 9.43904 13.1545 7.68618 11 7.68618C8.84597 7.68618 7.09311 9.43904 7.09311 11.5935C7.09311 13.748 8.84597 15.5009 11 15.5009C13.1545 15.5009 14.9073 13.748 14.9073 11.5935Z"
      fill={color}
    />
  </SVGBox>
);

export { Camera };
