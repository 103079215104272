import React, { type ReactNode } from "react";

import styles from "./RectangleTag.module.scss";

interface Props {
  readonly icon: ReactNode;
  readonly label: string;
  readonly backgroundColor: string;
  readonly textColor: string;
}

const RectangleTag = ({ icon, label, backgroundColor, textColor }: Props) => (
  <div className={styles.rectangleTag} style={{ backgroundColor }}>
    {icon}
    <span className={styles.tagLabel} style={{ color: textColor }}>
      {label}
    </span>
  </div>
);

export { RectangleTag };
