import React, { type HTMLAttributes, type ReactElement, useMemo, useState } from "react";
import cx from "classnames";

import styles from "./Carousel.module.scss";

import { type Themes } from "../commonProps";
import { Button } from "components/Button/Button";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { ArrowLeft, ArrowRight } from "components/MaterialIcons";
import { useMediaQueries } from "../../hooks/useMediaQueries";

export interface CarouselProps extends HTMLAttributes<HTMLDivElement> {
  readonly slidesPerView?: number;
  readonly slidesPerGroup?: number;
  readonly spaceBetween?: number;
  readonly slides: ReactElement[];
  readonly slideClassName?: string;
  readonly backgroundRgbColor?: string;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const roundSlidesPerView = (slidesPerView: number) => {
  return Math.floor(slidesPerView * 10) / 10;
};

const Carousel = ({
  slides = [],
  slidesPerView = 4,
  slidesPerGroup,
  spaceBetween = 16,
  backgroundRgbColor = "255, 255, 255",
  darkMode = false,
  theme,
  className,
  slideClassName,
  ...props
}: CarouselProps) => {
  const { isMobile } = useMediaQueries();

  const [swiper, setSwiper] = useState<any>(null);
  const [swiperState, setSwiperState] = useState<any>(null);

  const hasEmptySlide = useMemo(() => {
    const roundedSlidesPerView = roundSlidesPerView(slidesPerView);
    return slides.length >= roundedSlidesPerView && slides.length % roundedSlidesPerView !== 0;
  }, [slides, slidesPerView]);

  const nbMaxSlide = useMemo(() => {
    const nbSlides = slides.length + (hasEmptySlide ? 1 : 0);
    return Math.floor(nbSlides - slidesPerView);
  }, [slides, hasEmptySlide, slidesPerView]);

  const handleStateWithEmptySlider = (index: number) => {
    if (hasEmptySlide) {
      if (index >= nbMaxSlide) {
        setSwiperState({ isEnd: true });
      }
    }
  };
  return (
    <div
      className={cx(styles.Carousel, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      aria-label={`carousel`}
      role="presentation"
      {...props}
    >
      <Swiper
        modules={[Mousewheel]}
        spaceBetween={spaceBetween}
        slidesPerView={roundSlidesPerView(slidesPerView)}
        slidesPerGroup={!isMobile ? slidesPerGroup || Math.floor(slidesPerView / 2) : undefined}
        simulateTouch={false}
        mousewheel={{
          forceToAxis: true,
        }}
        loop={false}
        rewind={false}
        className={styles.slider}
        onSlideChange={(instance) => {
          setSwiperState({ isBeginning: instance.isBeginning, isEnd: instance.isEnd });
          handleStateWithEmptySlider(instance.activeIndex);
        }}
        onSwiper={(instance) => {
          setSwiper(instance);
          setSwiperState({ isBeginning: instance.isBeginning, isEnd: instance.isEnd });
        }}
      >
        {slides?.map((slide, index) => (
          <SwiperSlide key={index} className={cx(styles.slide, slideClassName)}>
            {slide}
          </SwiperSlide>
        ))}
        {hasEmptySlide && (
          <SwiperSlide key="last-empty-block" className={cx(styles.slide, slideClassName)} />
        )}

        {!isMobile && (
          <>
            <Button
              iconOnly
              icon={<ArrowLeft />}
              slot="container-start"
              className={cx(styles.previous, { [styles.hide]: swiperState?.isBeginning })}
              onClick={() => swiper?.slidePrev()}
              theme={theme}
              navButton
              style={
                {
                  "--bg-color": backgroundRgbColor,
                } as React.CSSProperties
              }
            />
            <Button
              iconOnly
              icon={<ArrowRight />}
              className={cx(styles.next, { [styles.hide]: swiperState?.isEnd })}
              slot="container-end"
              onClick={() => swiper?.slideNext()}
              theme={theme}
              navButton
              style={
                {
                  "--bg-color": backgroundRgbColor,
                } as React.CSSProperties
              }
            />
          </>
        )}
      </Swiper>
    </div>
  );
};

export { Carousel };
