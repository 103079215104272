import { type DateTime } from "luxon";

export const findClosestFutureDate = (base: DateTime, dates: DateTime[]) => {
  const diffMatrix = dates
    .map((date, dateIndex) => [date.valueOf() - base.valueOf(), dateIndex] as [number, number])
    .filter(([diff]) => diff >= 0)
    .sort(([a], [b]) => a - b);

  return dates[diffMatrix[0]?.[1]];
};
