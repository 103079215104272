import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgSearch = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M9.547 4C6.497 4 4 6.49 4 9.542c0 3.05 2.496 5.547 5.547 5.547 1.173 0 2.26-.376 3.155-1l1.474 1.62a.973.973 0 001.387 0 .976.976 0 000-1.38l-1.474-1.627c.63-.901 1-1.987 1-3.16C15.089 6.49 12.593 4 9.547 4zm0 1.953a3.572 3.572 0 013.583 3.589 3.572 3.572 0 01-3.583 3.588A3.574 3.574 0 015.96 9.542a3.573 3.573 0 013.588-3.589z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { SvgSearch };
