const addUser =
  '<path d="M9,31v-5l10-4v-4c0,0-3,0-3-4c0,0-1,0-1-2s1-2,1-2s-1-3-1-5s4-2,4-2s2-2,7-2s4,6,4,7s-1,2-1,2s1,0,1,2s-1,2-1,2 c0,4-3,4-3,4v4l5,2v7H9z"/><line x1="1" y1="14" x2="13" y2="14"/><line x1="7" y1="20" x2="7" y2="8"/>';
const letterNo =
  '<path d="M3.15 22.85V7.15h26.7v9.386l-6.66 6.314H3.15z"/><circle cx="26.5" cy="20.5" r="4.5"/><path d="M3.5 7.5l13 7.5M29.5 7.5l-13 7.5"/>';
const letter =
  '<path d="M3.15 22.85V7.15h26.7v15.7H3.15z"/><path d="M3.5 7.5l13 7.5M29.5 7.5l-13 7.5"/>';
const arobase =
  '<circle cx="16" cy="16" r="5.5"/><path d="M16,31.5C7.4,31.5,0.5,24.6,0.5,16S7.4,0.5,16,0.5S31.5,7.4,31.5,16c0,3-2.2,5.5-5,5.5s-5-2.5-5-5.5"/>';
const calendar =
  '<rect y="10" width="32" height="22"/><polyline points="26,3 32,3 32,10"/><line x1="11" y1="3" x2="21" y2="3"/><polyline points="0,10 0,3 6,3"/><rect x="6" width="5" height="6"/><rect x="21" width="5" height="6"/><polyline points="9,18 12,15 12,26"/><line x1="9" y1="26" x2="15" y2="26"/><polyline points="17,17 17,15 23,15 19,26"/><line x1="18" y1="21" x2="23" y2="21"/>';
const chart = '<polyline points="1,1 1,31 31,31"/><polyline points="3,24 12,14 22,16 30,5"/>';
const check = '<polyline points="1,15 11,25 31,6"/>';
const chevronBottom = '<polyline points="1,10 16,24 31,10"/>';
const chevronLeft = '<polyline points="23,1 9,16 23,31"/>';
const chevronRight = '<polyline points="9,31 23,16 9,1"/>';
const chevronTop = '<polyline points="31,24 16,10 1,24"/>';
const collapseLeft =
  '<line x1="1" y1="31" x2="1" y2="1"/><line x1="31" y1="16" x2="1" y2="16"/><line x1="1" y1="16" x2="12" y2="5"/><line x1="1" y1="16" x2="12" y2="27"/>';
const collapseRight =
  '<line x1="31" y1="1" x2="31" y2="31"/><line x1="1" y1="16" x2="31" y2="16"/><line x1="31" y1="16" x2="20" y2="27"/><line x1="31" y1="16" x2="20" y2="5"/>';
const clipboard =
  '<path d="M22,4h3c2,0,3,1,3,3v21c0,2-1,3-3,3H7c-2,0-3-1-3-3V7c0-2,1-3,3-3h3"/><path d="M11,1h10c1,0,1,0,1,1v4c0,1,0,1-1,1H11c-1,0-1,0-1-1V2C10,1,10,1,11,1z"/>';
const company =
  '<polyline points="1,31 1,16 10,16"/><polyline points="10,31 10,9 22,9 22,31"/><line x1="13" y1="12" x2="13" y2="31"/><line x1="16" y1="12" x2="16" y2="31"/><line x1="19" y1="12" x2="19" y2="31"/><polyline points="10,9 16,4 22,9"/><line x1="16" y1="4" x2="16" y2="1"/><polyline points="22,12 31,12 31,31"/><line x1="25" y1="15" x2="25" y2="19"/><line x1="25" y1="22" x2="25" y2="26"/><path d="M23,27.6"/><line x1="25" y1="29" x2="25" y2="31"/><line x1="28" y1="29" x2="28" y2="31"/><line x1="28" y1="22" x2="28" y2="26"/><line x1="28" y1="15" x2="28" y2="19"/><line x1="4" y1="19" x2="4" y2="21"/><line x1="4" y1="24" x2="4" y2="26"/><line x1="4" y1="29" x2="4" y2="31"/><line x1="7" y1="29" x2="7" y2="31"/><line x1="7" y1="24" x2="7" y2="26"/><line x1="7" y1="19" x2="7" y2="21"/>';
const cross = '<line x1="1" y1="1" x2="31" y2="31"/><line x1="1" y1="31" x2="31" y2="1"/>';
const date =
  '<polygon points="1,5 1,29 3,31 29,31 31,29 31,5"/><rect x="16.8" y="18" width="8.2" height="7"/><rect x="4" y="1" width="8.2" height="4"/><rect x="19.8" y="1" width="8.2" height="4"/><line x1="1" y1="11" x2="31" y2="11"/>';
const doubleChevron = '<path d="M5 11L15 1l10 10M25 21L15 31 5 21"/>';
const download =
  '<polyline points="1,26 1,31 31,31 31,26"/><line x1="16" y1="2" x2="16" y2="26"/><polyline points="9,19.5 16,26.5 23.5,19"/>';
const edit =
  '<polygon points="1,31 5,22 10,27"/><polyline points="10,27 28,9 23,4 5,22"/><path d="M23,4c0,0,4-5,7-2s-2,7-2,7"/>';
const equalizer =
  '<line x1="11" y1="5" x2="29" y2="5"/><line x1 = "3" y1 = "15" x2 = "22" y2 = "15"/><line x1 = "3" y1 = "25" x2 = "14" y2 = "25"/><line x1 = "20" y1 = "25" x2 = "30" y2 = "25"/><line x1 = "28" y1 = "15" x2 = "30" y2 = "15"/><line x1 = "3" y1 = "5" x2 = "5" y2 = "5"/><circle class="st1" cx = "8" cy = "5" r = "3"/><circle class="st1" cx = "25" cy = "15" r = "3"/><circle class="st1" cx = "17" cy = "25" r = "3"/>';
const euro =
  '<path d="M29.6,26.6c-5.9,5.9-15.4,5.9-21.2,0s-5.9-15.4,0-21.2s15.4-5.9,21.2,0"/><line x1="2" y1="12" x2="22" y2="12"/><line x1="2" y1="20" x2="22" y2="20"/>';
const filter = '<polygon points="1,1 31,1 21,16 21,26 12,31 12,16 "/>';
const forward =
  '<polyline points="25,25 25,32 0,32 0,3 11,3"/><path d="M6,24h1c0-8,9.1-9,15-9v5l10-10L22,0v6C7,6,6,14,6,24z"/>';
const heart =
  '<path d="M23,1c-4,0-7,4-7,4s-3-4-7-4S0,6,0,11c0,11,16,20,16,20s16-9,16-20C32,6,27,1,23,1L23,1z"/>';
const hourglass =
  '<polyline points="8,1 8,8 24,24 24,31"/><polyline points="24,1 24,8 8,24 8,31"/><line x1="5" y1="1" x2="27" y2="1"/><line x1="5" y1="31" x2="27" y2="31"/>';
const key =
  '<circle cx="8" cy="8" r="3"/><path d="M19.8,15.8c0.8-1.4,1.2-3.1,1.2-4.8c0-5.5-4.5-10-10-10S1,5.5,1,11c0,5.5,4.5,10,10,10c1.7,0,3.4-0.4,4.8-1.2 L20,24l0,2h2v2h2v2h2l1,1h4v-4L19.8,15.8z"/>';
const link =
  '<polyline points="16,12 15,11 25,1 31,7 21,17 20,16"/><polyline points="16,20 17,21 7,31 1,25 11,15 12,16"/><line x1="11" y1="21" x2="21" y2="11"/>';
const linkedin =
  '<rect y="10" width="8" height="22"/><path d="M4,8C2,8,0,6,0,4s2-4,4-4s4,2,4,4C8,6,6,8,4,8z"/><path d="M32,32h-7V20c0-1,0-5-3-5s-4,3-4,5v12h-7V10h7v3c0,0,3-4,7-4s7,4,7,8V32z"/>';
const list =
  '<path d="M7,16c0,2-1,3-3,3s-3-1-3-3s1-3,3-3S7,14,7,16z"/><path d="M7,27c0,2-1,3-3,3s-3-1-3-3s1-3,3-3S7,25,7,27z"/><path d="M7,5c0,2-1,3-3,3S1,7,1,5s1-3,3-3S7,3,7,5z"/><line x1="11" y1="5" x2="31" y2="5"/><line x1="11" y1="16" x2="31" y2="16"/><line x1="11" y1="27" x2="31" y2="27"/>';
const login =
  '<polyline points="23,28 23,31 4,31 4,1 23,1 23,4"/><polyline points="19,10 13,16 29,16"/><line x1="19" y1="22" x2="13" y2="16"/>';
const logout =
  '<polyline points="23,28 23,31 4,31 4,1 23,1 23,4"/><polyline points="23,22 29,16 13,16"/><line x1="23" y1="10" x2="29" y2="16"/>';
const magnifyingGlass =
  '<circle cx="12" cy="12" r="12"/><path d="M12,4c4.4,0,8,3.6,8,8"/><line x1="32" y1="32" x2="23" y2="23"/>';
const mapPin =
  '<path d="M16,0C10,0,5,5,5,11c0,10,11,21,11,21s11-11,11-21C27,5,22,0,16,0z M16,16c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5 S18.8,16,16,16z"/>';
const menu =
  '<line x1="1" y1="6" x2="31" y2="6"/><line x1="1" y1="16" x2="31" y2="16"/><line x1="1" y1="26" x2="31" y2="26"/>';
const minus = '<line x1="1" y1="16" x2="31" y2="16"/>';
const numbers =
  '<polyline points="1,7 3,7 3,27"/><polyline points="12,7 18,7 18,17 12,17 12,27 18,27"/><polyline points="25,7 31,7 31,27 25,27"/><line x1="27" y1="17" x2="31" y2="17"/>';
const paragraph =
  '<path d="M31,1H8c0,0-7,0-7,8s7,8,7,8h6"/><line x1="14" y1="31" x2="14" y2="1"/><line x1="24" y1="31" x2="24" y2="1"/>';
const pdfDoc =
  '<polygon points="3,0 3,32 29,32 29,8 22,0"/><path d="M7,28V16c0,0,4,0,4,4s-4,4-4,4"/><path d="M14,16v12c0,0,5,0,5-6C19,16,14,16,14,16z"/><polyline points="27,16 22,16 22,28"/><line x1="25" y1="21" x2="22" y2="21"/><polyline points="22,0 22,8 29,8"/>';
const phone =
  '<path d="M10,22c8,8,16,10,17,9l3-3c2-2,1-4,0-5l-4-4c-1-1-3-1-4,0l-2,2l-9-9l2-2c1-1,1-3,0-4L9,2C8,1,6,0,4,2L1,5 C0,6.1,2,14,10,22z"/>';
const plus = '<line x1="1" y1="16" x2="31" y2="16"/><line x1="16" y1="1" x2="16" y2="31"/>';
const position =
  '<rect x="1" y="14" width="30" height="16"/><polyline points="12,9 1,9 1,14"/><polyline points="20,9 31,9 31,14"/><path d="M12,11h8V4c0-4-8-4-8,0V11z"/><circle cx="16" cy="5" r="1"/><circle cx="8" cy="19" r="3"/><path d="M13,27H3c0-2.8,2.2-5,5-5S13,24.2,13,27z"/><line x1="17" y1="17" x2="23" y2="17"/><line x1="17" y1="20" x2="28" y2="20"/><line x1="17" y1="23" x2="28" y2="23"/><line x1="17" y1="26" x2="28" y2="26"/>';
const reverso =
  '<polyline points="8,2 1,9 8,16 "/><line x1="1" y1="9" x2="31" y2="9"/><polyline points="24,30 31,23 24,16 "/><line x1="1" y1="23" x2="31" y2="23"/>';
const save =
  '<polygon points="1,1 1,31 31,31 31,8 25,1"/><polyline points="9,1 9,8 23,8 23,1"/><rect x="6" y="17" width="20" height="9"/>';
const share =
  '<circle cx="6" cy="16" r="5"/><circle cx="26" cy="6" r="5"/><circle cx="26" cy="26" r="5"/><line x1="21" y1="7" x2="10" y2="13"/><line x1="10" y1="19" x2="21" y2="25"/>';
const text =
  '<polyline points="1,6 1,1 31,1 31,6"/><line x1="16" y1="1" x2="16" y2="31"/><line x1="12" y1="31" x2="20" y2="31"/>';
const trainee =
  '<path d="M21,21c5,0,10,10,10,10H1c0,0,3.9-9.9,10-10"/><polyline points="23,22 16,29 8.8,21.8"/><polyline points="4,5 4,10 1,17 7,17 4,10"/><polygon points="1,4 16,1 31,4 16,7"/><path d="M9,6v5c0,0-1,1-1,2s1,2,1,2s1,9,7,9s7-9,7-9s1-1,1-2s-1-2-1-2V6"/><path d="M9,8c3,2,11,2,14,0"/><path d="M11,13c1-1,3,0,3,0"/><path d="M18,13c0,0,2-1,3,0"/>';
const trashBin =
  '<polyline points="7,8 7,32 25,32 25,8"/><rect x="4" y="4" width="24" height="4"/><polyline points="12,4 12,1 20,1 20,4"/><line x1="11" y1="12" x2="11" y2="28"/><line x1="16" y1="12" x2="16" y2="28"/><line x1="21" y1="12" x2="21" y2="28"/>';
const user =
  '<path d="M2,31v-5l10-4v-4c0,0-3,0-3-4c0,0-1,0-1-2s1-2,1-2S8,7,8,5s4-2,4-2s2-2,7-2s4,6,4,7s-1,2-1,2s1,0,1,2s-1,2-1,2 c0,4-3,4-3,4v4l11,4v5H2z"/>';
const users =
  '<path d="M1,26.4l9.3-3.7V19c0,0-3,0.1-3-3.5c0,0-0.9,0-0.9-1.9s0.9-1.9,0.9-1.9S6.4,8.8,6.4,6.9C6.4,5,10,5,10,5 s1.9-1.9,6.4-1.9S20,8.7,20,9.5s-0.9,1.9-0.9,1.9s0.9,0,0.9,1.9s-0.9,1.9-0.9,1.9c0,3.6-2.4,3.7-2.4,3.7v3.7l9.3,3.7V31H1V26.4z"/> <path d="M18,2c0-1.1,1.3-0.9,3.4-0.9c4.5,0,3.6,5.5,3.6,6.4s-0.9,1.9-0.9,1.9s0.9,0,0.9,1.9s-0.9,1.9-0.9,1.9 c0,3.6-2.4,3.7-2.4,3.7v3.7l9.3,3.7V29h-3"/>';
const warning =
  '<polygon points="16,1 31,31 1,31"/><line x1="16" y1="8" x2="16" y2="23"/><circle cx="16" cy="27" r="2"/>';
const userPortal =
  '<path d="M9,8l9-6c0,0,13,10,13,10"/><path d="M28,13v15H17"/><path d="M1,31v-4l6-2v-3c0,0-2,0-2-3c0,0-1,0-1-1s1-1,1-1s-1-1-1-2c0-2-1-5,2-5c4,0,6,1,6,1s3,0,3,2s-1,4-1,4s1,0,1,1 s-1,1-1,1c0,3-2,3-2,3v3l3,1v5"/>';
const arobaseNo =
  '<circle cx="16" cy="16" r="5.5"/><path d="M16,31.5C7.4,31.5,0.5,24.6,0.5,16S7.4,0.5,16,0.5S31.5,7.4,31.5,16c0,3-2.2,5.5-5,5.5s-5-2.5-5-5.5"/><circle cx=16 cy=16 r=22.5 stroke="#DE4343" stroke-width="1.4"/><path stroke-width="1.4" stroke="#DE4343" d="M0.0900974233 0.0900974233 L31.9099025767 31.9099025767" />';

export {
  addUser,
  arobase,
  arobaseNo,
  calendar,
  chart,
  check,
  chevronBottom,
  chevronLeft,
  chevronRight,
  chevronTop,
  clipboard,
  collapseLeft,
  collapseRight,
  company,
  cross,
  date,
  doubleChevron,
  download,
  edit,
  equalizer,
  euro,
  filter,
  forward,
  heart,
  hourglass,
  key,
  letter,
  letterNo,
  link,
  linkedin,
  list,
  login,
  logout,
  magnifyingGlass,
  mapPin,
  menu,
  minus,
  numbers,
  paragraph,
  pdfDoc,
  phone,
  plus,
  position,
  reverso,
  save,
  share,
  text,
  trainee,
  trashBin,
  user,
  users,
  userPortal,
  warning,
};
