import React, { useMemo } from "react";
import cx from "classnames";

import { format } from "@skillup/shared-utils";

import styles from "./DatePicker.module.scss";

import {
  DateRangeInput,
  type Props as DateRangeInputProps,
} from "../../Legacy_components/DateRangePicker/partials/DateRangeInput";
import { type Themes } from "components/commonProps";

// DatePicker component
// TODO improve it, it currently uses DateRangeInput component but it's a bit hacky

export type DatePickerProps = Omit<DateRangeInputProps, "value" | "label"> & {
  value: Date;
  theme: Themes;
  autoFocus?: boolean;
  withIcon?: boolean;
  disabled?: boolean;
};
function DatePicker({
  value,
  className,
  classNameContainer,
  classNameCalendar,
  placeholder = "JJ/MM/AAAA",
  withIcon = true,
  disabled = false,
  ...rest
}: DatePickerProps) {
  const valueString = useMemo(() => value && format(value, "yyyy/MM/dd"), [value]);

  return (
    <DateRangeInput
      label=""
      value={valueString}
      className={cx(className, styles.DatePicker)}
      classNameContainer={cx(classNameContainer, styles.container)}
      classNameCalendar={cx(classNameCalendar, styles.calendar)}
      placeholder={placeholder}
      disabled={disabled}
      withIcon={withIcon}
      {...rest}
    />
  );
}

export { DatePicker };
