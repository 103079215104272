import React from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { RadioBox, type RadioChoice } from "../RadioBox";
import styles from "./RadioForm.module.scss";

interface Props<T = any> {
  readonly className?: string;
  readonly choices: RadioChoice<T>[];
  readonly active?: T;
  readonly onChange?: (value: string) => void;
  readonly canUncheck?: boolean;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly errored?: boolean;
  readonly horizontal?: boolean;
}

const RadioForm = ({
  className,
  choices,
  active,
  onChange,
  canUncheck,
  readOnly,
  disabled,
  horizontal,
  errored,
}: Props) => (
  <Flex
    column={!horizontal}
    className={cx(styles.RadioForm, className)}
    disabled={disabled}
    data-testid="radio-form"
  >
    {choices.map(({ label, value, help, helpText }: RadioChoice, index: number) => (
      <RadioBox
        key={index}
        className={styles.line}
        label={label}
        value={value}
        help={help}
        helpText={helpText}
        onClick={onChange}
        checked={value === active}
        canUncheck={canUncheck}
        readOnly={readOnly}
        disabled={disabled}
        horizontal={horizontal}
        errored={errored}
      />
    ))}
  </Flex>
);

export { RadioForm };
