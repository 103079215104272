import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const MoreIcon = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41667 11C6.41667 9.9875 5.59586 9.16669 4.58333 9.16669C3.57081 9.16669 2.75 9.9875 2.75 11C2.75 12.0125 3.57081 12.8334 4.58333 12.8334C5.59586 12.8334 6.41667 12.0125 6.41667 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8333 11C12.8333 9.9875 12.0125 9.16669 11 9.16669C9.98748 9.16669 9.16667 9.9875 9.16667 11C9.16667 12.0125 9.98748 12.8334 11 12.8334C12.0125 12.8334 12.8333 12.0125 12.8333 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.25 11C19.25 9.9875 18.4292 9.16669 17.4167 9.16669C16.4041 9.16669 15.5833 9.9875 15.5833 11C15.5833 12.0125 16.4041 12.8334 17.4167 12.8334C18.4292 12.8334 19.25 12.0125 19.25 11Z"
      fill={color}
    />
  </SVGBox>
);

export { MoreIcon };
