import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronDown = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M15.741 9.0119L11.2684 13.4845L6.78301 9.0119"
      stroke={color}
      strokeWidth="1.70872"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGBox>
);

export { ChevronDown };
