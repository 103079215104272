import { useAsync } from "react-use";
import { isEmpty } from "lodash";

import useAuth from "hooks/useAuth";
import request from "helpers/request";
import { OrganizationRoutes } from "api/types";

import Logo from "./Logo";
import styles from "./LoginViewStyles.module.scss";

interface Props {
  reference: string;
  strategy?: string;
}

const OrganizationCheck = ({ reference, strategy }: Props) => {
  const { setOrganizationHasBeenFetched, setAuthStrategy, setLogo } = useAuth();

  const state = useAsync(async () => {
    const organization = await request<
      OrganizationRoutes["GET"]["/v1/organization-by-reference/{reference}"]["response"]
    >({
      target: "AUTH",
      url: `/organization-by-reference/${reference}${
        isEmpty(strategy) ? "" : `?strategy=${strategy}`
      }`,
    });
    setOrganizationHasBeenFetched(true);
    setLogo(organization.data.logo);
    if (organization.data.authStrategy) {
      setAuthStrategy(organization.data.authStrategy);
    } else {
      setAuthStrategy(null);
    }
  }, [reference]);

  if (state.error) {
    setOrganizationHasBeenFetched(false);
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Erreur</h1>
          <p>Identifiant invalide, veuillez contacter votre service RH pour valider votre accès.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Veuillez patienter</h1>
        <p>Récupération des paramètres de connexion au service en cours.</p>
      </div>
    </div>
  );
};

export default OrganizationCheck;
