import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { StrictMode } from "react";
import { render } from "react-dom";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";

import "@skillup/ui/build/index.css";
import "styles/shared/index.scss";
import App from "containers/App";

const { REACT_APP_ENV: APP_ENV, REACT_APP_SENTRY_DSN_URL: SENTRY_DSN_URL } = process.env;

if (APP_ENV !== "development") {
  Sentry.init({ dsn: SENTRY_DSN_URL, environment: APP_ENV });
}

render(
  <StrictMode>
    <RecoilRoot>
      <Router>
        <App />
      </Router>
    </RecoilRoot>
  </StrictMode>,
  document.getElementById("root")
);
