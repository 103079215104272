import React from "react";

import { Time } from "../Icon";
import { RectangleTag } from "./RectangleTag";

interface Props {
  readonly numberOfDays: number;
  readonly daysForRed?: number;
}

const DaysRemaining = ({ numberOfDays, daysForRed }: Props) => (
  <RectangleTag
    label={numberOfDays > 1 ? `${numberOfDays} jours restants` : `${numberOfDays} jour restant`}
    icon={
      daysForRed !== undefined ? (
        numberOfDays < daysForRed ? (
          <Time color="#ff6760" strokeWidth="1.2" />
        ) : (
          <Time color="#ffb229" strokeWidth="1.2" />
        )
      ) : (
        <Time color="#ffb229" strokeWidth="1.2" />
      )
    }
    backgroundColor={
      daysForRed !== undefined ? (numberOfDays < daysForRed ? "#feece7" : "#fef8e7") : "#fef8e7"
    }
    textColor={
      daysForRed !== undefined ? (numberOfDays < daysForRed ? "#ff6760" : "#ffb229") : "#ffb229"
    }
  />
);

export { DaysRemaining };
