import React, { useState, useCallback, type FormEvent, useMemo, useRef, useEffect } from "react";
import { useToggle } from "react-use";
import { isEmpty } from "lodash";
import cx from "classnames";
import { Flex } from "../../components/Flex";
import * as Icon from "../Icon";
import { colors } from "../../assets/colors";
import styles from "./CatalogSearch.module.scss";
import { HorizontalDivider } from "components/Divider/HorizontalDivider";

export interface IProps {
  readonly className?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly defaultValue?: string;
  readonly withShadow?: boolean;
  readonly suggests?: Array<{ label: string; count?: number }>;
  readonly recommendedSuggests?: { label: string; count?: number };
  readonly onChange?: (value: string) => void;
  readonly onSubmit?: (value: string) => void;
  readonly onClickSuggest?: (
    searchValue: string,
    suggestLabel: string,
    hideRecommendedTrainings?: boolean,
    withoutFilterByCategory?: boolean
  ) => void;
}

const CatalogSearch = (props: IProps) => {
  const {
    className,
    disabled,
    placeholder,
    defaultValue = "",
    withShadow,
    suggests = [],
    recommendedSuggests,
    onChange,
    onSubmit,
    onClickSuggest,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>(defaultValue);
  const [isFocus, toggleFocus] = useToggle(false);
  const [isMouseOnSuggests, toggleSuggestsHover] = useToggle(false);
  const NumberOfItemsToDisplays = 17;

  useEffect(() => {
    setSearchValue(defaultValue);
  }, [defaultValue, setSearchValue]);

  const handleChangeValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [setSearchValue, onChange]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      if (onSubmit && searchValue.length > 0) {
        onSubmit(searchValue);
      }

      if (inputRef.current) {
        inputRef.current.blur();
      }
    },
    [searchValue, onSubmit, inputRef]
  );

  const handleBlur = useCallback(() => {
    if (isFocus && !isMouseOnSuggests) {
      toggleFocus(false);
    }
  }, [isMouseOnSuggests, isFocus, toggleFocus]);

  const handleSuggestClick = useCallback(
    (params: {
      suggestLabel: string;
      hideRecommendedTrainings?: boolean;
      withoutFilterByCategory?: boolean;
    }) => {
      if (onClickSuggest) {
        onClickSuggest(
          searchValue,
          params.suggestLabel,
          params.hideRecommendedTrainings,
          params.withoutFilterByCategory
        );
      }

      if (isFocus) {
        toggleFocus(false);
      }
    },
    [isFocus, toggleFocus, searchValue, onClickSuggest]
  );

  const isOpenSuggests = useMemo(() => {
    return isFocus && searchValue.length > 0 && suggests.length > 0;
  }, [isFocus, searchValue, suggests]);

  if (disabled) return null;

  const count = useMemo(() => {
    return suggests.reduce((acc, cur) => {
      return acc + (cur.count ?? 0);
    }, 0);
  }, [suggests]);

  return (
    <div className={cx(styles.CatalogSearch, className)} data-testid="main-wrapper">
      <Flex
        className={cx(styles.searchWithSuggests, {
          [styles.withShadow]: withShadow,
          [styles.isOpenSuggests]: isOpenSuggests,
        })}
        data-testid="search-container"
        column
      >
        <form className={styles.searchInputContainer} onSubmit={handleSubmit}>
          <Icon.Search size="1.25rem" color={isFocus ? colors.secondaryBlue : colors.blueyGrey} />
          <input
            ref={inputRef}
            className={styles.searchInput}
            placeholder={placeholder}
            value={searchValue}
            onChange={({ target: { value } }) => handleChangeValue(value)}
            onFocus={() => toggleFocus(true)}
            onBlur={handleBlur}
            data-testid="search-input"
          />
          <Icon.Container
            backgroundColor="transparent"
            className={styles.clearBtn}
            onClick={() => handleChangeValue("")}
            disabled={isEmpty(searchValue)}
          >
            <Icon.Cross size="1.25rem" color={colors.bwBlack} />
          </Icon.Container>
        </form>

        {recommendedSuggests && (
          <Flex
            className={styles.suggestionsBox}
            column
            onMouseEnter={toggleSuggestsHover}
            onMouseLeave={toggleSuggestsHover}
          >
            <Flex
              className={styles.suggestsTitle}
              onClick={() =>
                handleSuggestClick({
                  suggestLabel: searchValue,
                  withoutFilterByCategory: true,
                })
              }
            >
              <span className={styles.title}>{recommendedSuggests.label}</span>
              <Flex center className={styles.countContainer}>
                <span className={styles.count} data-testid="suggest-count">
                  {recommendedSuggests.count}
                </span>
              </Flex>
            </Flex>
            <HorizontalDivider top="xxs" bottom="xxs" className={styles.divider} />
          </Flex>
        )}
        <Flex
          className={styles.suggestionsBox}
          column
          onMouseEnter={toggleSuggestsHover}
          onMouseLeave={toggleSuggestsHover}
        >
          <Flex
            className={styles.suggestsTitle}
            onClick={() =>
              handleSuggestClick({
                suggestLabel: searchValue,
                hideRecommendedTrainings: true,
                withoutFilterByCategory: true,
              })
            }
          >
            <span className={styles.title}>Catalogue Skillup</span>
            <Flex className={styles.countContainer} center>
              {count && (
                <span className={styles.count} data-testid="suggest-count">
                  {count}
                </span>
              )}
            </Flex>
          </Flex>
          <Flex className={styles.suggests} data-testid="search-suggests" column wrap>
            {suggests.slice(0, NumberOfItemsToDisplays).map(({ label, count }, index) => (
              <Flex
                key={index}
                className={styles.suggest}
                data-testid="search-suggest"
                onClick={() =>
                  handleSuggestClick({ suggestLabel: label, hideRecommendedTrainings: true })
                }
              >
                <span className={styles.label} data-testid="suggest-label">
                  {label}
                </span>
                <Flex center className={styles.countContainer}>
                  <span className={styles.count} data-testid="suggest-count">
                    {count}
                  </span>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export { CatalogSearch };
