import React, { type CSSProperties } from "react";
import { type LegendProps as NLegendProps } from "@nivo/legends";

export type ILegend = Partial<NLegendProps>;

export const LegendStyle: CSSProperties = {
  fontFamily: "Noto Sans Display",
  fontSize: 14,
  fill: "#1A304D",
  dominantBaseline: "central",
  pointerEvents: "none",
  userSelect: "none",
};

export interface LegendProps
  extends Pick<ILegend, "translateX" | "translateY" | "itemWidth" | "itemHeight"> {
  legend: {
    color?: string;
    label: string | number;
  };
}

const Legend = ({
  translateX = 0,
  translateY = 0,
  itemWidth = 80,
  itemHeight = 15,
  legend,
}: LegendProps) => (
  <g transform={`translate(${translateX}, ${translateY})`}>
    <rect width={itemWidth} height={itemHeight} fill="transparent"></rect>
    <circle
      r="7"
      cx="7"
      cy="11"
      fill={legend?.color as string}
      opacity="1"
      strokeWidth="0"
      stroke="transparent"
      transform={`translate(0, -10)`}
    ></circle>
    <text
      textAnchor="left"
      dominantBaseline="central"
      style={LegendStyle}
      transform={`translate(18, 0)`}
    >{`${legend?.label ?? ""}`}</text>
  </g>
);

export { Legend };
