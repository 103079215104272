import React from "react";
import { useTranslation } from "react-i18next";

import { type GridColumnMenu, type GridColumnMenuItemProps } from "@mui/x-data-grid-pro";

import { DropdownItem } from "components/Dropdown/DropdownItem";
import { FilterAlt, FilterAltOff } from "components/MaterialIcons";

import { type OnFilterHandler } from "../../types";

export type FilterItemProps = {
  onFilter?: OnFilterHandler;
  colDef: GridColumnMenuItemProps["colDef"];
  hideMenu: Parameters<typeof GridColumnMenu>[0]["hideMenu"];
};

const FilterItem = (props: FilterItemProps) => {
  const { t } = useTranslation();
  const { hideMenu, onFilter } = props;

  const isDisabled = props.colDef.filterable === false || !onFilter;

  return (
    <DropdownItem
      disabled={isDisabled}
      index={3}
      tooltipLabel={
        isDisabled
          ? t("datagrid.columnMenu.filter.disabled", {
              defaultValue: "Filtrage non disponible pour cette colonne",
            })
          : undefined
      }
      onClick={(e) => {
        hideMenu(e);

        // Unfortunately, the menu panel keep stealing the focus
        // We need to wait for the event to finish bubbling to trigger the filter apparition/focus
        // Here we add the code to the end of the event loop
        Promise.resolve().then(() => {
          onFilter?.(props.colDef);
        });
      }}
      icon={isDisabled ? <FilterAltOff /> : <FilterAlt />}
      label={t("datagrid.columnMenu.filter", {
        defaultValue: "Filtrer",
      })}
    />
  );
};

export { FilterItem };
