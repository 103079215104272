import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgCalendar = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.872 6.84654C10.872 6.59993 11.0719 6.40002 11.3186 6.40002C11.5652 6.40002 11.7651 6.59993 11.7651 6.84654V10.6419C11.7651 10.8885 11.5652 11.0884 11.3186 11.0884C11.0719 11.0884 10.872 10.8885 10.872 10.6419V6.84654ZM20.249 6.84654C20.249 6.59993 20.4489 6.40002 20.6955 6.40002C20.9421 6.40002 21.142 6.59993 21.142 6.84654V10.6419C21.142 10.8885 20.9421 11.0884 20.6955 11.0884C20.4489 11.0884 20.249 10.8885 20.249 10.6419V6.84654ZM8.19306 7.73956C7.20051 7.73956 6.40002 8.54004 6.40002 9.53259V13.5442H25.6V9.53259C25.6 8.54004 24.7995 7.73956 23.807 7.73956H22.4744H21.5884V9.64422C21.8622 9.88948 22.0349 10.2453 22.0349 10.6419C22.0349 11.3817 21.4352 11.9814 20.6954 11.9814C19.9556 11.9814 19.3558 11.3817 19.3558 10.6419C19.3558 10.2453 19.5285 9.88948 19.8024 9.64422V7.73956H12.2047V9.63724C12.4828 9.88269 12.6582 10.2418 12.6582 10.6419C12.6582 11.3817 12.0584 11.9814 11.3186 11.9814C10.5788 11.9814 9.97911 11.3817 9.97911 10.6419C9.97911 10.2489 10.149 9.89623 10.4186 9.65119V7.73956H8.19306ZM6.40002 23.3604V14.4372L25.6 14.4372V23.3604C25.6 24.353 24.7995 25.1535 23.807 25.1535H8.19306C7.20049 25.1535 6.40002 24.353 6.40002 23.3604Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgCalendar };
