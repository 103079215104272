import React, { useEffect, useState } from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";

import styles from "./ToggleButtonGroup.module.scss";
import { Tooltip, type TooltipProps } from "components/Tooltip/Tooltip";

export interface ToggleButtonGroupProps {
  readonly options?: {
    label?: string;
    icon?: JSX.Element;
    value: string;
  }[];
  readonly value?: string;
  readonly onChange?: (value: string) => void;
  readonly disabled?: boolean;
  readonly tooltipDirection?: TooltipProps["direction"];

  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly className?: string;
}

const ToggleButtonGroup = ({
  options,
  value,
  onChange,
  disabled,
  tooltipDirection,
  darkMode = false,
  theme,
  className,
}: ToggleButtonGroupProps) => {
  const [position, setPosition] = useState<{
    top?: number;
    left?: number;
    width?: number;
  } | null>();

  const updateActiveButtonPosition = () => {
    const activeButton: HTMLButtonElement | null = document.querySelector(
      `.${styles.ToggleButtonGroup} button.${styles.active}`
    );
    const position = activeButton?.getBoundingClientRect();
    setPosition({
      top: activeButton?.offsetTop,
      width: position?.width,
      left: activeButton?.offsetLeft,
    });
  };

  const updateButtonsWidth = () => {
    const buttons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
      `.${styles.ToggleButtonGroup} button`
    );
    buttons?.forEach((button) => {
      button.style.width = `${Math.ceil(button.getBoundingClientRect().width)}px`;
    });
  };

  useEffect(() => {
    updateActiveButtonPosition();
    updateButtonsWidth();

    window.addEventListener("navigationStateChanged", updateActiveButtonPosition);
    window.addEventListener("resize", updateActiveButtonPosition);

    return () => {
      window.removeEventListener("navigationStateChanged", updateActiveButtonPosition);
      window.removeEventListener("resize", updateActiveButtonPosition);
    };
  }, [value, options]);

  return (
    <div
      aria-label={`buttongroup`}
      className={cx(styles.ToggleButtonGroup, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
    >
      <span
        className={cx(styles.indicator, { [styles.darkMode]: darkMode })}
        style={{ top: position?.top, left: position?.left, width: position?.width }}
      />
      {options?.map((opt) => (
        <button
          className={cx(styles.button, {
            [styles.icon]: !!opt.icon,
            [styles.active]: value === opt.value,
            [styles.darkMode]: darkMode,
          })}
          disabled={disabled}
          onClick={() => onChange?.(opt.value)}
          key={opt.label}
        >
          {!opt.icon && opt.label}
          {opt.icon && (
            <Tooltip
              className={styles.tooltip}
              label={opt.label}
              direction={tooltipDirection}
              theme={theme}
              darkMode={darkMode}
            >
              {opt.icon}
            </Tooltip>
          )}
        </button>
      ))}
    </div>
  );
};

export { ToggleButtonGroup };
