export function partitionArray<A, B>(
  arr: Array<A>,
  partitionner: (v: A) => B
): Array<{ key: B; value: Array<A> }> {
  const mutAccMap = new Map<string, { key: B; value: Array<A> }>();

  arr.forEach((e) => {
    const key = partitionner(e);
    const keyString = JSON.stringify(key);
    const maybeValues = mutAccMap.get(keyString);

    mutAccMap.set(
      keyString,
      !maybeValues ? { key: key, value: [e] } : { ...maybeValues, value: [...maybeValues.value, e] }
    );
  });

  return [...mutAccMap.values()];
}
