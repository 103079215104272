import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Upload = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 74 74" fill="none" {...props}>
    <path
      d="M57.5458 28.8352C54.6957 17.4813 43.1813 10.5877 31.8275 13.4378C22.9547 15.6651 16.5121 23.3337 15.8483 32.4576C9.54824 33.4965 5.28323 39.4459 6.32217 45.7459C7.24575 51.3467 12.0988 55.4484 17.7751 55.4259H27.4095V51.5721H17.7751C13.5184 51.5721 10.0677 48.1214 10.0677 43.8647C10.0677 39.608 13.5184 36.1572 17.7751 36.1572C18.8394 36.1572 19.702 35.2945 19.702 34.2303C19.6924 24.6526 27.4489 16.8806 37.0265 16.8711C45.3174 16.8628 52.4544 22.7241 54.0581 30.8583C54.2165 31.6704 54.8756 32.2909 55.696 32.3998C60.9638 33.1499 64.626 38.0284 63.8759 43.2961C63.2024 48.0263 59.1636 51.5483 54.3857 51.5721H46.6782V55.4259H54.3857C61.835 55.4034 67.8555 49.3462 67.8329 41.897C67.8141 35.696 63.5697 30.3067 57.5458 28.8352Z"
      fill={color}
    />
    <path
      d="M35.6759 36.7156L27.9684 44.4231L30.6853 47.14L35.1171 42.7275V61.2061H38.9708V42.7275L43.3834 47.14L46.1003 44.4231L38.3928 36.7156C37.6412 35.9685 36.4275 35.9685 35.6759 36.7156Z"
      fill={color}
    />
  </SVGBox>
);

export { Upload };
