import React from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Tag.module.scss";

interface Props {
  readonly className?: string;
  readonly label: string;
  readonly color: string;
  readonly disabled?: boolean;
}

const Tag = ({ className, label, color, disabled }: Props) => (
  <Flex
    className={cx(styles.Tag, className)}
    style={{ backgroundColor: `${color}` }}
    disabled={disabled}
    data-testid="tag-container"
    center
  >
    <span className={styles.label} data-testid="tag-label">
      {label}
    </span>
  </Flex>
);

export { Tag };
