import React, { useContext } from "react";

export const RadioGroupContext = React.createContext<string>("");

export const useRadioGroupContext = () => {
  return useContext(RadioGroupContext);
};

const RadioGroup = ({ name, children }: { name: string; children: React.ReactNode }) => {
  return <RadioGroupContext.Provider value={name}>{children}</RadioGroupContext.Provider>;
};

export { RadioGroup };
