import React, { type HTMLAttributes, type ReactElement } from "react";
import cx from "classnames";

import spacing from "../../atoms/SpacingTokens/SpacingExport.module.scss";
import colors from "../../atoms/Colors/ColorsExport.module.scss";

import MuiTimeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import styles from "./Timeline.module.scss";

import { type Themes } from "../commonProps";

export interface TimelineProps extends HTMLAttributes<HTMLDivElement> {
  readonly size?: "M" | "S";
  readonly items: {
    timePeriod: string | ReactElement;
    title?: string | ReactElement;
    subTitle?: string | ReactElement;
    content: string | ReactElement;
  }[];

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const Timeline = ({ items, size = "M", darkMode = false, theme, className }: TimelineProps) => {
  return (
    <MuiTimeline
      className={cx(styles.Timeline, styles[theme], className, { [styles.darkMode]: darkMode })}
    >
      {items?.map((item, index) => (
        <TimelineItem sx={{ minHeight: 0, "::before": { flexGrow: 0 } }}>
          {size === "M" && (
            <TimelineOppositeContent
              sx={{
                padding: 0,
                marginRight: spacing.skuiSpacingS,
                flexGrow: 0,
                minWidth: "4rem",
                maxWidth: "4rem",
              }}
              className={styles.timePeriod}
            >
              {item.timePeriod}
            </TimelineOppositeContent>
          )}
          <TimelineSeparator>
            <TimelineConnector
              sx={{
                height: size === "S" ? spacing.skuiSpacingXS : spacing.skuiSpacingS,
                backgroundColor: index === 0 ? "transparent" : colors["greyscale-dark"],
              }}
            />
            <TimelineDot
              sx={{
                margin: 0,
                backgroundColor: colors["greyscale-dark"],
                padding: size === "S" ? "2px" : "4px",
                boxShadow: "none",
              }}
            />
            {
              <TimelineConnector
                sx={{
                  height: "100%",
                  backgroundColor:
                    index === items.length - 1 ? "transparent" : colors["greyscale-dark"],
                }}
              />
            }
          </TimelineSeparator>
          <TimelineContent
            classes={{
              positionLeft: cx(styles.TimelineRoot, styles[theme]),
              positionRight: cx(styles.TimelineRoot, styles[theme]),
            }}
            sx={{
              paddingTop: 0,
              paddingLeft: size === "S" ? spacing.skuiSpacingXS : spacing.skuiSpacingS,
              paddingBottom: size === "S" ? spacing.skuiSpacingXS : spacing.skuiSpacingL,
            }}
            className={styles.content}
          >
            {size === "M" && (
              <>
                {item.title && <h3>{item.title}</h3>}
                {item.subTitle && (
                  <h6 className={cx({ [styles.noTitle]: !item.title })}>{item.subTitle}</h6>
                )}
              </>
            )}
            {size === "S" && <h3 className={styles.S}>{item.timePeriod}</h3>}
            <div className={styles[size]}>{item.content}</div>
          </TimelineContent>
        </TimelineItem>
      ))}
    </MuiTimeline>
  );
};

export { Timeline };
