import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronRight = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M9.02563 6.76923L13.4982 11.2418L9.02563 15.7272"
      stroke={color}
      strokeWidth="1.70872"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGBox>
);

export { ChevronRight };
