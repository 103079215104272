import React from "react";
import cx from "classnames";

import { useMediaQueries } from "../../../hooks/useMediaQueries";

import styles from "../DSModal.module.scss";
import { DSModal } from "..";

import { type ModalContentColumns } from "./ModalContentColumns";

export function ModalContent({
  children,
  debug,
  backgroundColor,
  borderColor,
  className,
}: ModalContentProps) {
  return (
    <DSModal.ScrollableLayer
      debug={debug}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      className={className}
    >
      {children}
    </DSModal.ScrollableLayer>
  );
}

export function DialogContent({ children, debug }: ModalContentProps) {
  const { isMobile } = useMediaQueries();

  return (
    <div
      className={cx(styles.content, styles.dialog, { [styles.isMobile]: isMobile })}
      id="modal-content"
    >
      {debug && <div className={styles.debug}>{children}</div>}
      {!debug && children}
    </div>
  );
}

export type ModalContentProps = {
  children: React.ReactNode;
  debug?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  className?: string;
};

export type ModalContentType = React.ReactElement<
  typeof ModalContent | typeof DialogContent | typeof ModalContentColumns
>;
