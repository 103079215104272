import { useEffect, useState } from "react";

// https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
export function useIsStickyPinned(htmlElement: HTMLElement | null | undefined) {
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    if (!window.IntersectionObserver || !htmlElement) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsPinned(e.intersectionRatio < 1);
      },
      { root: null, rootMargin: "0px", threshold: 1.0 }
    );
    observer.observe(htmlElement);
    return () => {
      observer.unobserve(htmlElement);
    };
  }, [htmlElement]);

  return isPinned;
}
