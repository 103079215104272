import { useEffect, useState } from "react";

const currentModalStore: {
  value?: string;
  listeners: Array<(value: string | undefined) => void>;
} = {
  listeners: [],
};

export const useModalContext = () => {
  const [currentModal, setCurrentValue] = useState(currentModalStore.value);

  useEffect(() => {
    currentModalStore.listeners.push(setCurrentValue);
    return () => {
      currentModalStore.listeners = currentModalStore.listeners.filter(
        (listener) => listener !== setCurrentValue
      );
    };
  });

  function setCurrentModal(id: string | undefined) {
    for (const listener of currentModalStore.listeners) {
      listener(id);
    }
  }

  function removeCurrentModal() {
    setCurrentModal(undefined);
  }

  return {
    currentModal,
    setCurrentModal,
    removeCurrentModal,
  };
};
