import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Categories = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <rect x="3" y="4" width="3.99998" height="3.99998" rx="1.14285" fill={color} />
    <rect x="3" y="9.33334" width="3.99998" height="3.99998" rx="1.14285" fill={color} />
    <rect x="3" y="14.667" width="3.99998" height="3.99998" rx="1.14285" fill={color} />
    <rect x="8.3335" y="4.00021" width="10.6666" height="3.99998" rx="1.14285" fill={color} />
    <rect x="8.3335" y="9.33334" width="10.6666" height="3.99998" rx="1.14285" fill={color} />
    <rect x="8.3335" y="14.667" width="10.6666" height="3.99998" rx="1.14285" fill={color} />
  </SVGBox>
);

export { Categories };
