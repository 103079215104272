import React, { type ReactNode, useContext, useEffect, useState, useMemo } from "react";
import cx from "classnames";

import { Done } from "components/MaterialIcons";

import styles from "./Dropdown.module.scss";
import { DropdownContext } from "./DropdownContext";
import { Tooltip } from "components/Tooltip";
import { type TooltipProps } from "components/Tooltip/Tooltip";

export interface IItemProps {
  readonly label: string | ReactNode;
  readonly disabled?: boolean;
  readonly index?: number;
  readonly selectable?: boolean;
  readonly selected?: boolean;
  readonly icon?: ReactNode;
  readonly tooltipLabel?: TooltipProps["label"];
  readonly tooltipDirection?: TooltipProps["direction"];
  readonly asyncAction?: () => Promise<any>;
}

export function DropdownAsyncItem({
  label,
  disabled,
  selectable,
  selected,
  icon,
  tooltipLabel,
  tooltipDirection,
  asyncAction,
  index,
}: IItemProps) {
  const { popoverActions, setActiveIndex, activeIndex, isLoading, setIsLoading } =
    useContext(DropdownContext);

  const [hasAsyncClicked, setHasAsyncClicked] = useState(false);
  async function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled && asyncAction) {
      if (index !== undefined) setActiveIndex(index);
      setIsLoading(true);

      setHasAsyncClicked(true);
      await asyncAction();

      setActiveIndex(-1);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (asyncAction && hasAsyncClicked && !isLoading) {
      popoverActions?.close();
    }
  }, [asyncAction, hasAsyncClicked, isLoading, popoverActions]);

  const isActiveElement = useMemo(() => activeIndex === index, [activeIndex, index]);

  return (
    <Tooltip
      data-testid="dropdown-async-item"
      className={cx(styles.DropdownItem, {
        [styles.focus]: isActiveElement,
        [styles.disabled]: disabled || (isActiveElement && isLoading),
      })}
      label={tooltipLabel}
      direction={tooltipDirection}
      onClick={handleClick}
    >
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          {selectable && <span className={styles.icon}>{selected && <Done />}</span>}
          {!selectable && icon && <span className={styles.icon}>{icon}</span>}
          <div className={styles.label}>{label}</div>
        </div>
        {isActiveElement && isLoading && <div className={styles.loading}></div>}
      </div>
    </Tooltip>
  );
}
DropdownAsyncItem.className = "DropdownAsyncItem";
