import React from "react";

import { Users } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const ManagerOnlyCampaign = () => (
  <RectangleTag
    label="Géré par le responsable"
    icon={<Users />}
    backgroundColor="#EE82EE"
    textColor="#000000"
  />
);

export { ManagerOnlyCampaign };
