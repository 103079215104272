import React, { useMemo, type CSSProperties } from "react";
import type { Completion, TargetItem, OrdinalTarget, NumericTarget } from "@skillup/types";
import cx from "classnames";

import { Flex } from "../../components/Flex";

import styles from "./TargetEvaluation.module.scss";

interface Props {
  readonly style?: CSSProperties;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
  readonly active?: boolean;
  readonly withEmoji?: boolean;
  readonly completion?: Completion;
  readonly scale: TargetItem["scale"];
  readonly choices?: OrdinalTarget["choices"];
  readonly unit?: NumericTarget["unit"];
  readonly small?: boolean;
}

const TargetEvaluation = ({
  style,
  className,
  disabled = false,
  onClick,
  active = false,
  completion = { max: 5, value: 0 },
  scale,
  choices = [],
  withEmoji = false,
  unit,
  small = false,
}: Props) => {
  const { max, value } = completion;

  const emojis = useMemo(() => {
    return scale === "numeric" ? ["😞", "😕", "😐", "😀", "👏"] : ["😞", "😕", "😀", "👏"];
  }, []);

  const chunkIndex: number = useMemo(() => {
    const maxChunkIndex = scale === "numeric" ? 5 : 4;
    const maxChunkSize = max / maxChunkIndex;

    for (let index = 0; index < maxChunkIndex; index++) {
      if (value <= maxChunkSize * (index + 1)) {
        return index;
      }
    }

    return maxChunkIndex - 1;
  }, [completion, scale]);

  const label = useMemo(() => {
    return scale === "ordinal" ? choices?.[chunkIndex] : `${value} ${unit}`;
  }, [scale, choices, chunkIndex, value, unit]);

  return (
    <Flex
      style={style}
      className={cx(
        styles.TargetEvaluation,
        className,
        styles[scale],
        styles[`chunk-${chunkIndex}`],
        {
          [styles.active]: active,
          [styles.small]: small,
        }
      )}
      onClick={onClick}
      disabled={disabled}
      role="button"
      center
    >
      {label && (
        <span className={cx(styles.label, { [styles.withEmoji]: withEmoji })}>{label}</span>
      )}
      {withEmoji && (
        <span role="img" aria-label="feeling" className={styles.emoji}>
          {emojis[chunkIndex]}
        </span>
      )}
    </Flex>
  );
};

export { TargetEvaluation };
