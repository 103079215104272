import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Settings = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M17.6706 9.40594L16.5946 9.17203C16.501 8.88556 16.3851 8.60614 16.2483 8.33698L16.844 7.41039C16.9504 7.24483 16.927 7.02747 16.7879 6.8884L15.1116 5.2121C14.9725 5.07303 14.7552 5.04964 14.5896 5.15602L13.663 5.75171C13.3939 5.61488 13.1144 5.49899 12.828 5.40543L12.5941 4.32941C12.5523 4.13715 12.382 4 12.1853 4H9.81471C9.61797 4 9.44771 4.13715 9.40594 4.32941L9.17203 5.40543C8.88556 5.49899 8.60614 5.61488 8.33698 5.75171L7.41039 5.15602C7.24483 5.04964 7.02747 5.07303 6.8884 5.2121L5.2121 6.8884C5.07303 7.02747 5.04964 7.24483 5.15602 7.41039L5.75171 8.33698C5.61488 8.60614 5.49899 8.88556 5.40543 9.17203L4.32941 9.40594C4.13715 9.44781 4 9.61797 4 9.81471V12.1853C4 12.382 4.13715 12.5522 4.32941 12.5941L5.40543 12.828C5.49899 13.1144 5.61488 13.3939 5.75171 13.663L5.15602 14.5896C5.04964 14.7552 5.07303 14.9725 5.2121 15.1116L6.8884 16.7879C7.02747 16.927 7.24483 16.9504 7.41039 16.844L8.33698 16.2483C8.60614 16.3851 8.88556 16.501 9.17203 16.5946L9.40594 17.6706C9.44771 17.8629 9.61797 18 9.81471 18H12.1853C12.382 18 12.5523 17.8629 12.5941 17.6706L12.828 16.5946C13.1144 16.501 13.3939 16.3851 13.663 16.2483L14.5896 16.844C14.7552 16.9504 14.9725 16.9271 15.1116 16.7879L16.7879 15.1116C16.927 14.9725 16.9504 14.7552 16.844 14.5896L16.2483 13.663C16.3851 13.3939 16.501 13.1144 16.5946 12.828L17.6706 12.5941C17.8629 12.5522 18 12.382 18 12.1853V9.81471C18 9.61797 17.8629 9.44781 17.6706 9.40594ZM13.51 11C13.51 12.384 12.384 13.51 11 13.51C9.61604 13.51 8.49004 12.384 8.49004 11C8.49004 9.61604 9.61604 8.49004 11 8.49004C12.384 8.49004 13.51 9.61604 13.51 11Z"
      fill={color}
    />
  </SVGBox>
);

export { Settings };
