import React, { type ReactNode, type ButtonHTMLAttributes, type CSSProperties, type KeyboardEvent } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { HelpOutline } from "components/MaterialIcons";
import { Dropdown } from "components/Dropdown/Dropdown";

import { type Themes } from "../commonProps";

import { Tooltip } from "../Tooltip/Tooltip";

import styles from "./Card.module.scss";

export interface CardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly labels?: ReactNode[];
  readonly title?: string;
  readonly showTitle?: boolean;
  readonly tooltip?: string;
  readonly quickActions?: ReactNode[];
  readonly actionItems?: ReactNode[] | ReactNode;
  readonly actionPosition?: "header" | "title" | "footer";
  readonly coverUrl?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly children?: ReactNode;
  readonly width?: CSSProperties["width"];
  readonly onClick?: () => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  readonly titleClassName?: string;
  readonly contentClassName?: string;
  readonly labelClassName?: string;
}

const Card = ({
  labels = [],
  coverUrl,
  title,
  showTitle = true,
  tooltip,
  children,
  quickActions,
  actionItems,
  actionPosition,
  theme,
  width,
  darkMode = false,
  className,
  titleClassName,
  contentClassName,
  labelClassName,
  onClick,
  onFocus,
  ...props
}: CardProps) => {
  const withHeader = !isEmpty(labels) || coverUrl;
  const currentPosition = actionPosition || (!isEmpty(labels) && !coverUrl ? "header" : "title");
  const withFooter =
    !isEmpty(quickActions) || (!isEmpty(actionItems) && currentPosition === "footer");
  const isActionable = !!onClick;

  const handleKey = (e: KeyboardEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) return; // only handle key events on the card itself
    switch (e.key) {
      case "Enter":
      case " ":
        onClick?.();
        break;
      case "Escape":
        if (e.target instanceof HTMLElement) e.target.blur();
    }
  };

  return (
    <article
      aria-label={`card-${title}`}
      className={cx(styles.Card, className, styles[theme], {
        [styles.actionnable]: isActionable,
        [styles.withCover]: !!coverUrl,
        [styles.withFooter]: withFooter,
        [styles.darkMode]: darkMode,
      })}
      style={{ width }}
      data-testid="card-container"
      onClick={onClick}
      onFocus={onFocus ?? undefined}
      onKeyDown={isActionable ? handleKey : undefined}
      tabIndex={isActionable ? 0 : -1}
      {...props}
    >
      {withHeader && (
        <div
          className={cx(styles.header, {
            [styles.cover]: !!coverUrl,
            [styles.withActions]: !isEmpty(actionItems),
          })}
          style={{ background: coverUrl ? `url(${coverUrl})` : undefined }}
        >
          {!isEmpty(labels) && <span className={cx(styles.labels, labelClassName)}>{labels}</span>}
          {currentPosition === "header" && !isEmpty(actionItems) && (
            <Dropdown className={styles.dropdown} theme={theme}>
              {actionItems}
            </Dropdown>
          )}
        </div>
      )}
      {showTitle && (
        <div
          className={cx(titleClassName, styles.title, {
            [styles.withActions]: !isEmpty(actionItems),
            [styles.withHeader]: withHeader,
          })}
        >
          <span className={styles.titleWrapper}>
            <label className={styles.label}>{title}</label>
            {tooltip && (
              <Tooltip className={styles.tooltip} label={tooltip} theme={theme}>
                <HelpOutline />
              </Tooltip>
            )}
          </span>
          {currentPosition === "title" && !isEmpty(actionItems) && (
            <Dropdown className={styles.dropdown} theme={theme} buttonClassName={styles.toggler}>
              {actionItems}
            </Dropdown>
          )}
        </div>
      )}
      <div className={cx(styles.content, contentClassName)}>{children}</div>
      {withFooter && (
        <div className={styles.footer}>
          <div className={styles.quickActions}>{quickActions}</div>
          {!isEmpty(actionItems) && currentPosition === "footer" && (
            <Dropdown className={styles.dropdown} theme={theme}>
              {actionItems}
            </Dropdown>
          )}
        </div>
      )}
    </article>
  );
};

export { Card };
