export enum AuthStrategyType {
  OTL = "otl",
  PASSWORD = "password",
  SSO = "saml",
}

type AuthStrategyBase = {
  authSettings?: { samlRequestQueryParameters: Record<string, string> };
};

export interface AuthStrategySaml extends AuthStrategyBase {
  type: AuthStrategyType.SSO;
  authProvider: string;
}

export interface AuthStrategyOtl extends AuthStrategyBase {
  type: AuthStrategyType.OTL;
}

export interface AuthStrategyPassword extends AuthStrategyBase {
  type: AuthStrategyType.PASSWORD;
}

export type AuthStrategiesLight = AuthStrategySaml | AuthStrategyOtl | AuthStrategyPassword;
