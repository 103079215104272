import React, { useMemo } from "react";
import { isArray } from "lodash";

import { Button as DSButton } from "../../Button";
import { Close } from "../../MaterialIcons";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

import styles from "../DSModal.module.scss";

type ModalHeaderProps = {
  children:
    | [React.ReactElement<typeof HeaderTitle>, React.ReactElement<typeof HeaderCloseIcon>]
    | [
        React.ReactElement<typeof HeaderTitle>,
        React.ReactElement<typeof HeaderSubHeader>,
        React.ReactElement<typeof HeaderCloseIcon>
      ]
    | React.ReactElement<typeof HeaderTitle>;
};

export const HeaderTitle: React.FunctionComponent<{
  title: string;
}> = ({ title }) => <h1>{title}</h1>;

export const HeaderSubHeader: React.FunctionComponent<{
  children: React.ReactElement | React.ReactElement[];
}> = ({ children }) => <div className={styles.subheader}>{children}</div>;

export function HeaderCloseIcon({ onClose }: { onClose: () => void }) {
  const { isMobile } = useMediaQueries();
  return (
    <DSButton
      icon={<Close />}
      onClick={onClose}
      iconOnly
      emphasis="Low"
      buttonSize={isMobile ? "S" : "M"}
      aria-label="close"
    />
  );
}

export function BasicHeader({ children }: ModalHeaderProps) {
  return <header>{children}</header>;
}

export function ClosableModalHeader({
  children,
  onClose,
}: ModalHeaderProps & { onClose: () => void }) {
  const { title, subheader } = useMemo(() => {
    const elements = isArray(children) ? children : [children];
    return {
      title: elements.find((child) => child.type === HeaderTitle),
      subheader: elements.find((child) => child.type === HeaderSubHeader),
    };
  }, [children]);
  return (
    <header>
      <div className={styles.title}>
        {title}
        <HeaderCloseIcon onClose={onClose} />
      </div>
      {subheader}
    </header>
  );
}

BasicHeader.Title = HeaderTitle;
BasicHeader.SubHeader = HeaderSubHeader;
ClosableModalHeader.Title = HeaderTitle;
ClosableModalHeader.SubHeader = HeaderSubHeader;

export type BasicHeaderType = React.ReactElement<typeof BasicHeader>;
export type ClosableModalHeaderType = React.ReactElement<typeof ClosableModalHeader>;
export type HeaderType = BasicHeaderType | ClosableModalHeaderType;
