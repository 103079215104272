import { atom, useRecoilState } from "recoil";
import { AuthStrategiesLight } from "./types";

const organizationHasBeenFetchedAtom = atom<boolean>({
  key: "organizationHasBeenFetched",
  default: false,
});

const authStrategyAtom = atom<AuthStrategiesLight | null>({
  key: "authStrategy",
  default: null,
});

const logoAtom = atom<string | null>({
  key: "logo",
  default: null,
});

const loginAtom = atom<string | null>({
  key: "login",
  default: null,
});

const passwordAtom = atom<string | null>({
  key: "password",
  default: null,
});

const passwordPolicyAtom = atom<string | null>({
  key: "passwordPolicy",
  default: null,
});

const loginStepAtom = atom<string | null>({
  key: "loginStep",
  default: null,
});

interface Auth {
  organizationHasBeenFetched: boolean;
  setOrganizationHasBeenFetched: (organization: boolean) => void;
  authStrategy: AuthStrategiesLight | null;
  setAuthStrategy: (authStrategy: AuthStrategiesLight | null) => void;
  logo: string | null;
  setLogo: (logo: string | null) => void;
  login: string | null;
  setLogin: (login: string | null) => void;
  password: string | null;
  setPassword: (password: string | null) => void;
  passwordPolicy: string | null;
  setPasswordPolicy: (password: string | null) => void;
  step: string | null;
  setStep: (step: string | null) => void;
}

const useAuth = (): Auth => {
  const [organizationHasBeenFetched, setOrganizationHasBeenFetched] = useRecoilState(
    organizationHasBeenFetchedAtom
  );
  const [authStrategy, setAuthStrategy] = useRecoilState(authStrategyAtom);
  const [logo, setLogo] = useRecoilState(logoAtom);
  const [login, setLogin] = useRecoilState(loginAtom);
  const [password, setPassword] = useRecoilState(passwordAtom);
  const [passwordPolicy, setPasswordPolicy] = useRecoilState(passwordPolicyAtom);
  const [step, setStep] = useRecoilState(loginStepAtom);

  return {
    organizationHasBeenFetched,
    setOrganizationHasBeenFetched,
    authStrategy,
    setAuthStrategy,
    logo,
    setLogo,
    login,
    setLogin,
    password,
    setPassword,
    passwordPolicy,
    setPasswordPolicy,
    step,
    setStep,
  };
};

export default useAuth;
