import React, { useMemo, useCallback } from "react";
import cx from "classnames";

import { Checkbox } from "components/Form/Checkbox";
import { type Column, type Row } from "components/DataTable/types";

import styles from "./SelectFilter.styles.module.scss";

type Option = Required<Column<Row<string, any>>>["options"][0] & { display: boolean };

export interface Props {
  readonly className?: string;
  readonly options: Array<Option>;
  readonly values?: Set<string>;
  readonly onChange: (key?: Set<string>) => void;
  readonly withSelectAll?: boolean;
}

export function SelectFilter({
  className,
  options,
  values,
  withSelectAll = true,
  onChange,
}: Props) {
  const selectedOptions = useMemo(
    () => values ?? new Set<string>(options.map((o) => o.value)),
    [values, options]
  );

  const isAllActive = useMemo(() => {
    if (options.every((item) => selectedOptions.has(item.value))) {
      return true;
    }
    if (selectedOptions.size === 0) {
      return false;
    }
    return undefined;
  }, [options, selectedOptions]);

  const optionsToDisplay = useMemo(() => {
    return options.filter((item) => item.display);
  }, [options]);

  function onSelect(key: string) {
    const v = new Set<string>(selectedOptions);
    if (v.has(key)) {
      v.delete(key);
    } else {
      v.add(key);
    }
    if (v.size === options?.length) onChange(undefined);
    else onChange(v);
  }

  const unSelectAllOptions = () => {
    onChange(new Set<string>());
  };

  const selectAllOptions = () => {
    onChange(undefined);
  };

  const selectAllHandler = useCallback(() => {
    if (isAllActive) {
      unSelectAllOptions();
    } else {
      selectAllOptions();
    }
  }, [isAllActive]);

  return (
    <div className={cx(styles.selectFilter, className)}>
      <div className={styles.selectFilterContainer}>
        {withSelectAll && (
          <div className={styles.selectAll}>
            <Checkbox
              checked={isAllActive}
              onChange={selectAllHandler}
              label={"Tout sélectionner"}
              value="all"
              name="select-all"
            />
          </div>
        )}
        <div className={cx(styles.options, { [styles.all]: withSelectAll })}>
          {optionsToDisplay.map((option) => (
            <Checkbox
              key={option.value}
              className={styles.searchSelectLine}
              checked={selectedOptions.has(option.value)}
              onChange={() => onSelect(option.value)}
              aria-label={`${option.isSelected ? "deselectionner" : "selectionner"}-${
                option.label
              }`}
              label={option.label}
              value={option.value}
              name={option.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
