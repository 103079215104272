import React, { type KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Button } from "components/Button/Button";
import { Chip } from "components/Chip/Chip";
import { Avatar } from "components/Avatar/Avatar";
import { Rating } from "components/training/Rating/Rating";

import { type TrainingProgramProps } from "../TrainingProgram";

import styles from "./TrainingProgramCard.module.scss";

export function TrainingProgramCard({
  title,
  organisation,
  rating,
  details = [],
  isSelected,
  onShowProgram,
  onSelectionChange,
  className,
  theme,
}: TrainingProgramProps) {
  const { t } = useTranslation();

  const isActionable = !!onSelectionChange;

  const handleKey = (e: KeyboardEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) return; // only handle key events on the card itself
    switch (e.key) {
      case "Enter":
      case " ":
        onSelectionChange?.(!isSelected);
        break;
      case "Escape":
        onSelectionChange?.(false);
    }
  };

  return (
    <article
      className={cx(styles.TrainingProgramCard, styles[theme], className, {
        [styles.actionnable]: isActionable,
        [styles.selected]: isSelected,
      })}
      onKeyDown={isActionable ? handleKey : undefined}
      tabIndex={isActionable ? 0 : -1}
    >
      {isActionable && (
        <div className={styles.selection}>
          <input
            checked={isSelected}
            type="radio"
            onKeyDown={isActionable ? handleKey : undefined}
            onClick={() => onSelectionChange?.(!isSelected)}
          />
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.header}>
          <h1>{title}</h1>
          {organisation && (
            <Chip
              label={organisation.label}
              theme={theme}
              icon={
                <Avatar
                  imageUrl={organisation.iconUrl}
                  size="XS"
                  type="Picture"
                  color="Pink"
                  theme={theme}
                />
              }
            />
          )}
        </div>
        {rating && (
          <div className={styles.rating}>
            <Rating grade value={rating?.score} max={10} reviews={rating?.voters} theme={theme} />
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.chips}>
            {details.map((label) => (
              <Chip key={label} label={label} theme={theme} />
            ))}
          </div>
          <Button
            theme={theme}
            buttonSize="S"
            emphasis="Low"
            label={t("training.program.link", { defaultValue: "Voir dans le catalogue" })}
            onClick={onShowProgram}
          />
        </div>
      </div>
    </article>
  );
}
