import React from "react";

import { SVGBox, type SVGProps } from "./SVGBox";

const SvgFrenchFlag = ({ size = "1rem", ...props }: SVGProps) => (
  <SVGBox height={size} viewBox="0 0 3 2" {...props}>
    <rect width="3" height="2" fill="#ED2939" />
    <rect width="2" height="2" fill="#FFFFFF" />
    <rect width="1" height="2" fill="#002395" />
  </SVGBox>
);

export { SvgFrenchFlag };
