import React, { type ReactNode } from "react";
import cx from "classnames";

import { Card as DSCard, type CardProps as DSCardProps } from "../../Card/Card";
import { type Themes } from "../../commonProps";
import { type WrappableCard } from "../DashboardCardView/types";

import styles from "./DashboardCardWrapper.module.scss";
export interface DashboardCardWrapperProps extends DSCardProps, WrappableCard {
  readonly id: string;
  readonly children?: ReactNode | ReactNode[];
  readonly theme: Themes;
  readonly darkMode?: boolean;
  readonly className?: string;
  readonly contentClassName?: string;
}

const DashboardCardWrapper = ({
  className,
  contentClassName,
  children,
  ...props
}: DashboardCardWrapperProps) => {
  return (
    <DSCard
      width="100%"
      className={cx(styles.DashboardCardWrapper, className)}
      contentClassName={cx(styles.content, contentClassName)}
      {...props}
    >
      {children}
    </DSCard>
  );
};

export { DashboardCardWrapper };
