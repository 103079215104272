import { useRef, useLayoutEffect } from "react";
import { useAsync } from "react-use";
import { isEmpty } from "lodash";
import qs from "qs";

import request from "helpers/request";
import Logo from "./Logo";

import styles from "./LoginViewStyles.module.scss";

import { SamlRoutes } from "api/types";

interface Props {
  provider: string;
  settings?: {
    samlRequestQueryParameters?: Record<string, unknown>;
  } | null;
  redirect?: string;
}

const SAMLRequest = ({ provider, settings, redirect }: Props) => {
  const state = useAsync(async (): Promise<
    SamlRoutes["GET"]["/v1/saml/{provider}/login-request"]["response"]
  > => {
    const queryString = qs.stringify({
      RelayState: !isEmpty(redirect) ? redirect : undefined,
    });

    const { data: samlRequest } = await request<
      SamlRoutes["GET"]["/v1/saml/{provider}/login-request"]["response"]
    >({
      target: "AUTH",
      url: `/saml/${provider}/login-request?${queryString}`,
    });

    return samlRequest;
  }, [provider]);

  const samlRequest = state.value;
  const samlForm = useRef<HTMLFormElement>(null);

  // We have to wait for the form inputs to update before doing submit, hence useLayoutEffect
  useLayoutEffect(() => {
    if (samlRequest) {
      if (samlRequest.binding === "POST") {
        samlForm.current?.submit();
      } else if (samlRequest.binding === "Redirect") {
        const queryString = qs.stringify({
          SAMLRequest: samlRequest.context,
          RelayState: !isEmpty(samlRequest.relayState) ? samlRequest.relayState : undefined,
          SigAlg: samlRequest.signatureAlgorithm,
          Signature: samlRequest.signature,
          ...(settings?.samlRequestQueryParameters ?? {}),
        });

        const url = `${samlRequest.entityEndpoint}?${queryString}`;
        window.location.href = url;
      }
    }
  }, [samlRequest, settings]);

  if (state.error) {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Erreur</h1>
          <p>Identifiant invalide, veuillez contacter votre service RH pour valider votre accès.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Veuillez patienter</h1>
        <p>Connexion au service en cours.</p>
        <form method="post" action={samlRequest?.entityEndpoint} autoComplete="off" ref={samlForm}>
          <input type="hidden" name={samlRequest?.type} value={samlRequest?.context ?? ""} />
          <input type="hidden" name="RelayState" value={samlRequest?.relayState ?? ""} />
        </form>
      </div>
    </div>
  );
};

export default SAMLRequest;
