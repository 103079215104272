import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgInterviews = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M8.943 10.976c-.426.36-.945.586-1.568.586a2.375 2.375 0 01-1.568-.586c-1.033.261-2.103.654-2.37 1.024-.3.417-.437 1.19-.437 1.75 0 .479.383.875.875.875h7a.872.872 0 00.875-.875c0-.56-.137-1.333-.438-1.75-.266-.37-1.336-.763-2.369-1.024zm-1.568-.289c1.568 0 2.188-2.292 2.188-3.5a2.188 2.188 0 00-4.376 0c0 1.208.62 3.5 2.188 3.5zm5.25 3.063c0 .32-.093.616-.243.875h3.743A.872.872 0 0017 13.75c0-.56-.137-1.333-.438-1.75-.266-.37-1.337-.763-2.369-1.024-.426.36-.945.586-1.568.586-.22 0-.421-.036-.615-.088.38.484.615 1.425.615 2.276zm0-3.063c1.568 0 2.188-2.292 2.188-3.5a2.188 2.188 0 00-4.376 0c0 1.208.62 3.5 2.188 3.5z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { SvgInterviews };
