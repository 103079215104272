import React, { type HTMLAttributes, forwardRef, type KeyboardEvent, type ReactNode } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Themes } from "components/commonProps";

import styles from "./NavigationTabs.module.scss";

export type NavigationTab = {
  readonly id: string;
  readonly label: string;
  readonly url?: string;
  readonly spacingLeft?: boolean;
  readonly disabled?: boolean;
  readonly alignRight?: boolean;
  readonly tooltipLabel?: string;
  readonly primaryAction?: ReactNode;
};

export interface NavigationTabsProps extends HTMLAttributes<HTMLDivElement> {
  readonly tabs: NavigationTab[];
  readonly activeTab?: string;
  readonly onChangeTab?: (tabId: string) => void;
  readonly spacingLeft?: boolean;
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const NavigationTabs = forwardRef<HTMLDivElement, NavigationTabsProps>(
  ({ tabs, activeTab, onChangeTab, spacingLeft, darkMode, theme, className, ...props }, ref) => {
    const handleKey = (e: KeyboardEvent<HTMLAnchorElement>) => {
      if (e.target !== e.currentTarget) return; // only handle key events on the tab itself
      switch (e.key) {
        case "Escape":
          if (e.target instanceof HTMLElement) e.target.blur();
      }
    };
    const handleClick = (
      e: React.MouseEvent<HTMLAnchorElement>,
      tab: NavigationTabsProps["tabs"][0]
    ) => {
      if (tab.disabled || !tab.url) {
        e.preventDefault();
      }
      if (tab.disabled) return;
      onChangeTab?.(tab.id);
    };

    const leftTabs = tabs.filter((tab) => !tab.alignRight);
    const rightTabs = tabs.filter((tab) => tab.alignRight);

    return (
      <div
        ref={ref}
        aria-label="navigation-tabs"
        className={cx(styles.NavigationTabs, className, styles[theme], {
          [styles.darkMode]: theme === Themes.ESPACE_RH,
          [styles.moreThan3]: tabs.length > 3,
          [styles.spacingLeft]: spacingLeft,
        })}
        {...props}
      >
        {leftTabs.map((tab) => (
          <Link
            title={tab.tooltipLabel}
            key={tab.id}
            to={tab.url ?? ""}
            className={cx(styles.tab, {
              [styles.disabled]: tab.disabled,
            })}
            onClick={(e) => handleClick(e, tab)}
            onKeyDown={handleKey}
            aria-label={`navigation-tab-${tab.id}-${tab.disabled ? "disabled" : "active"}`}
          >
            <div
              className={cx(styles.content, {
                [styles.active]: tab.id === activeTab,
                [styles.disabled]: tab.disabled,
              })}
            >
              {tab.label}
            </div>
          </Link>
        ))}

        {rightTabs && (
          <>
            <div className={styles.spacer} />
            {rightTabs?.length >= 1 &&
              rightTabs.map((tab) => (
                <Link
                  title={tab.tooltipLabel}
                  key={tab.id}
                  to={tab.url ?? ""}
                  className={cx(styles.tab, {
                    [styles.disabled]: tab.disabled,
                  })}
                  onClick={(e) => handleClick(e, tab)}
                  onKeyDown={handleKey}
                  aria-label={`navigation-tab-${tab.id}-${tab.disabled ? "disabled" : "active"}`}
                >
                  <div
                    className={cx(styles.content, {
                      [styles.active]: tab.id === activeTab,
                      [styles.disabled]: tab.disabled,
                    })}
                  >
                    {tab.label}
                  </div>
                </Link>
              ))}
          </>
        )}
      </div>
    );
  }
);

export { NavigationTabs };
