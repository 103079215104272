import { keyBy } from "lodash";
import { useMemo } from "react";
import { useState } from "react";

import { FormatDate, ParseDate } from "@skillup/shared-utils";

import { type Column, ColumnType, type DataTableRow, type FilterValue, type TableFilters } from "../types";

export interface UseFilterProps<R extends DataTableRow> {
  readonly rows: R[];
  readonly columns: Column<R>[];
  readonly defaultFilters?: TableFilters<R>;
}

export function useFilters<R extends DataTableRow>({
  rows,
  columns,
  defaultFilters = {},
}: UseFilterProps<R>) {
  const [filters, setFilters] = useState<TableFilters<R>>(defaultFilters);

  const filteredRows = useMemo(() => {
    const filterEntries = Object.entries(filters);
    if (!filterEntries.length) return rows;
    const columnsByKey = keyBy(columns, "key");
    return rows.filter((row) => {
      for (const [key, value] of filterEntries) {
        const col = columnsByKey[key];
        if (!col) continue;
        if (!matchesFilter(row, col, value)) return false;
      }
      return true;
    });
  }, [rows, filters]);

  function resetFilters() {
    setFilters(defaultFilters);
  }

  function handleFilter(column: Column<R>, value: FilterValue) {
    if (value == null || value === "") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setFilters(({ [column.key]: _, ...rest }) => rest as any);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [column.key]: value,
      }));
    }
  }

  function matchesFilter(row: R, col: Column<R>, value: FilterValue) {
    if (value == null) return true;

    if (col.filterFn) return col.filterFn(row, value);
    switch (col.type) {
      case ColumnType.NUMBER:
        return row.data[col.key] === value;
      case ColumnType.DATE:
        const date1 =
          row.data[col.key] && FormatDate.ToISOShort(ParseDate.FromParsableJS(row.data[col.key]));
        const date2 = value && FormatDate.ToISOShort(ParseDate.FromParsableJS(value as string));
        return date1 === date2;
      case ColumnType.SELECT:
      case ColumnType.SEARCH_SELECT:
        if (!(value instanceof Set)) return true;
        return [...value].some((v) => row.data[col.key] === v);
      case ColumnType.BOOL:
        return !row.data[col.key] === !value;
      default:
        const val = renderText(row, col);
        return val.toLowerCase().includes(value.toString().toLowerCase());
    }
  }

  return {
    filters,
    resetFilters,
    handleFilter,
    filteredRows,
  };
}

export function renderText<R extends DataTableRow>(row: R, col: Column<R>) {
  const value = row.data[col.key];
  switch (col.type) {
    case ColumnType.SELECT:
      if (col.options) return col.options.find((option) => option.value === value)?.label ?? "";
  }
  return value?.toString() ?? "";
}

export function getSortKey<R extends DataTableRow>(row: R, col: Column<R>): string | number {
  const value = row.data[col.key];
  switch (col.type) {
    case ColumnType.NUMBER:
    case ColumnType.BOOL:
      return value;
    case ColumnType.DATE:
      return (value && new Date(value).getTime()) || "";
    case ColumnType.SELECT:
    case ColumnType.SEARCH_SELECT:
      const optionKeyOrderMap: Record<string, number> = {};
      col.options?.forEach(({ value }, i) => (optionKeyOrderMap[value] = i));
      const nbOptions = col.options?.length;
      return optionKeyOrderMap[value] ?? nbOptions;
    default:
      return renderText(row, col);
  }
}
