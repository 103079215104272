import { useSetState } from "react-use";
import { useCallback, useMemo } from "react";

function usePasswordChecks({
  password,
  oldPassword,
  passwordPolicy,
}: {
  password: string;
  oldPassword: string | null;
  passwordPolicy: string | null;
}) {
  return useMemo(() => {
    const differentThanOld = password !== oldPassword;
    const hasMinLength = passwordPolicy === "secure" ? password.length >= 15 : password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = passwordPolicy === "secure" ? /[!.@#$%]/.test(password) : true;
    return {
      differentThanOld,
      hasMinLength,
      hasNumber,
      hasUpperCase,
      hasLowerCase,
      hasSpecialChar,
    };
  }, [password, passwordPolicy, oldPassword]);
}

export function usePasswordsFormLogic({
  oldPassword,
  passwordPolicy,
}: {
  oldPassword: string | null;
  passwordPolicy: string | null;
}) {
  const [{ password, passwordConfirm }, setState] = useSetState({
    password: "",
    passwordConfirm: "",
  });

  const setPassword = useCallback((password: string) => setState({ password }), [setState]);

  const setPasswordConfirm = useCallback(
    (passwordConfirm: string) => setState({ passwordConfirm }),
    [setState]
  );

  const passwordChecks = usePasswordChecks({ password, oldPassword, passwordPolicy });
  const confirmIsValid = useMemo(() => password === passwordConfirm, [password, passwordConfirm]);

  const submitError = useMemo(() => {
    if (!Object.values(passwordChecks).every(Boolean))
      return "Votre mot de passe ne remplit pas les critères d’acceptabilité.";
    if (!confirmIsValid) return "Les mots de passe ne correspondent pas.";
    return undefined;
  }, [passwordChecks, confirmIsValid]);

  return {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordChecks,
    submitError,
  };
}
