import React, { useRef, useCallback, type ChangeEvent, type TextareaHTMLAttributes } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./TextArea.module.scss";
import { useEffect } from "react";

interface Props extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  readonly onChange?: (value: string) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly rows?: number;
  readonly errorMessage?: string;
  readonly errored?: boolean;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly adaptToInputSize?: boolean;
}

const TextArea = ({
  className,
  placeholder = "Aa",
  onChange,
  onFocus,
  onBlur,
  readOnly = false,
  value,
  rows = 3,
  errored = false,
  errorMessage,
  disabled,
  adaptToInputSize,
  ...props
}: Props) => {
  const self = useRef<HTMLTextAreaElement>(null);

  const adaptInputHeight = useCallback(() => {
    if (self?.current) {
      const { offsetHeight, clientHeight, scrollHeight } = self.current;
      const offset = offsetHeight - clientHeight;

      self.current.style.height = scrollHeight + offset + "px";
    }
  }, [self]);

  const handleChangeValue = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const {
        target: { value: nextValue },
      } = event;

      if (onChange && !readOnly) {
        onChange(nextValue);
      }

      adaptInputHeight();
    },
    [onChange, readOnly, adaptInputHeight]
  );

  useEffect(() => {
    if (adaptToInputSize) {
      adaptInputHeight();
    }
  }, []);

  return (
    <Flex
      className={cx(styles.TextArea, className)}
      data-testid="textarea-wrapper"
      disabled={disabled}
    >
      <textarea
        onFocus={onFocus}
        onBlur={onBlur}
        ref={self}
        data-testid="textarea-input"
        className={cx({
          [styles.hasError]: errored || errorMessage,
          [styles.readOnly]: readOnly,
        })}
        placeholder={placeholder}
        value={value}
        rows={rows}
        onChange={handleChangeValue}
        readOnly={readOnly}
        {...props}
      />
      {errorMessage && (
        <span className={styles.errorMessage} data-testid="textarea-error">
          {errorMessage}
        </span>
      )}
    </Flex>
  );
};

export { TextArea };
