import React, { type CSSProperties } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import * as Icon from "../Icon";
import { colors } from "../../assets/colors";
import styles from "./StatusBadge.module.scss";

export interface IProps {
  readonly className?: string;
  readonly style?: CSSProperties;
  readonly label?: string;
  readonly type?: "pending" | "done";
  readonly loading?: boolean;
  readonly disabled?: boolean;
}

const icon = {
  pending: {
    component: Icon.Bell,
    color: colors.primaryYellow,
  },
  done: {
    component: Icon.ThumbUp,
    color: colors.primaryBlue,
  },
};

const StatusBadge = (props: IProps): JSX.Element => {
  const { className, style, label, type = "pending", loading, disabled } = props;
  const { component: StateIcon, color } = icon[type];

  return (
    <Flex
      className={cx(styles.StatusBadge, className, styles[type], { [styles.loading]: loading })}
      style={style}
      role="badge"
      disabled={disabled}
    >
      <StateIcon className={styles.stateIcon} color={color} size={18} />
      <span className={styles.label} role="label">
        {label}
      </span>
    </Flex>
  );
};

export { StatusBadge };
