import React from "react";
import cx from "classnames";

import { Button } from "components/Button/Button";
import { OpenInNew } from "components/MaterialIcons";
import { Rating } from "components/training/Rating/Rating";

import { type TrainingProgramProps } from "../TrainingProgram";

import styles from "./TrainingProgramListItem.module.scss";
import { useTranslation } from "react-i18next";

export function TrainingProgramListItem({
  title,
  organisation,
  rating,
  isSelected,
  onShowProgram,
  onSelectionChange,
  className,
  theme,
}: TrainingProgramProps) {
  const { t } = useTranslation();

  const isActionable = !!onSelectionChange;

  return (
    <article
      className={cx(styles.TrainingProgramListItem, styles[theme], className, {
        [styles.actionnable]: isActionable,
        [styles.selected]: isSelected,
      })}
      tabIndex={isActionable ? 0 : -1}
    >
      {isActionable && (
        <div className={styles.selection}>
          <input
            checked={isSelected}
            type="radio"
            onClick={() => onSelectionChange?.(!isSelected)}
          />
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.rating}>
          <Rating value={rating?.score} max={10} theme={theme} />
        </div>
        <h1>{title}</h1>
        {organisation && (
          <div className={styles.organisation}>
            {organisation?.label
              ? t("generic.by", { defaultValue: "Par {{name}}", name: organisation.label })
              : ""}
          </div>
        )}
      </div>
      <div className={styles.action}>
        <Button
          theme={theme}
          icon={<OpenInNew />}
          iconOnly
          buttonSize="S"
          onClick={onShowProgram}
        />
      </div>
    </article>
  );
}
