import React from "react";
import { Link } from "react-router-dom";
import { type GridLabelProps, ResponsiveRadar, type RadarSliceTooltipProps } from "@nivo/radar";
import { SkillPopover } from "../SkillsListDetail/components/SkillPopover";

export type RadarProps = {
  readonly keys: Array<string>;
  readonly data: Array<{
    readonly name: string;
    readonly level?: number;
    readonly levelName?: string;
    readonly levelDescription?: string;
    readonly expectedLevel?: number;
    readonly expectedLevelName?: string;
    readonly expectedLevelDescription?: string;
    readonly comment?: string;
    readonly evaluatedBy?: string;
    readonly evaluatedAt?: string;
    readonly href?: string;
  }>;
  readonly margin?: {
    readonly top: number;
    readonly right: number;
    readonly bottom: number;
    readonly left: number;
  };
  readonly color?: Array<string>;
  readonly chunkSize?: number;
  readonly gridLevels?: number;
  readonly displayLevelBelowExpectation?: boolean;
};

const defaultMargins = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const Radar = ({
  data: pData,
  margin: pMargin,
  keys,
  color = ["#7EC2E2", "#AD4A48"],
  chunkSize = 28,
  gridLevels = 5,
  displayLevelBelowExpectation = false,
}: RadarProps) => {
  const isMoreThanTwelveSkills = pData?.length > 12;

  const data = pData?.map((rest, index) => ({
    id: index,
    ...rest,
  }));

  const margin = {
    ...defaultMargins,
    ...pMargin,
  };

  const getColor = (e: { index: number; key: string }) => color[e.index];

  const customLabel = (props: GridLabelProps) => {
    if (isMoreThanTwelveSkills) return null;

    const {
      expectedLevel = 0,
      level = 0,
      href = "",
    } = pData.find((item) => item.name === props.id) || {};
    const hasLevelBelowExpectation = expectedLevel > level;
    const warningIcon = hasLevelBelowExpectation && displayLevelBelowExpectation ? "⚠️" : "";

    const anchor = props.x > 5 ? "start" : props.x < -5 ? "end" : "middle";

    const words = props.id.split(" ");
    const chunks: string[] = [];
    let currentChunk = "";

    for (const word of words) {
      if ((currentChunk + " " + word).length <= chunkSize) {
        currentChunk += (currentChunk.length > 0 ? " " : "") + word;
      } else {
        chunks.push(currentChunk);
        currentChunk = word;
      }
    }

    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    if (chunks.length > 2) {
      chunks[1] = chunks[1].substring(0, chunkSize - 1) + "...";
    }

    let adjustedY = props.y;

    if (props.y === 125) {
      adjustedY = props.y;
    } else if (props.y === -125) {
      adjustedY = props.y - 40;
    } else {
      adjustedY = props.y - 20;
    }

    const skillName = chunks.slice(0, 2).map((chunk, index) => {
      return (
        <tspan key={index} x="0" dy="1.2em" style={{ fontWeight: warningIcon ? "bold" : "normal" }}>
          {index === 0 && <>{warningIcon} </>}
          {chunk}
        </tspan>
      );
    });

    return (
      <g transform={`translate(${props.x}, ${adjustedY})`}>
        {href ? (
          <Link to={href}>
            <text
              alignmentBaseline={"middle"}
              textAnchor={anchor}
              fontSize={12}
              color="#1A304D"
              style={{ fontWeight: warningIcon ? "500" : "normal" }}
            >
              {skillName}
            </text>
          </Link>
        ) : (
          <text alignmentBaseline={"middle"} textAnchor={anchor} fontSize={12} color="#1A304D">
            {skillName}
          </text>
        )}
      </g>
    );
  };

  const customTooltip = (props: RadarSliceTooltipProps) => {
    const skill = data.find((item) => item.name === props.index);

    if (!skill) return null;

    return <SkillPopover skill={skill} />;
  };

  const safeData = data.map((skill) => {
    const newData = {
      name: skill.name,
      expectedLevel: skill.expectedLevel ?? 0,
      level: skill.level ?? 0,
    };

    for (const key of keys) {
      if (!newData[key]) {
        newData[key] = 0;
      }
    }

    return newData;
  });

  return (
    <div
      style={{
        display: "grid",
        gridAutoRows: "minmax(0px, 2fr)",
        width: "inherit",
        height: "inherit",
      }}
    >
      <ResponsiveRadar
        data={safeData}
        keys={keys}
        indexBy="name"
        margin={margin}
        borderColor={{ from: "color" }}
        gridLabelOffset={15}
        dotSize={1}
        isInteractive={true}
        dotBorderWidth={5}
        colors={getColor}
        fillOpacity={0.1}
        blendMode="multiply"
        motionConfig="wobbly"
        gridLabel={customLabel}
        animate={true}
        gridLevels={gridLevels}
        sliceTooltip={customTooltip}
      />
    </div>
  );
};

export { Radar };
