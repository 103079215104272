import React from "react";

export interface IProps {
  className?: string;
}

const skillupLogo: React.FunctionComponent<IProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 48" className={className || ""}>
    <path
      d="M18.1 35.8c1.8-1.4 2.8-3.1 2.8-5.1 0-3.1-1.7-4.4-2.9-5.2-1.2-.8-6.6-2.2-8-2.5-1.4-.3-1.7-.8-1.9-1.4-.2-.8-.1-1.7.5-2.3.7-.7 1.6-1.1 3.5-1.1s3.7 1.4 4.6 1.9c.8.5 2.3.5 2.9-.5.6-.9.3-2.2-.6-2.8-2-1.5-5-2.4-7-2.4s-4 .6-5.7 1.7c-1.7 1.3-2.7 3.1-2.7 4.9 0 1.9.9 3.6 2.3 4.6 2 1.4 9.1 2.8 9.8 3.2.7.4 1 .9 1 1.8 0 1.5-2.2 3.2-4.6 3.1-2.3-.1-4.7-1.1-5.9-2.3-.8-.7-2.1-.5-2.8.3-.7.8-.6 2 .3 2.7 2.6 2.1 5.5 3.2 8.5 3.3 2.1.1 4.1-.6 5.9-1.9M74 28v-7c0-4 5-4 5 0v7c0 2 .1 5.8 5.1 5.8C89 33.8 89 30 89 28v-7c0-4 5-4 5 0v7c0 2 0 10-10 10s-10-8-10-10z"
      style={{ fill: "#737373" }}
    />
    <path
      d="M55 35c0 4 5 4 5 0V11l-5 5v19zm-10 0c0 4 5 4 5 0V21l-5 5v9zM65 6v29c0 4 5 4 5 0V1l-5 5z"
      style={{ fill: "#ffD042" }}
    />
    <circle cx="47.5" cy="11" r="3" style={{ fill: "#ffD042" }} />
    <path
      d="M108 18c-1.9 0-3.8.6-5.3 1.5-1.1-2.4-4.7-1.9-4.7 1.5v20c0 4 5 4 5 0v-4.3c1.5.9 3.2 1.3 5 1.3 5.5 0 10-4.5 10-10s-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm-67 .4l-6.3-6.3 5.5-5.5c2.8-2.8-.7-6.4-3.5-3.5L30 24.7V12c0-4-5-4-5 0v23c0 4 5 4 5 0v-3.2l1.1-1.1 6.3 6.3c2.9 2.8 6.4-.7 3.6-3.6z"
      style={{ fill: "#737373" }}
    />
  </svg>
);

export default skillupLogo;
