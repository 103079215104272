import React, { useEffect, type MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import cx from "classnames";

import { ArrowDownKeyboard, ArrowUpKeyboard } from "components/MaterialIcons";
import { Themes } from "components/commonProps";

import styles from "./NavigationItem.module.scss";

export interface SubItemProps {
  readonly id: string;
  readonly label: string;
  readonly to?: string;
  readonly active?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme?: Themes;
}

export interface ItemProps extends SubItemProps {
  readonly icon?: JSX.Element;
  readonly open?: boolean;
  readonly children?: JSX.Element[] | JSX.Element;
  readonly forceOpenId?: string | undefined;
}

export interface ItemPropsWithTheme extends Omit<ItemProps, "theme"> {
  readonly theme: Themes;
}

const NavigationItem = ({
  icon,
  label,
  to,
  open,
  active,
  children,
  className,
  onClick,
  darkMode,
  forceOpenId,
  theme = Themes.ESPACE_RH,
}: ItemProps) => {
  const [showSubs, toggleShow] = useToggle(!!open);
  const childItems = React.Children.toArray(children);
  const hasChildren = childItems.length > 0;

  useEffect(() => toggleShow(!!open), [open]);

  return (
    <div
      className={cx(styles.NavigationItem, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
    >
      <div className={cx(styles.wrapper, className)}>
        {to ? (
          <Link
            to={to}
            onClick={(e) => onClick?.(e)}
            className={cx(styles.button, { [styles.active]: active })}
          >
            {icon}
            <label>{label}</label>
          </Link>
        ) : (
          <div
            tabIndex={0}
            className={cx(forceOpenId ? styles.navItem : styles.button, {
              [styles.active]: active,
            })}
            onClick={() => {
              if (hasChildren && !forceOpenId) toggleShow();
            }}
            onKeyPress={({ key }) => {
              if (key === "Enter" && hasChildren && !forceOpenId) toggleShow();
            }}
          >
            {icon}
            <label>{label}</label>
            {childItems.length > 0 && !forceOpenId && (
              <div className={styles.moreIcon}>
                {showSubs ? <ArrowUpKeyboard /> : <ArrowDownKeyboard />}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={cx(styles.children, { [styles.hide]: !showSubs })}>{childItems}</div>
    </div>
  );
};

export { NavigationItem };
