import React from "react";
import cx from "classnames";

import { Flex } from "../../../components/Flex";
import * as Icon from "../../Icon";
import { colors } from "../../../assets/colors";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

import { Reviews } from "../Reviews";
import { Modalities } from "../Modalities";
import type { modalities } from "../modalitiesData";
import styles from "./SearchResultsTrainingCard.module.scss";

interface Props {
  readonly className?: string;
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  readonly trainingName: string;
  readonly disabled?: boolean;
  readonly isCPF?: boolean;
  readonly mode: keyof typeof modalities;
  readonly price?: number;
  readonly duration?: string;
  readonly organizationProfileLogo?: string;
  readonly reviewsGlobalScore?: number;
  readonly reviewsCount?: number;
  readonly subtitle?: string;
}

const SearchResultsTrainingCard = ({
  className,
  disabled,
  onClick,
  trainingName,
  organizationProfileLogo,
  reviewsGlobalScore,
  reviewsCount,
  subtitle,
  duration,
  mode,
  price,
  isCPF,
}: Props) => {
  const { isMobile } = useMediaQueries();

  return (
    <Flex
      className={cx(styles.SearchResultsTrainingCard, className)}
      onClick={onClick}
      disabled={disabled}
      data-testid="training-card-container"
      grow
      column
    >
      <Flex className={styles.top}>
        <Flex grow className={styles.main}>
          <img
            className={styles.orgProfileLogo}
            src={organizationProfileLogo}
            alt="organization logo"
          />
          <div className={styles.sectionContainer}>
            <h3 className={styles.name} data-testid="training-name">
              {trainingName}
            </h3>
            <Reviews reviewsGlobalScore={reviewsGlobalScore} reviewsCount={reviewsCount} />
            <Modalities
              style={{ paddingLeft: "1.25rem" }}
              mode={mode}
              isCPF={isCPF}
              disabled={!isMobile}
            />
            <p className={styles.subtitle} data-testid="training-subtitle">
              {subtitle}
            </p>
          </div>
        </Flex>
        <Modalities
          style={{ marginBottom: "0.625rem" }}
          mode={mode}
          isCPF={isCPF}
          disabled={isMobile}
        />
      </Flex>
      <Flex className={styles.bottom}>
        <Flex className={styles.meta} disabled={!duration}>
          <Icon.Time color={isMobile ? colors.blueyGrey : colors.bwBlack} size="1.125rem" />
          <span className={styles.value} data-testid="training-duration">
            {duration}
          </span>
        </Flex>
        <Flex className={styles.meta} disabled={!price}>
          <Icon.Euro color={isMobile ? colors.blueyGrey : colors.bwBlack} size="1.125rem" />
          <span className={styles.value} data-testid="training-price">
            {price} €
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { SearchResultsTrainingCard };
