import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { isNumber } from "lodash";

import useUserData from "./useUserData";

declare global {
  interface Window {
    ga: typeof Function;
  }
}

interface IOptions {
  readonly root?: boolean;
  readonly development?: boolean;
  readonly consent?: {
    readonly necessary: boolean;
    readonly functionality: boolean;
    readonly analytics: boolean;
  };
}

const { REACT_APP_GOOGLE_ANALYTICS_ID, REACT_APP_ENV } = process.env as Record<string, string>;

const useGoogleAnalytics = ({ root = false, development = false, consent }: IOptions = {}) => {
  const { pathname, search } = useLocation();
  const user = useUserData();

  const [ga, setGA] = useState<typeof import("react-ga")>();
  useEffect(() => {
    const canLoadScript = (REACT_APP_ENV === "production" || development) && root;
    const hasCookieConsent = consent?.analytics;
    const isAlreadyLoaded = ga !== undefined;
    if (!canLoadScript || !hasCookieConsent || isAlreadyLoaded) {
      return;
    }

    import("react-ga").then((module) => {
      setGA(module);

      module.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

      module.set({ dimension1: "(Company unknown)" });
      module.set({ dimension2: "(Unauthentified)" });
      module.set({ dimension4: "(No uuid)" });

      const activeCompany = user?.activeCompany;
      const activeTrainingOrganization = user?.activeTrainingOrganization;

      // User level
      let userLvl = "Not found";
      if (user.isSkillupAdmin() || user.isSkillupDeveloper()) {
        userLvl = "SkillupUser"; // skillup
      } else if (user.isCompanyAdmin()) {
        userLvl = "RH"; //  RH
      } else if (user.isManager()) {
        userLvl = "Manager"; // Userless
      } else if (user.isRegularUser()) {
        userLvl = "User"; // Regular user
      } else if (user.isUserless()) {
        userLvl = "Userless"; // Userless
      } else if (user.isTrainingOrganizationAdmin()) {
        userLvl = "TrainingOrganizationUser"; // Training org user
      }

      // Company name
      let companyName = "Not found";
      if (activeCompany && activeCompany.name) {
        companyName = activeCompany.name;
      } else if (activeTrainingOrganization) {
        companyName = activeTrainingOrganization.name;
      }

      module.set({ dimension1: companyName });
      module.set({ dimension2: userLvl });
      module.set({ dimension4: user.uuid || "userless" });

      if (user.uuid) module.set({ userId: user.uuid });

      module.event({ category: "Authentication", action: "Authenticated" });
    });
  }, [development, root, consent, ga, user]);

  const sendPageview = useCallback(
    (pathname: string, title?: string) => {
      if (ga) {
        ga.pageview(pathname, undefined, title);
      }
    },
    [ga]
  );

  const sendEvent = useCallback(
    (category: string, action: string, label?: string, value?: number) => {
      if (ga) {
        ga.event({
          category,
          action,
          label,
          ...(isNumber(value) && { value }),
        });
      }
    },
    [ga]
  );

  const setDimension = useCallback(
    (dimension: string, value: any) => {
      if (ga) {
        ga.set({ [dimension]: value });
      }
    },
    [ga]
  );

  const setUser = useCallback(
    (userId: string) => {
      if (ga) {
        ga.set({ userId });
      }
    },
    [ga]
  );

  useEffect(() => {
    sendPageview(pathname + search, document.title);
  }, [pathname, search, sendPageview]);

  return useMemo(
    () => ({
      sendPageview,
      sendEvent,
      setDimension,
      setUser,
    }),
    [sendPageview, sendEvent, setDimension, setUser]
  );
};

export default useGoogleAnalytics;
