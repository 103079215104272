import React, { useEffect } from "react";
import { MdDelete, MdDragIndicator } from "react-icons/md";
import classNames from "classnames";
import { type DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { Tooltip as DSTooltip } from "components/Tooltip";

import styles from "./DSSortable.module.scss";

export interface Props {
  removeAlwaysVisible?: boolean;
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  title: React.ReactNode;
  content: React.ReactNode;
  dragRef?: React.Ref<HTMLDivElement> | null;
  isLastItem?: boolean;
  onRemove?(): void;
  remove?: {
    disabled?: boolean;
    tooltip?: string;
  };
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props["transform"];
    transition: Props["transition"];
    title: Props["title"];
    content: Props["content"];
  }): React.ReactElement;
  className?: string;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        height,
        index,
        listeners,
        onRemove,
        remove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        title,
        removeAlwaysVisible,
        content,
        wrapperStyle,
        className,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          fadeIn: Boolean(fadeIn),
          index,
          listeners,
          ref,
          style,
          transform,
          transition,
          title,
          content,
        })
      ) : (
        <li
          className={classNames(
            styles.wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition].filter(Boolean).join(", "),
              "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
              "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
              "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
              "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
              "--index": index,
              "--color": color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <div
            className={classNames(
              styles.item,
              dragging && styles.dragging,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )}
            style={style}
            data-cypress="draggable-item"
            {...props}
            tabIndex={0}
          >
            <div className={styles.head}>
              <div
                className={classNames(styles.handle, disabled && styles.disabled)}
                {...listeners}
              >
                <MdDragIndicator />
              </div>

              <div className={styles.title}>{title}</div>
              {onRemove ? (
                <div
                  className={classNames(
                    styles.remove,
                    removeAlwaysVisible && styles.alwaysVisible,
                    { [styles.removeActionDisabled]: remove?.disabled }
                  )}
                >
                  {remove && remove.tooltip ? (
                    <DSTooltip label={remove.tooltip} direction="top">
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!remove?.disabled) onRemove();
                        }}
                        aria-disabled
                      />
                    </DSTooltip>
                  ) : (
                    <MdDelete
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!remove?.disabled) onRemove();
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
            {content && <div className={styles.content}>{content}</div>}
          </div>
        </li>
      );
    }
  )
);
