import React from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Toggle.module.scss";

export interface IProps {
  readonly className?: string;
  readonly onToggle?: (status?: boolean) => void;
  readonly active?: boolean;
  readonly disabled?: boolean;
}

const Toggle = ({ className, active = false, disabled = false, onToggle }: IProps) => (
  <Flex
    className={cx(styles.Toggle, className, {
      [styles.active]: active,
      [styles.disabled]: disabled,
    })}
    onClick={onToggle && !disabled ? () => onToggle(!active) : undefined}
    data-testid="toggle-container"
  >
    <Flex center className={styles.switch} data-testid="toggle-switch" />
  </Flex>
);

export { Toggle };
