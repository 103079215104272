import { withTheme } from "components/DesignSystemContext";
import { DataTable, DataTableProps } from "./DataTable";
import { type DataTableRow } from "./types";
export { ColumnType, Column, DataTableRow } from "./types";
export { DataTableProps };

export function DSDataTable<T extends DataTableRow = DataTableRow>(
  props: Omit<DataTableProps<T>, "theme">
) {
  return withTheme<DataTableProps<T>>(DataTable)(props);
}

