import { DateTime } from "luxon";

type DateParams = {
  to?: string;
  from?: string;
  locale?: string;
};

const startOfToday = () => DateTime.now().startOf("day").toISO();

export const ToStringFormat = (luxonDate: DateTime, format: string): string => {
  return luxonDate.toFormat(format).toLocaleString();
};

export const ToStringLiteralFormat = (
  luxonDate: DateTime,
  format: string,
  locale = "fr"
): string => {
  return luxonDate.setLocale(locale).toFormat(format).toLocaleString();
};

export const ToISOShort = (luxonDate: DateTime): string => {
  return luxonDate.toFormat("yyyy-MM-dd").toLocaleString();
};

export const ToYear = (luxonDate: DateTime): string => {
  return luxonDate.toFormat("yyyy");
};

export const getFirstDayOfYear = (yearString: string): DateTime => {
  const luxonDate = DateTime.fromFormat(yearString, "yyyy");
  return luxonDate.startOf("year");
};

export const DifferenceInDays = ({ from = startOfToday(), to = startOfToday() }: DateParams) => {
  const fromDateTime = DateTime.fromISO(from);
  const toDateTime = DateTime.fromISO(to);

  return Math.floor(fromDateTime.diff(toDateTime, "days").days);
};

export const ToRelative = ({
  from = startOfToday(),
  to = startOfToday(),
  locale = "fr",
}: DateParams) => {
  const fromDateTime = DateTime.fromISO(from);

  return fromDateTime
    .setLocale(locale)
    .minus({ days: DifferenceInDays({ to }) })
    .toRelative();
};

export const getDayMonthYearFormat = (locale = "fr") => {
  switch (locale) {
    default:
      return "dd/MM/yyyy";
  }
}
export const getDateTimeFormat = (locale = "fr") => {
  switch (locale) {
    default:
      return "dd/MM/yyyy à HH:mm:ss";
  }
};

export const GetLiteralMonthFormat = (locale = "fr") => {
  switch (locale) {
    default:
      return "d LLLL yyyy";
  }
};

export const GetExcelFormat = (locale = "fr") => {
  switch (locale) {
    default: // fr
      return "DD/MM/YYYY";
  }
};

// Luxon format
export const GetExcelDateTimeFormat = (locale = "fr") => {
  switch (locale) {
    default: // fr
      return "dd/MM/yyyy";
  }
};

export const GetShortISODateTimeFormat = () => "yyyy-MM-dd'T'HH:mm:ss";
export const GetShortISODateFormat = () => "yyyy-MM-dd";

export const toStringLiteralFormatFromUtc = (dateISOString: string, format = "dd/MM/yyyy") => {
  const dt = DateTime.fromISO(dateISOString).setZone("utc");
  if (dt.isValid) {
    return dt.toFormat(format);
  }
  return "";
};

export const getUTCDateFromLocalDateTime = (dateTime: Date) => {
  return new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()));
};
