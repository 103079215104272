import React, { useRef, useMemo, useCallback, useState, useEffect } from "react";
import { useClickAway, useToggle } from "react-use";
import { isEmpty } from "lodash";
import cx from "classnames";

import { CheckBoxRH } from "../CheckBox_RH";
import { Checkbox as CheckboxCollab } from "../Checkbox";
import { Flex } from "../../components/Flex";
import { ChevronUp, ChevronDown } from "../Icon";
import { TextInput } from "../TextInput";
import { SearchInput } from "../SearchInput";

import styles from "./DropDownCheckbox.module.scss";

interface Labels {
  readonly overrideFirstItem?: string;
  readonly itemSingular: string;
  readonly itemPlural: string;
  readonly allItemsSelected?: string;
  readonly noItemSelected: string;
  readonly searchPlaceholder?: string;
  readonly noItemFound?: string;
}

interface Item<T> {
  readonly label: string;
  readonly value: T;
  readonly isSelected: boolean | "intermediate";
}

export interface Props<T extends Item<any>> {
  readonly "aria-label"?: string;
  readonly className?: string;
  readonly items: Array<T>;
  readonly onChange: (items: Array<T>) => void;
  readonly labels: Labels;
  readonly canFilter?: boolean;
  readonly canSelectAll?: boolean;
  readonly theme?: "rh" | "collab";
  readonly onlyOne?: boolean;
  readonly searchAutoFocus?: boolean;
  readonly searchImage?: string;
  readonly onSearch?: (query: string) => Promise<T[]>;
  readonly id?: string;
}

export function DropDownCheckbox<T extends Item<any>>({
  "aria-label": ariaLabel,
  className,
  items,
  onChange,
  labels,
  canFilter = true,
  canSelectAll = true,
  theme = "rh",
  searchAutoFocus,
  searchImage,
  onSearch,
  onlyOne = false,
}: Props<T>) {
  const self = useRef<HTMLDivElement>(null);
  const [isOpen, toggleOpenState] = useToggle(false);
  const [filteredItems, setFilteredItems] = useState<T[]>(items);

  useClickAway(self, () => {
    if (isOpen) {
      setSearch("");
      toggleOpenState(false);
    }
  });

  const isAllActive = useMemo(() => !onSearch && items.every((item) => item.isSelected), [items]);

  const title = useMemo(() => {
    if (!items) {
      return undefined;
    }

    if (isAllActive && canSelectAll) {
      return labels.allItemsSelected;
    }

    const [firstSelectedItem, ...rest] = items.filter((a) => a.isSelected);

    if (!firstSelectedItem) {
      return labels.noItemSelected;
    }

    return (
      labels.overrideFirstItem ||
      `${firstSelectedItem.label} ${
        rest?.length
          ? ` + ${rest.length} ${rest.length === 1 ? labels.itemSingular : labels.itemPlural}`
          : ""
      }`
    );
  }, [items, isAllActive, labels]);

  const CheckBox = useMemo(() => {
    return theme === "rh" ? CheckBoxRH : CheckboxCollab;
  }, [theme]);

  const SearchBox = useMemo(() => {
    return theme === "rh" ? TextInput : SearchInput;
  }, [theme]);

  const onSelect = useCallback(
    (value: T) => {
      if (onlyOne) {
        onChange(
          items.map((i) => {
            if (i.value === value) {
              return { ...i, isSelected: !i.isSelected };
            } else {
              return { ...i, isSelected: false };
            }
          })
        );
      } else {
        const updatedItems = [
          ...items,
          ...(items.find((item) => item?.value === value)
            ? []
            : [filteredItems.find((item) => item?.value === value) as T]), // external search
        ];
        onChange(
          updatedItems.map((i) => {
            if (i?.value === value) {
              return { ...i, isSelected: !i.isSelected };
            } else {
              return i;
            }
          })
        );
      }
    },
    [items, filteredItems]
  );

  const onSelectAll = useCallback(
    (_value) => {
      onChange(items.map((i) => ({ ...i, isSelected: !isAllActive })));
    },
    [items]
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!search) {
      setFilteredItems(items);
      return;
    }
    if (onSearch) {
      onSearch(search).then((results) => setFilteredItems(results));
      return;
    }
    setFilteredItems(
      items.filter((item) => item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    );
  }, [items, search]);

  return (
    <div ref={self} className={cx(styles.DropDownCheckbox, className)}>
      <Flex
        aria-label={`${isOpen ? "fermer" : "ouvrir"}-${ariaLabel || "liste"}`}
        className={styles.activeContainer}
        role="button"
        onClick={toggleOpenState}
      >
        <h2 className={styles.title}>{title}</h2>
        {isOpen ? <ChevronUp size="1.5rem" /> : <ChevronDown size="1.5rem" />}
      </Flex>
      {isOpen && (
        <div className={styles.DropDownContainer}>
          {canFilter && (
            <SearchBox
              autoFocus={searchAutoFocus}
              className={styles.searchInput}
              value={search}
              onChange={setSearch}
              placeholder={labels.searchPlaceholder ?? "Recherche..."}
            />
          )}
          {canSelectAll && !search && (
            <div className={styles.selectAll}>
              <CheckBox
                checked={isAllActive}
                onClick={onSelectAll}
                label={labels.allItemsSelected}
                value="all"
              />
            </div>
          )}
          {filteredItems.length === 0 && !isEmpty(search) && (
            <p className={styles.empty}>
              {searchImage && <img src={searchImage} alt="empty" />}
              {labels.noItemFound ?? "Aucun résultat trouvé, veuillez modifier votre recherche."}
            </p>
          )}
          {filteredItems.map((item, index) => (
            <CheckBox
              key={index}
              className={cx(styles.dropDownLine, {
                [styles.collab]: theme === "collab",
              })}
              checked={item.isSelected}
              onClick={onSelect}
              aria-label={`${item.isSelected ? "deselectionner" : "selectionner"}-${item.label}`}
              label={item.label}
              value={item.value}
            />
          ))}
        </div>
      )}
    </div>
  );
}
