import React, { useCallback } from "react";
import cx from "classnames";

import { Check_RH } from "../Icon";
import { type Props } from "../Checkbox";

import styles from "./CheckBox_RHStyles.module.scss";

export function CheckBox<T>({
  label,
  value,
  onClick,
  checked,
  readOnly,
  className,
  disabled = false,
  "aria-label": ariaLabel,
}: Props<T>): JSX.Element {
  const toogleCheckbox = useCallback((): void => {
    if (onClick && !readOnly && !disabled) {
      return onClick(value);
    }
  }, [onClick, readOnly, value]);

  return (
    <div
      className={cx(styles.containerCheckbox, className)}
      onClick={toogleCheckbox}
      aria-label={ariaLabel}
    >
      <div
        className={cx(styles.checkbox, {
          [styles.selected]: !!checked,
          [styles.disabled]: disabled,
        })}
      >
        <div>{checked === true && <Check_RH width={8} />}</div>
      </div>
      <div>
        <span className={styles.label}>{label || value}</span>
      </div>
    </div>
  );
}
