import { useEffect, useState } from "react";
import { useInterval, useMount } from "react-use";
import { useResize } from "../../../hooks/useResize";

export function useCellWidth(selector: string, key?: any) {
  const [cellWidth, setCellWidth] = useState<number>();
  const [cell, setCell] = useState<HTMLElement | null>();

  useMount(() => {
    const cellElement: HTMLElement | null = document.querySelector(selector);
    if (cellElement?.offsetWidth) {
      setCell(cellElement);
    }
  });

  // waiting for cell to be ready if necessary
  useInterval(
    () => {
      const cellElement: HTMLElement | null = document.querySelector(selector);
      if (cellElement?.offsetWidth) {
        setCell(cellElement);
      }
    },
    !cell ? 250 : 1000 + Math.random() * 1000
  );

  useEffect(() => {
    setCell(null); // recompute html element
  }, [key]);

  useResize(() => {
    if (cell?.offsetWidth) {
      setCellWidth(cell.offsetWidth);
    }
  }, cell);

  return cellWidth;
}
