import React, { useCallback } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { Checkbox, type CheckboxChoice } from "../Checkbox";
import styles from "./Checkboxes.module.scss";

interface Props<T = any> {
  readonly className?: string;
  readonly choices: CheckboxChoice<T>[];
  readonly selected?: T[];
  readonly onChange?: (values: string[]) => void;
  readonly readOnly?: boolean;
  readonly errored?: boolean;
  readonly disabled?: boolean;
}

const Checkboxes = ({
  className,
  choices,
  onChange,
  readOnly,
  errored,
  disabled,
  selected = [],
}: Props) => {
  const handleChange = useCallback(
    (value: any): void => {
      const nextSelected = selected.includes(value)
        ? selected.filter((v) => value !== v)
        : selected.concat(value);

      if (onChange && !readOnly) {
        return onChange(nextSelected);
      }
    },
    [selected, onChange, readOnly]
  );

  return (
    <Flex
      className={cx(styles.Checkboxes, className)}
      disabled={disabled}
      data-testid="checkboxes-container"
      column
      wrap
    >
      {choices.map(({ label, value }, index) => (
        <Checkbox
          key={index}
          className={styles.item}
          label={label}
          value={value}
          checked={selected.includes(value)}
          onClick={handleChange}
          errored={errored}
          readOnly={readOnly}
        />
      ))}
    </Flex>
  );
};

export { Checkboxes };
