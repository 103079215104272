import React, { useState } from "react";
import { isEmpty } from "lodash";
import cx from "classnames";

import { type Themes } from "components/commonProps";
import { type DataTableProps } from "../DataTable";
import { type DataTableRow } from "../types";
import {
  Dropdown as DSDropdown,
  DropdownButton,
  type DropdownProps,
} from "components/Dropdown/Dropdown";

import styles from "../DataTable.module.scss";
import { MoreVert } from "components/MaterialIcons";

export function RowActions<R extends DataTableRow>({
  theme,
  actions,
  row,
  visible,
  mode = "large",
  hasScrolled,
  tooltip,
}: {
  theme: Themes;
  row: R;
  visible: boolean;
  actions: (row: R) => DropdownProps["children"];
  mode: DataTableProps<R>["mode"];
  hasScrolled: boolean;
  tooltip?: string;
}) {
  const [isToggleOpened, setToggleOpened] = useState(false);
  const renderAction = actions(row);

  return (
    <td
      key={row.id}
      className={cx(styles.action, styles[mode], {
        [styles.invisible]: !visible,
        [styles.toggleOpened]: isToggleOpened,
        [styles.scroll]: hasScrolled,
      })}
      data-row={row.id}
      onClick={(e) => e.stopPropagation()}
    >
      <DSDropdown
        theme={theme}
        onToggleDropdown={setToggleOpened}
        buttonSize={mode === "large" ? "M" : "S"}
        disabled={isEmpty(renderAction)}
        button={
          <DropdownButton
            iconOnly
            icon={<MoreVert />}
            buttonSize={mode === "large" ? "M" : "S"}
            theme={theme}
            type="button"
            tooltip={isEmpty(renderAction) ? tooltip : undefined}
          />
        }
      >
        {renderAction}
      </DSDropdown>
    </td>
  );
}
