import React, { type HTMLAttributes } from "react";
import cx from "classnames";

import { type Themes } from "components/commonProps";

import styles from "./ProgressBar.module.scss";
import { ProgressBarList } from "./ProgressBarList";
import { ProgressBarBlock } from "./ProgressBarBlock";
import { ProgressBarSegmented } from "./ProgressBarSegmented";

export enum COLORS {
  blue = "blue",
  yellow = "yellow",
  red = "red",
  green = "green",
  pink = "pink",
  orange = "orange",
  grey = "grey",
  success = "success",
  successLight = "successLight",
  transparent = "transparent",
  neutral = "neutral",
}

export enum TypeProgressBar {
  PERCENTAGE = "percentage",
  RAW = "raw",
}

export enum ValueLayoutProgressBar {
  VALUE = "value",
  PERCENTAGE = "percentage",
  VALUE_PERCENTAGE = "value_percentage",
}

export type ProgressBarProps = {
  readonly data: ProgressBarData[];
  readonly max?: number;
  readonly type: TypeProgressBar.PERCENTAGE | TypeProgressBar.RAW;
  readonly valueLayout?:
    | ValueLayoutProgressBar.PERCENTAGE
    | ValueLayoutProgressBar.VALUE
    | ValueLayoutProgressBar.VALUE_PERCENTAGE;
  readonly layout?: "block" | "list" | "segmented";
  readonly legendsLayout?: "grid" | "column";
  readonly showLegends?: boolean;
  readonly className?: string;
  readonly theme: Themes;
  readonly darkMode?: boolean;
  readonly border?: boolean;
  readonly thin?: boolean;
  readonly leftOversTooltip?: string;
  readonly disabled?: boolean;
  readonly labelFormatter?: (label: string, value: number) => JSX.Element | string;
  readonly valueFormatter?: (label: string, value: number) => JSX.Element | string;
};

export type ProgressBarData = {
  label?: string;
  value: number;
  component?: JSX.Element;
  color?: keyof typeof COLORS;
};

export type ProgressBarPropsWithPercent = Omit<ProgressBarProps, "data"> & {
  readonly data: Array<
    ProgressBarData & {
      containerProps?: HTMLAttributes<HTMLDivElement>;
      headerProps?: HTMLAttributes<HTMLDivElement>;
      barProps?: HTMLAttributes<HTMLDivElement>;
      percentage: number;
    }
  >;
  readonly showLegends?: boolean;
};

const PureProgressBar = ({
  layout = "list",
  data,
  className,
  leftOversTooltip,
  disabled,
  ...props
}: ProgressBarProps) => {
  const computedData = data.map((current) => {
    if (props.type === TypeProgressBar.RAW) {
      return {
        ...current,
        percentage: (current.value / (props.max || 100)) * 100,
      };
    } else {
      return {
        ...current,
        percentage: current.value,
      };
    }
  });

  if (leftOversTooltip) {
    if (props.type === TypeProgressBar.PERCENTAGE) {
      const leftOver = computedData.reduce((acc, current) => acc - current.value, 100);
      if (leftOver > 0) {
        computedData.push({
          label: leftOversTooltip,
          value: leftOver,
          percentage: leftOver,
          color: "transparent",
        });
      }
    }

    if (props.type === TypeProgressBar.RAW) {
      const totalValue = computedData.reduce((acc, current) => acc + current.value, 0);
      const leftOver = (props.max || 100) - totalValue;
      if (leftOver > 0) {
        computedData.push({
          label: leftOversTooltip,
          value: leftOver,
          percentage: (leftOver / (props.max || 100)) * 100,
          color: "transparent",
        });
      }
    }
  }

  if (disabled)
    computedData.forEach((current) => {
      current.color = "neutral";
    });

  return (
    <div
      className={cx(styles.ProgressBar, className, styles[props.theme], {
        [styles.darkMode]: props.darkMode,
      })}
    >
      {layout === "list" && <ProgressBarList {...props} data={computedData} />}
      {layout === "block" && <ProgressBarBlock {...props} data={computedData} />}
      {layout === "segmented" && <ProgressBarSegmented {...props} data={computedData} />}
    </div>
  );
};

export const ProgressBar = Object.assign(PureProgressBar, {
  Type: TypeProgressBar,
  ValueLayout: ValueLayoutProgressBar,
});
