import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Edit = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M5.5188 13.7729L14.073 5.21244C14.1612 5.1237 14.2805 5.0737 14.4049 5.0737H14.4055C14.5299 5.0737 14.6492 5.12307 14.7367 5.21119L17.7677 8.24217C17.8558 8.33029 17.9052 8.44965 17.9052 8.57339C17.9052 8.69775 17.8558 8.81712 17.7677 8.90524L9.20597 17.4607L5.5188 13.7729Z"
      fill={color}
    />
    <path
      d="M18.1037 7.69428L18.4118 7.38618C18.7899 7.00747 18.9986 6.50814 18.9986 5.98006C18.9986 5.44011 18.7886 4.93765 18.413 4.56893C17.6618 3.81025 16.3413 3.81088 15.592 4.56768L15.2839 4.87578L18.1037 7.69428Z"
      fill={color}
    />
    <path
      d="M5.26495 14.403L4.02131 18.3701C3.96944 18.5363 4.01381 18.7182 4.13755 18.8413C4.22629 18.9307 4.34628 18.9788 4.46877 18.9788C4.51564 18.9788 4.56251 18.9719 4.60938 18.9576L8.57591 17.7139L5.26495 14.403Z"
      fill={color}
    />
    <path
      d="M8.3559 9.47223C8.47589 9.47223 8.59587 9.4266 8.68712 9.33474L11.7131 6.30875C11.875 6.14752 12.0912 6.08377 12.303 6.0994L13.043 5.35884C12.403 5.01637 11.5894 5.10699 11.05 5.64569L8.02405 8.67167C7.84094 8.85478 7.84094 9.15163 8.02405 9.33474C8.11592 9.42598 8.23528 9.47223 8.3559 9.47223Z"
      fill={color}
    />
  </SVGBox>
);

export { Edit };
