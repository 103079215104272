import React from "react";
import { Button as DSButton, type DSButtonProps } from "../../Button";
import { useMediaQueries } from "../../../hooks/useMediaQueries";

import styles from "../DSModal.module.scss";

function PrimaryButton(props: DSButtonProps) {
  const { isMobile } = useMediaQueries();
  return (
    <DSButton
      className={styles.primary}
      emphasis="High"
      buttonSize={isMobile ? "S" : "M"}
      {...props}
    />
  );
}

function CancelButton(props: DSButtonProps) {
  const { isMobile } = useMediaQueries();
  return (
    <DSButton
      className={styles.cancel}
      emphasis="Low"
      buttonSize={isMobile ? "S" : "M"}
      {...props}
    />
  );
}

function OptionalButton(props: DSButtonProps) {
  return <DSButton className={styles.optional} emphasis="Low" buttonSize="S" {...props} />;
}

type CancelAndPrimaryButtons =
  | [
      React.ReactElement<typeof CancelButton>,
      React.ReactElement<typeof PrimaryButton>,
      React.ReactElement<typeof PrimaryButton>
    ]
  | [React.ReactElement<typeof CancelButton>, React.ReactElement<typeof PrimaryButton>];
type CancelAndPrimaryAndOptionalButtons =
  | [
      React.ReactElement<typeof CancelButton>,
      React.ReactElement<typeof PrimaryButton>,
      React.ReactElement<typeof OptionalButton>
    ]
  | [
      React.ReactElement<typeof CancelButton>,
      React.ReactElement<typeof PrimaryButton>,
      React.ReactElement<typeof PrimaryButton>,
      React.ReactElement<typeof OptionalButton>
    ]
  | [React.ReactElement<typeof CancelButton>];

type ModalFooterWithOptionsProps = {
  children: CancelAndPrimaryAndOptionalButtons;
};

type ModalInfoFooterProps = {
  children: React.ReactElement<typeof CancelButton>;
};

/**
 * This component is used to render a footer with three buttons, in THAT order:
 * - Optional button
 * - Cancel button
 * - Primary button
 *
 * It will look like this
 * [Optional button]__________[Cancel button]_[Primary button]
 */
export function ModalFooterWithOptions({ children }: ModalFooterWithOptionsProps) {
  return <footer>{children}</footer>;
}

/**
 * This component is used to render an empty footer
 */
export function ModalFooterEmpty() {
  return <footer></footer>;
}

type BasicModalFooter = {
  children: CancelAndPrimaryButtons;
};

/**
 * This component is used to render a footer with two buttons, in THAT order:
 * - Cancel button
 * - Primary button
 *
 * It will look like this
 * ____________[Cancel button]_[Primary button]
 *
 */
export function BasicFooter({ children }: BasicModalFooter) {
  return (
    <footer>
      <div className={styles.filler} />
      {children}
    </footer>
  );
}

export function InfoFooter({ children }: ModalInfoFooterProps) {
  return (
    <footer>
      <div className={styles.filler} />
      {children}
    </footer>
  );
}

export type ModalFooterWithOptionsType = React.ReactElement<typeof ModalFooterWithOptions>;
export type BasicModalFooterType = React.ReactElement<typeof BasicFooter>;
export type EmptyModalFooterType = React.ReactElement<typeof ModalFooterEmpty>;
export type FooterType = ModalFooterWithOptionsType | BasicModalFooterType | EmptyModalFooterType;
export const BasicFooterComp = Object.assign(BasicFooter, {
  PrimaryButton,
  CancelButton,
});
export type InfoModalFooterType = React.ReactElement<typeof InfoFooter>;
export const InfoFooterComp = Object.assign(InfoFooter, {
  CancelButton,
});

export const FooterWithOptionsComp = Object.assign(ModalFooterWithOptions, {
  PrimaryButton,
  CancelButton,
  OptionalButton,
});
