import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const ChevronLeftDouble = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M5.27067 12.1528L9.84628 16.7284C10.2072 17.0893 10.7925 17.0893 11.1534 16.7284C11.5142 16.3675 11.5142 15.7822 11.1534 15.4213L7.23169 11.4997L11.1534 7.57808C11.5142 7.2172 11.5142 6.6319 11.1534 6.27101C10.7925 5.91013 10.2072 5.91013 9.84628 6.27101L5.27067 10.8466C4.90978 11.2066 4.90978 11.7919 5.27067 12.1528ZM10.7723 12.4299L14.405 16.2585C14.77 16.6428 15.3612 16.6428 15.7262 16.2585C16.0913 15.8743 16.0913 15.251 15.7262 14.8663L12.754 11.7337L15.7262 8.60119C16.0913 8.21694 16.0913 7.5932 15.7262 7.20895C15.3612 6.8247 14.77 6.8247 14.405 7.20895L10.7723 11.0376C10.4073 11.4218 10.4073 12.0452 10.7723 12.4299Z"
      fill={color}
    />
  </SVGBox>
);

export { ChevronLeftDouble };
