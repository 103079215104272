import { DateTime, Settings } from "luxon";
import { isNil, startCase } from "lodash";

import {
  type AvailableLangCodes,
  type DateHoursTranslations,
  type DateRangeInfos,
  type SessionTranslationFormat,
  type TimeFormat,
  type TimePair,
} from "./types";

const translations: DateHoursTranslations = {
  "fr-FR": {
    dateFormat: "dd/MM/yyyy",
    hourFormat: "H:mm",
    dates: {
      from: "du",
      to: "au",
      on: "le",
    },
    hours: {
      from: "de",
      to: "à",
      allDay: "(toute la journée)",
    },
  },
  "en-US": {
    dateFormat: "MM/dd/yyyy",
    hourFormat: "H:mm",
    dates: {
      from: "from",
      to: "to",
      on: "on",
    },
    hours: {
      from: "from",
      to: "to",
      allDay: "(all day)",
    },
  },
  "de-DE": {
    dateFormat: "MM.dd.yyyy",
    hourFormat: "H:mm",
    dates: {
      from: "vom",
      to: "bis zum",
      on: "am",
    },
    hours: {
      from: "von",
      to: "bis",
      allDay: "(ganztägig)",
    },
  },
};

const defaultTimeFormat = { date: "dd/MM/yyyy", hour: "HH:mm" };

function toTimePairs(intervals: Array<string>): Array<TimePair> {
  const parsedIntervals = intervals.map((interval) => interval.split("/"));

  return parsedIntervals.map(
    (interval) =>
      [
        new Date(interval[0]),
        isNil(interval[1]) || interval[1] === "undefined" ? null : new Date(interval[1]),
      ] as TimePair
  );
}

function parseDateRange(
  timePair: TimePair,
  timeFormat: TimeFormat = defaultTimeFormat,
  locale: AvailableLangCodes = "fr-FR"
): DateRangeInfos {
  const moments = timePair
    .map((date) => (date ? DateTime.fromJSDate(date) : null))
    .filter((date) => date !== null) as DateTime[];

  const [startDate, endDate] = moments.map((date) => date.toFormat(timeFormat.date, { locale }));

  const days = !endDate || startDate === endDate ? [startDate] : [startDate, endDate];
  if (!endDate) return { days };

  const hours = moments.map((date) => date.toFormat(timeFormat.hour, { locale }));
  if (hours[0] === hours[1]) return { days };
  return { days, hours };
}

function getTranslationsFromLangCode(langCode: AvailableLangCodes): SessionTranslationFormat {
  const trad = translations[langCode] ?? translations["fr-FR"];

  const fromDate = startCase(trad.dates.from);
  const toDate = trad.dates.to;
  const onDate = startCase(trad.dates.on);

  const fromHour = trad.hours.from;
  const toHour = trad.hours.to;
  const allDayHour = trad.hours.allDay;

  return {
    dateFormat: trad.dateFormat,
    hourFormat: trad.hourFormat,
    fromDate,
    toDate,
    onDate,
    fromHour,
    toHour,
    allDayHour,
  };
}

function makeTextForInterval(
  interval: TimePair,
  translations: SessionTranslationFormat,
  langCode: AvailableLangCodes
) {
  const timeFormat = { date: translations.dateFormat, hour: translations.hourFormat };
  const { days, hours } = parseDateRange(interval, timeFormat, langCode);

  const dateInfos = days[1]
    ? `${translations.fromDate} ${days[0]} ${translations.toDate} ${days[1]}`
    : `${translations.onDate} ${days[0]}`;

  const hourInfos = hours
    ? ` ${translations.fromHour} ${hours[0]} ${translations.toHour} ${hours[1]}`
    : "";
    
  return dateInfos + hourInfos;
}

// We need to change interval to TimePair format:
// Intervals is an array of string looking like this [ ["12-03-2021/12-03-2021"], ["12-03-2021/12-03-2021"] ...]
// To keep a logical structure we transform this interval into a TimePair
// wich is the use to define the timespan of a session
function convertForIntraSessions(langCode: AvailableLangCodes, intervals: Array<string>): string[] {
  const translations = getTranslationsFromLangCode(langCode);
  const timePairs = toTimePairs(intervals);

  return timePairs.map((pair) => makeTextForInterval(pair, translations, langCode));
}

function setTimezoneAndLocale(langCode = "fr", timezone = "Europe/Paris") {
  Settings.defaultLocale = langCode;
  Settings.defaultZone = timezone;
}

export default { convertForIntraSessions, toTimePairs, setTimezoneAndLocale };
