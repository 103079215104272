import React, { type ChangeEvent, type FocusEventHandler } from "react";

import { BaseInput, type BaseInputProps, type CommonInputProps } from "./BaseInput";
import { withTheme } from "components/DesignSystemContext";

export type NumberInputProps = Omit<CommonInputProps, "onChange"> &
  Pick<
    BaseInputProps,
    // Number input specific props
    "step" | "min" | "max"
  > & {
    defaultValue?: number;
    onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
  };

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => {
  return (
    <BaseInput
      {...props}
      type="number"
      ref={ref}
      onChange={(e) => props.onChange?.(e.target.value, e)}
      onBlur={props.onBlur}
    />
  );
});

export const ThemedNumberInput = withTheme<NumberInputProps>(NumberInput);
