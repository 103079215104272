import { useLocation } from "react-router-dom";

const AuthPaths = {
  UpdatePassword: "/connexion/modification-mot-de-passe",
  CreatePassword: "/connexion/creation",
  ResetPassword: "/connexion/reinitialisation",
  Signin: "/connexion",
  DEPRECATED_LOGIN: "/connexion/authentification",
} as const;

type TAuthPaths = typeof AuthPaths;

export function useAuthPaths() {
  return AuthPaths;
}

export function useIsInPath(path: TAuthPaths[keyof TAuthPaths]) {
  const location = useLocation();
  return location.pathname.startsWith(path);
}
