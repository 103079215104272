import { type DataGridProProps, type GridApiPro, type GridColDef, type GridEventListener } from "@mui/x-data-grid-pro";
import { type ListUtils } from "@skillup/shared-utils";
import { type Themes } from "components/commonProps";
import { type MutableRefObject } from "react";

const PAGE_SIZE_OPTIONS = [20, 50, 100] as const;

export { PAGE_SIZE_OPTIONS };

export type OnFilterHandler = (column: GridColDef) => void;
export type OnSortHandler = (field: string, direction?: "ASC" | "DESC") => void;
export type OnPageChangeHandler = (page: number, pageSize: number) => void;

export interface DataGridProps
  extends Omit<DataGridProProps, "rowHeight" | "columnHeaderHeight" | "theme"> {
  editable?: boolean;
  entityName?: string;
  apiRef?: MutableRefObject<GridApiPro>;
  theme: Themes;
  translationPrefix?: string;
  toolbarButtons?: JSX.Element[];
  toolbarActionItems?: JSX.Element[];
  disableToolbar?: boolean;
  onFilter?: OnFilterHandler;
  onSort?: OnSortHandler;
  sorting?: ListUtils.SortingProperties;
  onPageChange?: OnPageChangeHandler;
  onRowClick?: GridEventListener<"rowClick">;
  isError?: boolean;
  emptyOverlay?: {
    text?: string | string[];
    button?: {
      label: string;
      callback: () => void;
    };
  };
  errorOverlay?: {
    text?: string | string[];
    button?: {
      label: string;
      callback: () => void;
    };
  };
}
