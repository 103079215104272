import { useState } from "react";

export function generateSidePanelContext() {
  return function useSidePanel(initialValue = false) {
    const [isOpen, setOpen] = useState<boolean>(initialValue);

    const openSidePanel = () => setOpen(true);
    const closeSidePanel = () => setOpen(false);

    return {
      isOpen,
      openSidePanel,
      closeSidePanel,
    };
  };
}
