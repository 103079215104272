import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SignError = ({ color = colors.error, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M23,0H9L0,9v14l9,9h14l9-9V9L23,0z M18,24h-4v-4h4V24z M14,18V9h4v9H14z" fill={color} />
  </SVGBox>
);

export { SignError };
