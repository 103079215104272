import React, { type MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { Themes } from "components/commonProps";

import styles from "./NavigationSubItem.module.scss";

export interface SubItemProps {
  readonly id: string;
  readonly label: string;
  readonly to?: string;
  readonly active?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme?: Themes;
}

export interface SubItemPropsWithTheme extends Omit<SubItemProps, "theme"> {
  readonly theme: Themes;
}

const NavigationSubItem = ({
  label,
  to,
  active,
  className,
  onClick,
  darkMode,
  theme = Themes.ESPACE_RH,
}: SubItemProps) => {
  return (
    <div
      className={cx(styles.NavigationSubItem, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
    >
      <Link
        to={to ?? ""}
        onClick={onClick}
        className={cx(styles.button, { [styles.active]: active })}
      >
        {label}
      </Link>
    </div>
  );
};

export { NavigationSubItem };
