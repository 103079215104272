import React, { type ReactNode, useMemo } from "react";
import cx from "classnames";

import { type Themes } from "components/commonProps";
import { Done } from "components/MaterialIcons";
import { Tooltip } from "components/Tooltip/Tooltip";

import styles from "./Stepper.module.scss";

export type StepperProps = {
  readonly steps: {
    label: string;
    icon?: ReactNode;
    tooltip?: string;
    disabled?: boolean;
    done?: boolean;
  }[];
  readonly direction?: "horizontal" | "vertical";
  readonly doneStep?: number;
  readonly activeStep?: number;
  readonly disableNextSteps?: boolean;
  readonly onChangeStep?: (step: number) => void;

  readonly className?: string;
  readonly theme: Themes;
  readonly darkMode?: boolean;
};

const Stepper = ({
  steps,
  direction = "horizontal",
  doneStep = -1,
  activeStep = 1,
  disableNextSteps,
  onChangeStep,
  className,
  theme,
  darkMode,
}: StepperProps) => {
  const { elements } = useMemo(() => {
    return steps.reduce<{ previousValidation: boolean; elements: Array<ReactNode> }>(
      ({ previousValidation, elements }, step, index) => {
        const currentStep = index + 1;
        const isDone = step.done || currentStep < activeStep || currentStep <= doneStep;
        const isActive = currentStep === activeStep;
        const isInvalid =
          (step.disabled ||
            (disableNextSteps && currentStep > doneStep && currentStep >= activeStep)) ??
          false;

        const isDisabled = isInvalid && !previousValidation;

        return {
          previousValidation: !isInvalid,
          elements: [
            ...elements,
            <div
              className={cx(styles.step, {
                [styles.done]: isDone,
                [styles.current]: isActive,
                [styles.disabled]: isDisabled,
              })}
              tabIndex={1}
              key={index}
              aria-label={`stepper-step-${index}-${isDisabled ? "disabled" : "active"}`}
              role="presentation"
              onClick={() => !isDisabled && onChangeStep?.(index + 1)}
            >
              <div className={styles.line}>
                <Tooltip className={styles.dot} label={step.tooltip} theme={theme} direction="top">
                  {isDone && <Done />}
                  {!isDone && (step.icon || index + 1)}
                </Tooltip>
              </div>
              <label>{step.label}</label>
            </div>,
          ],
        };
      },
      { previousValidation: true, elements: new Array<ReactNode>() }
    );
  }, [steps, activeStep, doneStep, disableNextSteps, onChangeStep, theme]);

  return (
    <div
      aria-label="stepper"
      className={cx(styles.Stepper, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.horizontal]: direction === "horizontal",
        [styles.vertical]: direction === "vertical",
      })}
    >
      {elements}
    </div>
  );
};

export { Stepper };
