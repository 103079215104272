import useAuth from "hooks/useAuth";
import SkillupLogo from "components/SkillupLogo";

import styles from "./LoginViewStyles.module.scss";

const Logo = () => {
  const { logo } = useAuth();
  const { REACT_APP_PUBLIC_UPLOADS_URL } = process.env;

  if (logo) {
    return (
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${REACT_APP_PUBLIC_UPLOADS_URL}${logo})` }}
      />
    );
  }

  return <SkillupLogo className={styles.logo} />;
};

export default Logo;
