import React, { Suspense, useEffect, useState } from "react";
import { findIndex, isEmpty } from "lodash";
import cx from "classnames";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { type Themes } from "../../commonProps";
import { Loader } from "../../Loader";

import { type DashboardCard } from "./types";
import { DashboardSortableItem } from "./DashboardSortableItem";
import { DashboardErrorBoundary } from "./DashboardErrorBoundary";

import styles from "./DashboardCardView.module.scss";

function compare(a: DashboardCard, b: DashboardCard) {
  const orderA = a.order || 0;
  const orderB = b.order || 0;
  if (orderA !== orderB) {
    return orderA > orderB ? 1 : -1;
  } else {
    return 0;
  }
}
export interface DashboardCardViewProps {
  readonly cards?: Array<DashboardCard>;
  readonly theme: Themes;
  readonly darkMode?: boolean;
  readonly className?: string;
}

const DashboardCardView = ({
  cards = [],
  theme,
  darkMode = false,
  className,
}: DashboardCardViewProps): JSX.Element => {
  const [items, setItems] = useState(cards.sort(compare));
  useEffect(() => {
    setItems(cards.sort(compare));
  }, [cards]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = findIndex(items, ({ id }) => active.id === id);
        const newIndex = findIndex(items, ({ id }) => over?.id === id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  if (isEmpty(items)) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <div
            className={cx(styles.DashboardCardView, className, styles[theme], {
              [styles.darkMode]: darkMode,
            })}
          >
            {items.map((item) => (
              <DashboardErrorBoundary key={JSON.stringify(item)}>
                <DashboardSortableItem id={item.id} item={item} theme={theme} darkMode={darkMode} />
              </DashboardErrorBoundary>
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </Suspense>
  );
};

export { DashboardCardView };
