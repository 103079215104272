import { useState } from "react";

export function generateModalContext() {
  return function useModal(initialValue = false) {
    const [isOpen, setOpen] = useState<boolean>(initialValue);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    return {
      isOpen,
      openModal,
      closeModal,
    };
  };
}
