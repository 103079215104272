import React, { type CSSProperties, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./Pagination.module.scss";
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "components/MaterialIcons";
import { NumberInput } from "../../Legacy_components/NumberInput";
import { Select, MenuPlacement } from "components/Select/Select";
import { type Themes } from "index";
import { Button } from "components/Button/Button";
import { type Table } from "@tanstack/react-table";

import spacing from "../../atoms/SpacingTokens/SpacingExport.module.scss";

export enum PaginationDirection {
  ROW = "row",
  COLUMN = "column",
}
export interface Props {
  readonly className?: string;
  readonly table: Table<any>;
  readonly rowsPerPageOptions?: number[];
  readonly rowsPerPageLabel?: string;
  readonly itemsCountLabel?: string;
  readonly pageLabel?: string;
  readonly label?: string;
  readonly theme: Themes;
  readonly darkMode?: boolean;
  readonly direction?: PaginationDirection;
  readonly style?: CSSProperties;
  readonly activePage?: number;
  readonly loading?: boolean;
  readonly loadingLabel?: string;
  readonly onPageChange?: (newPage: number) => void;
  readonly onPageSizeChange?: (newPageSize: number) => void;
}

const Pagination = ({
  table,
  rowsPerPageOptions,
  rowsPerPageLabel,
  itemsCountLabel,
  pageLabel,
  direction,
  theme,
  darkMode,
  className,
  style,
  activePage,
  loading,
  loadingLabel,
  onPageChange,
  onPageSizeChange,
}: Props) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(
    activePage ? activePage + 1 : table.getState().pagination.pageIndex + 1
  );

  useEffect(() => {
    setCurrentPage(activePage ? activePage + 1 : table.getState().pagination.pageIndex + 1);
  }, [table.getState().pagination.pageIndex, activePage]);

  const rowsCount = table.getRowModel().rows.length;

  const pageRowsOptions = useMemo(
    () =>
      (rowsPerPageOptions ?? [10, 25, 50, 75, 100]).map((rowsPerPageOption) => ({
        label: `${rowsPerPageOption}`,
        value: rowsPerPageOption,
      })),
    [rowsPerPageOptions]
  );

  const itemsCountLabelParts = useMemo(
    () => itemsCountLabel?.match(/(.*)%range%(.*)%count%/) ?? [],
    [itemsCountLabel]
  );

  return (
    <div
      className={cx(styles.Pagination, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.column]: direction === PaginationDirection.COLUMN,
      })}
      style={style}
    >
      <label className={styles.PaginationLeft}>
        <span>{rowsPerPageLabel}</span>
        <Select
          className={styles.rowsPerPage}
          paginationCustomStyle={{
            width: "fit-content",
            paddingTop: "0.375rem",
            paddingBottom: "0.375rem",
            paddingLeft: spacing.skuiSpacingS,
            paddingRight: spacing.skuiSpacingXS,
          }}
          theme={theme}
          value={table.getState().pagination.pageSize}
          options={pageRowsOptions}
          menuPlacement={MenuPlacement.TOP}
          onChange={(newSize) => {
            if (newSize) {
              table.setPageSize(newSize);
              if (onPageSizeChange) onPageSizeChange(newSize);
            }
          }}
          disabled={loading}
        />
      </label>
      <div className={styles.PaginationCenter}>
        {loading &&
          (loadingLabel ||
            t("datatable.pagination.loading", {
              defaultValue: "Loading...",
            }))}
        {!loading && (
          <>
            {itemsCountLabelParts[1]}
            <strong>
              {(currentPage - 1) * table.getState().pagination.pageSize + 1}-
              {Math.min(currentPage * table.getState().pagination.pageSize, rowsCount)}
            </strong>
            {itemsCountLabelParts[2]}
            {rowsCount}
          </>
        )}
      </div>
      <div className={styles.PaginationRight}>
        <div className={styles.navigationBtns}>
          <Button
            theme={theme}
            onClick={() => {
              if (onPageChange) {
                onPageChange(0);
              } else {
                table.setPageIndex(0);
              }
            }}
            disabled={!table.getCanPreviousPage()}
            iconOnly
            icon={<FirstPage />}
            aria-label="First"
          />
          <Button
            theme={theme}
            onClick={() => {
              if (onPageChange && activePage !== undefined) {
                onPageChange(activePage - 1);
              } else {
                table.previousPage();
              }
            }}
            disabled={!table.getCanPreviousPage()}
            iconOnly
            icon={<ChevronLeft />}
            aria-label="Previous"
          />
        </div>
        <label className={styles.navigationInput}>
          {pageLabel}
          <NumberInput
            min={1}
            max={table.getPageCount()}
            value={currentPage}
            controlled
            disabled={loading || table.getPageCount() <= 1}
            onChange={(value) => {
              if (value) {
                if (onPageChange) {
                  onPageChange(value - 1);
                } else {
                  table.setPageIndex(value - 1);
                  setCurrentPage(value);
                }
              }
            }}
          />
          {` / ${loading ? "..." : table.getPageCount()}`}
        </label>
        <div className={styles.navigationBtns}>
          <Button
            theme={theme}
            onClick={() => {
              if (onPageChange && activePage !== undefined) {
                onPageChange(activePage + 1);
              } else {
                table.nextPage();
              }
            }}
            disabled={!table.getCanNextPage()}
            iconOnly
            icon={<ChevronRight />}
            aria-label="Next"
          />
          <Button
            theme={theme}
            onClick={() => {
              if (onPageChange) {
                onPageChange(table.getPageCount() - 1);
              } else {
                table.setPageIndex(table.getPageCount() - 1);
              }
            }}
            disabled={!table.getCanNextPage()}
            iconOnly
            icon={<LastPage />}
            aria-label="Last"
          />
        </div>
      </div>
    </div>
  );
};

export { Pagination };
