import { useEffect, useCallback } from "react";

declare global {
  interface Window {
    Beacon: (...args: any[]) => void;
    isBot: boolean;
  }
}

interface IOptions {
  readonly root?: boolean;
  readonly development?: boolean;
  readonly consent?: {
    readonly necessary: boolean;
    readonly functionality: boolean;
    readonly analytics: boolean;
  };
  readonly opened?: boolean;
}

const beaconIds = {
  public: "de77515a-35ca-4c92-85ab-ddf54070f803",
};

const { REACT_APP_ENV } = process.env as Record<string, string>;

const useHelpScout = ({ root = false, development = false, consent, opened }: IOptions = {}) => {
  useEffect(() => {
    const canLoadScript = (REACT_APP_ENV === "production" || development) && root && !window.isBot;
    const hasCookieConsent = consent?.functionality;
    const isAlreadyLoaded = window.Beacon !== undefined;
    if (!canLoadScript || !hasCookieConsent || isAlreadyLoaded) {
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.text = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
    script.nonce = "792c2dbcfbef5";
    document.body.appendChild(script);

    // Previous script should ensure that window.Beacon is defined
    if (window.Beacon) {
      window.Beacon("init", beaconIds.public);
      if (opened) {
        window.Beacon("open");
      }
    }
  }, [development, root, consent, opened]);

  const open = useCallback(() => {
    if (window.Beacon) {
      window.Beacon("open");
    } else {
      console.error("Cannot open HelpScout Beacon");
    }
  }, []);

  // const beaconId = useMemo(() => {
  //   return beaconIds.public;
  // }, []);

  // useEffect(() => {
  //   if (!root) return;

  //   if (window.Beacon) {
  //     window.Beacon("destroy");
  //     window.Beacon("init", beaconId);

  //     if (opened) {
  //       open();
  //     }
  //   }

  //   return () => {
  //     // Remove helpScout scripts on cleanup
  //     if (window.Beacon) {
  //       window.Beacon("destroy");
  //     }
  //   };
  // }, [beaconId, opened, open, root]);

  return { open };
};

export default useHelpScout;
