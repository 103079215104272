import React, { useRef } from "react";

import { useScrollPosition } from "../../hooks/useScrollPosition";

import styles from "./DSSidePanel.module.scss";

import cx from "classnames";
import { SidePanelAlert } from "./SidePanelAlert";

export function SidePanelContent({ alert, children, debug }: SidePanelContentProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollPosition, handleScroll } = useScrollPosition(scrollRef.current, "vertical");

  return (
    <div
      className={cx(styles.content, {
        [styles.scrollTop]: ["end", "middle"].includes(scrollPosition),
        [styles.scrollBottom]: ["start", "middle"].includes(scrollPosition),
      })}
      id="sidepanel-content"
    >
      <div className={styles.scroller} onScroll={handleScroll} ref={scrollRef}>
        <div className={cx({ [styles.debug]: debug })}>
          {alert && <SidePanelAlert message={alert.message} type={alert.type} />}
          <div className={styles.contentWrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export type SidePanelContentProps = {
  children: React.ReactNode;
  debug?: boolean;
  alert?:
    | {
        type: "success" | "error" | "warning" | "info";
        message: string;
      }
    | undefined;
};

export type SidePanelContentType = React.ReactElement<typeof SidePanelContent>;
