import { type UIEvent, useEffect, useState } from "react";
import { useIsOverflow } from "./useIsOverflow";

export function useScrollPosition(
  htmlElement: HTMLElement | null,
  direction: "horizontal" | "vertical" = "horizontal"
) {
  const isOverflow = useIsOverflow(htmlElement, direction);
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "middle" | "none">(
    isOverflow ? "start" : "none"
  );

  useEffect(() => {
    setScrollPosition(isOverflow ? "start" : "none");
  }, [isOverflow]);

  const handleScroll = (event: UIEvent) => {
    const elt = event.currentTarget;
    if (elt) {
      if (
        (direction === "vertical" && elt.scrollTop === 0) ||
        (direction === "horizontal" && elt.scrollLeft === 0)
      ) {
        setScrollPosition("start");
      } else if (
        (direction === "vertical" && elt.scrollTop >= elt.scrollHeight - elt.clientHeight - 1) ||
        (direction === "horizontal" && elt.scrollLeft >= elt.scrollWidth - elt.clientWidth - 1)
      ) {
        setScrollPosition("end");
      } else {
        setScrollPosition("middle");
      }
    }
  };

  return {
    scrollPosition,
    handleScroll,
  };
}
