import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Bookmark = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M14.4286 4H6.57143C5.70714 4 5 4.70714 5 5.57143V18.1429L10.5 15.7857L16 18.1429V5.57143C16 4.70714 15.2929 4 14.4286 4Z"
      fill={color}
    />
  </SVGBox>
);

export { Bookmark };
