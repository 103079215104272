export const colors = {
  primaryBlue: "#0088c6",
  secondaryBlue: "#5fc3d7",
  primaryOrange: "#ff974a",
  secondaryOrange: "#ffb229",
  secondaryGreen: "#0eaf85",
  primaryYellow: "#fdbe00",
  secondaryRed: "#ff6760",
  secondaryViolet: "#2668f6",
  secondaryBlueCpf: "#164194",
  statusOrange: "#fef8e7",
  statusGreen: "#eeffed",
  statusRed: "#feece7",
  statusBlue: "#cce7f4",
  statusViolet: "#eff4ff",
  modalityIndividuel: "#eec751",
  modalityDigitalLearning: "#9bbfc4",
  modalityBlendedLearning: "#aeb261",
  modalityPresentiel: "#5b96ce",
  avatarBackground: "#e6f3fe",
  avatarInitials: "#0683f9",
  bwBlack: "#192f4b",
  slate: "#535d6d",
  paleLilac: "#eaeaf1",
  blueyGrey: "#9aa7b8",
  lightGrey: "#fafafa",
  hoverRed: "#ca433d",
  secondaryYellow: "#f4cc0c",
  error: "#de4343",
  errorL: "#f9a1a1",
  mainColorL: "#f5f8fc",
  lightblue: "#2d74f6",
  secondaryBlueOpacity: "#e1f0f5",
  yellowWarning: "#ffb229",
  $blueDarker: "#4490A7",
  $blueLighter: "#CEEDF3",
  $greyLighter: "#f6f7f8",
  $yellowLighter: "#FEEDB4",
  $yellowDarker: "#B28600",
  $orangeBase: "#ff6600",
  lightGreyscale: "#e5e8eb",
  darkGreyscale: "#1a304d",
  decorationYellow: "#ffbb00",
};
