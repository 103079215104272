/**
 * Helps to chunk a large array into smaller chunks and process them in batches
 * Usefull when you want to batch processes but not overload the receiver of the actions (neo4j server for example)
 *
 * Example of usage:
 *
 * const results = await chunkBatchActions([item1, item2, item3], (item) => {
 *  return doSomething(item);
 * });
 *
 * @param items List of items to process
 * @param mapper Function that will receive each item and return a Promise
 * @param chunkSize Size of batch to process, default to 10
 * @returns a Promise that will resolve to an array of the results of the mapper function
 */
export const chunkBatchActions = async <
  ItemType,
  MapperFunction extends (item: ItemType, index: number) => Promise<any>,
  MapperResultType extends Awaited<ReturnType<MapperFunction>>
>(
  items: ItemType[],
  mapper: MapperFunction,
  chunkSize = 10
): Promise<MapperResultType[]> => {
  // We copy the array to mutating the args passed down to us
  // Because 'splice' mutate the array to delete N items form it and returns the deleted items
  // So we apply splice on a copy instead of the parameter
  const itemsCopy = [...items];
  let currentBatch = itemsCopy.splice(0, chunkSize);
  const responseBatches: MapperResultType[][] = [];

  while (currentBatch.length > 0) {
    const promises = currentBatch.map(mapper);

    const responses = await Promise.all(promises);

    responseBatches.push(responses);

    currentBatch = itemsCopy.splice(0, chunkSize);
  }

  return responseBatches.flat();
};
