// Deprecated - Replace by Select component, this should be the default behavior of the select component

import React from "react";
import cx from "classnames";

import styles from "./Autocomplete.module.scss";
import { Select, type SelectPropsWithoutTheme as SelectProps } from "../Select/Select";
import { useEffect } from "react";
import { useState } from "react";
import { type Themes } from "components/commonProps";
import { useMemo } from "react";

// Autocomplete componentSelect
// TODO improve it, it currently uses Select component but it's a bit hacky

const useDebounce = (value: string, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    if (!delay) {
      setDebouncedValue(value);
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);
  return debouncedValue;
};

export type AutocompletePropsWithoutTheme<T> = SelectProps<T> & {
  query: (search: string) => Promise<SelectProps<T>["options"]>;
  maxResults?: number;
  debounce?: number;
  autoFocus?: boolean;
};
export type AutocompleteProps<T> = AutocompletePropsWithoutTheme<T> & {
  theme: Themes;
};
function Autocomplete<T>({
  query,
  debounce,
  className,
  maxResults,
  autoFocus,
  ...rest
}: AutocompleteProps<T>) {
  const [options, setOptions] = useState<SelectProps<T>["options"]>([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, debounce);

  useEffect(() => {
    query(debouncedSearch).then(setOptions);
  }, [debouncedSearch]);

  const displayedOptions = useMemo(() => {
    if (maxResults == null) return options;
    return options?.slice(0, maxResults);
  }, [options, maxResults]);

  return (
    <Select
      className={cx(className, styles.Autocomplete)}
      options={displayedOptions}
      onInputChange={setSearch}
      autoFocus={autoFocus}
      {...rest}
    />
  );
}

export { Autocomplete };
