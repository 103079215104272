import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "lodash";

export type TargetType = "API" | "PORTAL" | "AUTH" | undefined;

export interface IConfig extends AxiosRequestConfig {
  target?: TargetType;
  authentificationToken?: string | null;
  authentificationOrganization?: string | null;
  responseType?: "arraybuffer" | "blob" | "document" | "json" | "text";
}

const { REACT_APP_PUBLIC_API_ROOT, REACT_APP_PORTAL_API_ROOT, REACT_APP_AUTH_API_ROOT } =
  process.env;

const baseUrls = {
  API: `${REACT_APP_PUBLIC_API_ROOT}/v1`,
  PORTAL: `${REACT_APP_PORTAL_API_ROOT}/v1`,
  AUTH: `${REACT_APP_AUTH_API_ROOT}/v1`,
};

const request = async <T>({
  target = "API",
  authentificationToken = localStorage.getItem("X-Auth-Token") ??
    sessionStorage.getItem("X-Auth-Token"),
  responseType = "json",
  ...config
}: IConfig) => {
  return axios.request<any, AxiosResponse<T>>({
    baseURL: baseUrls[target],
    responseType,
    headers: {
      ...(responseType === "json" && {
        "Content-Type": "application/json; charset=UTF-8",
      }),
      ...(!isNil(authentificationToken) && {
        "X-Auth-Token": authentificationToken,
      }),
    },
    ...config,
    withCredentials: true,
  });
};

export default request;
