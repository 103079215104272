import { useState } from "react";
import { useResize } from "./useResize";

export function useIsOverflow(
  htmlElement: HTMLElement | null,
  direction: "horizontal" | "vertical" = "horizontal"
) {
  const [isOverflow, setIsOverflow] = useState(false);

  useResize(() => {
    const element = htmlElement;
    if (element) {
      if (direction === "horizontal") {
        setIsOverflow(element.scrollWidth > element.offsetWidth);
      } else {
        setIsOverflow(element.scrollHeight > element.offsetHeight);
      }
    }
  }, htmlElement);

  return isOverflow;
}
