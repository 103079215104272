import React from "react";
import cx from "classnames";

import { type Themes } from "../../commonProps";

import { Label, type LabelProps } from "components/Form/Label/Label";
import {
  AssistiveArea,
  type AssistiveAreaProps,
} from "components/Form/AssistiveArea/AssistiveArea";

import styles from "./FormGroupSwitch.module.scss";
import { Toggle } from "components/Switch/Toggle";

export interface FormGroupSwitchProps extends LabelProps {
  readonly assistiveText?: AssistiveAreaProps["text"];
  readonly assistiveArea?: AssistiveAreaProps["area"];
  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const FormGroupSwitch = ({
  label,
  assistiveText,
  assistiveArea,
  darkMode = false,
  theme,
  className,
  ...props
}: FormGroupSwitchProps) => {
  const [checked, setChecked] = React.useState(false);

  return (
    <div
      className={cx(styles.formGroupSwitch, className, styles[theme], {
        [styles.darkMode]: darkMode,
      })}
      aria-label={`forminput-${label}`}
      role="presentation"
      {...props}
    >
      <div className={styles.row}>
        <Toggle className={styles.toggle} onToggle={() => setChecked(!checked)} active={checked} />
        <Label className={styles.label} label={label} theme={theme} darkMode={darkMode} />
      </div>
      <AssistiveArea
        className={styles.assistiveText}
        text={assistiveText}
        area={assistiveArea}
        theme={theme}
        darkMode={darkMode}
      />
    </div>
  );
};

export { FormGroupSwitch };
