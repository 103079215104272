import React, { type ReactNode, type HTMLAttributes } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./Container.module.scss";
import { colors } from "../../assets/colors";

interface Props extends HTMLAttributes<HTMLDivElement> {
  readonly children?: ReactNode;
  readonly backgroundColor?: string;
  readonly size?: number | string;
  readonly notifications?: number;
  readonly round?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Container = ({
  className,
  style,
  children,
  backgroundColor = colors.primaryBlue,
  size = "1.5rem",
  notifications,
  round = false,
  disabled = false,
  onClick,
  ...props
}: Props) => (
  <Flex
    center
    data-testid="icon-container"
    className={cx(styles.Container, className, { [styles.round]: round })}
    style={{
      ...style,
      backgroundColor,
      width: size,
      height: size,
    }}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {notifications ? (
      <Flex center className={styles.notifications} role="badge">
        <span className={styles.count}>{notifications > 9 ? "9+" : notifications}</span>
      </Flex>
    ) : null}
    {children}
  </Flex>
);

export { Container };
