import React from "react";
import cx from "classnames";

import { COLORS, type ProgressBarPropsWithPercent } from "./ProgressBar";

// @ts-ignore
const getColorByIndex = (index: number) => COLORS[Object.keys(COLORS)[index]];

import styles from "./ProgressBar.module.scss";
import { Tooltip } from "components/Tooltip/Tooltip";

export function ProgressBarSegmented({
  data,
  legendsLayout = "grid",
  border = false,
  thin = false,
  showLegends = false,
  theme,
  darkMode,
  labelFormatter = (label: string, _: number) => label,
}: ProgressBarPropsWithPercent) {
  return (
    <section className={cx(styles.section, styles.segmented)}>
      <div
        className={cx(
          styles.bar,
          border && styles.border,
          thin && styles.thin,
          darkMode && styles.darkMode
        )}
      >
        {data.map(({ percentage, color }, index) => (
          <div
            className={cx(styles.subBar, styles[color || getColorByIndex(index)])}
            key={index}
            style={{ width: `${percentage}%` }}
          />
        ))}
        <div className={cx(styles.tooltipWrapper)}>
          {data.map(({ percentage, label, value }, index) => (
            <Tooltip
              disabled={!label}
              className={cx(styles.tooltip)}
              key={index}
              label={`${labelFormatter(label ?? "", value)} (${Math.floor(percentage)}%)`}
              style={{ width: `${percentage}%` }}
              darkMode={darkMode}
              theme={theme}
              direction="top"
            >
              &nbsp;
            </Tooltip>
          ))}
        </div>
      </div>
      {showLegends && (
        <div className={cx(styles.legends, styles[legendsLayout])}>
          {data.map(({ label, color, value }, index) => (
            <div className={styles.legend} key={index}>
              <div className={cx(styles.bullet, styles[color || getColorByIndex(index)])} />
              <label className={styles.label}>{labelFormatter(label ?? "", value)}</label>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}
