import React, { type ReactNode, useState } from "react";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import styles from "./FoldedCard.module.scss";
import * as Icon from "../Icon";
import { colors } from "assets/colors";
import { isArray, isEmpty } from "lodash";

interface Props {
  readonly className?: string;
  readonly label: string;
  readonly description: string | string[];
  readonly color: string;
  readonly width: string;
  readonly children?: ReactNode;
  readonly backgroundColor?: string;
  readonly border?: string;
}

const FoldedCard = ({
  className,
  label,
  description,
  color,
  width,
  children,
  border,
  backgroundColor,
}: Props) => {
  const [openedState, setOpenedState] = useState<boolean>(false);
  const displayItem = (item: { label: string; description: string | string[] }) => {
    return (
      <div
        className={styles.item}
        style={{ width, border: border ?? `1px solid ${colors.paleLilac}`, backgroundColor }}
      >
        <Flex row>
          {children}
          <span className={styles.itemLabel} aria-label="error-list-label">
            {item.label}
          </span>
          {!isEmpty(description) && (
            <div onClick={() => setOpenedState(!openedState)} className={styles.itemIcon}>
              {!openedState ? (
                <Icon.ChevronRight size="1.25rem" color={color} />
              ) : (
                <Icon.ChevronDown size="1.25rem" color={color} />
              )}
            </div>
          )}
        </Flex>
        {openedState && (
          <div className={styles.itemDescription} data-testid="folded-card-description">
            {isArray(description)
              ? description.map((descriptionItem) => (
                  <>
                    {descriptionItem}
                    <br />
                  </>
                ))
              : description}
          </div>
        )}
      </div>
    );
  };
  return (
    <Flex
      className={cx(styles.Tag, className)}
      style={{ width }}
      data-testid="folded-card-container"
    >
      {displayItem({ label, description })}
    </Flex>
  );
};

export { FoldedCard };
