type GenericError<T> = {
  error: T;
  errorDetail?: unknown;
};

export class ResponseError<T, TDetails = void> implements GenericError<T> {
  error: T;
  public _isSkillupCustomError = true;

  constructor(error: T, public errorDetail: TDetails) {
    // super();
    this.error = error;
  }

  toString(): string {
    if (typeof this.errorDetail === "string") {
      return `Error "${this.error}" ${this.errorDetail}`;
    }

    return `Error "${this.error}" ${JSON.stringify(this.errorDetail, null, 2)}`;
  }

  getDetails(): TDetails {
    return this.errorDetail;
  }

  isInstanceOf<TExpectedError extends ResponseError<any, any>>(
    err: new (...args: any[]) => TExpectedError
  ): this is TExpectedError {
    return isInstanceOfSkillupError(this, err);
  }
}

ResponseError.prototype._isSkillupCustomError = true;

class NotFoundError<TDetails = void> extends ResponseError<"not-found", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("not-found", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is NotFoundError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class UnknownError<TDetails = void> extends ResponseError<"unknown-error", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("unknown-error", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is UnknownError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class NotAuthorizedError<TDetails = void> extends ResponseError<"not-authorized", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("not-authorized", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is NotAuthorizedError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class InvalidParamsError<TDetails = void> extends ResponseError<"invalid-params", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("invalid-params", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is InvalidParamsError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class CreationFailedError<TDetails = void> extends ResponseError<"creation-failed", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("creation-failed", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is CreationFailedError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class UpdateFailedError<TDetails = void> extends ResponseError<"update-failed", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("update-failed", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is UpdateFailedError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class DeleteFailedError<TDetails = void> extends ResponseError<"delete-failed", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("delete-failed", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is DeleteFailedError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class BadRequestError<TDetails = void> extends ResponseError<"bad-request", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("bad-request", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is BadRequestError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class ConflictError<TDetails = void> extends ResponseError<"data-conflict", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("data-conflict", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is BadRequestError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

class TooManyRequestError<TDetails = void> extends ResponseError<"too-many-request", TDetails> {
  constructor(public errorDetail: TDetails) {
    super("too-many-request", errorDetail);
  }

  [Symbol.hasInstance](instance: any): instance is BadRequestError<TDetails> {
    return isInstanceOfSkillupError(this, instance);
  }
}

export const Errors = {
  Unknown: UnknownError,
  InvalidParams: InvalidParamsError,
  NotAuthorized: NotAuthorizedError,
  BadRequest: BadRequestError,
  Conflict: ConflictError,
  TooManyRequest: TooManyRequestError,

  // CRUD
  NotFound: NotFoundError,
  CreationFailed: CreationFailedError,
  UpdateFailed: UpdateFailedError,
  DeleteFailed: DeleteFailedError,

  Any: ResponseError,
} as const;

function isSkillupCustomError(error: any): error is GenericError<string> {
  return error !== null && Boolean(error._isSkillupCustomError);
}

function isInstanceOfSkillupError<T extends ResponseError<any, any>>(
  error: any,
  skillupErrorClass: new (...args: any[]) => T
): error is T {
  if (error instanceof skillupErrorClass) {
    return true;
  }

  const errInst = new skillupErrorClass();
  return isSkillupCustomError(error) && errInst.error === error.error;
}

/** Kinda forced to use a namespace for dynamic arguments ease of use
 *  Not bad per se, since it's a central place for all errors in the codebase.
 */
export namespace ErrorsTypes {
  export type Unknown<T = void> = UnknownError<T>;
  export type InvalidParams<T = void> = InvalidParamsError<T>;
  export type NotAuthorized<T = void> = NotAuthorizedError<T>;
  export type NotFound<T = void> = NotFoundError<T>;
  export type CreationFailed<T = void> = CreationFailedError<T>;
  export type UpdateFailed<T = void> = UpdateFailedError<T>;
  export type DeleteFailed<T = void> = DeleteFailedError<T>;
  export type BadRequest<T = void> = BadRequestError<T>;
  export type Conflict<T = void> = ConflictError<T>;
  export type TooManyRequest<T = void> = TooManyRequestError<T>;
}
