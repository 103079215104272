import { type ListUtils } from "@skillup/shared-utils";

import { withTheme } from "components/DesignSystemContext";

import { Filters as PureFilters, type Props as DSFiltersPropsWithTheme } from "./Filters";

export type { FilterRef } from "./Filters";
export type { FilterProps, FilterProp } from "./types";

export type DSFiltersProps<T extends ListUtils.FilterConfigurationMap> = Omit<
  DSFiltersPropsWithTheme<T>,
  "theme"
>;

export const DSFilters = withTheme<DSFiltersPropsWithTheme<any>>(PureFilters, true);
export { useFilters, useUrlSyncedFilters, UseFilterProp } from "./hooks";
export { useShortenedFilters, useUrlSyncedShortenedFilters } from "./shortenedHooks";
