import React from "react";

import { PlusSlim } from "../Icon";
import { RectangleTag } from "./RectangleTag";

const AdHocCampaign = () => (
  <RectangleTag
    label="Entretiens ponctuels"
    icon={<PlusSlim />}
    backgroundColor="#f5f5f5"
    textColor="#92929d"
  />
);

export { AdHocCampaign };
