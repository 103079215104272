import React from "react";
import { useToggle } from "react-use";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { Search } from "../Icon";
import { colors } from "../../assets/colors";

import styles from "./SearchInput.module.scss";

interface Props {
  readonly className?: string;
  readonly onChange: (query: string) => void;
  readonly value: string | undefined;
  readonly placeholder: string;
}

const SearchInput = ({ className, onChange, placeholder, value }: Props) => {
  const [isFocus, toggleFocus] = useToggle(false);

  return (
    <Flex className={cx(styles.SearchInput, className)}>
      <Search size="1.375rem" color={isFocus ? colors.bwBlack : colors.blueyGrey} />
      <input
        onChange={(e) => onChange(e.currentTarget?.value)}
        type="search"
        className={styles.searchValue}
        value={value}
        placeholder={placeholder}
        onFocus={() => toggleFocus(true)}
        onBlur={() => toggleFocus(false)}
      />
    </Flex>
  );
};

export { SearchInput };
