import { isNil, transform } from "lodash";
import { type Compute } from "../types";

type RemoveNullAndUndefinedKeys<T extends Record<string, any>> = Pick<
  T,
  { [Key in keyof T]: T[Key] extends undefined | null ? never : Key }[keyof T]
>;

type ReplaceNullByUndefined<T extends Record<string, any>> = {
  [Key in keyof T]: null extends T[Key] ? Exclude<T[Key], null> | undefined : T[Key];
};

type OnlyKeepsDefined<T extends Record<string, any>> = {
  [K in keyof T as undefined extends T[K] ? never : K]: T[K];
};

type OnlyKeepsUndefined<T extends Record<string, any>> = {
  [K in keyof T as undefined extends T[K] ? K : never]-?: T[K];
};

type UndefinedToOptional<T extends Record<string, any>> = Compute<
  OnlyKeepsDefined<T> & Partial<OnlyKeepsUndefined<T>>
>;

export default function compactObject<T extends Record<string, any>>(
  item: T
): UndefinedToOptional<ReplaceNullByUndefined<RemoveNullAndUndefinedKeys<T>>> {
  return transform(
    item,
    (acc, value, key) => {
      if (!isNil(value)) {
        acc[key] = value;
      }
    },
    {} as any
  );
}
