import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Bell = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M8.479 14.57h3.02a1.51 1.51 0 11-3.02 0zM10.049 4h-.06c-.626 0-1.133.507-1.133 1.133v.566a3.398 3.398 0 00-2.265 3.209v.944c0 .6-.238 1.176-.663 1.6l-.156.157c-.319.32-.403.804-.212 1.212a1.1 1.1 0 001.008.617h6.883c.59 0 1.068-.478 1.068-1.068v-.047c0-.255-.091-.501-.257-.695l-.33-.377a2.265 2.265 0 01-.546-1.473v-.87a3.398 3.398 0 00-2.265-3.21v-.501A1.176 1.176 0 0010.05 4zm-3.145.208a.755.755 0 00-.82.102 6.04 6.04 0 00-2.077 3.726.755.755 0 101.497.2A4.515 4.515 0 017.04 5.469a.755.755 0 00-.136-1.261zm9.093 3.83a6.04 6.04 0 00-2.076-3.727.755.755 0 10-.961 1.16 4.515 4.515 0 011.536 2.767.755.755 0 101.497-.2h.004z"
      fill={color}
      fillRule="nonzero"
    />
  </SVGBox>
);

export { Bell };
