import React, { useState, useMemo, useCallback, useRef, type CSSProperties, useEffect } from "react";
import cx from "classnames";
import { useClickAway } from "react-use";
import { chunk } from "lodash";

import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  lightFormat,
  type Locale,
  isSunday,
  isSaturday,
  isFriday,
  isTuesday,
  isWednesday,
  isThursday,
  isMonday,
  isWeekend,
  isSameDay,
  isBefore,
  isAfter,
  addMonths,
  subMonths,
  subYears,
  addYears,
} from "date-fns";

import { Flex } from "../../../components/Flex";
import {
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
  Container,
} from "../../Icon";

import styles from "./DateRangeCalendar.module.scss";

interface Props {
  readonly minValue?: string | null;
  readonly maxValue?: string | null;
  readonly value?: string | null;
  readonly style?: CSSProperties;
  readonly className?: string;
  readonly locale?: Locale;
  readonly onChange?: (date: Date | null) => void;
  readonly onClickAway?: () => void;
}

const DateRangeCalendar = ({
  value,
  minValue,
  maxValue,
  className,
  locale,
  onChange,
  onClickAway,
  style,
}: Props) => {
  const today = new Date();
  const self = useRef<HTMLDivElement>(null);

  const [activeMonth, setActiveMonth] = useState<Date>(value ? new Date(value) : today);
  const [date, setDate] = useState<Date | null>(value ? new Date(value) : null);
  const minDate = useMemo(() => (minValue ? new Date(minValue) : null), [minValue]);
  const maxDate = useMemo(() => (maxValue ? new Date(maxValue) : null), [maxValue]);

  const daysOfWeek = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(today, { weekStartsOn: 1 }),
        end: endOfWeek(today, { weekStartsOn: 1 }),
      }),
    [today]
  );

  const daysOfActiveMonth = useMemo(() => {
    const firstDayOfTheMonth = startOfMonth(activeMonth);
    const lastDayOfTheMonth = endOfMonth(activeMonth);
    const availableDays = eachDayOfInterval({ start: firstDayOfTheMonth, end: lastDayOfTheMonth });

    const check = [isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday];

    for (let index = 0; check[index]; index++) {
      if (check[index](firstDayOfTheMonth)) {
        return Array(index).fill(null).concat(availableDays);
      }
    }

    return availableDays;
  }, [activeMonth]);

  const handleClick = useCallback(
    (value: Date) => {
      setDate(value);
      onChange?.(value);
    },
    [date, setDate]
  );

  useClickAway(self, () => {
    if (onClickAway) {
      return onClickAway();
    }
  });

  useEffect(() => {
    setDate(value ? new Date(value) : null);
    setActiveMonth(value ? new Date(value) : today);
  }, [value]);

  return (
    <div className={cx(styles.DateRangePickerCalendar, className)} style={style} ref={self}>
      <Flex className={styles.header}>
        <Flex className={styles.displayActions}>
          <Container
            backgroundColor="transparent"
            className={styles.subBtn}
            onClick={() => setActiveMonth(subYears(activeMonth, 1))}
          >
            <ChevronLeftDouble size="1.125rem" />
          </Container>
          <Container
            backgroundColor="transparent"
            className={styles.subBtn}
            onClick={() => setActiveMonth(subMonths(activeMonth, 1))}
          >
            <ChevronLeft size="1.125rem" />
          </Container>
        </Flex>
        <span className={styles.activeMonth}>{format(activeMonth, "MMMM yyyy", { locale })}</span>
        <Flex className={styles.displayActions}>
          <Container
            backgroundColor="transparent"
            className={styles.addBtn}
            onClick={() => setActiveMonth(addMonths(activeMonth, 1))}
          >
            <ChevronRight size="1.125rem" />
          </Container>
          <Container
            backgroundColor="transparent"
            className={styles.addBtn}
            onClick={() => setActiveMonth(addYears(activeMonth, 1))}
          >
            <ChevronRightDouble size="1.125rem" />
          </Container>
        </Flex>
      </Flex>
      <Flex className={styles.line}>
        {daysOfWeek.map((day, index) => (
          <Flex center key={index} className={styles.inlineItem}>
            <span className={styles.dayLabel}>{format(day, "EEE", { locale })}</span>
          </Flex>
        ))}
      </Flex>
      <div className={styles.daysOfActiveMonth}>
        {chunk(daysOfActiveMonth, 7).map((weekDays, lineIndex) => (
          <Flex key={`line-${lineIndex}`} className={cx(styles.line, styles.days)}>
            {weekDays.map((day, dayIndex) => {
              const isSelectable =
                (!minDate || isAfter(day, minDate)) && (!maxDate || isBefore(day, maxDate));
              return (
                <Flex
                  key={`day-${dayIndex}`}
                  className={cx(styles.inlineItem, {
                    [styles.activeStartDate]: date && isSameDay(day, date),
                  })}
                  center
                >
                  <Flex
                    className={cx(styles.dayContainer, {
                      [styles.disabled]: !isSelectable,
                    })}
                    onClick={isSelectable ? () => handleClick(day) : undefined}
                    disabled={!day}
                    role="button"
                    center
                  >
                    {day && (
                      <span
                        className={cx(styles.dayNumber, { [styles.isWeekend]: isWeekend(day) })}
                      >
                        {lightFormat(day, "d")}
                      </span>
                    )}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ))}
      </div>
    </div>
  );
};

export { DateRangeCalendar };
