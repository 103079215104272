import React from "react";
import { useTranslation } from "react-i18next";

import { type GridColumnMenu, type GridColumnMenuItemProps, useGridApiContext } from "@mui/x-data-grid-pro";

import { DropdownItem } from "components/Dropdown/DropdownItem";
import { ArrowUp, ArrowDown } from "components/MaterialIcons";

import { type OnSortHandler } from "../../types";
import { type ListUtils } from "@skillup/shared-utils";

export type FilterItemProps = {
  colDef: GridColumnMenuItemProps["colDef"];
  onSort?: OnSortHandler;
  sorting?: ListUtils.SortingProperties;
  hideMenu: Parameters<typeof GridColumnMenu>[0]["hideMenu"];
};

const SortingItems = (props: FilterItemProps) => {
  const { hideMenu, colDef, onSort, sorting } = props;
  const { t } = useTranslation();

  const apiRef = useGridApiContext();

  if (colDef.sortable === false) {
    return null;
  }

  const isCurrentSortingColumn = sorting && colDef.field === sorting.property;

  const isASC = isCurrentSortingColumn && sorting!.direction === "ASC";
  const isDESC = isCurrentSortingColumn && sorting!.direction === "DESC";

  return (
    <>
      <DropdownItem
        index={0}
        style={isASC ? { fontWeight: "bold" } : undefined}
        onClick={(e) => {
          hideMenu(e);

          // Unfortunately, the menu panel keep stealing the focus
          // We need to wait for the event to finish bubbling to trigger the filter apparition/focus
          // Here we add the code to the end of the event loop
          Promise.resolve().then(() => {
            if (onSort) {
              onSort(colDef.field, "ASC");
            } else {
              apiRef.current.setSortModel([{ field: colDef.field, sort: "asc" }]);
            }
          });
        }}
        icon={<ArrowUp />}
        label={t("datagrid.columnMenu.sort.asc", {
          defaultValue: "Tri croissant",
        })}
      />
      <DropdownItem
        index={2}
        style={isDESC ? { fontWeight: "bold" } : undefined}
        onClick={(e) => {
          hideMenu(e);

          // Unfortunately, the menu panel keep stealing the focus
          // We need to wait for the event to finish bubbling to trigger the filter apparition/focus
          // Here we add the code to the end of the event loop
          Promise.resolve().then(() => {
            if (onSort) {
              onSort(colDef.field, "DESC");
            } else {
              apiRef.current.setSortModel([{ field: colDef.field, sort: "desc" }]);
            }
          });
        }}
        icon={<ArrowDown />}
        label={t("datagrid.columnMenu.sort.desc", {
          defaultValue: "Tri décroissant",
        })}
        selected={isCurrentSortingColumn && sorting!.direction === "ASC"}
      />
    </>
  );
};

export { SortingItems };
