import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const SvgCheck = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70356 8.97202C4.31108 8.58346 3.67792 8.58663 3.28936 8.97911C2.9008 9.37158 2.90397 10.0047 3.29644 10.3933L9.12179 16.1606C9.53306 16.5677 10.203 16.5418 10.5816 16.1042L18.7563 6.65425C19.1176 6.23656 19.0719 5.60505 18.6542 5.24372C18.2365 4.8824 17.605 4.92809 17.2437 5.34578L9.76881 13.9868L4.70356 8.97202Z"
      fill={color}
    />
  </SVGBox>
);

export { SvgCheck };
