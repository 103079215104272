import React, { type CSSProperties } from "react";
import { type IUser } from "@skillup/types";
import cx from "classnames";

import { Flex } from "../../components/Flex";
import { Avatar } from "../Avatar";
import { colors } from "../../assets/colors";

import styles from "./UserWithAvatar.module.scss";

interface Trainee {
  readonly firstName?: IUser["firstName"];
  readonly lastName?: IUser["lastName"];
  readonly fullName?: IUser["fullName"];
  readonly initials?: string;
  readonly role?: IUser["role"];
}

interface Props extends Trainee {
  readonly status?: string;
  readonly className?: string;
  readonly notifications?: number;
  readonly style?: CSSProperties;
  readonly loading?: boolean;
  readonly disabled?: boolean;
}

const getFirstLetters = (user: Pick<Trainee, "firstName" | "lastName">): string => {
  return (
    (user?.firstName ?? "").trim().charAt(0).toUpperCase() +
    (user?.lastName ?? "").trim().charAt(0).toUpperCase()
  );
};

const UserWithAvatar = ({
  className,
  fullName,
  firstName,
  lastName,
  initials,
  role,
  style,
  notifications,
  status,
  loading = false,
  disabled = false,
}: Props) => (
  <Flex
    className={cx(styles.UserWithAvatar, className, { [styles.loading]: loading })}
    style={style}
    data-testid="user-container"
    disabled={disabled}
    grow
  >
    <Flex className={styles.left}>
      <Avatar
        className={styles.avatar}
        label={initials ?? getFirstLetters({ firstName, lastName })}
        backgroundColor={colors.statusViolet}
        labelColor={colors.primaryBlue}
        loading={loading}
        small
      />

      <Flex column className={styles.meta} data-testid="user-meta">
        <span className={styles.fullName}>{fullName}</span>
        <span className={styles.subtitle}>{status ?? role}</span>
      </Flex>
    </Flex>

    <Flex center className={styles.right}>
      {notifications && (
        <Flex center className={styles.notifications} data-testid="notifications-badge">
          <span className={styles.count}>{notifications > 9 ? "9+" : notifications}</span>
        </Flex>
      )}
    </Flex>
  </Flex>
);

export { UserWithAvatar };
