import React from "react";

import { SVGBox, type SVGProps } from "./SVGBox";

const Check_RH = ({ ...props }: SVGProps) => {
  return (
    <SVGBox viewBox="0 0 32 32" {...props}>
      <polyline points="1,15 11,25 31,6" />
    </SVGBox>
  );
};

export { Check_RH };
