import React from "react";

import { colors } from "../../assets/colors";
import { SVGBox, type SVGProps } from "./SVGBox";

const Filter = ({ color = colors.bwBlack, size = "1rem", ...props }: SVGProps) => (
  <SVGBox size={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M17.1938 4H3.82086C3.58485 3.99955 3.36862 4.13132 3.2608 4.34115C3.15149 4.55382 3.17068 4.8099 3.31032 5.00413L8.20959 11.9058C8.21123 11.9082 8.21301 11.9105 8.21465 11.9128C8.39266 12.1532 8.48903 12.4443 8.48947 12.7434V18.3703C8.48843 18.5369 8.55387 18.6971 8.6712 18.8153C8.78869 18.9334 8.94841 19 9.11497 19C9.19959 18.9999 9.28346 18.983 9.36169 18.9505L12.1147 17.9008C12.3613 17.8254 12.525 17.5927 12.525 17.3125V12.7434C12.5255 12.4443 12.6218 12.1532 12.7997 11.9128C12.8013 11.9105 12.8031 11.9082 12.8047 11.9058L17.7042 5.00398C17.8438 4.8099 17.863 4.55396 17.7537 4.3413C17.646 4.13132 17.4296 3.99955 17.1938 4Z"
      fill={color}
    />
  </SVGBox>
);

export { Filter };
