import React, { useState, type CSSProperties, useEffect, useMemo } from "react";
import cx from "classnames";

import { format, isBefore, type Locale } from "date-fns";

import { Flex } from "../../components/Flex";

import styles from "./DateRangePicker.module.scss";
import { DateRangeInput } from "./partials/DateRangeInput";

interface Props {
  readonly labelStart?: string;
  readonly labelEnd?: string;
  readonly placeholder?: string;
  readonly period?: { startDate: string | null; endDate: string | null } | null;
  readonly styleCalendar?: CSSProperties;
  readonly className?: string;
  readonly classNameCalendar?: string;
  readonly locale?: Locale;
  readonly onChange?: (dates?: { startDate: Date | null; endDate: Date | null }) => void;
  readonly onClickAway?: () => void;
}

const DateRangePicker = ({
  period,
  labelStart,
  labelEnd,
  className,
  classNameCalendar,
  locale,
  onChange,
  styleCalendar,
}: Props) => {
  const [startDate, setStartDate] = useState<string | null>(period?.startDate || null);
  const [endDate, setEndDate] = useState<string | null>(period?.endDate || null);

  const isEndDateNotValid = useMemo(
    () =>
      (!!period?.startDate && !period?.endDate) ||
      (!!period?.endDate &&
        !!period?.startDate &&
        isBefore(new Date(period.endDate), new Date(period?.startDate))),
    [period]
  );

  useEffect(() => {
    setStartDate(period?.startDate || null);
    setEndDate(period?.endDate || null);
  }, [startDate, endDate]);

  return (
    <Flex wrap className={cx(styles.DateRangePicker, className)}>
      <DateRangeInput
        label={labelStart || "Début de la période"}
        value={startDate}
        maxValue={endDate}
        onChange={(date) => {
          setStartDate(date ? format(date, "yyyy/MM/dd") : null);
          onChange?.({ startDate: date, endDate: endDate ? new Date(endDate) : null });
        }}
        locale={locale}
        classNameCalendar={classNameCalendar}
        styleCalendar={styleCalendar}
        hasError={!period?.startDate && !!period?.endDate}
      />
      <DateRangeInput
        label={labelEnd || "Fin de la période"}
        value={endDate}
        minValue={startDate}
        onChange={(date) => {
          setEndDate(date ? format(date, "yyyy/MM/dd") : null);
          onChange?.({ startDate: startDate ? new Date(startDate) : null, endDate: date });
        }}
        locale={locale}
        classNameCalendar={classNameCalendar}
        styleCalendar={styleCalendar}
        hasError={isEndDateNotValid}
      />
    </Flex>
  );
};

export { DateRangePicker };
