import { useNavigate } from "react-router-dom";
import qs from "qs";

import Logo from "./Logo";

import styles from "./LoginViewStyles.module.scss";

interface Props {
  reason: string;
  redirect?: string;
}

const Logout = ({ reason, redirect }: Props) => {
  const navigate = useNavigate();

  const login = () => {
    const queryString = qs.stringify({
      redirect,
    });
    navigate(`/connexion/?${queryString}`);
  };

  if (reason === "inactivity") {
    return (
      <div className={styles.view}>
        <div className={styles.box}>
          <Logo />
          <h1>Session expirée</h1>
          <p>Votre session a expiré, veuillez vous reconnecter.</p>
          <div className={styles.actions}>
            <button onClick={login}>Se reconnecter</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.view}>
      <div className={styles.box}>
        <Logo />
        <h1>Déconnecté</h1>
        <p>Vous êtes maintenant déconnecté et vous pouvez fermer cette fenêtre.</p>
        <div className={styles.actions}>
          <button onClick={login}>Se reconnecter</button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
